import { faLock } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PropsWithChildren, useMemo } from 'react';
import { Capabilities, useCapabilities } from '../hooks/useCapabilities';
import Box from './content/Box';

type Props = PropsWithChildren<{
  readonly behaviour?: 'error' | 'hide';
  readonly requirements: readonly (keyof Capabilities)[];
}>;

export default function CapabilityGuard({
  behaviour = 'error',
  requirements,
  children,
}: Props) {
  const capabilities = useCapabilities();

  const enabled = useMemo(() => {
    return requirements.every((requirement) =>
      Boolean(capabilities[requirement]),
    );
  }, [capabilities, requirements]);

  if (!enabled) {
    if (behaviour === 'error') {
      return (
        <section className="flex items-center justify-center w-full h-full">
          <Box>
            <div className="flex items-center space-x-3">
              <FontAwesomeIcon icon={faLock} />
              <div>
                You do not have permission to access this functionality.
              </div>
            </div>
          </Box>
        </section>
      );
    }

    return null;
  }

  return <>{children}</>;
}
