import { gql, MutationHookOptions, useMutation } from '@apollo/client';

const MUTATION = gql`
  mutation createProduct($input: CreateProductInput!, $parentId: ID) {
    createProduct(input: $input, parentId: $parentId) {
      id
    }
  }
`;

export type Variables = {
  readonly input: {
    readonly name: string;
    readonly sku: string;
    readonly packSize: number;
  };
  readonly parentId?: string;
};

export type Response = {
  readonly createProduct: {
    readonly id: string;
  };
};

export const useCreateProductMutation = (
  options?: MutationHookOptions<Response, Variables>,
) => useMutation<Response, Variables>(MUTATION, options);
