import { useMutation, useQuery } from '@apollo/client';
import Box from '../../../../components/content/Box';
import Divider from '../../../../components/content/Divider';
import DropdownField from '../../../../components/controls/DropdownField';
import filters, { DATETIME_FORMAT_MEDIUM } from '../../../../filters';
import {
  MUTATION,
  Response,
  Variables,
} from './mutations/updateServiceJobStatusMutation';
import {
  QUERY as SERVICE_JOB_STATUSES_QUERY,
  Response as ServiceJobStatusesResponse,
} from './queries/serviceJobStatusesQuery';

type Props = {
  readonly serviceJobId: string;
  readonly created: string;
  readonly updated: string;
  readonly customer: {
    readonly id: string;
    readonly fullName: string;
    readonly email: string;
    readonly mobilePhone: string | null;
  };
  readonly statusId: string;
  readonly onUpdate: () => void;
};

export function ServiceJobMeta({
  serviceJobId,
  created,
  updated,
  customer,
  statusId,
  onUpdate,
}: Props) {
  const { data: serviceJobStatuses } = useQuery<ServiceJobStatusesResponse>(
    SERVICE_JOB_STATUSES_QUERY,
    {},
  );
  const [updateServiceJobStatus, { loading: updateServiceJobStatusLoading }] =
    useMutation<Response, Variables>(MUTATION, {
      onCompleted: () => onUpdate(),
    });

  return (
    <div className="lg:w-96">
      <Box>
        <div>
          <p className="text-sm text-gray-400 mb-1">Created</p>
          <p className="mb-4">
            {filters.formatDate(created, DATETIME_FORMAT_MEDIUM)}
          </p>
          <p className="text-sm text-gray-400 mb-1">Updated</p>
          <p>{filters.formatDate(updated, DATETIME_FORMAT_MEDIUM)}</p>
        </div>
        <Divider />
        <div>
          <p className="text-sm text-gray-400 mb-1">Customer</p>
          <p>
            {customer.fullName}
            <br />
            {customer.email}
            <br />
            {customer.mobilePhone ?? ''}
          </p>
        </div>
        <Divider />
        <div>
          <DropdownField
            label="Status"
            value={statusId}
            onChange={(value) => {
              value &&
                updateServiceJobStatus({
                  variables: { id: serviceJobId, statusId: value },
                });
            }}
            disabled={updateServiceJobStatusLoading}
            options={(serviceJobStatuses?.serviceJobStatuses ?? []).map(
              (serviceJobStatus) => ({
                text: serviceJobStatus.name,
                value: serviceJobStatus.id,
              }),
            )}
          />
        </div>
      </Box>
    </div>
  );
}
