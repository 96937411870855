import { gql, QueryHookOptions, useQuery } from '@apollo/client';

const QUERY = gql`
  query search($query: String!) {
    searchLeads(query: $query) {
      id
      updated
      status {
        name
      }
      customer {
        firstName
        lastName
        mobilePhone
        email
      }
      address {
        postcode
      }
      actions {
        id
        due
        type {
          id
          name
        }
      }
    }
  }
`;

export type Variables = {
  readonly query: string;
};

export type Response = {
  readonly searchLeads: readonly {
    readonly id: string;
    readonly updated: string;
    readonly status: {
      readonly name: string;
    };
    readonly customer: {
      readonly firstName: string;
      readonly lastName: string;
      readonly mobilePhone: string;
      readonly email: string;
    } | null;
    readonly address: {
      readonly postcode: string;
    } | null;
    readonly actions: readonly {
      readonly id: number;
      readonly due: string;
      readonly type: {
        readonly id: number;
        readonly name: string;
      };
    }[];
  }[];
};

export const useSearchQuery = (
  options?: QueryHookOptions<Response, Variables>,
) => useQuery<Response, Variables>(QUERY, options);
