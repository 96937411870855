import TextLink from '../../../../../components/content/TextLink';
import Table from '../../../../../components/content/table/Table';
import TableBody from '../../../../../components/content/table/TableBody';
import TableCell from '../../../../../components/content/table/TableCell';
import TableHeader from '../../../../../components/content/table/TableHeader';
import TableHeaderCell from '../../../../../components/content/table/TableHeaderCell';
import TableRow from '../../../../../components/content/table/TableRow';
import ButtonLink from '../../../../../components/controls/ButtonLink';
import Status from '../../../../../components/misc/Status';
import { Response } from '../queries/getOrdersQuery';

type Props = {
  readonly orders: Response['orders'];
};

export default function OrdersTable({ orders }: Props) {
  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHeaderCell>#</TableHeaderCell>
          <TableHeaderCell>Lead</TableHeaderCell>
          <TableHeaderCell>Supplier</TableHeaderCell>
          <TableHeaderCell>Status</TableHeaderCell>
          <TableHeaderCell>Sent</TableHeaderCell>
          <TableHeaderCell />
        </TableRow>
      </TableHeader>
      <TableBody>
        {orders.length === 0 ? (
          <TableRow>
            <TableCell colspan={5}>No orders.</TableCell>
          </TableRow>
        ) : (
          orders.map((order) => (
            <TableRow key={order.id}>
              <TableCell>
                <code>{order.orderNumber}</code>
              </TableCell>
              <TableCell>
                <TextLink to={`/leads/${order.lead.id}/orders`}>
                  {order.lead.id} &ndash; {order.lead.customer?.fullName ?? '-'}
                </TextLink>
              </TableCell>
              <TableCell>
                {order.supplier ? (
                  <TextLink to={`/inventory/suppliers/${order.supplier.id}`}>
                    {order.supplier.name}
                  </TextLink>
                ) : (
                  <>-</>
                )}
              </TableCell>
              <TableCell>{order.status.name}</TableCell>
              <TableCell>
                <Status value={order.sentToSupplierAt ? 'good' : 'bad'} />
              </TableCell>
              <TableCell rightAlign>
                <ButtonLink text="View" to={`/inventory/orders/${order.id}`} />
              </TableCell>
            </TableRow>
          ))
        )}
      </TableBody>
    </Table>
  );
}
