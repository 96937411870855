import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import { BasicObjectTableItem } from '../../BasicObjectTable';

const QUERY = gql`
  query {
    finances {
      id
      text: name
      archived
    }
    energyRetailers {
      id
      text: name
      archived
    }
    commissionTiers {
      id
      text: value
      archived
    }
    leadTerminationReasons {
      id
      text: name
      archived
    }
    distributors {
      id
      text: name
      archived
    }
  }
`;

export type Response = {
  readonly finances: readonly BasicObjectTableItem[];
  readonly energyRetailers: readonly BasicObjectTableItem[];
  readonly commissionTiers: readonly BasicObjectTableItem[];
  readonly leadTerminationReasons: readonly BasicObjectTableItem[];
  readonly retailers: readonly BasicObjectTableItem[];
  readonly distributors: readonly BasicObjectTableItem[];
};

export const useGetObjectsQuery = (options?: QueryHookOptions<Response>) =>
  useQuery<Response>(QUERY, options);
