import { gql, MutationHookOptions, useMutation } from '@apollo/client';

export const MUTATION = gql`
  mutation createServiceJob($customerId: ID!, $input: ServiceJobInput!) {
    createServiceJob(customerId: $customerId, input: $input) {
      id
    }
  }
`;

export type Variables = {
  readonly customerId: number;
  readonly input: {
    readonly title: string;
    readonly description: string | null;
    readonly priority: number;
  };
};

export type Response = {
  readonly id: string;
};

export const useCreateServiceJobMutation = (
  options?: MutationHookOptions<Response, Variables>,
) => useMutation<Response, Variables>(MUTATION, options);
