import { gql } from '@apollo/client';

export const MUTATION = gql`
  mutation ($id: ID!, $model: UpdateUserInput!) {
    updateUser(id: $id, model: $model) {
      id
    }
  }
`;

export type Variables = {
  readonly id: string;
  readonly model: {
    readonly firstName: string;
    readonly lastName: string;
    readonly active: boolean;
    readonly accessLevel?: string;
    readonly dailyClaimLimit: number | null;
    readonly leadPoolId: string | null;
    readonly welcomeMessage: string | null;
    readonly target: number | null;
  };
};

export type Response = {
  readonly updateUser: {
    readonly id: string;
  };
};
