import { gql, QueryHookOptions, useQuery } from '@apollo/client';

export const QUERY = gql`
  query ($leadId: Float!, $context: String) {
    notes(leadId: $leadId, context: $context) {
      id
      content
      created
      agent {
        fullName
      }
    }
  }
`;

export type Variables = {
  readonly leadId: number;
  readonly context?: string;
};

export type Response = {
  readonly notes: readonly {
    readonly id: string;
    readonly content: string;
    readonly created: string;
    readonly agent: {
      readonly fullName: string;
    } | null;
  }[];
};

export const useGetNotesQuery = (
  options?: QueryHookOptions<Response, Variables>,
) => useQuery<Response, Variables>(QUERY, options);
