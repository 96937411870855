import DropdownField from '../../controls/DropdownField';
import React from 'react';
import { useGetEnergyRetailers } from '../../../hooks/useGetEnergyRetailers';

type Props = {
  readonly value?: string;
  readonly onChange: (value?: string) => void;
};

export default function EnergyRetailerDropdown({ value, onChange }: Props) {
  const energyRetailers = useGetEnergyRetailers();

  return (
    <DropdownField
      label="Energy Retailer"
      placeholder="Energy Retailer"
      value={value}
      options={energyRetailers.map((energyRetailer) => ({
        text: energyRetailer.name,
        value: energyRetailer.id.toString(),
      }))}
      onChange={onChange}
    />
  );
}
