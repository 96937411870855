import { gql, MutationHookOptions, useMutation } from '@apollo/client';

const MUTATION = gql`
  mutation updateLeadFinanceInfo($id: Float!, $model: UpdateLeadFinanceInput!) {
    updateLeadFinanceInfo(id: $id, model: $model) {
      id
    }
  }
`;

export type Variables = {
  readonly id: number;
  // @todo: Add explicit type
  readonly model: any;
};

export type Response = {
  readonly updateLeadFinanceInfo: {
    readonly id: string;
  };
};

export const useUpdateLeadFinanceInfoMutation = (
  options: MutationHookOptions<Response, Variables>,
) => useMutation<Response, Variables>(MUTATION, options);
