import { faExternalLinkAlt } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Divider from '../../../../components/content/Divider';
import ExpandableSection from '../../../../components/content/ExpandableSection';
import Table from '../../../../components/content/table/Table';
import TableBody from '../../../../components/content/table/TableBody';
import TableCell from '../../../../components/content/table/TableCell';
import TableHeader from '../../../../components/content/table/TableHeader';
import TableHeaderCell from '../../../../components/content/table/TableHeaderCell';
import TableRow from '../../../../components/content/table/TableRow';
import filters, { DATETIME_FORMAT_MEDIUM } from '../../../../filters';
import NewDocumentForm from './components/NewDocumentForm';

type Props = {
  readonly serviceJobId: string;
  readonly documents: readonly {
    readonly id: string;
    readonly created: string;
    readonly fileUpload: {
      readonly filename: string;
      readonly url: string;
      readonly extension: string;
      readonly description: string;
      readonly agent: {
        readonly fullName: string;
      };
    };
  }[];
  readonly onUpdate: () => void;
};

export function ServiceJobDocuments({
  serviceJobId,
  documents,
  onUpdate,
}: Props) {
  return (
    <>
      <ExpandableSection title="Documents">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHeaderCell>File</TableHeaderCell>
              <TableHeaderCell rightAlign>Upload Date</TableHeaderCell>
              <TableHeaderCell rightAlign>Uploaded By</TableHeaderCell>
            </TableRow>
          </TableHeader>
          <TableBody>
            {documents.map((document) => (
              <TableRow key={document.id}>
                <TableCell>
                  <a
                    className="font-mono text-sm text-secondary hover:text-secondary-lightest"
                    href={document.fileUpload.url}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {filters.truncateMiddle(document.fileUpload.filename)}
                    {document.fileUpload.extension}{' '}
                    <FontAwesomeIcon icon={faExternalLinkAlt} />
                  </a>
                  {document.fileUpload.description && (
                    <p className="text-gray-600 whitespace-normal">
                      {document.fileUpload.description}
                    </p>
                  )}
                </TableCell>
                <TableCell compress rightAlign>
                  {filters.formatDate(document.created, DATETIME_FORMAT_MEDIUM)}
                </TableCell>
                <TableCell compress rightAlign>
                  {document.fileUpload.agent?.fullName ?? '-'}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </ExpandableSection>

      <Divider />

      <NewDocumentForm
        serviceJobId={serviceJobId}
        onCreate={() => onUpdate()}
      />
    </>
  );
}
