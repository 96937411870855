import { faCheck } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { ReactNode } from 'react';

type Props = {
  readonly children: ReactNode;
  readonly value: boolean;
  readonly onChange: (value: boolean) => void;
};

export default function SidebarOption({ children, value, onChange }: Props) {
  return (
    <button
      className={clsx(
        'flex text-left w-full px-2 py-1 items-center space-x-2 rounded-md mb-1 last:mb-0',
        {
          'bg-green-600 bg-opacity-10 hover:bg-opacity-20': value,
          'hover:bg-gray-400 hover:bg-opacity-20': !value,
        },
      )}
      onClick={() => onChange(!value)}
    >
      <div
        className={clsx(
          'flex-shrink-0 w-5 h-5 rounded-full flex items-center justify-center',
          {
            'text-gray-300 bg-gray-400': !value,
            'text-white bg-green-600': value,
          },
        )}
      >
        <FontAwesomeIcon icon={faCheck} className="text-xs" />
      </div>
      <div
        className={clsx(
          'flex-grow overflow-hidden whitespace-nowrap text-ellipsis',
          {
            'text-gray-400': !value,
            'text-gray-900': value,
          },
        )}
      >
        {children}
      </div>
    </button>
  );
}
