import { useLazyQuery, useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import Divider from '../../../../components/content/Divider';
import ErrorMessage from '../../../../components/content/ErrorMessage';
import ExpandableSection from '../../../../components/content/ExpandableSection';
import Button from '../../../../components/controls/Button';
import TextField from '../../../../components/controls/TextField';
import PylonDesignDataSection from './components/PylonDesignDataSection';
import {
  MUTATION as ATTACH_PYLON_DESIGN_MUTATION,
  Response as AttachPylonDesignResponse,
} from './mutations/attachPylonDesign';
import {
  QUERY as GET_PYLON_DESIGN_QUERY,
  Response as GetPylonDesignResponse,
} from './queries/getPylonDesign';
import { Lead } from '../../queries/getLeadQuery';

type Props = {
  readonly lead: Lead;
  readonly onUpdate: () => void;
};

export function LeadPylonView({ lead, onUpdate }: Props) {
  const [designId, setDesignId] = useState('');

  const [
    getPylonDesign,
    { data: pylonDesign, loading: pylonDesignLoading, error: pylonDesignError },
  ] = useLazyQuery<GetPylonDesignResponse>(GET_PYLON_DESIGN_QUERY, {
    variables: {
      id: designId,
    },
  });

  const [
    attachPylonDesign,
    { loading: loadingAttachPylonDesign, error: attachPylonDesignError },
  ] = useMutation<AttachPylonDesignResponse>(ATTACH_PYLON_DESIGN_MUTATION, {
    onCompleted: () => {
      getPylonDesign();
      return onUpdate();
    },
  });

  const handleAttachPylonDesign = async () => {
    attachPylonDesign({
      variables: { leadId: parseInt(lead.id.toString()), designId },
    });
  };

  useEffect(() => {
    if (lead.pylonDesignId) {
      setDesignId(lead.pylonDesignId);
      getPylonDesign();
    }
  }, [lead]);

  return (
    <>
      <Helmet>
        <title>Pylon | Lead {lead.id} | Doug CRM</title>
      </Helmet>

      <TextField
        type="text"
        label="Pylon Design ID"
        placeholder="XXXXXXXX"
        value={designId}
        onChange={(value) => setDesignId(value)}
        hint={
          <article className="prose">
            <p>
              The Design ID is found at the end of the latest design's URL in
              the Pylon app. e.g.
            </p>
            <pre>
              <code>
                <span className="text-gray-400">
                  app.getpylon.com/library/M49HtmdHaA/view/
                </span>
                <span className="text-white underline">SVPYfrHaA</span>
              </code>
            </pre>
            <p>Ensure the the ID is updated if the design is revised.</p>
          </article>
        }
      />

      <Divider />

      <ExpandableSection title="Pylon Data">
        {pylonDesignLoading && <p>Loading from Pylon&hellip;</p>}
        {pylonDesignError && <ErrorMessage error={pylonDesignError} />}
        {pylonDesign ? (
          <PylonDesignDataSection pylonDesign={pylonDesign.pylonDesign} />
        ) : (
          !pylonDesignLoading && <p>No design attached.</p>
        )}
      </ExpandableSection>

      <Divider />

      {attachPylonDesignError && (
        <>
          <ErrorMessage error={attachPylonDesignError} />
          <Divider />
        </>
      )}

      <Button
        loading={loadingAttachPylonDesign}
        text="Save Design ID & Load from Pylon"
        onClick={() => handleAttachPylonDesign()}
      />
    </>
  );
}
