import { gql } from '@apollo/client';

export const QUERY = gql`
  query {
    installers {
      id
      name
      abn
      archived
      itemsOnHandCount
    }
  }
`;

export type Response = {
  readonly installers: readonly {
    readonly id: number;
    readonly name: string;
    readonly abn: string | null;
    readonly archived: boolean;
    readonly itemsOnHandCount: number;
  }[];
};
