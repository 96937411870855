import {
  faBoxCircleCheck,
  faCalendarAlt,
  faChartLine,
  faCoins,
  faHammer,
  faPhone,
  faPlus,
  faSearch,
  faUser,
  faUserFriends,
  faWrench,
} from '@fortawesome/pro-light-svg-icons';
import { useFlags } from 'flagsmith/react';
import { FLAG_INVENTORY_MANAGEMENT } from '../../flags';
import { useCapabilities } from '../../hooks/useCapabilities';
import { useUser } from '../../hooks/useUser';
import NavigationLink from './NavigationLink';
import NavigationUserInfo from './NavigationUserInfo';

export default function Navigation() {
  const user = useUser();
  const capabilities = useCapabilities();
  const flags = useFlags([FLAG_INVENTORY_MANAGEMENT]);

  const accessLevel = user?.accessLevel.handle;

  const isAdmin = accessLevel === 'admin';
  const isAdminOrOperations =
    isAdmin ||
    accessLevel === 'operations' ||
    accessLevel === 'operationsAdmin';

  return (
    <section className="flex h-screen flex-col w-16 lg:w-56 bg-secondary text-white shadow-lg">
      <div className="px-4 flex-shrink-0 flex items-center h-14 border-b border-secondary-lighter">
        <h1 className="font-medium text-lg">
          <span className="hidden lg:block">
            <img
              className="w-24"
              src="https://spaces.teho.com.au/logo-inverted.png"
              alt="Doug"
            />
          </span>
          <span className="block lg:hidden text-center">D</span>
        </h1>
      </div>

      <nav className="flex-grow py-4 overflow-y-auto">
        <NavigationLink link="/dashboard" text="Dashboard" icon={faChartLine} />
        <NavigationLink link="/search" text="Search" icon={faSearch} />
        <NavigationLink link="/create" text="Create Lead" icon={faPlus} />
        {(accessLevel === 'admin' || accessLevel === 'sales') && (
          <>
            <NavigationLink link="/diary" text="Diary" icon={faCalendarAlt} />
            <NavigationLink
              link="/presenter"
              text="Lead Presenter"
              icon={faPhone}
            />
          </>
        )}
        <NavigationLink
          link="/leads"
          text="My Customers"
          icon={faUserFriends}
        />
        <NavigationLink link="/deals" text="Deals" icon={faCoins} />
        {capabilities.canViewServiceJobs && (
          <NavigationLink
            text="Service Jobs"
            icon={faWrench}
            link="/service-jobs"
          />
        )}
        {capabilities.canViewUsers && (
          <NavigationLink link="/users" text="Users" icon={faUser} />
        )}
        {capabilities.canViewInstallers &&
          flags.inventory_management.enabled && (
            <NavigationLink
              link="/inventory"
              text="Inventory"
              icon={faBoxCircleCheck}
            />
          )}
        {capabilities.canViewTools && (
          <NavigationLink link="/tools" text="Tools" icon={faHammer} />
        )}
      </nav>

      {user && (
        <div className="flex-shrink border-t border-secondary-lighter p-4">
          <NavigationUserInfo />
        </div>
      )}
    </section>
  );
}
