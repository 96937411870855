import TextLink from '../../../../../../../../components/content/TextLink';
import TableCell from '../../../../../../../../components/content/table/TableCell';
import TableRow from '../../../../../../../../components/content/table/TableRow';
import TextField from '../../../../../../../../components/controls/TextField';
import filters from '../../../../../../../../filters';
import { Response } from '../../../../queries/getOrderQuery';

type Props = {
  readonly loading: boolean;
  readonly value: LineItemUpdate;
  readonly orderStatus: Response['order']['status']['handle'];
  readonly lineItem: Response['order']['lineItems'][number];
  readonly onChange: (value: LineItemUpdate) => void;
};

export function OrderLineItem({
  loading,
  value,
  orderStatus,
  lineItem,
  onChange,
}: Props) {
  return (
    <TableRow>
      <TableCell>
        <div>
          <TextLink to={`/inventory/products/${lineItem.product.id}`}>
            {lineItem.product.name}
          </TextLink>
        </div>
        <div className="text-sm text-gray-600">SKU: {lineItem.product.sku}</div>
      </TableCell>
      <TableCell>{lineItem.product.category?.name ?? '-'}</TableCell>
      <TableCell>
        {lineItem.quantity}{' '}
        {lineItem.product.packSize > 1 && (
          <>({lineItem.quantity * lineItem.product.packSize})</>
        )}
      </TableCell>
      <TableCell>
        {orderStatus === 'SENT' ? (
          <TextField
            value={value.received}
            onChange={(val) => onChange({ ...value, received: val })}
          />
        ) : (
          lineItem.received
        )}
      </TableCell>
      <TableCell>
        {orderStatus === 'SENT' ? (
          <TextField
            value={value.used}
            onChange={(val) => onChange({ ...value, used: val })}
          />
        ) : (
          lineItem.used
        )}
      </TableCell>
      {orderStatus === 'SENT' && (
        <TableCell>
          <TextField
            value={value.cost}
            onChange={(val) => onChange({ ...value, cost: val })}
            placeholder="0.00"
          />
        </TableCell>
      )}
      {orderStatus === 'ARCHIVED' && (
        <TableCell>
          {lineItem.cost ? filters.formatMoney(lineItem.cost) : '-'}
        </TableCell>
      )}
    </TableRow>
  );
}
