import { gql, QueryHookOptions, useQuery } from '@apollo/client';

const QUERY = gql`
  query getDiary($month: Float!, $year: Float!) {
    diary(month: $month, year: $year) {
      id
      due
      content
      type {
        name
      }
      lead {
        id
        customer {
          firstName
          lastName
          mobilePhone
        }
      }
    }
  }
`;

export type Variables = {
  readonly month: number;
  readonly year: number;
};

export type Response = {
  readonly diary: readonly {
    readonly id: number;
    readonly created: string;
    readonly due: string;
    readonly type: {
      readonly name: string;
    };
    readonly content: string;
    readonly lead: {
      readonly id: number;
      readonly customer: {
        readonly firstName: string;
        readonly lastName: string;
        readonly mobilePhone: string;
      } | null;
    };
  }[];
};

export const useGetDiaryQuery = (
  options?: QueryHookOptions<Response, Variables>,
) => useQuery<Response, Variables>(QUERY, options);
