import { gql, MutationHookOptions, useMutation } from '@apollo/client';

const MUTATION = gql`
  mutation createCombinedOrder($input: CreateCombinedOrderInput!) {
    createCombinedOrder(input: $input) {
      id
    }
  }
`;

export type Variables = {
  readonly input: {
    readonly orderIds: readonly string[];
  };
};

export type Response = {
  readonly createCombinedOrder: {
    readonly id: number;
  };
};

export const useCreateCombinedOrderMutation = (
  options: MutationHookOptions<Response, Variables>,
) => {
  return useMutation<Response, Variables>(MUTATION, options);
};
