import { gql, MutationHookOptions } from '@apollo/client';
import { useModifiedMutation } from '../../../../../../../../../apollo/hooks/useModifiedMutation';

const MUTATION = gql`
  mutation setLeadLineItem($leadId: ID!, $input: SetLeadLineItemInput!) {
    setLeadLineItem(leadId: $leadId, input: $input) {
      id
    }
  }
`;

export type Variables = {
  readonly leadId: string;
  readonly input: {
    readonly productId: string;
    readonly quantity: number;
    readonly fromStockOnHand: number;
  };
};

export const useSetLeadLineItemMutation = (
  options?: MutationHookOptions<Response, Variables>,
) => useModifiedMutation<Response, Variables>(MUTATION, options);
