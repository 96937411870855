import { useMemo, useState } from 'react';
import Button from '../../../../components/controls/Button';
import DropdownField from '../../../../components/controls/DropdownField';
import TextField from '../../../../components/controls/TextField';
import Tray from '../../../../components/layout/Tray';
import { useGetStates } from '../../../../hooks/useGetStates';
import { useCreateSupplierLocationMutation } from './mutations/createSupplierLocationMutation';

type Props = {
  readonly supplierId: number;
  readonly onClose: () => void;
  readonly onCreate: (id: string) => Promise<any>;
};

export function CreateSupplierLocationTray({
  supplierId,
  onClose,
  onCreate,
}: Props) {
  const states = useGetStates();

  const [name, setName] = useState('');
  const [addressLine1, setAddressLine1] = useState('');
  const [addressLine2, setAddressLine2] = useState('');
  const [suburb, setSuburb] = useState('');
  const [stateId, setStateId] = useState<string>();
  const [postcode, setPostcode] = useState('');

  const [createSupplierLocation, { loading }] =
    useCreateSupplierLocationMutation({
      variables: {
        input: {
          name,
          supplierId,
          address: {
            line1: addressLine1,
            line2: addressLine2,
            suburb,
            stateId: stateId ? parseInt(stateId) : undefined,
            postcode,
          },
        },
      },
      onCompleted: async (data) => {
        await onCreate(data.createSupplierLocation.id);
        onClose();
      },
    });

  const disabled = useMemo(() => {
    return name.length === 0;
  }, [name]);

  return (
    <Tray
      heading="New Supplier Location"
      onClose={onClose}
      actions={
        <>
          <Button
            disabled={disabled}
            loading={loading}
            primary
            text="Create"
            onClick={() => createSupplierLocation()}
          />
        </>
      }
    >
      <div>
        <h4 className="px-5 pt-5 font-bold text-gray-600">Details</h4>
        <div className="grid p-5 gap-5 md:grid-cols-2">
          <div className="md:col-span-2">
            <TextField
              value={name}
              onChange={setName}
              label="Name"
              placeholder="Name"
            />
          </div>
        </div>
        <hr />
        <h4 className="px-5 pt-5 font-bold text-gray-600">Address</h4>
        <div className="grid p-5 gap-5 md:grid-cols-2">
          <TextField
            value={addressLine1}
            onChange={setAddressLine1}
            label="Line 1"
            placeholder="Line 1"
          />
          <TextField
            value={addressLine2}
            onChange={setAddressLine2}
            label="Line 2"
            placeholder="Line 2"
          />
          <div className="md:col-span-2">
            <TextField
              value={suburb}
              onChange={setSuburb}
              label="Suburb"
              placeholder="Suburb"
            />
          </div>
          <DropdownField
            label="State"
            placeholder="State"
            options={states.map((state) => ({
              text: state.abbreviation,
              value: state.id,
            }))}
            value={stateId}
            onChange={(value) => setStateId(value)}
          />
          <TextField
            value={postcode}
            onChange={setPostcode}
            label="Postcode"
            placeholder="Postcode"
          />
        </div>
      </div>
    </Tray>
  );
}
