import { gql, QueryHookOptions, useQuery } from '@apollo/client';

const QUERY = gql`
  query getUserWelcomeData($month: Float!, $year: Float!) {
    me {
      user {
        firstName
        welcomeData {
          welcomeMessage
          target
        }
        report(month: $month, year: $year) {
          totalClaims
          totalClaimsToday
          totalClaimsYesterday
          totalSales
        }
      }
    }
  }
`;

export type Variables = {
  readonly month: number;
  readonly year: number;
};

export type Response = {
  readonly me: {
    readonly user: {
      readonly firstName: string;
      readonly welcomeData: {
        readonly welcomeMessage: string | null;
        readonly target: number | null;
      };
      readonly report: {
        readonly totalClaims: number;
        readonly totalClaimsToday: number;
        readonly totalClaimsYesterday: number;
        readonly totalSales: number;
      };
    };
  };
};

export const useGetUserWelcomeDataQuery = (
  options?: QueryHookOptions<Response, Variables>,
) => useQuery<Response, Variables>(QUERY, options);
