import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import { OrderStatusHandle } from '../../../../../globals';

const QUERY = gql`
  query getCombinedOrder($id: ID!) {
    combinedOrder(id: $id) {
      id
      created
      pickup
      pickupDate
      status {
        name
        handle
      }
      orders {
        id
        orderNumber
        documentSecret
        status {
          name
        }
        lineItems {
          id
          quantity
          product {
            id
            name
            sku
          }
        }
      }
      supplier {
        id
        name
      }
      installerContact {
        id
        name
        installer {
          id
          name
        }
      }
    }
  }
`;

export type Variables = {
  readonly id: string;
};

export type Response = {
  readonly combinedOrder: {
    readonly id: string;
    readonly created: string;
    readonly pickup: boolean;
    readonly pickupDate: string | null;
    readonly status: {
      readonly name: string;
      readonly handle: OrderStatusHandle;
    };
    readonly orders: readonly {
      readonly id: string;
      readonly orderNumber: string;
      readonly documentSecret: string;
      readonly status: {
        readonly name: string;
      };
      readonly lineItems: readonly {
        readonly id: string;
        readonly quantity: number;
        readonly product: {
          readonly id: string;
          readonly name: string;
          readonly sku: string;
        };
      }[];
    }[];
    readonly supplier: {
      readonly id: string;
      readonly name: string;
    };
    readonly installerContact: {
      readonly id: string;
      readonly name: string;
      readonly installer: {
        readonly id: string;
        readonly name: string;
      };
    };
  };
};

export const useGetCombinedOrderQuery = (
  options?: QueryHookOptions<Response, Variables>,
) => useQuery<Response, Variables>(QUERY, options);
