import { gql } from '@apollo/client';

export const MUTATION = gql`
  mutation($leadId: ID!, $category: String!) {
    toggleIgnoredDocument(leadId: $leadId, category: $category)
  }
`;

export type Response = {
  readonly toggleIgnoredDocument: boolean;
};
