import { gql, QueryHookOptions, useQuery } from '@apollo/client';

const QUERY = gql`
  query getLeadTerminationReasons {
    leadTerminationReasons {
      id
      name
    }
  }
`;

export type Response = {
  readonly leadTerminationReasons: readonly {
    readonly id: string;
    readonly name: string;
  }[];
};

export type LeadTerminationReason = Response['leadTerminationReasons'][number];

export const useGetLeadTerminationReasons = (
  options?: QueryHookOptions<Response>,
) => {
  const { data } = useQuery<Response>(QUERY, {
    fetchPolicy: 'cache-first',
    ...options,
  });

  return data?.leadTerminationReasons ?? [];
};
