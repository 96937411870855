import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import { LeadDocument } from '../../../../../globals';

const MUTATION = gql`
  mutation createLeadDocuments(
    $leadId: ID!
    $fileUploadIds: [ID!]!
    $category: String
    $context: String
  ) {
    createLeadDocuments(
      leadId: $leadId
      fileUploadIds: $fileUploadIds
      category: $category
      context: $context
    ) {
      id
    }
  }
`;

export type Variables = {
  readonly leadId: number;
  readonly fileUploadIds: readonly number[];
  readonly category?: LeadDocument['category'];
  readonly context?: string;
};

export type Response = {
  readonly createLeadDocuments: readonly {
    readonly id: string;
  }[];
};

export const useCreateLeadDocumentsMutation = (
  options: MutationHookOptions<Response, Variables>,
) => useMutation<Response, Variables>(MUTATION, options);
