import { gql } from '@apollo/client';

export const MUTATION = gql`
  mutation($leadId: Float!, $group: String!) {
    toggleLeadFieldLock(leadId: $leadId, group: $group) {
      id
      locked
    }
  }
`;

export type Response = {
  readonly toggleLeadFieldLock: {
    readonly id: number;
    readonly locked: boolean;
  };
};
