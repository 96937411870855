import { useEffect, useMemo, useState } from 'react';
import Button from '../../../../../../../../components/controls/Button';
import SearchField from '../../../../../../../../components/controls/SearchField';
import TextField from '../../../../../../../../components/controls/TextField';
import Tray from '../../../../../../../../components/layout/Tray';
import { Response } from '../../../../../../queries/getLeadQuery';
import { useSetLeadLineItemMutation } from './mutations/setLineItemMutation';
import { useSearchProductsQuery } from './queries/searchProductsQuery';

type Props = {
  readonly lead: Response['lead'];
  readonly target?: Response['lead']['lineItems'][number];
  readonly onClose: () => void;
  readonly onSubmit: () => Promise<any>;
};

export function SetLineItemTray({ lead, target, onClose, onSubmit }: Props) {
  const [productId, setProductId] = useState('');
  const [quantity, setQuantity] = useState('');
  const [fromStockOnHand, setFromStockOnHand] = useState('');

  const [searchProducts] = useSearchProductsQuery();
  const [setLineItem, { loading }] = useSetLeadLineItemMutation({
    onCompleted: () => onSubmit(),
  });

  const existingQuantity = useMemo(() => {
    return (
      lead.lineItems.find((line) => line.product.id === productId)?.quantity ??
      0
    );
  }, [lead, productId]);

  const stockOnHand = useMemo(() => {
    const itemOnHand = (
      lead.installerContact?.installer.itemsOnHand ?? []
    ).find((item) => item.product.id === productId);

    return itemOnHand?.quantity ?? 0;
  }, [lead, productId]);

  const selectableQuantity = useMemo(() => {
    if (target) {
      // Targeting item, first fetch that item from the fresh lead data (as this
      // updates when we click save).
      const targetFromLead = lead.lineItems.find(
        (line) => line.id === target.id,
      );

      if (targetFromLead) {
        return stockOnHand + targetFromLead.fromStockOnHand;
      }
    }

    // New item, just use stock on hand value.
    return stockOnHand;
  }, [stockOnHand, lead, target]);

  const canAdd = useMemo(() => {
    const parsedQuantity = parseInt(quantity, 10);
    const parsedFromStockOnHand = parseInt(fromStockOnHand, 10);

    if (
      parsedFromStockOnHand < 0 ||
      parsedFromStockOnHand > selectableQuantity
    ) {
      return false;
    }

    return productId && Number.isInteger(parsedQuantity) && parsedQuantity > 0;
  }, [productId, quantity, fromStockOnHand]);

  const installerName = useMemo(
    () => lead.installerContact?.installer.name ?? 'Installer',
    [lead],
  );

  useEffect(() => {
    if (target) {
      setProductId(target.product.id);
      setQuantity(target.quantity.toString());
      setFromStockOnHand(target.fromStockOnHand.toString());
    }
  }, [target]);

  return (
    <Tray
      heading={target ? 'Edit Line Item' : 'Add Line Item'}
      onClose={onClose}
      actions={
        <Button
          disabled={!canAdd}
          loading={loading}
          primary
          text={target ? 'Update' : 'Add'}
          onClick={() => {
            setLineItem({
              variables: {
                leadId: lead.id,
                input: {
                  productId,
                  quantity: target
                    ? // Set directly if editing line item.
                      parseInt(quantity, 10)
                    : // Additive.
                      parseInt(quantity, 10) + existingQuantity,
                  fromStockOnHand: fromStockOnHand
                    ? parseInt(fromStockOnHand, 10)
                    : 0,
                },
              },
            });
          }}
        />
      }
    >
      <div>
        <div className="grid p-5 gap-5">
          {target ? (
            <div>{target.product.name}</div>
          ) : (
            <SearchField
              label="Product"
              placeholder="Search products..."
              provider={async (search) => {
                const products = await searchProducts({
                  variables: {
                    search,
                  },
                });

                return (products.data?.products ?? []).map((product) => ({
                  value: product.id,
                  text: product.name,
                  details: (
                    <>
                      SKU: {product.sku}
                      {product.packSize > 1 && (
                        <>, Pack of {product.packSize}</>
                      )}
                    </>
                  ),
                }));
              }}
              onSelect={(selection) => setProductId(selection?.value ?? '')}
            />
          )}
          <TextField
            type="text"
            value={quantity}
            onChange={setQuantity}
            label="Required"
            hint="Required amount to be ordered - exclude any on-hand values entered below."
            placeholder="Qty"
          />
          {productId && (
            <div>
              {selectableQuantity > 0 ? (
                <div>
                  <p className="mb-1 text-sm text-gray-600"></p>
                  <TextField
                    type="text"
                    value={fromStockOnHand}
                    onChange={setFromStockOnHand}
                    label={`${installerName} has ${selectableQuantity} available - enter an amount up to this value below to use from stock`}
                    placeholder="From stock"
                  />
                </div>
              ) : (
                <div className="border-l-4 border-yellow-500 bg-yellow-100 text-yellow-800 rounded-md p-3">
                  <p>
                    {installerName} has no available stock for the selected
                    product.
                  </p>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </Tray>
  );
}
