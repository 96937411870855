import { gql, useQuery } from '@apollo/client';
import { FC } from 'react';
import Table from '../../../components/content/table/Table';
import TableBody from '../../../components/content/table/TableBody';
import TableCell from '../../../components/content/table/TableCell';
import TableFooter from '../../../components/content/table/TableFooter';
import TableHeader from '../../../components/content/table/TableHeader';
import TableHeaderCell from '../../../components/content/table/TableHeaderCell';
import TableRow from '../../../components/content/table/TableRow';

const GET_ATTEMPTS = gql`
  query {
    getContactAttemptReport {
      count
      attempts
    }
  }
`;

type GetAttemptsResponse = {
  readonly getContactAttemptReport: readonly {
    readonly count: number;
    readonly attempts: number;
  }[];
};

const DashboardContactAttemptsView: FC = () => {
  const { data } = useQuery<GetAttemptsResponse>(GET_ATTEMPTS);

  if (data) {
    return (
      <Table>
        <TableHeader>
          <TableRow>
            <TableHeaderCell>Attempt Count</TableHeaderCell>
            <TableHeaderCell>Total Leads</TableHeaderCell>
          </TableRow>
        </TableHeader>
        <TableBody>
          {data.getContactAttemptReport.map(({ count, attempts }, index) => (
            <TableRow key={index}>
              <TableCell>{attempts}</TableCell>
              <TableCell>{count}</TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell>Total</TableCell>
            <TableCell>
              {data.getContactAttemptReport.reduce(
                (total, entry) => total + entry.count,
                0,
              )}
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    );
  }

  return null;
};

export default DashboardContactAttemptsView;
