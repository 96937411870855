import Button from '../../../../components/controls/Button';
import Tray from '../../../../components/layout/Tray';
import TextField from '../../../../components/controls/TextField';
import { useMemo, useState } from 'react';
import { useCreateProductMutation } from './mutations/createProductMutation';

type Props = {
  readonly parentId?: string;
  readonly onClose: () => void;
  readonly onCreate: (id: string) => void;
};

export function CreateProductTray({ parentId, onClose, onCreate }: Props) {
  const [name, setName] = useState('');
  const [sku, setSku] = useState('');
  const [packSize, setPackSize] = useState('1');

  const [createSupplier, { loading }] = useCreateProductMutation({
    variables: {
      input: {
        name,
        sku,
        packSize: parseInt(packSize, 10),
      },
      parentId,
    },
    onCompleted: (data) => onCreate(data.createProduct.id),
  });

  const disabled = useMemo(() => {
    if (parseInt(packSize) < 1) return true;

    return name.length === 0 || sku.length === 0;
  }, [name, sku]);

  return (
    <Tray
      heading={parentId ? 'New Child Product' : 'New Product'}
      onClose={onClose}
      actions={
        <>
          <Button
            disabled={disabled}
            loading={loading}
            primary
            text="Create"
            onClick={() => createSupplier()}
          />
        </>
      }
    >
      <div>
        <h4 className="px-5 pt-5 font-bold text-gray-600">Details</h4>
        <div className="grid p-5 gap-5 md:grid-cols-2">
          <div className="md:col-span-2">
            <TextField
              value={name}
              onChange={setName}
              label="Name"
              placeholder="Name"
            />
          </div>
          <TextField
            type="text"
            value={sku}
            onChange={setSku}
            label="SKU"
            placeholder="ITEM123"
          />
          <TextField
            type="text"
            value={packSize}
            onChange={setPackSize}
            label="Pack Size"
            placeholder="1"
            hint="Number of individual products included in this pack. When assigned as a child product, affects the parent stock level by this amount."
          />
        </div>
      </div>
    </Tray>
  );
}
