import { gql, MutationHookOptions, useMutation } from '@apollo/client';

const MUTATION = gql`
  mutation deleteLeadPool($id: ID!) {
    deleteLeadPool(id: $id)
  }
`;

export type Variables = {
  readonly id: number;
};

export type Response = {
  readonly deleteLeadPool: boolean;
};

export const useDeleteLeadPoolMutation = (
  options?: MutationHookOptions<Response, Variables>,
) => useMutation<Response, Variables>(MUTATION, options);
