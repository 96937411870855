import { gql, QueryHookOptions, useQuery } from '@apollo/client';

const QUERY = gql`
  query getProductCategory($id: ID!) {
    productCategory(id: $id) {
      id
      created
      updated
      name
      totalProducts
      pylonTypeSlug
    }
  }
`;

export type Variables = {
  readonly id: string;
};

export type Response = {
  readonly productCategory: {
    readonly id: string;
    readonly created: string;
    readonly updated: string;
    readonly name: string;
    readonly totalProducts: number;
    readonly pylonTypeSlug: string | null;
  };
};

export const useGetProductCategoryQuery = (
  options?: QueryHookOptions<Response, Variables>,
) => useQuery<Response, Variables>(QUERY, options);
