import { useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import CapabilityGuard from '../../../../components/CapabilityGuard';
import Divider from '../../../../components/content/Divider';
import Notice from '../../../../components/layout/Notice';
import { Response } from '../../queries/getLeadQuery';
import LeadLineItems from './components/LeadLineItems';
import LeadOrders from './components/LeadOrders';

type Props = {
  readonly lead: Response['lead'];
  readonly onUpdate: () => Promise<any>;
};

export function LeadOrdersView({ lead, onUpdate }: Props) {
  const unallocated = useMemo(() => {
    const allocated = lead.lineItems.reduce(
      (acc, line) => acc + line.allocated,
      0,
    );
    const required = lead.lineItems.reduce(
      (acc, line) => acc + line.quantity,
      0,
    );

    return required - allocated;
  }, [lead]);

  return (
    <CapabilityGuard requirements={['canViewOrders']}>
      <Helmet>
        <title>Orders | Lead {lead.id} | Doug</title>
      </Helmet>

      <Notice type={unallocated === 0 ? 'success' : 'warning'} className="mb-5">
        {unallocated === 0 ? (
          <p>All products are covered by orders.</p>
        ) : (
          <p>
            {unallocated} {unallocated === 1 ? 'product is' : 'products are'}{' '}
            not covered by orders.
          </p>
        )}
      </Notice>

      <LeadLineItems lead={lead} onUpdate={onUpdate} />
      <Divider />
      <LeadOrders
        leadId={lead.id}
        lineItems={lead.lineItems}
        orders={lead.orders}
        installerContact={lead.installerContact}
        onUpdate={onUpdate}
      />
    </CapabilityGuard>
  );
}
