import { MutationHookOptions, gql, useMutation } from '@apollo/client';

const MUTATION = gql`
  mutation updateOrder($id: ID!, $input: UpdateOrderInput!) {
    updateOrder(id: $id, input: $input) {
      id
    }
  }
`;

export type Variables = {
  readonly id: string;
  readonly input: {
    readonly supplierId?: string;
    readonly lineItems: readonly {
      readonly productId: string;
      readonly quantity: number;
    }[];
  };
};

export type Response = {
  readonly updateOrder: {
    readonly id: string;
  };
};

export const useUpdateOrderMutation = (
  options: MutationHookOptions<Response, Variables>,
) => useMutation<Response, Variables>(MUTATION, options);
