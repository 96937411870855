import DropdownField from '../../controls/DropdownField';
import React from 'react';
import { useGetConnectionTypes } from '../../../hooks/useGetConnectionTypes';

type Props = {
  readonly value?: string;
  readonly onChange: (value?: string) => void;
};

export default function ConnectionTypesDropdown({ value, onChange }: Props) {
  const connectionTypes = useGetConnectionTypes();

  return (
    <DropdownField
      label="Connection Type"
      placeholder="Connection Type"
      value={value}
      options={connectionTypes.map((connectionType) => ({
        text: connectionType.name,
        value: connectionType.id.toString(),
      }))}
      onChange={onChange}
    />
  );
}
