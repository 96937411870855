import { gql, QueryHookOptions, useQuery } from '@apollo/client';

const QUERY = gql`
  query getLeadSources {
    leadSources {
      id
      name
    }
  }
`;

export type Response = {
  readonly leadSources: readonly {
    readonly id: string;
    readonly name: string;
  }[];
};

export type LeadSource = Response['leadSources'][number];

export const useGetLeadSources = (options?: QueryHookOptions<Response>) => {
  const { data } = useQuery<Response>(QUERY, {
    fetchPolicy: 'cache-first',
    ...options,
  });

  return data?.leadSources ?? [];
};
