import { Response } from '../queries/getUsersQuery';
import clsx from 'clsx';

type Props = {
  readonly accessLevel: Response['users'][number]['accessLevel'];
};

export default function UserAccessPill({ accessLevel }: Props) {
  return (
    <span
      className={clsx('inline-block px-2 rounded-full text-sm', {
        'bg-gray-100 text-gray-500': accessLevel.handle !== 'admin',
        'bg-primary text-white': accessLevel.handle === 'admin',
      })}
    >
      {accessLevel.name}
    </span>
  );
}
