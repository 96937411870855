import { faSpinner, faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useClickAway, useDebounce } from '@uidotdev/usehooks';
import { ReactNode, useEffect, useState } from 'react';
import TextField from './TextField';

type Selection = {
  readonly value: string;
  readonly text?: string;
  readonly details?: ReactNode;
};

type Props = {
  readonly label?: string;
  readonly placeholder?: string;
  readonly provider: (search: string) => Promise<readonly Selection[]>;
  readonly onSelect: (selection: Selection | null) => void;
};

export default function SearchField({
  label,
  placeholder = 'Search...',
  onSelect,
  provider,
}: Props) {
  const [loading, setLoading] = useState(false);

  const [value, setValue] = useState<Selection | null>(null);
  const [results, setResults] = useState<readonly Selection[]>([]);
  const [search, setSearch] = useState('');

  const debouncedSearch = useDebounce(search, 300);
  const ref = useClickAway<HTMLDivElement>(() => setResults([]));

  useEffect(() => {
    if (debouncedSearch.length > 0) {
      setLoading(true);

      provider(debouncedSearch)
        .then((results) => setResults(results))
        .finally(() => {
          setLoading(false);
        });
    }
  }, [debouncedSearch]);

  const selectValue = (value: Selection | null) => {
    setValue(value);
    setResults([]);
    setSearch('');

    onSelect(value);
  };

  return (
    <div className="relative">
      {value ? (
        <div>
          {label && (
            <label className="text-gray-600 text-sm mb-1 font-medium block">
              {label}
            </label>
          )}
          <div className="flex items-center border border-gray-300 rounded-md">
            <div className="flex-grow px-2 py-1">
              <div>{value.text ?? value.value}</div>
            </div>
            <div className="flex flex-shrink-0 items-center justify-center px-2 py-1">
              <button
                className="bg-red-700 hover:bg-red-500 text-white rounded-md w-5 h-5 flex items-center justify-center"
                onClick={() => selectValue(null)}
              >
                <FontAwesomeIcon icon={faTimes} />
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div className="relative w-full">
          <TextField
            label={label}
            value={search}
            placeholder={placeholder}
            onChange={setSearch}
            overlay={
              loading ? (
                <FontAwesomeIcon className="animate-spin" icon={faSpinner} />
              ) : undefined
            }
          />
        </div>
      )}

      {results.length > 0 && (
        <div
          ref={ref}
          className="absolute left-0 right-0 rounded-md max-h-40 overflow-y-auto bg-white shadow-md top-full z-10"
        >
          {results.map((result) => (
            <button
              className="block hover:bg-gray-100 text-left px-3 py-1 w-full border-b border-gray-200 last:border-0"
              onClick={() => selectValue(result)}
              key={result.value}
            >
              <div>{result.text ?? result.value}</div>
              {result.details && (
                <div className="text-xs text-gray-600">{result.details}</div>
              )}
            </button>
          ))}
        </div>
      )}
    </div>
  );
}
