import { ReactNode } from 'react';

type Props = {
  readonly children: ReactNode;
};

export function Toolbar({ children }: Props) {
  return (
    <div className="flex items-center border-b border-gray-300 h-14 relative bg-gray-100 shadow-sm">
      <div className="flex items-center border-r border-gray-300">
        {children}
      </div>
    </div>
  );
}
