import { InputDefinition } from '../../../../inputs/abstract';
import { Lead } from '../../queries/getLeadQuery';

export type LeadFinanceInput = {
  readonly systemBaseCost: string;
  readonly systemCost: string;
  readonly customerPrice: string;
  readonly revenue: string;
  readonly invoiceNumber: string;
  readonly agentCommissionTierId?: string;
  readonly agentCommissionPayable: string;
  readonly signoff: boolean;
  readonly totalInvoice: string;
  readonly depositPaid: string;
  readonly customerPaid: boolean;
  readonly stcPaymentReceived: boolean | null;
  readonly panelCost: string;
  readonly installCost: string;
  readonly racking: string;
  readonly inverter1: string;
  readonly inverter2: string;
  readonly battery1: string;
  readonly battery2: string;
  readonly compliance: string;
  readonly travel: string;
  readonly extras: string;
  readonly tehoMargin: string;
  readonly financeRate: string;
  readonly financeCost: string;
  readonly gst: string;
  readonly rebates: string;
  readonly stcCount: string;
  readonly stcUnitCost: string;
  readonly stcTotalCost: string;
  readonly subtotal: string;
  readonly totalCustomerPrice: string;
};

export const leadFinanceInputDefinition: InputDefinition<
  LeadFinanceInput,
  Lead
> = {
  getDefaultState() {
    return {
      systemBaseCost: '',
      systemCost: '',
      customerPrice: '',
      revenue: '',
      invoiceNumber: '',
      agentCommissionTierId: undefined,
      agentCommissionPayable: '',
      signoff: false,
      totalInvoice: '',
      depositPaid: '',
      customerPaid: false,
      stcPaymentReceived: null,
      panelCost: '',
      installCost: '',
      racking: '',
      inverter1: '',
      inverter2: '',
      battery1: '',
      battery2: '',
      compliance: '',
      travel: '',
      extras: '',
      tehoMargin: '',
      financeRate: '',
      financeCost: '',
      gst: '',
      rebates: '',
      stcCount: '',
      stcUnitCost: '',
      stcTotalCost: '',
      subtotal: '',
      totalCustomerPrice: '',
    };
  },
  toPrimitive(input) {
    return {
      systemBaseCost: input.systemBaseCost
        ? input.systemBaseCost.toFixed(2)
        : '',
      systemCost: input.systemCost ? input.systemCost.toFixed(2) : '',
      customerPrice: input.customerPrice ? input.customerPrice.toFixed(2) : '',
      revenue: input.revenue ? input.revenue.toFixed(2) : '',
      invoiceNumber: input.invoiceNumber ? input.invoiceNumber : '',
      agentCommissionTierId: input.agentCommissionTier
        ? input.agentCommissionTier.id.toString()
        : '',
      agentCommissionPayable: input.agentCommissionPayable
        ? input.agentCommissionPayable.toFixed(2)
        : '',
      signoff: input.signoff ?? false,
      totalInvoice: input.totalInvoice ? input.totalInvoice.toFixed(2) : '',
      depositPaid: input.depositPaid ? input.depositPaid.toFixed(2) : '',
      customerPaid: input.customerPaid ?? false,
      stcPaymentReceived: input.stcPaymentReceived ?? null,
      panelCost: input.panelCost ? input.panelCost.toFixed(2) : '',
      installCost: input.installCost ? input.installCost.toFixed(2) : '',
      racking: input.racking ? input.racking.toFixed(2) : '',
      inverter1: input.inverter1 ? input.inverter1.toFixed(2) : '',
      inverter2: input.inverter2 ? input.inverter2.toFixed(2) : '',
      battery1: input.battery1 ? input.battery1.toFixed(2) : '',
      battery2: input.battery2 ? input.battery2.toFixed(2) : '',
      compliance: input.compliance ? input.compliance.toFixed(2) : '',
      travel: input.travel ? input.travel.toFixed(2) : '',
      extras: input.extras ? input.extras.toFixed(2) : '',
      tehoMargin: input.tehoMargin ? input.tehoMargin.toFixed(2) : '',
      financeRate: input.financeRate ? input.financeRate.toFixed(2) : '',
      financeCost: input.financeCost ? input.financeCost.toFixed(2) : '',
      gst: input.gst ? input.gst.toFixed(2) : '',
      rebates: input.rebates ? input.rebates.toFixed(2) : '',
      stcCount: input.stcCount ? input.stcCount.toString() : '',
      stcUnitCost: input.stcUnitCost ? input.stcUnitCost.toFixed(2) : '',
      stcTotalCost: input.stcTotalCost ? input.stcTotalCost.toFixed(2) : '',
      subtotal: input.subtotal ? input.subtotal.toFixed(2) : '',
      totalCustomerPrice: input.totalCustomerPrice
        ? input.totalCustomerPrice.toFixed(2)
        : '',
    };
  },
  toActual(input) {
    return {
      systemBaseCost: input.systemBaseCost
        ? parseFloat(input.systemBaseCost)
        : null,
      systemCost: input.systemCost ? parseFloat(input.systemCost) : null,
      customerPrice: input.customerPrice
        ? parseFloat(input.customerPrice)
        : null,
      revenue: input.revenue ? parseFloat(input.revenue) : null,
      invoiceNumber: input.invoiceNumber,
      agentCommissionTierId: input.agentCommissionTierId
        ? parseFloat(input.agentCommissionTierId)
        : null,
      agentCommissionPayable: input.agentCommissionPayable
        ? parseFloat(input.agentCommissionPayable)
        : null,
      signoff: input.signoff,
      totalInvoice: input.totalInvoice ? parseFloat(input.totalInvoice) : null,
      depositPaid: input.depositPaid ? parseFloat(input.depositPaid) : null,
      customerPaid: input.customerPaid,
      stcPaymentReceived: input.stcPaymentReceived,
      panelCost: input.panelCost ? parseFloat(input.panelCost) : null,
      installCost: input.installCost ? parseFloat(input.installCost) : null,
      racking: input.racking ? parseFloat(input.racking) : null,
      inverter1: input.inverter1 ? parseFloat(input.inverter1) : null,
      inverter2: input.inverter2 ? parseFloat(input.inverter2) : null,
      battery1: input.battery1 ? parseFloat(input.battery1) : null,
      battery2: input.battery2 ? parseFloat(input.battery2) : null,
      compliance: input.compliance ? parseFloat(input.compliance) : null,
      travel: input.travel ? parseFloat(input.travel) : null,
      extras: input.extras ? parseFloat(input.extras) : null,
      tehoMargin: input.tehoMargin ? parseFloat(input.tehoMargin) : null,
      financeRate: input.financeRate ? parseFloat(input.financeRate) : null,
      financeCost: input.financeCost ? parseFloat(input.financeCost) : null,
      gst: input.gst ? parseFloat(input.gst) : null,
      rebates: input.rebates ? parseFloat(input.rebates) : null,
      stcCount: input.stcCount ? parseInt(input.stcCount) : null,
      stcUnitCost: input.stcUnitCost ? parseFloat(input.stcUnitCost) : null,
      stcTotalCost: input.stcTotalCost ? parseFloat(input.stcTotalCost) : null,
      subtotal: input.subtotal ? parseFloat(input.subtotal) : null,
      totalCustomerPrice: input.totalCustomerPrice
        ? parseFloat(input.totalCustomerPrice)
        : null,
    };
  },
};

function extract(input: LeadFinanceInput, key: keyof LeadFinanceInput): number {
  const value = parseFloat(String(input[key]));

  return Number.isFinite(value) ? value : 0;
}

export function calculateSystemBaseCost(input: LeadFinanceInput): number {
  return (
    extract(input, 'panelCost') +
    extract(input, 'installCost') +
    extract(input, 'racking') +
    extract(input, 'inverter1') +
    extract(input, 'inverter2') +
    extract(input, 'battery1') +
    extract(input, 'battery2') +
    extract(input, 'compliance') +
    extract(input, 'travel') +
    extract(input, 'extras')
  );
}

export function calculateSystemCost(input: LeadFinanceInput): number {
  return extract(input, 'tehoMargin') + extract(input, 'systemBaseCost');
}

export function calculateCustomerPrice(input: LeadFinanceInput): number {
  return calculateSystemCost(input) + extract(input, 'revenue');
}

export function calculateGST(input: LeadFinanceInput): number {
  return extract(input, 'subtotal') * 0.1;
}

export function calculateSubtotal(input: LeadFinanceInput): number {
  return extract(input, 'financeCost') + extract(input, 'customerPrice');
}

export function calculateTotalInvoice(input: LeadFinanceInput): number {
  return calculateGST(input) + extract(input, 'subtotal');
}

export function calculateSTCValue(input: LeadFinanceInput): number {
  return extract(input, 'stcCount') * extract(input, 'stcUnitCost');
}

export function calculateCustomerTotalPrice(input: LeadFinanceInput): number {
  return (
    calculateTotalInvoice(input) -
    (extract(input, 'rebates') + calculateSTCValue(input))
  );
}
