import { capitalCase } from 'change-case';
import { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router';
import Box from '../../components/content/Box';
import Container from '../../components/content/Container';
import ErrorMessage from '../../components/content/ErrorMessage';
import Page from '../../components/content/Page';
import Pagination from '../../components/controls/Pagination';
import LeadTable from '../../components/leads/LeadTable';
import SubNavigation from '../../components/navigation/SubNavigation';
import { GetLeadInput } from '../../globals';
import { useGetLeadsQuery } from './queries/getLeadsQuery';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../../hooks/useUser';

const LIMIT = 30;

type Params = {
  readonly status: string;
};

export function MyCustomersView() {
  const user = useUser();
  const navigate = useNavigate();

  const { status } = useParams<Params>();

  const statuses = useMemo(() => {
    if (user) {
      if (user.accessLevel.handle === 'finance') return ['sale'];
      if (user.accessLevel.handle === 'admin')
        return ['unclaimed', 'lead', 'quoted'];
      return ['lead', 'quoted'];
    }

    return [];
  }, [user]);

  const [offset, setOffset] = useState(0);

  const [criteria, setCriteria] = useState<GetLeadInput>({
    statuses: [],
    order: 'DESC',
    orderBy: 'actionDueDate',
  });

  useEffect(() => {
    if (statuses.length > 0) {
      if (!status) {
        navigate(`/leads/status/${statuses[0]}`);
      } else {
        setCriteria({
          ...criteria,
          statuses: [status as any],
          orderBy:
            status === 'lead' || status === 'quoted'
              ? 'actionDueDate'
              : 'updated',
        });
      }
    }
  }, [status, statuses]);

  useEffect(() => {
    setOffset(0);
  }, [criteria]);

  const { data, loading, error } = useGetLeadsQuery({
    fetchPolicy: 'no-cache',
    variables: {
      offset,
      limit: LIMIT,
      criteria,
    },
  });

  return (
    <>
      <Helmet>
        <title>My Customers | Doug CRM</title>
      </Helmet>

      <SubNavigation
        heading={data ? `My Customers (${data?.countLeads})` : 'My Customers'}
        tabs={statuses.map((status) => ({
          text: capitalCase(status),
          url: '/leads/status/' + status,
        }))}
      />

      <Page>
        <Container>
          {loading ? (
            <Box>
              <p>Loading&hellip;</p>
            </Box>
          ) : (
            <>
              {data && data.leads.length > 0 ? (
                <>
                  <LeadTable
                    leads={data.leads}
                    showOverdueActions={
                      status === 'lead' || status === 'quoted'
                    }
                  />
                  <Pagination
                    offset={offset}
                    limit={LIMIT}
                    total={data.countLeads}
                    onChange={(value) => setOffset(value)}
                  />
                </>
              ) : (
                <Box>
                  <p>No leads.</p>
                </Box>
              )}
            </>
          )}
          {error && (
            <Box>
              <ErrorMessage error={error} />
            </Box>
          )}
        </Container>
      </Page>
    </>
  );
}
