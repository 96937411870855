import { useNavigate } from 'react-router-dom';
import { useApolloClient } from '@apollo/client';

export function useLogout() {
  const client = useApolloClient();
  const navigate = useNavigate();

  return async () => {
    await client.clearStore();

    localStorage.clear();
    navigate('/login');
  };
}
