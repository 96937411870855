import React from 'react';
import { AddressInput } from '../../../../../globals';
import ExpandableSection from '../../../../../components/content/ExpandableSection';
import DropdownField from '../../../../../components/controls/DropdownField';
import TextField from '../../../../../components/controls/TextField';
import { useGetStates } from '../../../../../hooks/useGetStates';

type Props = {
  readonly address: AddressInput;
  readonly onChange: (value: AddressInput) => void;
};

export default function LeadAddressDataSection({ address, onChange }: Props) {
  const states = useGetStates();

  return (
    <ExpandableSection title="Site Details">
      <div className="grid grid-cols-4 gap-4">
        <div className="col-span-4 md:col-span-2">
          <TextField
            type="text"
            label="Line 1"
            placeholder="Line 1"
            value={address.line1}
            onChange={(value) => onChange({ ...address, line1: value })}
          />
        </div>
        <div className="col-span-4 md:col-span-2">
          <TextField
            type="text"
            label="Line 2"
            placeholder="Line 2"
            value={address.line2}
            onChange={(value) => onChange({ ...address, line2: value })}
          />
        </div>
        <div className="col-span-4 md:col-span-2">
          <TextField
            type="text"
            label="Suburb"
            placeholder="Suburb"
            value={address.suburb}
            onChange={(value) => onChange({ ...address, suburb: value })}
          />
        </div>
        <div className="col-span-2 md:col-span-1">
          <DropdownField
            label="State"
            placeholder="State"
            value={address.stateId}
            options={states.map((state) => ({
              text: state.abbreviation,
              value: state.id.toString(),
            }))}
            onChange={(value) => onChange({ ...address, stateId: value })}
          />
        </div>
        <div className="col-span-2 md:col-span-1">
          <TextField
            type="text"
            label="Postcode"
            placeholder="Postcode"
            value={address.postcode}
            onChange={(value) => onChange({ ...address, postcode: value })}
          />
        </div>
      </div>
    </ExpandableSection>
  );
}
