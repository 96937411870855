import { Helmet } from 'react-helmet-async';
import { Route, Routes, useParams } from 'react-router-dom';
import CapabilityGuard from '../../components/CapabilityGuard';
import Box from '../../components/content/Box';
import Container from '../../components/content/Container';
import Page from '../../components/content/Page';
import ServiceJobDetails from './components/ServiceJobDetails';
import ServiceJobDocuments from './components/ServiceJobDocuments';
import ServiceJobMeta from './components/ServiceJobMeta';
import ServiceJobNavigation from './components/ServiceJobNavigation';
import ServiceJobNotes from './components/ServiceJobNotes';
import { useGetServiceJobQuery } from './queries/serviceJobQuery';

type Params = {
  readonly id: string;
};

export function ServiceJobView() {
  const { id } = useParams<Params>();

  if (!id) {
    // @todo: some kind of 404
    return null;
  }

  const { data, loading, refetch } = useGetServiceJobQuery({
    variables: {
      id,
    },
  });

  if (loading || !data) {
    return null;
  }

  const { serviceJob } = data;

  return (
    <CapabilityGuard requirements={['canViewServiceJobs']}>
      <Helmet>
        <title>Service Job #{id} | Doug CRM</title>
      </Helmet>

      <ServiceJobNavigation
        id={id}
        customerFullName={serviceJob.customer.fullName}
        statusName={serviceJob.status.name}
        notesCount={serviceJob.notes.length}
        documentsCount={serviceJob.documents.length}
      />

      <Page>
        <Container>
          <div className="flex flex-col lg:flex-row">
            <div className="flex-grow">
              <Box>
                <Routes>
                  <Route
                    index
                    element={
                      <ServiceJobDetails
                        job={serviceJob}
                        serviceJobId={serviceJob.id}
                        onUpdate={() => refetch()}
                      />
                    }
                  />
                  <Route
                    path="/notes"
                    element={
                      <ServiceJobNotes
                        serviceJobId={serviceJob.id}
                        notes={serviceJob.notes}
                        onUpdate={() => refetch()}
                      />
                    }
                  />
                  <Route
                    path="/documents"
                    element={
                      <ServiceJobDocuments
                        serviceJobId={serviceJob.id}
                        documents={serviceJob.documents}
                        onUpdate={() => refetch()}
                      />
                    }
                  />
                </Routes>
              </Box>
            </div>

            <aside className="lg:max-w-md lg:pl-4 lg:pt-0 pt-4 flex-shrink-0">
              <ServiceJobMeta
                serviceJobId={serviceJob.id}
                created={serviceJob.created}
                updated={serviceJob.updated}
                customer={serviceJob.customer}
                statusId={serviceJob.status.id}
                onUpdate={() => refetch()}
              />
            </aside>
          </div>
        </Container>
      </Page>
    </CapabilityGuard>
  );
}
