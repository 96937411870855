import { ReactElement, ReactNode, useMemo } from 'react';
import SubNavigationItem from './SubNavigationItem';
import { User, useUser } from '../../hooks/useUser';

export type SubNavigationTab = {
  readonly text: string;
  readonly url: string;
  readonly counter?: number;
  readonly roles?: readonly User['accessLevel']['handle'][];
  readonly status?: 'done';
};

type Props = {
  readonly children?: ReactNode;
  readonly heading: ReactNode;
  readonly tabs?: readonly SubNavigationTab[];
  readonly controls?: ReactElement;
};

export default function SubNavigation({
  children,
  heading,
  tabs,
  controls,
}: Props) {
  const user = useUser();

  const visibleTabs = useMemo(() => {
    return (tabs ?? []).filter((tab) => {
      if (tab.roles) {
        if (!user || !tab.roles.includes(user.accessLevel.handle)) {
          return false;
        }
      }

      return true;
    });
  }, [tabs, user]);

  return (
    <header className="flex-shrink-0 flex-grow-0 bg-gray-100">
      <div className="px-6">
        <div className="flex container mx-auto h-16 items-center">
          <div className="flex-grow">
            <h2 className="font-medium text-xl">{heading}</h2>
          </div>
          {controls && <div className="text-right flex-shrink">{controls}</div>}
        </div>
      </div>

      {children && (
        <div className="border-t border-gray-200 pt-2 mb-2">
          <div className="px-6">
            <div className="container mx-auto">{children}</div>
          </div>
        </div>
      )}

      {visibleTabs.length > 0 && (
        <div className="px-6">
          <nav className="container mx-auto whitespace-nowrap overflow-x-auto">
            {visibleTabs.map((tab) => (
              <SubNavigationItem
                key={tab.url}
                text={tab.text}
                link={tab.url}
                counter={tab.counter}
                status={tab.status}
              />
            ))}
          </nav>
        </div>
      )}
    </header>
  );
}
