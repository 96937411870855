import { gql } from '@apollo/client';

export const MUTATION = gql`
  mutation($installerId: Int!, $input: UpsertInstallerContactInput!) {
    createInstallerContact(installerId: $installerId, input: $input) {
      id
    }
  }
`;

export type Response = {
  readonly createInstallerContact: {
    readonly id: number;
  };
};
