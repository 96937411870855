import { useState } from 'react';
import { useParams } from 'react-router-dom';
import Box from '../../../../components/content/Box';
import Container from '../../../../components/content/Container';
import Divider from '../../../../components/content/Divider';
import ExpandableSection from '../../../../components/content/ExpandableSection';
import Page from '../../../../components/content/Page';
import SplitLayout from '../../../../components/content/SplitLayout';
import Button from '../../../../components/controls/Button';
import ToolbarPage from '../../../../components/layout/ToolbarPage';
import Heading from '../../../../components/layout/ToolbarPage/components/Heading';
import ToolbarItem from '../../../../components/layout/ToolbarPage/components/Toolbar/components/ToolbarItem';
import Modal from '../../../../components/overlay/Modal';
import { useCapabilities } from '../../../../hooks/useCapabilities';
import InventoryToolbar from '../InventoryToolbar';
import AddInstallerContactModal from './components/AddInstallerContactModal';
import InstallerContactsView from './components/InstallerContactsView';
import InstallerDetailsView from './components/InstallerDetailsView';
import InstallerMeta from './components/InstallerMeta';
import ItemsOnHand from './components/ItemsOnHand';
import UpdateInstallerContactModal from './components/UpdateInstallerContactModal';
import { Response, useGetInstallerQuery } from './queries/getInstaller';

type Params = {
  readonly id: string;
};

export function InstallerView() {
  const { id } = useParams<Params>();
  const capabilities = useCapabilities();

  if (!id) {
    // todo: 404 or something
    return null;
  }

  const { data, loading, refetch } = useGetInstallerQuery({
    variables: {
      id: parseInt(id),
    },
  });

  const [showUpdateConfirmationModal, setShowUpdateConfirmationModal] =
    useState(false);

  const [showAddContactModal, setShowAddContactModal] = useState(false);
  const [selectedContact, setSelectedContact] = useState<
    Response['installer']['contacts'][0] | null
  >(null);

  if (loading) {
    return (
      <Page>
        <Container>
          <p>Loading&hellip;</p>
        </Container>
      </Page>
    );
  }

  if (!data) {
    return (
      <Page>
        <Container>
          <p>There was an error loading the installer.</p>
        </Container>
      </Page>
    );
  }

  return (
    <>
      <ToolbarPage
        requiredCapabilities={['canViewInstallers']}
        toolbar={
          <InventoryToolbar>
            <ToolbarItem text={data.installer.name} />
          </InventoryToolbar>
        }
      >
        <Container>
          <Heading text={data.installer.name} />

          <SplitLayout sidebar={<InstallerMeta installer={data.installer} />}>
            <Box>
              <ExpandableSection title="Details">
                <InstallerDetailsView
                  installer={data.installer}
                  onUpdate={() => {
                    refetch().finally(() =>
                      setShowUpdateConfirmationModal(true),
                    );
                  }}
                />
              </ExpandableSection>
              <Divider />
              <ExpandableSection title="Contacts">
                <div className="mb-5">
                  <InstallerContactsView
                    installer={data.installer}
                    onToggleArchived={() => refetch()}
                    onSelectInstallerContact={(contact) =>
                      setSelectedContact(contact)
                    }
                  />
                </div>

                <Button
                  size="sm"
                  text="Add Contact"
                  onClick={() => setShowAddContactModal(true)}
                />
              </ExpandableSection>

              {capabilities.canViewOrders && (
                <>
                  <Divider />
                  <ItemsOnHand
                    installerId={data.installer.id}
                    count={data.installer.itemsOnHandCount}
                    itemsOnHand={data.installer.itemsOnHand}
                    onUpdate={() => refetch()}
                  />
                </>
              )}
            </Box>
          </SplitLayout>
        </Container>
      </ToolbarPage>

      {showUpdateConfirmationModal && (
        <Modal
          onClose={() => setShowUpdateConfirmationModal(false)}
          heading="Installer Updated"
        >
          <p>Installer was updated successfully.</p>

          <Divider />

          <Button
            onClick={() => setShowUpdateConfirmationModal(false)}
            text="Close"
          />
        </Modal>
      )}

      {showAddContactModal && (
        <AddInstallerContactModal
          installerId={parseInt(id)}
          onClose={() => setShowAddContactModal(false)}
          onAddInstallerContact={() => refetch()}
        />
      )}

      {selectedContact && (
        <UpdateInstallerContactModal
          contact={selectedContact}
          onClose={() => setSelectedContact(null)}
          onUpdateInstallerContact={() => refetch()}
        />
      )}
    </>
  );
}
