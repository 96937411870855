import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Route, Routes } from 'react-router-dom';
import Box from '../../components/content/Box';
import Container from '../../components/content/Container';
import Page from '../../components/content/Page';
import MonthField from '../../components/controls/MonthField';
import YearField from '../../components/controls/YearField';
import SubNavigation from '../../components/navigation/SubNavigation';
import { useUser } from '../../hooks/useUser';
import DashboardContactAttemptsView from './components/DashboardContactAttemptsView';
import DashboardInstallationReport from './components/DashboardInstallationsView';
import DashboardOrdersReport from './components/DashboardOrdersView';
import WelcomeBox from './components/WelcomeMessage';
import { useGetAgentsQuery } from './queries/getAgentsQuery';

export function DashboardView() {
  const user = useUser();

  const [month, setMonth] = useState(String(new Date().getMonth() + 1));
  const [year, setYear] = useState(String(new Date().getFullYear()));

  const { data } = useGetAgentsQuery({
    variables: {
      month: parseInt(month),
      year: parseInt(year),
    },
    onError: (error) => console.error(error),
  });

  const tabs = [
    { text: 'Orders', url: '/dashboard' },
    { text: 'Installations', url: '/dashboard/installations' },
  ];

  if (user?.accessLevel.handle === 'admin') {
    tabs.push({
      text: 'Attempts',
      url: '/dashboard/attempts',
    });
  }

  return (
    <>
      <Helmet>
        <title>Dashboard | Doug CRM</title>
      </Helmet>

      <SubNavigation heading="Dashboard" tabs={tabs} />

      <Page>
        <Container>
          <div className="grid grid-cols-7 gap-4">
            <div className="col-span-5">
              <Routes>
                <Route
                  index
                  element={
                    <DashboardOrdersReport agents={data?.agents ?? []} />
                  }
                />
                <Route
                  path="/installations"
                  element={
                    <DashboardInstallationReport agents={data?.agents ?? []} />
                  }
                />
                <Route
                  path="/attempts"
                  element={<DashboardContactAttemptsView />}
                />
              </Routes>
            </div>
            <div className="col-span-2 space-y-5">
              <Box>
                <div className="mb-4">
                  <MonthField
                    value={month}
                    onChange={(value) => setMonth(value)}
                  />
                </div>
                <div>
                  <YearField
                    value={year}
                    onChange={(value) => setYear(value)}
                  />
                </div>
              </Box>

              <WelcomeBox />
            </div>
          </div>
        </Container>
      </Page>
    </>
  );
}
