import TextLink from '../../../../../components/content/TextLink';
import Table from '../../../../../components/content/table/Table';
import TableBody from '../../../../../components/content/table/TableBody';
import TableCell from '../../../../../components/content/table/TableCell';
import TableHeader from '../../../../../components/content/table/TableHeader';
import TableHeaderCell from '../../../../../components/content/table/TableHeaderCell';
import TableRow from '../../../../../components/content/table/TableRow';
import ButtonLink from '../../../../../components/controls/ButtonLink';
import { Response } from '../queries/getProductsQuery';

type Props = {
  readonly products: Response['products'];
};

export default function ProductsTable({ products }: Props) {
  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHeaderCell>SKU</TableHeaderCell>
          <TableHeaderCell>Name</TableHeaderCell>
          <TableHeaderCell>Category</TableHeaderCell>
          <TableHeaderCell>On Hand</TableHeaderCell>
          <TableHeaderCell />
        </TableRow>
      </TableHeader>
      <TableBody>
        {products.length === 0 ? (
          <TableRow>
            <TableCell colspan={3}>No products.</TableCell>
          </TableRow>
        ) : (
          (products ?? []).map((product) => (
            <TableRow key={product.id}>
              <TableCell>{product.sku}</TableCell>
              <TableCell>{product.name || '-'}</TableCell>
              <TableCell>
                {product.category ? (
                  <TextLink
                    to={`/inventory/product-categories/${product.category.id}`}
                  >
                    {product.category.name}
                  </TextLink>
                ) : (
                  '-'
                )}
              </TableCell>
              <TableCell>{product.itemsOnHandCount}</TableCell>
              <TableCell rightAlign>
                <ButtonLink
                  text="View"
                  to={`/inventory/products/${product.id}`}
                />
              </TableCell>
            </TableRow>
          ))
        )}
      </TableBody>
    </Table>
  );
}
