import { gql, QueryHookOptions, useQuery } from '@apollo/client';

const QUERY = gql`
  query getProduct($id: ID!) {
    product(id: $id) {
      id
      created
      updated
      sku
      name
      archived
      trackStock
      packSize
      pylonId
      category {
        id
        name
      }
      children {
        id
        name
        sku
        packSize
      }
      parent {
        id
        name
        sku
      }
      itemsOnHand {
        installer {
          id
          name
        }
        quantity
      }
      itemsOnHandCount
    }
  }
`;

export type Variables = {
  readonly id: string;
};

export type Response = {
  readonly product: {
    readonly id: string;
    readonly created: string;
    readonly updated: string;
    readonly sku: string;
    readonly name: string;
    readonly archived: boolean;
    readonly trackStock: boolean;
    readonly packSize: number;
    readonly pylonId: string | null;
    readonly category: {
      readonly id: string;
      readonly name: string;
    } | null;
    readonly children: readonly {
      readonly id: string;
      readonly name: string;
      readonly sku: string;
      readonly packSize: number;
    }[];
    readonly parent: {
      readonly id: string;
      readonly name: string;
      readonly sku: string;
    };
    readonly itemsOnHand: readonly {
      readonly installer: {
        readonly id: string;
        readonly name: string;
      };
      readonly quantity: number;
    }[];
    readonly itemsOnHandCount: number;
  };
};

export const useGetProductQuery = (
  options?: QueryHookOptions<Response, Variables>,
) => useQuery<Response, Variables>(QUERY, options);
