import {
  Sidebar,
  SidebarOption,
  SidebarSection,
} from '../../../../../components/layout/SidebarLayout';
import { OrderStatusHandle } from '../../../../../globals';
import { useGetOrderStatuses } from '../../../../../hooks/useGetOrderStatuses';
import { useGetSuppliers } from '../../../../../hooks/useGetSuppliers';

type Props = {
  readonly filter: {
    readonly statuses: readonly OrderStatusHandle[];
    readonly supplierIds: readonly string[];
  };
  readonly onChange: (value: Props['filter']) => void;
};

export default function OrdersSidebar({ filter, onChange }: Props) {
  const orderStatuses = useGetOrderStatuses();
  const suppliers = useGetSuppliers();

  return (
    <Sidebar>
      <SidebarSection title="Status">
        {orderStatuses.map((status) => (
          <SidebarOption
            value={filter.statuses.includes(status.handle)}
            onChange={(value) =>
              onChange({
                ...filter,
                statuses: value
                  ? filter.statuses.concat(status.handle)
                  : filter.statuses.filter(
                      (handle) => handle !== status.handle,
                    ),
              })
            }
            key={status.id}
          >
            {status.name}
          </SidebarOption>
        ))}
      </SidebarSection>
      <SidebarSection title="Supplier">
        {suppliers.map((supplier) => (
          <SidebarOption
            value={filter.supplierIds.includes(supplier.id)}
            onChange={(value) =>
              onChange({
                ...filter,
                supplierIds: value
                  ? filter.supplierIds.concat(supplier.id)
                  : filter.supplierIds.filter((id) => id !== supplier.id),
              })
            }
            key={supplier.id}
          >
            {supplier.name}
          </SidebarOption>
        ))}
      </SidebarSection>
    </Sidebar>
  );
}
