import { gql } from '@apollo/client';

export const MUTATION = gql`
  mutation disableLeadSourceForAgent($agentId: ID!, $leadSourceId: ID!) {
    disableLeadSourceForAgent(agentId: $agentId, leadSourceId: $leadSourceId) {
      id
    }
  }
`;

export type Variables = {
  readonly agentId: string;
  readonly leadSourceId: string;
};

export type Response = {
  readonly disableLeadSourceForAgent: {
    readonly id: string;
  };
};
