import { AnimatePresence } from 'framer-motion';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import Box from '../../../../components/content/Box';
import Container from '../../../../components/content/Container';
import Divider from '../../../../components/content/Divider';
import ExpandableSection from '../../../../components/content/ExpandableSection';
import SplitLayout from '../../../../components/content/SplitLayout';
import Table from '../../../../components/content/table/Table';
import TableBody from '../../../../components/content/table/TableBody';
import TableCell from '../../../../components/content/table/TableCell';
import TableHeader from '../../../../components/content/table/TableHeader';
import TableHeaderCell from '../../../../components/content/table/TableHeaderCell';
import TableRow from '../../../../components/content/table/TableRow';
import Button from '../../../../components/controls/Button';
import ButtonLink from '../../../../components/controls/ButtonLink';
import CheckboxField from '../../../../components/controls/CheckboxField';
import DropdownField from '../../../../components/controls/DropdownField';
import TextField from '../../../../components/controls/TextField';
import ToolbarPage from '../../../../components/layout/ToolbarPage';
import Heading from '../../../../components/layout/ToolbarPage/components/Heading';
import ToolbarItem from '../../../../components/layout/ToolbarPage/components/Toolbar/components/ToolbarItem';
import { useGetProductCategories } from '../../../../hooks/useGetProductCategories';
import CreateProductTray from '../CreateProductTray';
import InventoryToolbar from '../InventoryToolbar';
import ItemsOnHand from './components/ItemsOnHand';
import ProductMeta from './components/ProductMeta';
import { useUpdateProductMutation } from './mutations/updateProductMutation';
import { useGetProductQuery } from './queries/getProductQuery';

type Params = {
  readonly id: string;
};

type Props = {};

export function ProductView({}: Props) {
  const { id } = useParams<Params>();

  const [name, setName] = useState('');
  const [sku, setSku] = useState('');
  const [archived, setArchived] = useState(false);
  const [trackStock, setTrackStock] = useState(true);
  const [packSize, setPackSize] = useState('');
  const [categoryId, setCategoryId] = useState<string>();
  const [pylonId, setPylonId] = useState('');

  const [showCreateProductTray, setShowCreateProductTray] = useState(false);

  const categories = useGetProductCategories();

  const { data, refetch } = useGetProductQuery({
    variables: { id: id! },
  });

  const selectedCategory = useMemo(() => {
    return categories.find((category) => category.id === categoryId) ?? null;
  }, [categoryId, categories]);

  const [update, { loading }] = useUpdateProductMutation({
    variables: {
      id: id!,
      input: {
        name,
        sku,
        archived,
        trackStock,
        packSize: parseInt(packSize, 10),
        categoryId: categoryId ? parseInt(categoryId, 10) : null,
        pylonId: Boolean(selectedCategory?.pylonTypeSlug) ? pylonId : null,
      },
    },
    onCompleted: () => refetch(),
  });

  useEffect(() => {
    if (data) {
      setName(data.product.name);
      setSku(data.product.sku);
      setArchived(data.product.archived);
      setTrackStock(data.product.trackStock);
      setPackSize(String(data.product.packSize));
      setCategoryId(data.product.category?.id ?? undefined);
      setPylonId(data.product.pylonId ?? '');
    }
  }, [data]);

  if (!data) {
    return null;
  }

  return (
    <>
      <ToolbarPage
        requiredCapabilities={['canViewOrders']}
        toolbar={
          <InventoryToolbar>
            <ToolbarItem text={data.product.name} />
          </InventoryToolbar>
        }
      >
        <Container>
          <Heading
            text={data.product.name}
            actions={
              <>
                <Button
                  loading={loading}
                  primary
                  text="Save"
                  onClick={() => update()}
                />
              </>
            }
          />

          <SplitLayout sidebar={<ProductMeta product={data.product} />}>
            <Box>
              <ExpandableSection title="Details">
                <div className="grid gap-5 md:grid-cols-2">
                  <TextField
                    value={name}
                    onChange={setName}
                    label="Name"
                    placeholder="Name"
                  />
                  <TextField
                    value={sku}
                    onChange={setSku}
                    label="SKU"
                    placeholder="PROD123"
                  />
                  <TextField
                    hint="Number of individual products included in this pack. When assigned as a child product, affects the parent stock level by this amount."
                    value={packSize}
                    onChange={setPackSize}
                    label="Pack Size"
                    placeholder="1"
                  />
                  <DropdownField
                    label="Category"
                    placeholder="Select a category"
                    options={categories.map((category) => ({
                      text: category.name,
                      value: category.id,
                    }))}
                    value={categoryId}
                    onChange={setCategoryId}
                  />
                  {selectedCategory?.pylonTypeSlug && (
                    <div className="md:col-span-2">
                      <TextField
                        value={pylonId}
                        onChange={setPylonId}
                        label="Pylon ID / SKU"
                        placeholder="Pylon ID / SKU"
                        hint="The ID of the product in the Pylon system. If provided, automatically populates product information via the Pylon API. Must be used in tandem with a category that has a Pylon type."
                      />
                    </div>
                  )}

                  <CheckboxField checked={trackStock} onChange={setTrackStock}>
                    Track Stock
                  </CheckboxField>
                  <CheckboxField onChange={setArchived} checked={archived}>
                    Archived
                  </CheckboxField>
                </div>
              </ExpandableSection>
              <Divider />
              <ExpandableSection title="Children">
                <div className="space-y-4">
                  <div>
                    {data.product.children.length > 0 ? (
                      <Table>
                        <TableHeader>
                          <TableRow>
                            <TableHeaderCell>SKU</TableHeaderCell>
                            <TableHeaderCell>Name</TableHeaderCell>
                            <TableHeaderCell>Pack Size</TableHeaderCell>
                            <TableHeaderCell />
                          </TableRow>
                        </TableHeader>
                        <TableBody>
                          {data.product.children.map((child) => (
                            <TableRow key={child.id}>
                              <TableCell>{child.sku}</TableCell>
                              <TableCell>{child.name}</TableCell>
                              <TableCell>{child.packSize}</TableCell>
                              <TableCell rightAlign>
                                <ButtonLink
                                  text="View"
                                  to={`/inventory/products/${child.id}`}
                                />
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    ) : (
                      <p>This product has no children.</p>
                    )}
                  </div>
                  <div className="flex justify-end w-full">
                    <Button
                      size="sm"
                      text="Add Child"
                      onClick={() => setShowCreateProductTray(true)}
                    />
                  </div>
                </div>
              </ExpandableSection>
              <Divider />
              <ItemsOnHand
                productId={data.product.id}
                count={data.product.itemsOnHandCount}
                itemsOnHand={data.product.itemsOnHand}
                onUpdate={() => refetch()}
              />
            </Box>
          </SplitLayout>
        </Container>
      </ToolbarPage>
      {showCreateProductTray && (
        <AnimatePresence>
          <CreateProductTray
            onClose={() => setShowCreateProductTray(false)}
            onCreate={async () => {
              await refetch();
              setShowCreateProductTray(false);
            }}
            parentId={data.product.id}
          />
        </AnimatePresence>
      )}
    </>
  );
}
