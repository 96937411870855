import { ReactNode, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';

type Props = {
  readonly children: ReactNode;
};

export default function OverlayPortal({ children }: Props) {
  const el = useRef(document.createElement('div'));

  useEffect(() => {
    const mount = el.current;

    if (document) {
      document.body.appendChild(mount);

      return () => {
        document.body.removeChild(mount);
      };
    }
  }, []);

  return createPortal(children, el.current);
}
