import ReactDatePicker from 'react-datepicker';

type Props = {
  readonly value?: Date | null;
  readonly onChange: (value?: Date | null) => void;
};

export default function MonthPicker({ value, onChange }: Props) {
  return (
    <ReactDatePicker
      className="bg-gray-200 border-none rounded-md px-2 py-1 text-center w-32 focus:outline-none"
      selected={value}
      onChange={onChange}
      dateFormat="MMM yy"
      placeholderText="Month..."
      showMonthYearPicker
      showFullMonthYearPicker
    />
  );
}
