import { useMutation } from '@apollo/client';
import { faExternalLinkAlt } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMemo, useState } from 'react';
import Table from '../../../../components/content/table/Table';
import TableBody from '../../../../components/content/table/TableBody';
import TableCell from '../../../../components/content/table/TableCell';
import TableHeader from '../../../../components/content/table/TableHeader';
import TableHeaderCell from '../../../../components/content/table/TableHeaderCell';
import TableRow from '../../../../components/content/table/TableRow';
import CheckboxField from '../../../../components/controls/CheckboxField';
import filters, { DATETIME_FORMAT_MEDIUM } from '../../../../filters';
import { LeadDocument } from '../../../../globals';
import { MUTATION, Response } from './mutations/toggleIgnoredDocument';
import { useDeleteLeadDocumentMutation } from './mutations/deleteLeadDocumentMutation';
import Button from '../../../../components/controls/Button';
import FrontOfHouseAdditionalData from './components/FrontOfHouseAdditionalData';
import PowerBillAdditionalData from './components/PowerBillAdditionalData';
import { AdditionalFields } from './types';
import Divider from '../../../../components/content/Divider';
import { useUpdateLeadDocumentAdditionalFieldsMutation } from './mutations/updateLeadDocumentAdditionalInfoMutation';
import { useUser } from '../../../../hooks/useUser';
import { Lead } from '../../queries/getLeadQuery';

type Props = {
  readonly lead: Lead;
  readonly title?: string;
  readonly category: LeadDocument['category'];
  readonly ctx?: LeadDocument['context'];
  readonly preventIgnore?: boolean;
  readonly onDelete?: () => void;
  readonly onUpdate?: () => void;
};

export function LeadDocumentCategory({
  lead,
  title,
  category,
  ctx = null,
  preventIgnore,
  onUpdate,
}: Props) {
  const user = useUser();
  const canDelete = user?.accessLevel.handle === 'admin';

  const [ignore, setIgnore] = useState(
    lead.ignoredDocuments.includes(category),
  );

  const [deleted, setDeleted] = useState<readonly number[]>([]);
  const [additionalFields, setAdditionalFields] = useState<AdditionalFields>({
    nmi: lead.nmi ?? '',
    energyRetailerId: lead.energyRetailer?.id.toString() ?? undefined,
    distributorId: lead.distributor?.id.toString() ?? undefined,
    storeys: lead.address?.storeys?.toString(),
    roofTypeId: lead.address?.roofType?.id.toString() ?? undefined,
  });

  const [
    updateLeadDocumentAdditionalFields,
    { loading: updateLeadDocumentAdditionalFieldsLoading },
  ] = useUpdateLeadDocumentAdditionalFieldsMutation({
    variables: {
      leadId: lead.id,
      data: additionalFields,
    },
    onCompleted: () => {
      if (onUpdate) return onUpdate();
    },
  });

  const [toggleIgnoredDocument] = useMutation<Response>(MUTATION, {
    variables: {
      leadId: lead.id,
      category,
    },
    onCompleted: (data) => setIgnore(data.toggleIgnoredDocument),
  });

  const [deleteLeadDocument] = useDeleteLeadDocumentMutation({
    onCompleted: (data) =>
      setDeleted(deleted.concat(data.deleteLeadDocument.id)),
  });

  const relevant = useMemo(() => {
    return lead.documents.filter((document) => {
      return (
        document.category === category &&
        document.context === ctx &&
        !deleted.includes(document.id)
      );
    });
  }, [lead, deleted]);

  const additionalDataComponent = useMemo(() => {
    if (category === 'frontOfTheHouse')
      return (
        <FrontOfHouseAdditionalData
          loading={updateLeadDocumentAdditionalFieldsLoading}
          value={additionalFields}
          onChange={setAdditionalFields}
          onSave={() => updateLeadDocumentAdditionalFields()}
        />
      );
    if (category === 'powerBill')
      return (
        <PowerBillAdditionalData
          loading={updateLeadDocumentAdditionalFieldsLoading}
          value={additionalFields}
          onChange={setAdditionalFields}
          onSave={() => updateLeadDocumentAdditionalFields()}
        />
      );

    return null;
  }, [category, additionalFields]);

  return (
    <div className="mb-4 last:mb-0">
      {title && (
        <h5 className="mb-2 font-bold text-sm text-gray-600">
          {title} ({relevant.length})
        </h5>
      )}

      {additionalDataComponent && (
        <>
          {additionalDataComponent}
          <Divider />
        </>
      )}

      {relevant.length > 0 ? (
        <Table>
          <TableHeader>
            <TableRow>
              <TableHeaderCell>File</TableHeaderCell>
              <TableHeaderCell rightAlign>Upload Date</TableHeaderCell>
              <TableHeaderCell rightAlign>Uploaded By</TableHeaderCell>
              <TableHeaderCell />
            </TableRow>
          </TableHeader>
          <TableBody>
            {relevant.map((document) => (
              <TableRow key={document.id}>
                <TableCell>
                  <a
                    className="font-mono text-sm text-secondary hover:text-secondary-lightest"
                    href={document.fileUpload.url}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {filters.truncateMiddle(document.fileUpload.filename)}
                    {document.fileUpload.extension}{' '}
                    <FontAwesomeIcon icon={faExternalLinkAlt} />
                  </a>
                  {document.fileUpload.description && (
                    <p className="text-gray-600 whitespace-normal">
                      {document.fileUpload.description}
                    </p>
                  )}
                </TableCell>
                <TableCell compress rightAlign>
                  {filters.formatDate(document.created, DATETIME_FORMAT_MEDIUM)}
                </TableCell>
                <TableCell compress rightAlign>
                  {document.fileUpload.agent?.fullName ?? '-'}
                </TableCell>

                <TableCell compress rightAlign>
                  <div className="space-x-2">
                    {canDelete && (
                      <Button
                        text="Delete"
                        size="sm"
                        onClick={() => {
                          if (confirm('Delete document?')) {
                            deleteLeadDocument({
                              variables: {
                                id: parseInt(document.id.toString()),
                              },
                            });
                          }
                        }}
                      />
                    )}
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      ) : !preventIgnore ? (
        <>
          <CheckboxField
            checked={ignore}
            onChange={(value) => toggleIgnoredDocument()}
          >
            Not applicable
          </CheckboxField>
        </>
      ) : (
        <>No documents.</>
      )}
    </div>
  );
}
