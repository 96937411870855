import { useState } from 'react';

export const TOKEN_STORAGE_KEY = 'token';

export function useToken(): [string | null, (token: string | null) => void] {
  const [token, setToken] = useState(localStorage.getItem(TOKEN_STORAGE_KEY));

  return [
    token,
    (value: string | null) => {
      if (value) localStorage.setItem(TOKEN_STORAGE_KEY, value);
      else localStorage.removeItem(TOKEN_STORAGE_KEY);

      setToken(value);
    },
  ];
}
