import { useState } from 'react';
import Box from '../../../../components/content/Box';
import Divider from '../../../../components/content/Divider';
import MetaBlock from '../../../../components/content/MetaBlock';
import Button from '../../../../components/controls/Button';
import DropdownField from '../../../../components/controls/DropdownField';
import Modal from '../../../../components/overlay/Modal';
import filters, { DATETIME_FORMAT_MEDIUM } from '../../../../filters';
import { LeadStatusHandle } from '../../../../hooks/useGetLeadStatuses';
import { useGetLeadTerminationReasons } from '../../../../hooks/useGetLeadTerminationReasons';
import { useUser } from '../../../../hooks/useUser';
import { Lead } from '../../queries/getLeadQuery';
import LeadAgentInfo from './components/LeadAgentInfo';
import LeadSourceInfo from './components/LeadSourceInfo';
import LeadStatusSwitcher from './components/LeadStatusSwitcher';
import { useBookSaleMutation } from './mutations/bookSaleMutation';
import { useFinalizeMutation } from './mutations/finalizeMutation';
import { useMarkDeadMutation } from './mutations/markDeadMutation';
import { useMarkPaidMutation } from './mutations/markPaidMutation';
import { useMarkQuotedMutation } from './mutations/markQuotedMutation';

type Props = {
  readonly lead: Lead;
  readonly onUpdate: () => void;
};

const reactiveStatuses: readonly LeadStatusHandle[] = [
  'lead',
  'quoted',
  'sale',
  'installed',
];

export function LeadSidebar({ lead, onUpdate }: Props) {
  const user = useUser();

  const [showMarkDeadModal, setShowMarkDeadModal] = useState(false);
  const [leadTerminationReason, setLeadTerminationReason] = useState<string>();

  const leadTerminationReasons = useGetLeadTerminationReasons();

  const accessLevel = user ? user.accessLevel.handle : undefined;
  const canFinalize =
    accessLevel === 'admin' ||
    accessLevel === 'operations' ||
    accessLevel === 'operationsAdmin' ||
    accessLevel === 'finance';
  const canMarkPaid = accessLevel === 'admin' || accessLevel === 'finance';

  const [bookSale, { loading: bookSaleLoading }] = useBookSaleMutation({
    variables: {
      id: parseInt(lead.id.toString()),
    },
    onCompleted: () => onUpdate(),
  });

  const [markQuoted, { loading: markQuotedLoading }] = useMarkQuotedMutation({
    variables: {
      id: parseInt(lead.id.toString()),
    },
    onCompleted: () => onUpdate(),
  });

  const [finalize, { loading: finalizeLoading }] = useFinalizeMutation({
    variables: {
      id: parseInt(lead.id.toString()),
    },
    onCompleted: () => onUpdate(),
  });

  const [markPaid, { loading: markPaidLoading }] = useMarkPaidMutation({
    variables: {
      id: parseInt(lead.id.toString()),
    },
    onCompleted: () => onUpdate(),
  });

  const [markDead, { loading: markDeadLoading }] = useMarkDeadMutation({
    onCompleted: () => {
      setShowMarkDeadModal(false);
      onUpdate();
    },
  });

  const loading =
    bookSaleLoading || finalizeLoading || markPaidLoading || markQuotedLoading;

  return (
    <div className="lg:w-96">
      <Box>
        <LeadAgentInfo
          agent={lead.agent}
          lead={lead}
          onUpdate={() => onUpdate()}
        />

        <Divider />

        <div>
          <div className="mb-4">
            <LeadSourceInfo lead={lead} onUpdate={() => onUpdate()} />
          </div>
          <MetaBlock label="Captured">
            {filters.formatDate(lead.created, DATETIME_FORMAT_MEDIUM)}
          </MetaBlock>
          <MetaBlock label="Updated">
            {filters.formatDate(lead.updated, DATETIME_FORMAT_MEDIUM)}
          </MetaBlock>
        </div>
        <Divider />

        <div>
          {accessLevel === 'admin' ? (
            <LeadStatusSwitcher lead={lead} onUpdateStatus={() => onUpdate()} />
          ) : (
            <>
              <p className="text-sm text-gray-400 mb-1">Status</p>
              <p>
                <strong>{lead.status.name}</strong>
              </p>
            </>
          )}
        </div>

        <Divider />

        {reactiveStatuses.includes(lead.status.handle) && (
          <>
            {lead.status.handle === 'lead' && (
              <>
                <Button
                  className="mr-2"
                  loading={loading}
                  primary
                  text="Quoted"
                  onClick={() => markQuoted()}
                />
              </>
            )}
            {lead.status.handle === 'quoted' && (
              <>
                <Button
                  className="mr-2"
                  loading={loading}
                  primary
                  text="Sold"
                  onClick={() => bookSale()}
                />
              </>
            )}
            {lead.status.handle === 'sale' && canFinalize && (
              <>
                <Button
                  className="mr-2"
                  loading={loading}
                  primary
                  text="Finalize"
                  onClick={() => finalize()}
                />
              </>
            )}
            {lead.status.handle === 'installed' && canMarkPaid && (
              <>
                <Button
                  className="mr-2"
                  loading={loading}
                  primary
                  text="Mark Paid"
                  onClick={() => markPaid()}
                />
              </>
            )}
          </>
        )}

        <Button
          disabled={lead.status.handle === 'dead'}
          text="Mark Dead"
          onClick={() => setShowMarkDeadModal(true)}
        />
      </Box>
      {showMarkDeadModal && (
        <Modal
          onClose={() => setShowMarkDeadModal(false)}
          heading="Mark Lead Dead"
        >
          <p>Please provide a reason for marking this lead as dead.</p>

          {leadTerminationReasons && (
            <div className="my-4">
              <DropdownField
                label="Reason"
                placeholder="Select..."
                value={leadTerminationReason}
                onChange={(value) => setLeadTerminationReason(value)}
                options={leadTerminationReasons.map((reason) => ({
                  value: reason.id.toString(),
                  text: reason.name,
                }))}
              />
            </div>
          )}

          <Divider />

          <Button
            disabled={!leadTerminationReason}
            loading={markDeadLoading}
            primary
            text="Mark Dead"
            className="mr-2"
            onClick={() =>
              markDead({
                variables: {
                  id: parseInt(lead.id.toString()),
                  reasonId: leadTerminationReason
                    ? parseInt(leadTerminationReason)
                    : undefined,
                },
              })
            }
          />
          <Button text="Cancel" onClick={() => setShowMarkDeadModal(false)} />
        </Modal>
      )}
    </div>
  );
}
