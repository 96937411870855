import { gql } from '@apollo/client';

export const QUERY = gql`
  query getAgentsForInstalledLeads {
    users {
      id
      fullName
    }
  }
`;

export type Response = {
  readonly users: readonly {
    readonly id: string;
    readonly fullName: string;
  }[];
};
