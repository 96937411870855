import { ReactNode } from 'react';
import { Link, To } from 'react-router-dom';

type Props = {
  readonly children: ReactNode;
  readonly to: To;
};

export default function TextLink({ children, to }: Props) {
  return (
    <Link to={to} className="text-primary underline hover:text-black">
      {children}
    </Link>
  );
}
