import { gql, QueryHookOptions, useQuery } from '@apollo/client';

const QUERY = gql`
  query getProducts($input: GetProductsInput!) {
    products(input: $input) {
      id
      created
      updated
      name
      sku
      category {
        id
        name
      }
      itemsOnHandCount
    }
    productsCount(input: $input)
  }
`;

export type Variables = {
  readonly input: {
    readonly productCategoryIds?: readonly string[];
    readonly query?: string;
  };
};

export type Response = {
  readonly products: readonly {
    readonly id: string;
    readonly created: string;
    readonly updated: string;
    readonly name: string;
    readonly sku: string;
    readonly category: {
      readonly id: string;
      readonly name: string;
    } | null;
    readonly itemsOnHandCount: number;
  }[];
  readonly productsCount: number;
};

export const useGetProductsQuery = (
  options?: QueryHookOptions<Response, Variables>,
) => useQuery<Response, Variables>(QUERY, options);
