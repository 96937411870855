import { gql } from '@apollo/client';

export const MUTATION = gql`
  mutation createServiceJobNote($serviceJobId: ID!, $content: String!) {
    createServiceJobNote(serviceJobId: $serviceJobId, content: $content) {
      id
    }
  }
`;

export type Variables = {
  readonly serviceJobId: string;
  readonly content: string;
};

export type Response = {
  readonly createServiceJobNote: {
    readonly id: string;
  };
};
