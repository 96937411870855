import React, { ReactNode } from 'react';

type Props = {
  readonly children: ReactNode;
};

export default function ContentStream({ children }: Props) {
  return (
    <div className="border rounded-lg border-gray-300 divide-y divide-gray-300 divide-solid overflow-hidden shadow-inner">
      {children}
    </div>
  );
}
