import { Route, Routes } from 'react-router-dom';
import ToolbarPage from '../../components/layout/ToolbarPage';
import InstallersView from './components/InstallersView/InstallersView';
import InventoryToolbar from './components/InventoryToolbar';
import OrdersView from './components/OrdersView';
import OverviewView from './components/OverviewView';
import ProductCategoriesView from './components/ProductCategoriesView';
import ProductsView from './components/ProductsView';
import SuppliersView from './components/SuppliersView';

type Props = {};

export function InventoryView({}: Props) {
  return (
    <ToolbarPage
      requiredCapabilities={['canViewInventory']}
      toolbar={<InventoryToolbar />}
    >
      <Routes>
        <Route path="/" element={<OverviewView />} />
        <Route path="/suppliers" element={<SuppliersView />} />
        <Route path="/product-categories" element={<ProductCategoriesView />} />
        <Route path="/products" element={<ProductsView />} />
        <Route path="/orders" element={<OrdersView />} />
        <Route path="/installers" element={<InstallersView />} />
      </Routes>
    </ToolbarPage>
  );
}
