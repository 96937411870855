import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Table from '../../../../components/content/table/Table';
import TableBody from '../../../../components/content/table/TableBody';
import TableCell from '../../../../components/content/table/TableCell';
import TableFooter from '../../../../components/content/table/TableFooter';
import TableHeader from '../../../../components/content/table/TableHeader';
import TableHeaderCell from '../../../../components/content/table/TableHeaderCell';
import TableRow from '../../../../components/content/table/TableRow';
import Button from '../../../../components/controls/Button';
import TextField from '../../../../components/controls/TextField';
import DeletePoolModal from './components/DeletePoolModal';
import PoolRow from './components/PoolRow';
import { useCreateLeadPoolMutation } from './mutations/createLeadPoolMutation';
import { useGetLeadPoolsQuery } from './queries/getLeadPoolsQuery';

export function LeadPoolsView() {
  const navigate = useNavigate();

  const [deletePoolId, setDeletePoolId] = useState<number | null>(null);
  const [name, setName] = useState('');

  const { data, refetch } = useGetLeadPoolsQuery();

  const [createLeadPool, { loading: createLeadPoolLoading }] =
    useCreateLeadPoolMutation({
      variables: { input: { name } },
      onCompleted: (data) =>
        navigate(`/tools/lead-pools/${data.createLeadPool.id}`),
    });

  return (
    <>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHeaderCell>ID</TableHeaderCell>
            <TableHeaderCell>Name</TableHeaderCell>
            <TableHeaderCell>Dials</TableHeaderCell>
            <TableHeaderCell>Statuses</TableHeaderCell>
            <TableHeaderCell>Sources</TableHeaderCell>
            <TableHeaderCell>Dates</TableHeaderCell>
            <TableHeaderCell>Size</TableHeaderCell>
            <TableHeaderCell rightAlign>Actions</TableHeaderCell>
          </TableRow>
        </TableHeader>
        <TableBody>
          {data?.leadPools.map((pool) => (
            <PoolRow
              key={pool.id}
              pool={pool}
              onDelete={() => setDeletePoolId(pool.id)}
            />
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell colspan={7}>
              <TextField
                placeholder="New pool name..."
                value={name}
                onChange={setName}
              />
            </TableCell>
            <TableCell rightAlign>
              <Button
                disabled={name.length === 0}
                loading={createLeadPoolLoading}
                size="sm"
                primary
                text="Create"
                onClick={() => createLeadPool()}
              />
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>

      {deletePoolId && (
        <DeletePoolModal
          poolId={deletePoolId}
          onClose={() => setDeletePoolId(null)}
          onDelete={() => {
            refetch();
            setDeletePoolId(null);
          }}
        />
      )}
    </>
  );
}
