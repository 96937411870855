import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import Box from '../../components/content/Box';
import Container from '../../components/content/Container';
import Divider from '../../components/content/Divider';
import Page from '../../components/content/Page';
import TextField from '../../components/controls/TextField';
import LeadTable from '../../components/leads/LeadTable';
import SubNavigation from '../../components/navigation/SubNavigation';
import { useSearchQuery } from './queries/searchQuery';

export function SearchView() {
  const [query, setQuery] = useState('');

  const { data, loading } = useSearchQuery({
    fetchPolicy: 'no-cache',
    variables: { query },
  });

  return (
    <>
      <Helmet>
        <title>Search | Doug CRM</title>
      </Helmet>

      <SubNavigation heading="Search" />

      <Page>
        <Container>
          <Box>
            <TextField
              type="text"
              placeholder="Enter search query..."
              value={query}
              onChange={(value) => setQuery(value)}
            />
            <Divider />

            {loading ? (
              <p>Loading&hellip;</p>
            ) : (
              <>
                {data &&
                  (data.searchLeads.length === 0 ? (
                    <p>No results.</p>
                  ) : (
                    <LeadTable leads={data.searchLeads} />
                  ))}
              </>
            )}
          </Box>
        </Container>
      </Page>
    </>
  );
}
