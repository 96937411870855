import { Helmet } from 'react-helmet-async';
import { Route, Routes } from 'react-router-dom';
import CapabilityGuard from '../../components/CapabilityGuard';
import Container from '../../components/content/Container';
import Page from '../../components/content/Page';
import SubNavigation from '../../components/navigation/SubNavigation';
import LeadPoolView from './components/LeadPoolView';
import LeadPoolsView from './components/LeadPoolsView';
import ObjectsView from './components/ObjectsView';
import SettingsView from './components/SettingsView';
import ExportView from './components/ToolsExportView';

export function ToolsView() {
  return (
    <CapabilityGuard requirements={['canViewTools']}>
      <Helmet>
        <title>Tools | Doug CRM</title>
      </Helmet>

      <SubNavigation
        heading="Tools"
        tabs={[
          { text: 'Objects', url: '/tools' },
          { text: 'Settings', url: '/tools/settings' },
          { text: 'Lead Pools', url: '/tools/lead-pools' },
          { text: 'Export', url: '/tools/export' },
        ]}
      />

      <Page>
        <Container>
          <Routes>
            <Route index element={<ObjectsView />} />
            <Route path="/settings" element={<SettingsView />} />
            <Route path="/export" element={<ExportView />} />
            <Route path="/lead-pools" element={<LeadPoolsView />} />
            <Route path="/lead-pools/:id" element={<LeadPoolView />} />
          </Routes>
        </Container>
      </Page>
    </CapabilityGuard>
  );
}
