import { useState } from 'react';
import { FileUpload, LeadDocument } from '../../../../globals';
import Divider from '../../../content/Divider';
import Button from '../../../controls/Button';
import DropdownField from '../../../controls/DropdownField';
import FileField from '../../../controls/FileField';
import { useCreateLeadDocumentsMutation } from './mutations/createLeadDocumentsMutation';

type Props = {
  readonly leadId: string;
  readonly context?: LeadDocument['context'];
  readonly hideCategory?: boolean;
  readonly onCreateAttachment: () => void;
};

export function CreateLeadDocumentForm({
  leadId,
  context,
  hideCategory,
  onCreateAttachment,
}: Props) {
  const [files, setFiles] = useState<readonly FileUpload[]>([]);
  const [category, setCategory] = useState<
    LeadDocument['category'] | undefined
  >();

  const [createDocuments, { loading }] = useCreateLeadDocumentsMutation({
    onError: (error) => console.error(error),
    onCompleted: () => {
      setFiles([]);
      setCategory(undefined);

      return onCreateAttachment();
    },
  });

  const handleSubmit = async () => {
    if (files.length > 0) {
      createDocuments({
        variables: {
          leadId: parseInt(leadId.toString()),
          fileUploadIds: files.map((file) => file.id),
          category,
          context: context ?? undefined,
        },
      });
    }
  };

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        handleSubmit();
      }}
    >
      <div className="grid gap-4">
        <div>
          <FileField
            value={files}
            label="File"
            onChange={(value) => setFiles(value)}
          />
        </div>
        {!hideCategory && (
          <div>
            <DropdownField
              value={category ?? undefined}
              onChange={(value) =>
                setCategory(value as LeadDocument['category'])
              }
              placeholder="None / Misc"
              options={[
                { text: 'Front of the House', value: 'frontOfTheHouse' },
                {
                  text: 'Suggested Battery Location',
                  value: 'suggestedBatteryLocation',
                },
                {
                  text: 'Existing Solar Inverter Data Label',
                  value: 'existingSolarInverterDataLabel',
                },
                {
                  text: 'Meter and Switch Board',
                  value: 'meterAndSwitchBoard',
                },
                { text: 'Pole Greenbox Number', value: 'poleGreenboxNumber' },
                { text: 'Power Bill', value: 'powerBill' },
              ]}
              label="Document Type"
            />
          </div>
        )}
      </div>

      <Divider />

      <Button
        disabled={!files}
        loading={loading}
        text="Upload Document"
        type="submit"
      />
    </form>
  );
}
