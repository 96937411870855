import { ApolloError } from '@apollo/client';
import ErrorMessage from '../../../components/content/ErrorMessage';
import Button from '../../../components/controls/Button';

type Props = {
  readonly error: ApolloError | undefined;
  readonly onRequestLead: () => void;
};

export default function LeadRequestForm({ error, onRequestLead }: Props) {
  return (
    <div className="bg-white px-6 py-6 rounded-lg shadow-md self-center max-w-sm mx-auto w-72 text-center">
      <p className="mb-4 text-sm">Request the next available unclaimed lead.</p>

      {error && <ErrorMessage error={error} />}

      <Button text="Request Lead" primary onClick={onRequestLead} />
    </div>
  );
}
