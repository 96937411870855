import { AnimatePresence } from 'framer-motion';
import { useState } from 'react';
import ExpandableSection from '../../../../../../components/content/ExpandableSection';
import TextLink from '../../../../../../components/content/TextLink';
import Table from '../../../../../../components/content/table/Table';
import TableBody from '../../../../../../components/content/table/TableBody';
import TableCell from '../../../../../../components/content/table/TableCell';
import TableHeader from '../../../../../../components/content/table/TableHeader';
import TableHeaderCell from '../../../../../../components/content/table/TableHeaderCell';
import TableRow from '../../../../../../components/content/table/TableRow';
import Button from '../../../../../../components/controls/Button';
import Status from '../../../../../../components/misc/Status';
import { Response } from '../../../../queries/getLeadQuery';
import AddLineItemTray from './components/SetLineItemTray';
import { useRemoveLeadLineItemMutation } from './mutations/removeLeadLineItem';

type Props = {
  readonly lead: Response['lead'];
  readonly onUpdate: () => Promise<any>;
};

export function LeadLineItems({ lead, onUpdate }: Props) {
  const [showAddLineItemTray, setShowAddLineItemTray] = useState(false);
  const [editLineItem, setEditLineItem] = useState<
    Response['lead']['lineItems'][number] | null
  >(null);

  const [removeLeadLineItem, { loading }] = useRemoveLeadLineItemMutation({
    onCompleted: () => onUpdate(),
  });

  const closeLineItemTray = () => {
    setShowAddLineItemTray(false);
    setEditLineItem(null);
  };

  return (
    <>
      <ExpandableSection
        title="Line Items"
        counter={lead.lineItems.reduce(
          (total, line) => total + line.quantity,
          0,
        )}
      >
        <div className="mb-5">
          <Table>
            <TableHeader>
              <TableRow>
                <TableHeaderCell>Product</TableHeaderCell>
                <TableHeaderCell>Category</TableHeaderCell>
                <TableHeaderCell>Required</TableHeaderCell>
                <TableHeaderCell hint="Tracks allocation of this product across all orders related to the lead. Does not factor in the status of those orders.">
                  Allocated
                </TableHeaderCell>
                <TableHeaderCell />
              </TableRow>
            </TableHeader>
            <TableBody>
              {lead.lineItems.length > 0 ? (
                lead.lineItems.map((lineItem) => (
                  <TableRow key={lineItem.id}>
                    <TableCell>
                      <div>
                        <TextLink
                          to={`/inventory/products/${lineItem.product.id}`}
                        >
                          {lineItem.product.name}
                        </TextLink>
                      </div>
                      <div className="text-sm text-gray-600">
                        SKU: {lineItem.product.sku}
                      </div>
                    </TableCell>
                    <TableCell>
                      {lineItem.product.category?.name ?? '-'}
                    </TableCell>
                    <TableCell>
                      {lineItem.quantity}{' '}
                      {lineItem.fromStockOnHand > 0 && (
                        <small className="text-gray-600">
                          (+{lineItem.fromStockOnHand} from stock)
                        </small>
                      )}
                    </TableCell>
                    <TableCell>
                      {/* <AllocationCounter
                        value={lineItem.allocated}
                        status={
                          lineItem.allocated === lineItem.quantity
                            ? 'ok'
                            : lineItem.allocated > lineItem.quantity
                            ? 'error'
                            : 'warn'
                        }
                      /> */}
                      <Status
                        value={
                          lineItem.allocated === lineItem.quantity
                            ? 'good'
                            : 'bad'
                        }
                      />
                    </TableCell>
                    <TableCell rightAlign>
                      <div className="space-x-1">
                        <Button
                          disabled={loading}
                          size="sm"
                          text="Edit"
                          onClick={() => setEditLineItem(lineItem)}
                        />
                        <Button
                          loading={loading}
                          size="sm"
                          text="Remove"
                          disabled={lineItem.allocated > 0}
                          onClick={() =>
                            removeLeadLineItem({
                              variables: {
                                id: lineItem.id,
                              },
                            })
                          }
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colspan={4}>No line items</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>

        <Button
          size="sm"
          text="Add"
          onClick={() => setShowAddLineItemTray(true)}
        />
      </ExpandableSection>

      <AnimatePresence>
        {(showAddLineItemTray || editLineItem) && (
          <AddLineItemTray
            lead={lead}
            target={editLineItem ?? undefined}
            onClose={() => closeLineItemTray()}
            onSubmit={async () => {
              await onUpdate();
              closeLineItemTray();
            }}
          />
        )}
      </AnimatePresence>
    </>
  );
}
