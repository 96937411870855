import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Route, Routes } from 'react-router-dom';
import LoginForm from './components/LoginForm';
import AuthResetForm from './components/AuthResetForm';

export function LoginView() {
  return (
    <>
      <Helmet>
        <title>Login | Doug CRM</title>
      </Helmet>
      <div className="h-screen w-screen flex items-center justify-center bg-gray-100">
        <Routes>
          <Route index element={<LoginForm />} />
          <Route path="/reset" element={<AuthResetForm />} />
        </Routes>
      </div>
    </>
  );
}
