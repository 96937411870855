import Box from '../../../../../../components/content/Box';
import MetaBlock from '../../../../../../components/content/MetaBlock';
import TextLink from '../../../../../../components/content/TextLink';
import DropdownField from '../../../../../../components/controls/DropdownField';
import filters, { DATETIME_FORMAT_MEDIUM } from '../../../../../../filters';
import { useGetOrderStatuses } from '../../../../../../hooks/useGetOrderStatuses';
import { useUser } from '../../../../../../hooks/useUser';
import PurchaseOrderLinks from '../../../PurchaseOrderLinks';
import { useSetOrderStatusMutation } from '../../mutations/setOrderStatusMutation';
import { Response } from '../../queries/getOrderQuery';

type Props = {
  readonly order: Response['order'];
  readonly onUpdate: () => Promise<any>;
};

export function OrderMetadata({ order, onUpdate }: Props) {
  const user = useUser();
  const statuses = useGetOrderStatuses();

  const [setStatus, { loading }] = useSetOrderStatusMutation({
    onCompleted: () => onUpdate(),
  });

  return (
    <aside className="lg:w-96">
      <Box>
        <MetaBlock label="Created">
          {filters.formatDate(order.created, DATETIME_FORMAT_MEDIUM)}
        </MetaBlock>
        <MetaBlock label="Sent At">
          {order.sentToSupplierAt
            ? filters.formatDate(order.sentToSupplierAt, DATETIME_FORMAT_MEDIUM)
            : '-'}
        </MetaBlock>
        <MetaBlock label="Installer">
          {order.lead.installerContact ? (
            <>
              <div>
                <TextLink
                  to={`/inventory/installers/${order.lead.installerContact.installer.id}`}
                >
                  {order.lead.installerContact.installer.name}
                </TextLink>
              </div>
              <div>&rarr; {order.lead.installerContact.name}</div>
            </>
          ) : (
            <>-</>
          )}
        </MetaBlock>
        <MetaBlock label="Lead">
          <TextLink to={`/leads/${order.lead.id}/orders`}>
            {order.lead.id} &ndash;{' '}
            {order.lead.customer.fullName ?? order.lead.customer.email}
          </TextLink>
        </MetaBlock>
        <MetaBlock label="Purchase Order">
          <PurchaseOrderLinks
            orderIds={[order.id]}
            secret={order.documentSecret}
          />
        </MetaBlock>
        <MetaBlock label="Status">
          {user?.accessLevel?.handle === 'admin' ? (
            <DropdownField
              disabled={loading}
              options={statuses.map((status) => ({
                text: status.name,
                value: status.handle,
              }))}
              value={order.status.handle}
              onChange={(value) => {
                value &&
                  setStatus({ variables: { id: order.id, status: value } });
              }}
            />
          ) : (
            order.status.name
          )}
        </MetaBlock>
      </Box>
    </aside>
  );
}
