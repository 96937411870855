import { useEffect, useState } from 'react';
import Table from '../../../../../../../../components/content/table/Table';
import TableBody from '../../../../../../../../components/content/table/TableBody';
import TableCell from '../../../../../../../../components/content/table/TableCell';
import TableHeader from '../../../../../../../../components/content/table/TableHeader';
import TableHeaderCell from '../../../../../../../../components/content/table/TableHeaderCell';
import TableRow from '../../../../../../../../components/content/table/TableRow';
import Button from '../../../../../../../../components/controls/Button';
import DropdownField from '../../../../../../../../components/controls/DropdownField';
import TextField from '../../../../../../../../components/controls/TextField';
import Tray from '../../../../../../../../components/layout/Tray';
import { useGetOrderReturnStatuses } from '../../../../../../../../hooks/useOrderReturnStatuses';
import { Response } from '../../../../queries/getOrderQuery';
import { useUpdateOrderReturnMutation } from './mutations/updateOrderReturnMutation';

type Props = {
  readonly orderReturn: Response['order']['returns'][number];
  readonly onClose: () => void;
  readonly onUpdate: () => void;
};

export function EditOrderReturnTray({ orderReturn, onClose, onUpdate }: Props) {
  const orderReturnStatuses = useGetOrderReturnStatuses();

  const [returnNumber, setReturnNumber] = useState(
    orderReturn.returnNumber ?? '',
  );
  const [status, setStatus] = useState<string>(orderReturn.status.handle);

  const [update, { loading }] = useUpdateOrderReturnMutation({
    variables: {
      id: orderReturn.id,
      input: {
        returnNumber: returnNumber || null,
        status,
      },
    },
    onCompleted: () => onUpdate(),
  });

  useEffect(() => {
    setReturnNumber(orderReturn.returnNumber ?? '');
    setStatus(orderReturn.status.handle);
  }, [orderReturn]);

  return (
    <Tray
      heading="Edit Order Return"
      onClose={onClose}
      actions={
        <Button
          loading={loading}
          primary
          text="Update"
          onClick={() => update()}
        />
      }
    >
      <div className="p-5 space-y-5">
        <div className="grid gap-5">
          <TextField
            label="Return Number"
            value={returnNumber}
            onChange={setReturnNumber}
            placeholder="XXXX"
          />
          <DropdownField
            label="Status"
            value={status}
            onChange={(value) => value && setStatus(value)}
            options={orderReturnStatuses.map((status) => ({
              text: status.name,
              value: status.handle,
            }))}
          />
        </div>

        <div>
          <h4 className="mb-2">Items</h4>

          <Table>
            <TableHeader>
              <TableRow>
                <TableHeaderCell>Product</TableHeaderCell>
                <TableHeaderCell rightAlign>Qty</TableHeaderCell>
              </TableRow>
            </TableHeader>
            <TableBody>
              {orderReturn.lineItems.map((line) => (
                <TableRow key={line.id}>
                  <TableCell>{line.product.name}</TableCell>
                  <TableCell rightAlign>{line.quantity}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </div>
    </Tray>
  );
}
