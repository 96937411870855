import { gql } from '@apollo/client';

export const QUERY = gql`
  query {
    googleAuthenticationUrl
  }
`;

export type Response = {
  readonly googleAuthenticationUrl: string;
};
