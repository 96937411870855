import { ReactNode } from 'react';
import clsx from 'clsx';

type Props = {
  readonly children?: ReactNode;
  readonly rank?: number;
  readonly theme?: 'bad' | 'warn' | 'good' | 'inactive';
};

export default function TableRow({ children, rank, theme }: Props) {
  return (
    <tr
      className={clsx('border-b last:border-0', {
        'bg-gold-light': rank === 1,
        'bg-silver-light': rank === 2,
        'bg-bronze-light': rank === 3,
        'bg-red-100 text-red-900 border-red-200': theme === 'bad',
        'bg-green-100 text-green-900 border-green-200': theme === 'good',
        'bg-orange-100 text-orange-900 border-orange-200': theme === 'warn',
        'bg-gray-100 text-gray-400': theme === 'inactive',
        'border-gray-200': !theme,
      })}
    >
      {children}
    </tr>
  );
}
