import { gql, MutationHookOptions } from '@apollo/client';
import { useModifiedMutation } from '../../../../../apollo/hooks/useModifiedMutation';

const MUTATION = gql`
  mutation createStockTransfer($input: CreateStockTransferInput!) {
    createStockTransfer(input: $input) {
      id
    }
  }
`;

export type Variables = {
  readonly input: {
    readonly productId: string;
    readonly originInstallerId: string;
    readonly targetInstallerId: string;
    readonly quantity: number;
  };
};

export type Response = {
  readonly createStockTransfer: {
    readonly id: string;
  };
};

export const useCreateStockTransferMutation = (
  options: MutationHookOptions<Response, Variables>,
) => useModifiedMutation<Response, Variables>(MUTATION, options);
