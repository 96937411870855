import { gql } from '@apollo/client';

export const MUTATION = gql`
  mutation ($leadSourceId: Float!) {
    resetPartnerShareCode(leadSourceId: $leadSourceId)
  }
`;

export type Response = {
  readonly resetPartnerShareCode: string;
};
