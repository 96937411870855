import { useMutation, useQuery } from '@apollo/client';
import { faExternalLink } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import Divider from '../../../../../../components/content/Divider';
import Table from '../../../../../../components/content/table/Table';
import TableBody from '../../../../../../components/content/table/TableBody';
import TableCell from '../../../../../../components/content/table/TableCell';
import TableFooter from '../../../../../../components/content/table/TableFooter';
import TableHeader from '../../../../../../components/content/table/TableHeader';
import TableHeaderCell from '../../../../../../components/content/table/TableHeaderCell';
import TableRow from '../../../../../../components/content/table/TableRow';
import Button from '../../../../../../components/controls/Button';
import TextField from '../../../../../../components/controls/TextField';
import Modal from '../../../../../../components/overlay/Modal';
import {
  MUTATION as CREATE_LEAD_SOURCE_MUTATION,
  Response as CreateLeadSourceMutationResponse,
} from './mutations/createLeadSource';
import {
  MUTATION as RESET_PARTNER_SHARE_CODE_MUTATION,
  Response as ResetPartnerShareCodeResponse,
} from './mutations/resetPartnerShareCode';
import {
  MUTATION as TOGGLE_LEAD_SOURCE_ARCHIVED_MUTATION,
  Response as ToggleLeadSourceArchivedMutationResponse,
} from './mutations/toggleLeadSourceArchived';
import {
  QUERY as GET_LEAD_SOURCES_QUERY,
  Response as GetLeadSourcesResponses,
} from './queries/getLeadSources';

export function LeadSources() {
  const [name, setName] = useState('');
  const [selectedLeadSource, setSelectedLeadSource] = useState<
    GetLeadSourcesResponses['leadSources'][0] | null
  >(null);

  const { data, refetch } = useQuery<GetLeadSourcesResponses>(
    GET_LEAD_SOURCES_QUERY,
  );

  const [create, { loading: createLoading }] =
    useMutation<CreateLeadSourceMutationResponse>(CREATE_LEAD_SOURCE_MUTATION, {
      variables: {
        name,
      },
      onError: (error) => console.error(error),
      onCompleted: () => refetch(),
    });

  const [toggleArchived, { loading: toggleArchivedLoading }] =
    useMutation<ToggleLeadSourceArchivedMutationResponse>(
      TOGGLE_LEAD_SOURCE_ARCHIVED_MUTATION,
      {
        onError: (error) => console.error(error),
        onCompleted: () => refetch(),
      },
    );

  const [resetPartnerShareCode, { loading: resetPartnerShareCodeLoading }] =
    useMutation<ResetPartnerShareCodeResponse>(
      RESET_PARTNER_SHARE_CODE_MUTATION,
      {
        variables: {
          leadSourceId: parseInt(selectedLeadSource?.id.toString() ?? '0'),
        },
        onError: (error) => console.error(error),
        onCompleted: async () => {
          await refetch();
          setSelectedLeadSource(null);
        },
      },
    );

  if (!data) {
    return null;
  }

  return (
    <>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHeaderCell>ID</TableHeaderCell>
            <TableHeaderCell>Name</TableHeaderCell>
            <TableHeaderCell>Dashboard Link</TableHeaderCell>
            <TableHeaderCell rightAlign>Archived</TableHeaderCell>
          </TableRow>
        </TableHeader>
        <TableBody>
          {data.leadSources.map((source) => (
            <TableRow key={source.id}>
              <TableCell compress>
                <code>{source.id}</code>
              </TableCell>
              <TableCell>{source.name}</TableCell>
              <TableCell>
                <div className="flex items-center space-x-3">
                  {source.shareCode && (
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={`https://partners.teho.com.au/dashboard/${source.shareCode}`}
                      className="inline-block font-mono text-sm text-secondary hover:text-secondary-lighter"
                    >
                      {source.shareCode}{' '}
                      <FontAwesomeIcon icon={faExternalLink} />
                    </a>
                  )}

                  <Button
                    text="Reset Code"
                    size="sm"
                    onClick={() => setSelectedLeadSource(source)}
                  />
                </div>
              </TableCell>
              <TableCell rightAlign>
                <Button
                  loading={toggleArchivedLoading}
                  size="sm"
                  onClick={() =>
                    toggleArchived({
                      variables: { id: parseInt(source.id.toString()) },
                    })
                  }
                  text={source.archived ? 'Unarchive' : 'Archive'}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>

        <TableFooter>
          <TableRow>
            <TableCell />
            <TableCell>
              <TextField
                type="text"
                placeholder="New row"
                value={name}
                onChange={(value) => setName(value)}
              />
            </TableCell>
            <TableCell />
            <TableCell rightAlign>
              <Button
                loading={createLoading}
                disabled={!name}
                size="sm"
                text="Add"
                onClick={() => create()}
              />
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>

      {selectedLeadSource && (
        <Modal
          heading="Reset Partner Link"
          onClose={() => setSelectedLeadSource(null)}
        >
          <div className="prose">
            <p>
              Are you sure you want to reset the partner dashboard link for{' '}
              <strong>{selectedLeadSource.name}</strong>?
            </p>
            <p>This will break the current link and is irreversible.</p>
          </div>

          <Divider />

          <Button
            loading={resetPartnerShareCodeLoading}
            onClick={() => resetPartnerShareCode()}
            className="mr-2"
            primary
            text="Reset"
          />
          <Button onClick={() => setSelectedLeadSource(null)} text="Cancel" />
        </Modal>
      )}
    </>
  );
}
