import { ReactNode } from 'react';
import { Capabilities } from '../../../hooks/useCapabilities';
import CapabilityGuard from '../../CapabilityGuard';

type Props = {
  readonly toolbar: ReactNode;
  readonly children: ReactNode;
  readonly footer?: ReactNode;
  readonly requiredCapabilities?: readonly (keyof Capabilities)[];
};

export function ToolbarPage({
  toolbar,
  children,
  footer,
  requiredCapabilities = [],
}: Props) {
  console.log(requiredCapabilities);
  return (
    <CapabilityGuard requirements={requiredCapabilities} behaviour="error">
      <div className="h-screen flex flex-col overflow-hidden">
        <aside className="flex-shrink-0">{toolbar}</aside>

        <section className="flex-grow overflow-y-auto">{children}</section>

        {footer && (
          <section className="px-6 border-t border-gray-300 bg-gray-100">
            <div className="container mx-auto py-4">{footer}</div>
          </section>
        )}
      </div>
    </CapabilityGuard>
  );
}
