import { gql, QueryHookOptions, useQuery } from '@apollo/client';

const QUERY = gql`
  query getDistributors {
    distributors {
      id
      name
      archived
    }
  }
`;

export type Response = {
  readonly distributors: readonly {
    readonly id: string;
    readonly name: string;
    readonly archived: boolean;
  }[];
};

export type Distributor = Response['distributors'][number];

export const useGetDistributors = (options?: QueryHookOptions<Response>) => {
  const { data } = useQuery<Response>(QUERY, {
    fetchPolicy: 'cache-first',
    ...options,
  });

  return data?.distributors ?? [];
};
