import Box from '../../../../../components/content/Box';
import MetaBlock from '../../../../../components/content/MetaBlock';
import filters, { DATETIME_FORMAT_MEDIUM } from '../../../../../filters';
import PurchaseOrderLinks from '../../PurchaseOrderLinks';
import { Response } from '../queries/getCombinedOrderQuery';

type Props = {
  readonly combinedOrder: Response['combinedOrder'];
};

export default function CombinedOrderMeta({ combinedOrder }: Props) {
  return (
    <aside className="lg:w-96">
      <Box>
        <MetaBlock label="Created">
          {filters.formatDate(combinedOrder.created, DATETIME_FORMAT_MEDIUM)}
        </MetaBlock>
        <MetaBlock label="Supplier">{combinedOrder.supplier.name}</MetaBlock>
        <MetaBlock label="Installer">
          {combinedOrder.installerContact.name} (
          {combinedOrder.installerContact.installer.name})
        </MetaBlock>
        <MetaBlock label="Purchase Order">
          <PurchaseOrderLinks
            combinedOrderId={combinedOrder.id}
            orderIds={combinedOrder.orders.map((order) => order.id)}
            secret={combinedOrder.orders[0].documentSecret}
          />
        </MetaBlock>
        <MetaBlock label="Status">{combinedOrder.status.name}</MetaBlock>
      </Box>
    </aside>
  );
}
