import { useState } from 'react';
import Button from '../../../../../../components/controls/Button';
import TextField from '../../../../../../components/controls/TextField';
import Modal from '../../../../../../components/overlay/Modal';
import { useCreateServiceJobMutation } from './mutations/createServiceJobMutation';
import DropdownField from '../../../../../../components/controls/DropdownField';

type Props = {
  readonly customerId: number;
  readonly onClose: () => void;
  readonly onCreate: () => void;
};

export function CreateServiceJobModal({
  customerId,
  onClose,
  onCreate,
}: Props) {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [priority, setPriority] = useState('3');

  const [createServiceJob, { loading }] = useCreateServiceJobMutation({
    variables: {
      customerId,
      input: {
        title,
        description,
        priority: parseInt(priority),
      },
    },
    onCompleted: () => {
      onCreate();
      onClose();
    },
  });

  return (
    <Modal heading="New Service Job" onClose={onClose}>
      <form
        onSubmit={(event) => {
          event.preventDefault();
          createServiceJob();
        }}
      >
        <div className="grid gap-4 mb-4">
          <div>
            <TextField
              label="Title"
              value={title}
              onChange={(value) => setTitle(value)}
              placeholder="Title"
            />
          </div>
          <div>
            <TextField
              label="Description"
              value={description}
              onChange={(value) => setDescription(value)}
              placeholder="Description"
            />
          </div>
          <div>
            <DropdownField
              label="Priority"
              options={[
                { value: '1', text: 'High' },
                { value: '2', text: 'Medium' },
                { value: '3', text: 'Low' },
              ]}
              value={priority}
              onChange={(value) => setPriority(value ?? '3')}
            />
          </div>
        </div>

        <Button type="submit" text="Create" primary loading={loading} />
      </form>
    </Modal>
  );
}
