import { DocumentNode, MutationHookOptions, useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { formatErrorMessage } from '../support';

/**
 * Uses an enriched mutation hook that displays a toast notification on error.
 *
 * @param document The GQL document.
 * @param options Mutation options.
 */
export function useModifiedMutation<R, V>(
  document: DocumentNode,
  options?: MutationHookOptions<R, V>,
) {
  return useMutation<R, V>(document, {
    ...options,
    onError: (error) =>
      toast(formatErrorMessage(error), {
        type: 'error',
        theme: 'colored',
        position: 'bottom-center',
      }),
  });
}
