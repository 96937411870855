import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import Table from '../../../components/content/table/Table';
import TableBody from '../../../components/content/table/TableBody';
import TableCell from '../../../components/content/table/TableCell';
import TableFooter from '../../../components/content/table/TableFooter';
import TableHeader from '../../../components/content/table/TableHeader';
import TableHeaderCell from '../../../components/content/table/TableHeaderCell';
import TableRow from '../../../components/content/table/TableRow';
import filters from '../../../filters';
import { Response } from '../queries/getAgentsQuery';
import { faTrophy } from '@fortawesome/pro-light-svg-icons';
import clsx from 'clsx';

type Props = {
  readonly agents: Response['agents'];
};

export default function DashboardInstallationView({ agents }: Props) {
  const [rows, setRows] = useState<typeof agents>([]);

  useEffect(() => {
    setRows(
      agents
        .filter((agent) => agent.report.totalInstallations > 0)
        .sort((a, b) => {
          return a.report.totalInstallations < b.report.totalInstallations
            ? 1
            : -1;
        }),
    );
  }, [agents]);

  const reports = agents.map((agent) => agent.report);

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHeaderCell>Agent</TableHeaderCell>
          <TableHeaderCell rightAlign>Total</TableHeaderCell>
          <TableHeaderCell rightAlign>Revenue</TableHeaderCell>
          <TableHeaderCell rightAlign>System kW's</TableHeaderCell>
          <TableHeaderCell rightAlign>System kWh's</TableHeaderCell>
          <TableHeaderCell rightAlign>Projected Commission</TableHeaderCell>
          <TableHeaderCell rightAlign>Total Incl. GST</TableHeaderCell>
        </TableRow>
      </TableHeader>
      <TableBody>
        {rows.map((agent, index) => {
          const rank = index <= 2 ? index + 1 : undefined;

          return (
            <TableRow key={agent.id} rank={rank}>
              <TableCell>
                {rank && (
                  <FontAwesomeIcon
                    className={clsx('mr-3', {
                      'text-gold': rank === 1,
                      'text-silver': rank === 2,
                      'text-bronze': rank === 3,
                    })}
                    icon={faTrophy}
                  />
                )}
                {agent.firstName} {agent.lastName}
              </TableCell>
              <TableCell rightAlign>
                {agent.report.totalInstallations.toLocaleString()}
              </TableCell>
              <TableCell rightAlign>
                {filters.formatMoney(agent.report.totalInstallationsRevenue)}
              </TableCell>
              <TableCell rightAlign>
                {agent.report.totalInstallationsKilowatts.toLocaleString(
                  undefined,
                  {
                    minimumFractionDigits: 2,
                  },
                )}
              </TableCell>
              <TableCell rightAlign>
                {agent.report.totalInstallationsKilowattHours.toLocaleString(
                  undefined,
                  {
                    minimumFractionDigits: 2,
                  },
                )}
              </TableCell>
              <TableCell rightAlign>
                {filters.formatMoney(
                  agent.report.totalInstallationsProjectedCommission,
                )}
              </TableCell>
              <TableCell rightAlign>
                {filters.formatMoney(agent.report.totalInstalledInvoice)}
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
      <TableFooter>
        <TableRow>
          <TableCell />
          <TableCell rightAlign>
            {filters.sum(reports, 'totalInstallations').toLocaleString()}
          </TableCell>
          <TableCell rightAlign>
            {filters.formatMoney(
              filters.sum(reports, 'totalInstallationsRevenue'),
            )}
          </TableCell>
          <TableCell rightAlign>
            {filters
              .sum(reports, 'totalInstallationsKilowatts')
              .toLocaleString(undefined, { minimumFractionDigits: 2 })}
          </TableCell>
          <TableCell rightAlign>
            {filters
              .sum(reports, 'totalInstallationsKilowattHours')
              .toLocaleString(undefined, { minimumFractionDigits: 2 })}
          </TableCell>
          <TableCell rightAlign>
            {filters.formatMoney(
              filters.sum(reports, 'totalInstallationsProjectedCommission'),
            )}
          </TableCell>
          <TableCell rightAlign>
            {filters.formatMoney(filters.sum(reports, 'totalInstalledInvoice'))}
          </TableCell>
        </TableRow>
      </TableFooter>
    </Table>
  );
}
