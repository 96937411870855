import { gql } from '@apollo/client';

export const MUTATION = gql`
  mutation ($name: String!) {
    createLeadSource(name: $name) {
      id
    }
  }
`;

export type Response = {
  readonly createLeadSource: {
    readonly id: number;
  };
};
