import SubNavigation from '../../../components/navigation/SubNavigation';

type Props = {
  readonly id: string;
  readonly customerFullName: string;
  readonly statusName: string;
  readonly notesCount: number;
  readonly documentsCount: number;
};

export default function ServiceJobNavigation({
  id,
  customerFullName,
  statusName,
  notesCount,
  documentsCount,
}: Props) {
  return (
    <SubNavigation
      heading={`Service Job #${id} for ${customerFullName} (${statusName})`}
      tabs={[
        { text: 'Details', url: `/service-jobs/${id}` },
        {
          text: 'Notes',
          url: `/service-jobs/${id}/notes`,
          counter: notesCount,
        },
        {
          text: 'Documents',
          url: `/service-jobs/${id}/documents`,
          counter: documentsCount,
        },
      ]}
    />
  );
}
