import { gql } from '@apollo/client';
import { Lead } from '../../LeadView/queries/getLeadQuery';

export const QUERY = gql`
  query ($offset: Float!, $limit: Float!, $criteria: GetLeadsInput!) {
    leads(offset: $offset, limit: $limit, criteria: $criteria) {
      id
      updated
      expectedInstallDate
      installDate
      customerPaid
      dealDate
      agent {
        firstName
        lastName
      }
      status {
        name
      }
      customer {
        firstName
        lastName
        mobilePhone
        email
      }
      address {
        postcode
      }
      allDocumentsReceived
      checklist {
        signedProposal
        depositPaid
        depositFullFinance
        financeSubmitted
        financeApproved
        ptcSubmitted
        ptcApproved
        stockOrdered
        stcAllocated
        sitePlanPrinted
        sitePlanSubmitted
        userManualSent
        spvSent
        meteringSubmitted
        installerDocuments
      }
      agentCommissionPayable
    }
    countLeads(criteria: $criteria)
  }
`;

export type Response = {
  // @todo: replace with narrower type
  readonly leads: readonly Lead[];
  readonly countLeads: number;
};
