import { AnimatePresence } from 'framer-motion';
import { useState } from 'react';
import ExpandableSection from '../../../../../components/content/ExpandableSection';
import Table from '../../../../../components/content/table/Table';
import TableBody from '../../../../../components/content/table/TableBody';
import TableCell from '../../../../../components/content/table/TableCell';
import TableHeader from '../../../../../components/content/table/TableHeader';
import TableHeaderCell from '../../../../../components/content/table/TableHeaderCell';
import TableRow from '../../../../../components/content/table/TableRow';
import TextLink from '../../../../../components/content/TextLink';
import Button from '../../../../../components/controls/Button';
import TransferStockTray from '../../TransferStockTray';
import { Response } from '../queries/getInstaller';

type Props = {
  readonly installerId: string;
  readonly count: number;
  readonly itemsOnHand: Response['installer']['itemsOnHand'];
  readonly onUpdate: () => Promise<any>;
};

export default function ItemsOnHand({
  installerId,
  count,
  itemsOnHand,
  onUpdate,
}: Props) {
  const [selectedProductId, setSelectedProductId] = useState<string | null>(
    null,
  );

  return (
    <>
      <ExpandableSection title="Items on Hand" counter={count}>
        <Table>
          <TableHeader>
            <TableRow>
              <TableHeaderCell>Product</TableHeaderCell>
              <TableHeaderCell>Quantity</TableHeaderCell>
              <TableHeaderCell />
            </TableRow>
          </TableHeader>
          <TableBody>
            {itemsOnHand.length > 0 ? (
              itemsOnHand.map((itemOnHand) => (
                <TableRow key={itemOnHand.product.id}>
                  <TableCell>
                    <div>
                      <TextLink
                        to={`/inventory/products/${itemOnHand.product.id}`}
                      >
                        {itemOnHand.product.name}
                      </TextLink>
                    </div>
                    <div className="text-sm text-gray-600">
                      {itemOnHand.product.sku}
                    </div>
                  </TableCell>
                  <TableCell>{itemOnHand.quantity}</TableCell>
                  <TableCell rightAlign>
                    <Button
                      size="sm"
                      text="Transfer"
                      onClick={() =>
                        setSelectedProductId(itemOnHand.product.id)
                      }
                    />
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colspan={3}>No items on hand.</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </ExpandableSection>

      <AnimatePresence>
        {selectedProductId && (
          <TransferStockTray
            originInstallerId={installerId}
            productId={selectedProductId}
            onClose={() => setSelectedProductId(null)}
            onTransfer={async () => {
              await onUpdate();
              setSelectedProductId(null);
            }}
          />
        )}
      </AnimatePresence>
    </>
  );
}
