import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Fragment } from 'react/jsx-runtime';
import Box from '../../../../components/content/Box';
import Container from '../../../../components/content/Container';
import Divider from '../../../../components/content/Divider';
import ExpandableSection from '../../../../components/content/ExpandableSection';
import SplitLayout from '../../../../components/content/SplitLayout';
import Button from '../../../../components/controls/Button';
import CheckboxField from '../../../../components/controls/CheckboxField';
import DateField from '../../../../components/controls/DateField';
import ToolbarPage from '../../../../components/layout/ToolbarPage';
import Heading from '../../../../components/layout/ToolbarPage/components/Heading';
import ToolbarItem from '../../../../components/layout/ToolbarPage/components/Toolbar/components/ToolbarItem';
import InventoryToolbar from '../InventoryToolbar';
import CombinedOrderMeta from './components/CombinedOrderMeta';
import OrderDetails from './components/OrderDetails';
import { useSendCombinedOrderToSupplierMutation } from './mutations/sendCombinedOrderToSupplierMutation';
import { useSetCombinedOrderStatusMutation } from './mutations/setCombinedOrderStatusMutation';
import { useUpdateCombinedOrderMutation } from './mutations/updateCombinedOrderMutation';
import { useGetCombinedOrderQuery } from './queries/getCombinedOrderQuery';

type Props = {
  //
};

type Params = {
  readonly id: string;
};

export function CombinedOrderView({}: Props) {
  const { id } = useParams<Params>();

  const [pickup, setPickup] = useState(false);
  const [pickupDate, setPickupDate] = useState<Date | null>(null);

  const { data, refetch } = useGetCombinedOrderQuery({
    variables: { id: id! },
  });

  const [update, { loading: updateLoading }] = useUpdateCombinedOrderMutation({
    variables: {
      id: id!,
      input: {
        pickup,
        pickupDate: pickupDate?.toISOString(),
      },
    },
    onCompleted: () => refetch(),
  });

  const [setStatus, { loading: setStatusLoading }] =
    useSetCombinedOrderStatusMutation({
      onCompleted: () => refetch(),
    });

  const [sendToSupplier, { loading: sendToSupplierLoading }] =
    useSendCombinedOrderToSupplierMutation({
      onCompleted: () => refetch(),
    });

  useEffect(() => {
    if (data) {
      setPickup(data.combinedOrder.pickup);
      setPickupDate(
        data.combinedOrder.pickupDate
          ? new Date(data.combinedOrder.pickupDate)
          : null,
      );
    }
  }, [data]);

  const actions = useMemo(() => {
    if (data) {
      if (data.combinedOrder.status.handle === 'DRAFT') {
        return (
          <Button
            primary
            text="Mark Ready"
            loading={setStatusLoading}
            onClick={() =>
              setStatus({
                variables: {
                  id: data.combinedOrder.id,
                  status: 'READY',
                },
              })
            }
          />
        );
      }

      if (data.combinedOrder.status.handle === 'READY') {
        return (
          <Button
            primary
            text="Send to Supplier"
            loading={sendToSupplierLoading}
            onClick={() =>
              sendToSupplier({
                variables: {
                  id: data.combinedOrder.id,
                },
              })
            }
          />
        );
      }
    }

    return null;
  }, [data]);

  if (!data) {
    return null;
  }

  return (
    <>
      <ToolbarPage
        requiredCapabilities={['canViewOrders']}
        toolbar={
          <InventoryToolbar>
            <ToolbarItem text={`Combined Order #${data.combinedOrder.id}`} />
          </InventoryToolbar>
        }
      >
        <Container>
          <Heading
            text={`Combined Order #${data.combinedOrder.id}`}
            actions={actions}
          />

          <SplitLayout
            sidebar={<CombinedOrderMeta combinedOrder={data.combinedOrder} />}
          >
            <Box>
              <ExpandableSection title="Details">
                <div className="grid grid-cols-1 gap-5 mb-5">
                  <CheckboxField checked={pickup} onChange={setPickup}>
                    Pickup
                  </CheckboxField>
                  {pickup && (
                    <DateField
                      time
                      label="Pickup Date"
                      value={pickupDate}
                      onChange={setPickupDate}
                    />
                  )}
                </div>

                <Button
                  size="sm"
                  text="Update"
                  loading={updateLoading}
                  onClick={() => update()}
                />
              </ExpandableSection>
              <Divider />
              {data.combinedOrder.orders.map((order, idx) => (
                <Fragment key={order.id}>
                  <OrderDetails order={order} />
                  {idx < data.combinedOrder.orders.length - 1 && <Divider />}
                </Fragment>
              ))}
            </Box>
          </SplitLayout>
        </Container>
      </ToolbarPage>
    </>
  );
}
