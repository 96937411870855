import { useState } from 'react';
import Divider from '../../../../../../components/content/Divider';
import Button from '../../../../../../components/controls/Button';
import FileField from '../../../../../../components/controls/FileField';
import { FileUpload } from '../../../../../../globals';
import { useCreateServiceJobDocumentMutation } from './mutations/createServiceJobDocumentMutation';

type Props = {
  readonly serviceJobId: string;
  readonly onCreate: () => void;
};

export function NewDocumentForm({ serviceJobId, onCreate }: Props) {
  const [files, setFiles] = useState<readonly FileUpload[]>([]);

  const [createDocuments, { loading }] = useCreateServiceJobDocumentMutation({
    onError: (error) => console.error(error),
    onCompleted: () => {
      setFiles([]);

      return onCreate();
    },
  });

  const handleSubmit = async () => {
    if (files.length > 0) {
      createDocuments({
        variables: {
          serviceJobId,
          fileUploadIds: files.map((file) => file.id),
        },
      });
    }
  };

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        handleSubmit();
      }}
    >
      <div className="grid gap-4">
        <div>
          <FileField
            value={files}
            label="File"
            onChange={(value) => setFiles(value)}
          />
        </div>
      </div>
      <Divider />
      <Button
        disabled={!files}
        loading={loading}
        text="Upload Documents"
        type="submit"
      />
    </form>
  );
}
