import { AnimatePresence } from 'framer-motion';
import { useState } from 'react';
import ExpandableSection from '../../../../../../components/content/ExpandableSection';
import TextLink from '../../../../../../components/content/TextLink';
import Table from '../../../../../../components/content/table/Table';
import TableBody from '../../../../../../components/content/table/TableBody';
import TableCell from '../../../../../../components/content/table/TableCell';
import TableHeader from '../../../../../../components/content/table/TableHeader';
import TableHeaderCell from '../../../../../../components/content/table/TableHeaderCell';
import TableRow from '../../../../../../components/content/table/TableRow';
import Button from '../../../../../../components/controls/Button';
import ButtonLink from '../../../../../../components/controls/ButtonLink';
import { Response } from '../../../../queries/getLeadQuery';
import { OrderDetailsTray } from './components/OrderDetailsTray/OrderDetailsTray';
import { useDeleteOrderMutation } from './mutations/deleteOrderMutation';

type Props = {
  readonly leadId: string;
  readonly lineItems: Response['lead']['lineItems'];
  readonly orders: Response['lead']['orders'];
  readonly installerContact: Response['lead']['installerContact'];
  readonly onUpdate: () => Promise<any>;
};

export function LeadOrders({
  leadId,
  orders,
  installerContact,
  lineItems,
  onUpdate,
}: Props) {
  const [showDetailsTray, setShowDetailsTray] = useState(false);
  const [targetOrder, setTargetOrder] = useState<
    Response['lead']['orders'][number] | null
  >(null);

  const [deleteOrder, { loading: deleteOrderLoading }] = useDeleteOrderMutation(
    {
      onCompleted: () => onUpdate(),
    },
  );

  const closeTray = () => {
    setShowDetailsTray(false);
    setTargetOrder(null);
  };

  return (
    <>
      <ExpandableSection title="Orders" counter={orders.length}>
        <div className="mb-5">
          <Table>
            <TableHeader>
              <TableRow>
                <TableHeaderCell>#</TableHeaderCell>
                <TableHeaderCell>Supplier</TableHeaderCell>
                <TableHeaderCell>Status</TableHeaderCell>
                <TableHeaderCell>Products</TableHeaderCell>
                <TableHeaderCell />
              </TableRow>
            </TableHeader>
            <TableBody>
              {orders.length > 0 ? (
                orders.map((order) => (
                  <TableRow key={order.id}>
                    <TableCell>
                      <code>{order.orderNumber}</code>
                    </TableCell>
                    <TableCell>
                      {order.supplier ? (
                        <TextLink
                          to={`/inventory/suppliers/${order.supplier.id}`}
                        >
                          {order.supplier.name}
                        </TextLink>
                      ) : (
                        <>-</>
                      )}
                    </TableCell>
                    <TableCell>{order.status.name}</TableCell>
                    <TableCell>{order.lineItemsCount}</TableCell>
                    <TableCell rightAlign>
                      <div className="space-x-1">
                        <ButtonLink
                          text="View"
                          to={`/inventory/orders/${order.id}`}
                        />
                        <Button
                          size="sm"
                          text="Edit"
                          onClick={() => setTargetOrder(order)}
                        />
                        <Button
                          disabled={order.status.handle !== 'DRAFT'}
                          size="sm"
                          text="Delete"
                          loading={deleteOrderLoading}
                          onClick={() =>
                            deleteOrder({ variables: { id: order.id } })
                          }
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colspan={4}>No orders</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>

        <Button
          size="sm"
          disabled={!installerContact}
          text="Add"
          onClick={() => setShowDetailsTray(true)}
        />
      </ExpandableSection>

      <AnimatePresence>
        {(showDetailsTray || targetOrder) && (
          <OrderDetailsTray
            target={targetOrder ?? undefined}
            leadId={leadId}
            lineItems={lineItems}
            installerContact={installerContact}
            onCreate={() => onUpdate()}
            onClose={() => closeTray()}
          />
        )}
      </AnimatePresence>
    </>
  );
}
