import {
  ApolloClient,
  createHttpLink,
  from,
  InMemoryCache,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { TOKEN_STORAGE_KEY } from '../hooks/useToken';

export default new ApolloClient({
  link: from([
    setContext((request, { headers }) => {
      const token = localStorage.getItem(TOKEN_STORAGE_KEY);
      return {
        headers: {
          ...headers,
          authorization: token ? `Bearer ${token}` : '',
        },
      };
    }),
    createHttpLink({
      uri: process.env.REACT_APP_API_ENDPOINT + '/graphql',
    }),
  ]),
  cache: new InMemoryCache({}),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  },
});
