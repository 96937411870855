import { gql } from '@apollo/client';

export const MUTATION = gql`
  mutation updateServiceJobStatus($id: ID!, $statusId: ID!) {
    updateServiceJobStatus(id: $id, statusId: $statusId) {
      id
    }
  }
`;

export type Variables = {
  readonly id: string;
  readonly statusId: string;
};

export type Response = {
  readonly updateServiceJobStatus: {
    readonly id: string;
  };
};
