import { useMemo } from 'react';
import TableCell from '../../../../../components/content/table/TableCell';
import TableRow from '../../../../../components/content/table/TableRow';
import Button from '../../../../../components/controls/Button';
import ButtonLink from '../../../../../components/controls/ButtonLink';
import filters, { DATETIME_FORMAT_DATE } from '../../../../../filters';
import { Response } from '../queries/getLeadPoolsQuery';

type Props = {
  readonly pool: Response['leadPools'][number];
  readonly onDelete: () => void;
};

export default function PoolRow({ pool, onDelete }: Props) {
  const dialsText = useMemo(() => {
    if (pool.minDials !== null && pool.maxDials !== null) {
      return `${pool.minDials.toLocaleString()} to ${pool.maxDials.toLocaleString()}`;
    }

    if (pool.minDials !== null) {
      return `${pool.minDials.toLocaleString()} or more`;
    }

    if (pool.maxDials !== null) {
      return `${pool.maxDials.toLocaleString()} or less`;
    }

    return 'All';
  }, [pool]);

  const createdDateText = useMemo(() => {
    if (pool.fromCreatedDate && pool.toCreatedDate) {
      return `${filters.formatDate(
        pool.fromCreatedDate,
        DATETIME_FORMAT_DATE,
      )} to ${filters.formatDate(pool.toCreatedDate, DATETIME_FORMAT_DATE)}`;
    }

    if (pool.fromCreatedDate) {
      return `${filters.formatDate(
        pool.fromCreatedDate,
        DATETIME_FORMAT_DATE,
      )} or later`;
    }

    if (pool.toCreatedDate) {
      return `${filters.formatDate(
        pool.toCreatedDate,
        DATETIME_FORMAT_DATE,
      )} or earlier`;
    }

    return 'All Time';
  }, [pool]);

  return (
    <TableRow>
      <TableCell compress>{pool.id}</TableCell>
      <TableCell>{pool.name}</TableCell>
      <TableCell>{dialsText}</TableCell>
      <TableCell>
        <div className="whitespace-break-spaces">
          {pool.statuses.length > 0
            ? pool.statuses.map((status) => status.name).join(', ')
            : 'All'}
        </div>
      </TableCell>
      <TableCell>
        <div className="whitespace-break-spaces">
          {pool.leadSources.length > 0
            ? pool.leadSources.map((source) => source.name).join(', ')
            : 'All'}
        </div>
      </TableCell>
      <TableCell>{createdDateText}</TableCell>
      <TableCell>{pool.size.toLocaleString()}</TableCell>
      <TableCell rightAlign>
        <div className="space-x-1">
          <ButtonLink text="View" to={`/tools/lead-pools/${pool.id}`} />
          <Button size="sm" text="Delete" onClick={onDelete} />
        </div>
      </TableCell>
    </TableRow>
  );
}
