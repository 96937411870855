import { gql, MutationHookOptions, useMutation } from '@apollo/client';

export const MUTATION = gql`
  mutation updateServiceJob($id: ID!, $input: ServiceJobInput!) {
    updateServiceJob(id: $id, input: $input) {
      id
    }
  }
`;

export type Variables = {
  readonly id: string;
  readonly input: {
    readonly title: string;
    readonly description: string | null;
    readonly priority: number;
  };
};

export type Response = {
  readonly updateServiceJob: {
    readonly id: string;
  };
};

export const useUpdateServiceJobMutation = (
  options?: MutationHookOptions<Response, Variables>,
) => useMutation<Response, Variables>(MUTATION, options);
