import { ReactNode } from 'react';

type Props = {
  readonly children: ReactNode;
};

export function FilterControls({ children }: Props) {
  return (
    <aside className="mb-5 flex items-center space-x-5">
      <div className="flex-grow flex justify-end items-center space-x-2">
        {children}
      </div>
    </aside>
  );
}
