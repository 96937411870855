import { useEffect, useMemo, useState } from 'react';
import Table from '../../../../components/content/table/Table';
import TableBody from '../../../../components/content/table/TableBody';
import TableCell from '../../../../components/content/table/TableCell';
import TableHeader from '../../../../components/content/table/TableHeader';
import TableHeaderCell from '../../../../components/content/table/TableHeaderCell';
import TableRow from '../../../../components/content/table/TableRow';
import Button from '../../../../components/controls/Button';
import TextField from '../../../../components/controls/TextField';
import Tray from '../../../../components/layout/Tray';
import { useCreateOrderReturnMutation } from './mutations/createOrderReturnMutation';

type Props = {
  readonly orderId: string;
  readonly lineItems: readonly {
    readonly id: string;
    readonly used: number;
    readonly received: number;
    readonly product: {
      readonly id: string;
      readonly name: string;
    };
  }[];
  readonly returns: readonly {
    readonly lineItems: readonly {
      readonly quantity: number;
      readonly product: {
        readonly id: string;
      };
    }[];
  }[];
  readonly onClose: () => void;
  readonly onCreateOrderReturn: () => void;
};

export function CreateReturnTray({
  orderId,
  lineItems,
  returns,
  onClose,
  onCreateOrderReturn,
}: Props) {
  const [returnValues, setReturnValues] = useState<Record<string, string>>({});

  const [createOrderReturn, { loading }] = useCreateOrderReturnMutation({
    variables: {
      input: {
        orderId,
        lineItems: Object.entries(returnValues)
          .map(([productId, quantity]) => ({
            productId,
            quantity: parseInt(quantity, 10),
          }))
          .filter((line) => line.quantity > 0),
      },
    },
    onCompleted: () => onCreateOrderReturn(),
  });

  const returnable = useMemo(() => {
    return lineItems
      .map((line) => {
        const returned = returns
          .map((ret) => ret.lineItems)
          .flat()
          .find((returnedLine) => returnedLine.product.id === line.product.id);

        return {
          ...line,
          unused: line.received - line.used - (returned?.quantity ?? 0),
        };
      })
      .filter((line) => line.unused > 0);
  }, [lineItems, returns]);

  useEffect(() => {
    setReturnValues(
      returnable.reduce(
        (items, value) => ({ ...items, [value.product.id]: '0' }),
        {},
      ),
    );
  }, [returnable]);

  const totalReturnQty = useMemo(
    () =>
      Object.values(returnValues).reduce(
        (total, value) => parseInt(value, 10) + total,
        0,
      ),
    [returnValues],
  );

  return (
    <Tray
      heading="Create Return"
      onClose={onClose}
      size="lg"
      actions={
        <>
          <Button
            primary
            loading={loading}
            disabled={totalReturnQty === 0}
            text={
              totalReturnQty == 0
                ? 'Return'
                : `Return ${totalReturnQty} Item${
                    totalReturnQty === 1 ? '' : 's'
                  }`
            }
            onClick={() => createOrderReturn()}
          />
        </>
      }
    >
      <div className="p-5">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHeaderCell>Product</TableHeaderCell>
              <TableHeaderCell>Unused</TableHeaderCell>
              <TableHeaderCell>Return Qty</TableHeaderCell>
            </TableRow>
          </TableHeader>
          <TableBody>
            {returnable.map((line) => (
              <TableRow key={line.id}>
                <TableCell>{line.product.name}</TableCell>
                <TableCell>{line.unused}</TableCell>
                <TableCell>
                  <TextField
                    value={returnValues[line.product.id] ?? ''}
                    onChange={(value) => {
                      const parsed = parseInt(value, 10);

                      setReturnValues({
                        ...returnValues,
                        [line.product.id]: Number.isFinite(parsed)
                          ? Math.min(line.unused, Math.abs(parsed)).toString()
                          : '0',
                      });
                    }}
                    placeholder="0"
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </Tray>
  );
}
