import { gql, QueryHookOptions, useQuery } from '@apollo/client';

const QUERY = gql`
  query getLeadPools {
    leadPools {
      id
      created
      name
      size
      minDials
      maxDials
      fromCreatedDate
      toCreatedDate
      leadSources {
        id
        name
      }
      statuses {
        id
        name
        handle
      }
    }
  }
`;

export type Variables = {};

export type Response = {
  readonly leadPools: {
    readonly id: number;
    readonly created: string;
    readonly name: string;
    readonly size: number;
    readonly minDials: number | null;
    readonly maxDials: number | null;
    readonly fromCreatedDate: string | null;
    readonly toCreatedDate: string | null;
    readonly leadSources: {
      readonly id: number;
      readonly name: string;
    }[];
    readonly statuses: {
      readonly id: number;
      readonly name: string;
      readonly handle: string;
    }[];
  }[];
};

export const useGetLeadPoolsQuery = (
  options?: QueryHookOptions<Response, Variables>,
) => useQuery<Response, Variables>(QUERY, options);
