import { gql } from '@apollo/client';

export const MUTATION = gql`
  mutation($leadId: Float!, $designId: String!) {
    attachPylonDesign(leadId: $leadId, designId: $designId) {
      pylonDesignId
    }
  }
`;

export type Response = {
  readonly attachPylonDesign: {
    readonly pylonDesignId: string;
  };
};
