import { Link } from 'react-router-dom';
import Box from '../../../../../components/content/Box';
import MetaBlock from '../../../../../components/content/MetaBlock';
import filters, { DATETIME_FORMAT_MEDIUM } from '../../../../../filters';
import { Response } from '../queries/getProductQuery';

type Props = {
  readonly product: Response['product'];
};

export default function ProductMeta({ product }: Props) {
  return (
    <aside className="lg:w-96">
      <Box>
        <MetaBlock label="Created">
          {filters.formatDate(product.created, DATETIME_FORMAT_MEDIUM)}
        </MetaBlock>
        <MetaBlock label="Updated">
          {filters.formatDate(product.updated, DATETIME_FORMAT_MEDIUM)}
        </MetaBlock>
        <MetaBlock label="On Hand">{product.itemsOnHandCount}</MetaBlock>
        {product.parent && (
          <MetaBlock label="Part Of">
            <Link
              className="text-primary underline hover:text-black"
              to={`/inventory/products/${product.parent.id}`}
            >
              {product.parent.name}
            </Link>{' '}
            ({product.parent.sku})
          </MetaBlock>
        )}
      </Box>
    </aside>
  );
}
