import { gql } from '@apollo/client';

export const MUTATION = gql`
  mutation($id: Int!, $input: UpsertInstallerInput!) {
    updateInstaller(id: $id, input: $input) {
      id
    }
  }
`;

export type Response = {
  readonly updateInstaller: {
    readonly id: number;
  };
};
