import { useEffect, useMemo, useState } from 'react';
import Table from '../../../../../../../../components/content/table/Table';
import TableBody from '../../../../../../../../components/content/table/TableBody';
import TableCell from '../../../../../../../../components/content/table/TableCell';
import TableHeader from '../../../../../../../../components/content/table/TableHeader';
import TableHeaderCell from '../../../../../../../../components/content/table/TableHeaderCell';
import TableRow from '../../../../../../../../components/content/table/TableRow';
import Button from '../../../../../../../../components/controls/Button';
import CheckboxField from '../../../../../../../../components/controls/CheckboxField';
import DropdownField from '../../../../../../../../components/controls/DropdownField';
import Tray from '../../../../../../../../components/layout/Tray';
import { useGetSuppliers } from '../../../../../../../../hooks/useGetSuppliers';
import { Response } from '../../../../../../queries/getLeadQuery';
import { useStartOrderMutation } from './mutations/startOrderMutation';
import { useUpdateOrderMutation } from './mutations/updateOrderMutation';

type Props = {
  readonly leadId: string;
  readonly lineItems: Response['lead']['lineItems'];
  readonly target?: Response['lead']['orders'][number];
  readonly installerContact: Response['lead']['installerContact'];
  readonly onClose: () => void;
  readonly onCreate: () => void;
};

export function OrderDetailsTray({
  leadId,
  lineItems,
  target,
  installerContact,
  onClose,
  onCreate,
}: Props) {
  const suppliers = useGetSuppliers();

  const [supplierId, setSupplierId] = useState<string>();
  const [selectedLineItemIds, setSelectedLineItemIds] = useState<
    readonly string[]
  >([]);

  const selectedLineItems = useMemo(() => {
    return lineItems.filter((line) => selectedLineItemIds.includes(line.id));
  }, [selectedLineItemIds, lineItems]);

  const [startOrder, { loading: startOrderLoading }] = useStartOrderMutation({
    variables: {
      input: {
        leadId,
        supplierId,
        lineItems: selectedLineItems.map((selectedLineItem) => ({
          productId: selectedLineItem.product.id,
          quantity: selectedLineItem.quantity,
        })),
      },
    },
    onCompleted: () => {
      onCreate();
      onClose();
    },
  });

  const [updateOrder, { loading: updateOrderLoading }] = useUpdateOrderMutation(
    {
      onCompleted: () => {
        onCreate();
        onClose();
      },
    },
  );

  const canSubmit = useMemo(() => {
    return selectedLineItems.length > 0;
  }, [selectedLineItems]);

  useEffect(() => {
    if (target) {
      setSupplierId(target.supplier?.id);
      setSelectedLineItemIds(
        lineItems
          .filter((line) =>
            target.lineItems.some(
              (targetLineItem) => targetLineItem.product.id === line.product.id,
            ),
          )
          .map((line) => line.id),
      );
    }
  }, [target, lineItems]);

  return (
    <Tray
      size="lg"
      heading={target ? `Edit ${target.orderNumber}` : 'New Order'}
      onClose={onClose}
      actions={
        target ? (
          <Button
            disabled={!canSubmit}
            loading={updateOrderLoading}
            primary
            text="Update"
            onClick={() =>
              updateOrder({
                variables: {
                  id: target.id,
                  input: {
                    supplierId,
                    lineItems: selectedLineItems.map((selectedLineItem) => ({
                      productId: selectedLineItem.product.id,
                      quantity: selectedLineItem.quantity,
                    })),
                  },
                },
              })
            }
          />
        ) : (
          <Button
            text="Save"
            disabled={!canSubmit}
            primary
            loading={startOrderLoading}
            onClick={() => startOrder()}
          />
        )
      }
    >
      <div className="p-5">
        <div className="grid grid-cols-2 gap-2 mb-5">
          <div className="bg-gray-100 rounded-md p-3">
            <div className="text-sm text-gray-500 mb-2">Installer</div>
            <p>{installerContact?.name ?? '-'}</p>
          </div>
          <div className="bg-gray-100 rounded-md p-3">
            <div className="text-sm text-gray-500 mb-2">Supplier</div>
            <DropdownField
              placeholder="Undecided..."
              options={suppliers.map((supplier) => ({
                text: supplier.name,
                value: supplier.id.toString(),
              }))}
              value={supplierId}
              onChange={setSupplierId}
            />
          </div>
        </div>
        <div className="prose max-w-none mb-5">
          <p>
            Select products from the associated lead that should be included in
            this order. Faded out products are already included in another order
            and cannot be selected.
          </p>
        </div>
        <Table>
          <TableHeader>
            <TableRow>
              <TableHeaderCell />
              <TableHeaderCell>Product</TableHeaderCell>
              <TableHeaderCell rightAlign>Quantity</TableHeaderCell>
            </TableRow>
          </TableHeader>
          <TableBody>
            {lineItems.map((line) => {
              const getCanEdit = () => {
                if (target) {
                  // If editing a target order, allow alteration of line items
                  // that are included in that order.
                  const includesProduct = target.lineItems.some(
                    (targetLineItem) =>
                      targetLineItem.product.id === line.product.id,
                  );

                  if (includesProduct) {
                    return true;
                  }
                }

                return line.allocated !== line.quantity;
              };

              const canEdit = getCanEdit();

              return (
                <TableRow
                  key={line.id}
                  theme={!canEdit ? 'inactive' : undefined}
                >
                  <TableCell>
                    <CheckboxField
                      disabled={!canEdit}
                      checked={selectedLineItemIds.includes(line.id)}
                      onChange={(value) =>
                        setSelectedLineItemIds(
                          value
                            ? selectedLineItemIds.concat(line.id)
                            : selectedLineItemIds.filter(
                                (entry) => entry !== line.id,
                              ),
                        )
                      }
                    />
                  </TableCell>
                  <TableCell>{line.product.name}</TableCell>
                  <TableCell rightAlign>{line.quantity}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
    </Tray>
  );
}
