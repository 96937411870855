import {
  faCheck,
  faExclamation,
  faTimes,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';

type Props = {
  readonly value: 'good' | 'bad' | 'warning';
};

const theme = (value: Props['value']) =>
  clsx(
    'w-5 h-5 rounded-full shadow-sm flex items-center text-center text-white text-xs',
    {
      'bg-red-500': value === 'bad',
      'bg-yellow-500': value === 'warning',
      'bg-green-500': value === 'good',
    },
  );

const icon = (value: Props['value']) => {
  if (value === 'good') return faCheck;
  if (value === 'warning') return faExclamation;

  return faTimes;
};

export default function Status({ value }: Props) {
  return (
    <span className={theme(value)}>
      <FontAwesomeIcon className="mx-auto" icon={icon(value)} />
    </span>
  );
}
