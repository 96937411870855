import DropdownField from '../../controls/DropdownField';
import React from 'react';
import { useGetRoofTypes } from '../../../hooks/useGetRoofTypes';

type Props = {
  readonly value?: string;
  readonly onChange: (value?: string) => void;
};

export default function RoofTypesDropdown({ value, onChange }: Props) {
  const roofTypes = useGetRoofTypes();

  return (
    <DropdownField
      label="Roof Type"
      placeholder="Roof Type"
      value={value}
      options={roofTypes.map((roofType) => ({
        text: roofType.name,
        value: roofType.id.toString(),
      }))}
      onChange={onChange}
    />
  );
}
