import { gql } from '@apollo/client';

export const QUERY = gql`
  query getLeadPools {
    leadPools {
      id
      name
    }
  }
`;

export type Variables = {};

export type Response = {
  readonly leadPools: {
    readonly id: number;
    readonly name: string;
  }[];
};
