import { MutationHookOptions, gql, useMutation } from '@apollo/client';

const MUTATION = gql`
  mutation makeTentativeClaim {
    makeTentativeClaim {
      id
      created
      expires
      lead {
        id
      }
    }
  }
`;

export type Variables = {};

export type Response = {
  readonly makeTentativeClaim: {
    readonly id: number;
    readonly created: string;
    readonly expires: string;
    readonly lead: {
      readonly id: number;
    };
  };
};

export const useMakeTentativeClaimMutation = (
  options: MutationHookOptions<Response, Variables>,
) => useMutation<Response, Variables>(MUTATION, options);
