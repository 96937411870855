import { faExternalLinkAlt } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getPurchaseOrderDocumentURL } from '../../../support';

type Props = {
  readonly orderIds: readonly string[];
  readonly secret: string;
  readonly combinedOrderId?: string;
};

export default function PurchaseOrderLinks({
  orderIds,
  secret,
  combinedOrderId,
}: Props) {
  const getPreviewUrl = (format: 'html' | 'pdf') => {
    return getPurchaseOrderDocumentURL({
      orderIds,
      secret,
      format,
      combinedOrderId,
    });
  };

  return (
    <div className="flex items-center space-x-2">
      <a
        className="flex items-center space-x-1 text-primary hover:text-black underline"
        href={getPreviewUrl('html')}
        target="_blank"
      >
        <span>HTML</span>
        <FontAwesomeIcon size="2xs" icon={faExternalLinkAlt} />
      </a>
      <a
        className="flex items-center space-x-1 text-primary hover:text-black underline"
        href={getPreviewUrl('pdf')}
        target="_blank"
      >
        <span>PDF</span>
        <FontAwesomeIcon size="2xs" icon={faExternalLinkAlt} />
      </a>
    </div>
  );
}
