import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Table from '../../../../../../components/content/table/Table';
import TableBody from '../../../../../../components/content/table/TableBody';
import TableCell from '../../../../../../components/content/table/TableCell';
import TableHeader from '../../../../../../components/content/table/TableHeader';
import TableHeaderCell from '../../../../../../components/content/table/TableHeaderCell';
import TableRow from '../../../../../../components/content/table/TableRow';
import TextLink from '../../../../../../components/content/TextLink';
import Button from '../../../../../../components/controls/Button';
import ButtonLink from '../../../../../../components/controls/ButtonLink';
import CheckboxField from '../../../../../../components/controls/CheckboxField';
import { Response } from '../../queries/getOrderQuery';
import { useCreateCombinedOrderMutation } from './mutations/createCombinedOrderMutation';

type Props = {
  readonly orderId: string;
  readonly orders: Response['order']['combinableOrders'];
};

export function CombinableOrders({ orderId, orders }: Props) {
  const navigate = useNavigate();

  const [selected, setSelected] = useState<readonly string[]>([]);

  const [combineOrders, { loading }] = useCreateCombinedOrderMutation({
    variables: {
      input: {
        orderIds: selected.concat(orderId),
      },
    },
    onCompleted: (data) =>
      navigate(`/inventory/combined-orders/${data.createCombinedOrder.id}`),
  });

  if (orders.length === 0) {
    return <p>No similar orders were found.</p>;
  }

  return (
    <>
      <section className="mb-5">
        <p>
          These orders can be combined as they reference the same supplier and
          installer.
        </p>
      </section>
      <div className="mb-5">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHeaderCell />
              <TableHeaderCell>#</TableHeaderCell>
              <TableHeaderCell>Lead</TableHeaderCell>
              <TableHeaderCell>Status</TableHeaderCell>
              <TableHeaderCell />
            </TableRow>
          </TableHeader>
          <TableBody>
            {orders.map((order) => (
              <TableRow key={order.id}>
                <TableCell compress>
                  <CheckboxField
                    checked={selected.includes(order.id)}
                    onChange={(value) =>
                      setSelected(
                        value
                          ? selected.concat(order.id)
                          : selected.filter((id) => id !== order.id),
                      )
                    }
                  />
                </TableCell>
                <TableCell>
                  <code>{order.orderNumber}</code>
                </TableCell>
                <TableCell>
                  <TextLink to={`/leads/${order.lead.id}`}>
                    {order.lead.id} &ndash;{' '}
                    {order.lead.customer.fullName ?? order.lead.customer.email}
                  </TextLink>
                </TableCell>
                <TableCell>{order.status.name}</TableCell>
                <TableCell rightAlign>
                  <ButtonLink
                    to={`/inventory/orders/${order.id}`}
                    text="View"
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>

      <Button
        size="sm"
        text="Combine Orders"
        disabled={selected.length === 0}
        loading={loading}
        onClick={() => combineOrders()}
      />
    </>
  );
}
