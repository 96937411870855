import { gql, QueryHookOptions, useQuery } from '@apollo/client';

const QUERY = gql`
  query getCommissionTiers {
    commissionTiers {
      id
      value
      archived
    }
  }
`;

export type Response = {
  readonly commissionTiers: readonly {
    readonly id: string;
    readonly value: number;
    readonly archived: boolean;
  }[];
};

export type CommissionTier = Response['commissionTiers'][number];

export const useGetCommissionTiers = (
  hideArchived = true,
  options?: QueryHookOptions<Response>,
) => {
  const { data } = useQuery<Response>(QUERY, {
    fetchPolicy: 'cache-first',
    ...options,
  });

  const value = data?.commissionTiers ?? [];

  return hideArchived ? value.filter((tier) => !tier.archived) : value;
};
