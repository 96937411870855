import { gql, MutationHookOptions } from '@apollo/client';
import { useModifiedMutation } from '../../../../../../../apollo/hooks/useModifiedMutation';

const MUTATION = gql`
  mutation updateLineItems($input: [UpdateOrderLineItemInput!]!) {
    updateLineItems(input: $input) {
      id
    }
  }
`;

export type Variables = {
  readonly input: readonly {
    readonly id: string;
    readonly quantity: number;
    readonly received: number;
    readonly used: number;
    readonly cost: number | null;
  }[];
};

export type Response = {
  readonly updateLineItems: readonly {
    readonly id: string;
  }[];
};

export const useUpdateLineItemsMutation = (
  options?: MutationHookOptions<Response, Variables>,
) => useModifiedMutation<Response, Variables>(MUTATION, options);
