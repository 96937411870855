import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import { OrderStatusHandle } from '../../../../../globals';

const QUERY = gql`
  query getOrders($input: GetOrdersInput!) {
    orders(input: $input) {
      id
      orderNumber
      created
      sentToSupplierAt
      status {
        name
      }
      supplier {
        id
        name
      }
      lead {
        id
        customer {
          fullName
        }
      }
    }
    ordersCount(input: $input)
  }
`;

export type Variables = {
  readonly input: {
    readonly statuses?: readonly OrderStatusHandle[];
    readonly supplierIds?: readonly string[];
  };
};

export type Response = {
  readonly orders: readonly {
    readonly id: string;
    readonly orderNumber: string;
    readonly created: string;
    readonly sentToSupplierAt: string | null;
    readonly status: {
      readonly name: string;
    };
    readonly supplier: {
      readonly id: string;
      readonly name: string;
    } | null;
    readonly lead: {
      readonly id: string;
      readonly customer: {
        readonly fullName: string;
      } | null;
    };
  }[];
  readonly ordersCount: number;
};

export const useGetOrdersQuery = (
  options?: QueryHookOptions<Response, Variables>,
) => useQuery<Response, Variables>(QUERY, options);
