import { useMemo } from 'react';
import Box from '../../../../components/content/Box';
import filters from '../../../../filters';
import { useGetUserWelcomeDataQuery } from './queries/getUserWelcomeDataQuery';

export function WelcomeBox() {
  const timeTerm = useMemo(() => {
    const date = new Date();

    if (date.getHours() < 12) return 'morning';
    if (date.getHours() < 18) return 'afternoon';

    return 'evening';
  }, []);

  const { data } = useGetUserWelcomeDataQuery({
    variables: {
      month: new Date().getMonth() + 1,
      year: new Date().getFullYear(),
    },
  });

  if (!data?.me.user) return <Box>Loading&hellip;</Box>;

  const user = data.me.user;
  const { report, welcomeData } = user;

  const conversion = welcomeData.target
    ? ((report.totalClaims / welcomeData.target) * 100).toFixed(1)
    : 0;

  return (
    <Box>
      <div className="prose max-w-none">
        <p>
          Good {timeTerm} {user.firstName}!
        </p>
        <p>
          Yesterday you claimed {report.totalClaimsYesterday} lead
          {report.totalClaimsYesterday === 1 ? '' : 's'} and so far today you
          have claimed {report.totalClaimsToday} lead
          {report.totalClaimsToday === 1 ? '' : 's'}. This month you have
          claimed a total of {report.totalClaims} lead
          {report.totalClaims === 1 ? '' : 's'} and your current conversion rate
          is{' '}
          {report.totalClaims > 0
            ? filters.formatPercentage(
                report.totalSales / report.totalClaims || 0,
                1,
              )
            : '0.0%'}
          .
        </p>
        <p>
          Your target for the month is {welcomeData.target} and you have
          achieved {conversion}% of your target.
        </p>
        {welcomeData.welcomeMessage && <p>{welcomeData.welcomeMessage}</p>}
      </div>
    </Box>
  );
}
