import { useMemo, useState } from 'react';
import Button from '../../../../components/controls/Button';
import TextField from '../../../../components/controls/TextField';
import Tray from '../../../../components/layout/Tray';
import { useCreateSupplierContactMutation } from './mutation/createSupplierContactMutation';

type Props = {
  readonly supplierId: number;
  readonly onClose: () => void;
  readonly onCreate: (id: string) => Promise<any>;
};

export function CreateSupplierContactTray({
  supplierId,
  onClose,
  onCreate,
}: Props) {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');

  const [createSupplierContact, { loading }] = useCreateSupplierContactMutation(
    {
      variables: {
        input: {
          name,
          supplierId,
          email,
          phone,
        },
      },
      onCompleted: async (data) => {
        await onCreate(data.createSupplierContact.id);
        onClose();
      },
    },
  );

  const disabled = useMemo(() => {
    return name.length === 0;
  }, [name]);

  return (
    <Tray
      heading="New Supplier Contact"
      onClose={onClose}
      actions={
        <>
          <Button
            disabled={disabled}
            loading={loading}
            primary
            text="Create"
            onClick={() => createSupplierContact()}
          />
        </>
      }
    >
      <div>
        <h4 className="px-5 pt-5 font-bold text-gray-600">Details</h4>
        <div className="grid p-5 gap-5 md:grid-cols-2">
          <div className="md:col-span-2">
            <TextField
              value={name}
              onChange={setName}
              label="Name"
              placeholder="Name"
            />
          </div>
          <TextField
            value={email}
            onChange={setEmail}
            label="Email"
            placeholder="Email"
          />
          <TextField
            value={phone}
            onChange={setPhone}
            label="Phone"
            placeholder="Phone"
          />
        </div>
      </div>
    </Tray>
  );
}
