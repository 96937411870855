import { gql, QueryHookOptions, useQuery } from '@apollo/client';

const QUERY = gql`
  query getRoofTypes {
    roofTypes {
      id
      name
    }
  }
`;

export type Response = {
  readonly roofTypes: readonly {
    readonly id: string;
    readonly name: string;
  }[];
};

export type RoofType = Response['roofTypes'][number];

export const useGetRoofTypes = (options?: QueryHookOptions<Response>) => {
  const { data } = useQuery<Response>(QUERY, {
    fetchPolicy: 'cache-first',
    ...options,
  });

  return data?.roofTypes ?? [];
};
