import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useParams } from 'react-router-dom';
import CapabilityGuard from '../../components/CapabilityGuard';
import Box from '../../components/content/Box';
import Container from '../../components/content/Container';
import Page from '../../components/content/Page';
import Table from '../../components/content/table/Table';
import TableBody from '../../components/content/table/TableBody';
import TableCell from '../../components/content/table/TableCell';
import TableHeader from '../../components/content/table/TableHeader';
import TableHeaderCell from '../../components/content/table/TableHeaderCell';
import TableRow from '../../components/content/table/TableRow';
import ButtonLink from '../../components/controls/ButtonLink';
import Pagination from '../../components/controls/Pagination';
import Loading from '../../components/misc/Loading';
import SubNavigation from '../../components/navigation/SubNavigation';
import filters, { DATETIME_FORMAT_MEDIUM } from '../../filters';
import { useGetServiceJobsQuery } from './queries/serviceJobsQuery';

const LIMIT = 25;

type Params = {
  readonly status: 'open' | 'closed';
};

export function ServiceJobsView() {
  const navigate = useNavigate();
  const { status } = useParams<Params>();

  const [offset, setOffset] = useState(0);

  const { data, loading } = useGetServiceJobsQuery({
    variables: {
      statuses: [status ?? 'open'],
      offset,
      limit: LIMIT,
    },
  });

  useEffect(() => {
    if (!status) {
      navigate('/service-jobs/status/open');
    }
  }, [history, status]);

  return (
    <CapabilityGuard requirements={['canViewServiceJobs']}>
      <Helmet>
        <title>Service Jobs | Doug CRM</title>
      </Helmet>

      <SubNavigation
        heading={
          data ? `Service Jobs (${data.countServiceJobs})` : 'Service Jobs'
        }
        tabs={[
          { text: 'Open', url: '/service-jobs/status/open' },
          { text: 'Closed', url: '/service-jobs/status/closed' },
        ]}
      />

      <Page>
        <Container>
          {loading ? (
            <Box>
              <Loading />
            </Box>
          ) : data && data.serviceJobs.length > 0 ? (
            <>
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHeaderCell>ID</TableHeaderCell>
                    <TableHeaderCell>Created</TableHeaderCell>
                    <TableHeaderCell>Updated</TableHeaderCell>
                    <TableHeaderCell>Title</TableHeaderCell>
                    <TableHeaderCell>Customer</TableHeaderCell>
                    <TableHeaderCell />
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {data.serviceJobs.map((job) => {
                    const getTheme = () => {
                      if (job.priority === 1) return 'bad';
                      if (job.priority === 2) return 'warn';
                      if (job.priority === 3) return 'good';

                      return undefined;
                    };

                    return (
                      <TableRow theme={getTheme()} key={job.id}>
                        <TableCell>{job.id}</TableCell>
                        <TableCell>
                          {filters.formatDate(
                            job.created,
                            DATETIME_FORMAT_MEDIUM,
                          )}
                        </TableCell>
                        <TableCell>
                          {filters.formatDate(
                            job.updated,
                            DATETIME_FORMAT_MEDIUM,
                          )}
                        </TableCell>
                        <TableCell>{job.title}</TableCell>
                        <TableCell>
                          {job.customer.fullName} &lt;{job.customer.email}&gt;
                        </TableCell>
                        <TableCell rightAlign>
                          <ButtonLink
                            text="View"
                            to={`/service-jobs/${job.id}`}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>

              <Pagination
                offset={offset}
                limit={LIMIT}
                total={data.countServiceJobs}
                onChange={(value) => setOffset(value)}
              />
            </>
          ) : (
            <Box>No service jobs.</Box>
          )}
        </Container>
      </Page>
    </CapabilityGuard>
  );
}
