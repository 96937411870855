import { gql, QueryHookOptions, useQuery } from '@apollo/client';

const QUERY = gql`
  query getLeadActivities($leadId: ID!) {
    leadActivities(leadId: $leadId) {
      id
      timestamp
      agent {
        email
        fullName
      }
      type
      data
      summary
    }
  }
`;

export type Variables = {
  readonly leadId: string;
};

export type Response = {
  readonly leadActivities: readonly {
    readonly id: string;
    readonly timestamp: string;
    readonly agent: {
      readonly email: string;
      readonly fullName: string;
    };
    readonly type: string;
    readonly data: Record<string, any>;
    readonly summary: string | null;
  }[];
};

export const useGetLeadActivitiesQuery = (
  options: QueryHookOptions<Response, Variables>,
) => useQuery<Response, Variables>(QUERY, options);
