import Table from '../../../../../components/content/table/Table';
import TableBody from '../../../../../components/content/table/TableBody';
import TableCell from '../../../../../components/content/table/TableCell';
import TableHeader from '../../../../../components/content/table/TableHeader';
import TableHeaderCell from '../../../../../components/content/table/TableHeaderCell';
import TableRow from '../../../../../components/content/table/TableRow';
import { Response } from '../queries/getPylonDesign';
import PylonDesignDataRow from './PylonDesignDataRow';

type Props = {
  readonly pylonDesign: Response['pylonDesign'];
};

export default function PylonDesignDataSection({ pylonDesign }: Props) {
  return (
    <>
      <div className="grid md:grid-cols-4 md:gap-x-4 gap-y-2 md:gap-y-0 overflow-hidden">
        <PylonDesignDataRow heading="Description">
          {pylonDesign.description ?? <>&ndash;</>}
        </PylonDesignDataRow>
        <PylonDesignDataRow heading="Proposal PDF">
          {pylonDesign.pdf ? (
            <a
              className="text-blue-600 hover:text-blue-500 overflow-ellipsis"
              href={pylonDesign.pdf}
              target="_blank"
              rel="noreferrer"
            >
              {pylonDesign.pdf}
            </a>
          ) : (
            <>&ndash;</>
          )}
        </PylonDesignDataRow>
        <PylonDesignDataRow heading="System Size">
          {pylonDesign.systemSize ? (
            <>{pylonDesign.systemSize}kW</>
          ) : (
            <>&ndash;</>
          )}
        </PylonDesignDataRow>
        <PylonDesignDataRow heading="Battery Size">
          {pylonDesign.batterySize ? (
            <>{pylonDesign.batterySize}kWh</>
          ) : (
            <>&ndash;</>
          )}
        </PylonDesignDataRow>
        <PylonDesignDataRow heading="Subtotal">
          {pylonDesign.subtotal ?? <>&ndash;</>}
        </PylonDesignDataRow>
        {pylonDesign.stcCount && (
          <PylonDesignDataRow heading={'STCs x' + pylonDesign.stcCount}>
            -{pylonDesign.stcTotal ?? <>&ndash;</>}
          </PylonDesignDataRow>
        )}

        <PylonDesignDataRow heading="Total">
          {pylonDesign.total ?? <>&ndash;</>}
        </PylonDesignDataRow>
      </div>

      <div className="pt-4">
        <p className="mb-2">
          <strong>Components</strong>
        </p>
        <Table>
          <TableHeader>
            <TableRow>
              <TableHeaderCell>Description</TableHeaderCell>
              <TableHeaderCell>Type</TableHeaderCell>
              <TableHeaderCell rightAlign>Quantity</TableHeaderCell>
            </TableRow>
          </TableHeader>
          <TableBody>
            {pylonDesign.modules.map((module, index) => (
              <TableRow key={index}>
                <TableCell>{module.description}</TableCell>
                <TableCell>
                  <span className="uppercase text-xs">{module.type}</span>
                </TableCell>
                <TableCell rightAlign>x{module.quantity}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </>
  );
}
