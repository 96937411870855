import { useEffect, useState } from 'react';
import { useUpdateServiceJobMutation } from './mutations/updateServiceJobMutation';
import TextField from '../../../../components/controls/TextField';
import DropdownField from '../../../../components/controls/DropdownField';
import Button from '../../../../components/controls/Button';

type Props = {
  readonly job: {
    readonly title: string;
    readonly description: string | null;
    readonly priority: number;
  };
  readonly serviceJobId: string;
  readonly onUpdate: () => void;
};

export function ServiceJobDetails({ job, serviceJobId, onUpdate }: Props) {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [priority, setPriority] = useState('3');

  const [updateServiceJob, { loading }] = useUpdateServiceJobMutation({
    variables: {
      id: serviceJobId,
      input: {
        title,
        description,
        priority: parseInt(priority),
      },
    },
    onCompleted: () => {
      onUpdate();
    },
  });

  useEffect(() => {
    setTitle(job.title);
    setDescription(job.description ?? '');
    setPriority(job.priority.toString());
  }, [job]);

  return (
    <>
      <form
        onSubmit={(event) => {
          event.preventDefault();
          updateServiceJob();
        }}
      >
        <div className="grid gap-4">
          <div>
            <TextField
              label="Title"
              value={title}
              onChange={(value) => setTitle(value)}
              placeholder="Title"
            />
          </div>
          <div>
            <TextField
              label="Description"
              value={description}
              onChange={(value) => setDescription(value)}
              placeholder="Description"
            />
          </div>
          <div>
            <DropdownField
              label="Priority"
              options={[
                { text: 'High', value: '1' },
                { text: 'Medium', value: '2' },
                { text: 'Low', value: '3' },
              ]}
              value={priority}
              onChange={(value) => setPriority(value ?? '3')}
            />
          </div>

          <div>
            <Button type="submit" primary text="Update" loading={loading} />
          </div>
        </div>
      </form>
    </>
  );
}
