import { ReactNode, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

type Props = {
  readonly children: ReactNode;
};

export default function PortalMount({ children }: Props) {
  const mount: Element | null =
    typeof window !== 'undefined'
      ? (document.querySelector('#portal-mount') as Element)
      : null;

  const [mounted, setMounted] = useState(false);

  useEffect(() => setMounted(true));

  return mount && mounted ? createPortal(children, mount) : null;
}
