import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { faPhone } from '@fortawesome/pro-light-svg-icons';

type Props = {
  readonly number: string;
};

export default function LeadCallButton({ number }: Props) {
  return (
    <a
      className="inline-block rounded-full px-3 py-1 border border-gray-300 hover:bg-primary hover:text-white hover:border-primary"
      href={'tel:' + number}
    >
      <FontAwesomeIcon icon={faPhone} className="mr-2" />
      {number}
    </a>
  );
}
