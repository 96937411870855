import { useMutation } from '@apollo/client';
import { useState } from 'react';
import ErrorMessage from '../../../../../components/content/ErrorMessage';
import Button from '../../../../../components/controls/Button';
import TextField from '../../../../../components/controls/TextField';
import Modal from '../../../../../components/overlay/Modal';
import { MUTATION, Response } from '../mutations/createInstallerContact';

type Props = {
  readonly installerId: number;
  readonly onClose: () => void;
  readonly onAddInstallerContact: () => void;
};

export default function AddInstallerContactModal({
  installerId,
  onClose,
  onAddInstallerContact,
}: Props) {
  const [name, setName] = useState('');
  const [electricalLicenceNumber, setElectricalLicenceNumber] = useState('');
  const [cecAccrediationNumber, setCecAccrediationNumber] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');

  const [createInstallerContact, { loading, error }] = useMutation<Response>(
    MUTATION,
    {
      variables: {
        installerId,
        input: {
          name,
          electricalLicenceNumber,
          cecAccrediationNumber,
          email,
          phone,
        },
      },
      onCompleted: () => {
        onClose();
        return onAddInstallerContact();
      },
    },
  );

  return (
    <Modal heading="Add Contact" onClose={onClose}>
      <form
        onSubmit={(event) => {
          event.preventDefault();
          createInstallerContact();
        }}
      >
        <div className="grid gap-4 grid-cols-2 mb-4">
          <div className="col-span-2">
            <TextField
              label="Name"
              placeholder="Name"
              value={name}
              onChange={(value) => setName(value)}
            />
          </div>
          <div>
            <TextField
              label="Electrical Licence Number"
              placeholder="Electrical Licence Number"
              value={electricalLicenceNumber}
              onChange={(value) => setElectricalLicenceNumber(value)}
            />
          </div>
          <div>
            <TextField
              label="CEC Accrediation Number"
              placeholder="CEC Accrediation Number"
              value={cecAccrediationNumber}
              onChange={(value) => setCecAccrediationNumber(value)}
            />
          </div>
          <div>
            <TextField
              label="Email Address"
              placeholder="Email Address"
              value={email}
              onChange={(value) => setEmail(value)}
            />
          </div>
          <div>
            <TextField
              label="Phone Number"
              placeholder="Phone Number"
              value={phone}
              onChange={(value) => setPhone(value)}
            />
          </div>
        </div>

        {error && <ErrorMessage error={error} />}

        <Button type="submit" text="Add" loading={loading} />
      </form>
    </Modal>
  );
}
