import { faChevronDown } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type DropdownOption = {
  readonly text: string;
  readonly value: string;
  readonly disabled?: boolean;
};

type DropdownGroup = {
  readonly text: string;
  readonly children: readonly DropdownOption[];
};

type DropdownEntry = DropdownOption | DropdownGroup;

type Props = {
  readonly placeholder?: string;
  readonly options: readonly DropdownEntry[];
  readonly value: string | undefined;
  readonly label?: string;
  readonly disabled?: boolean;
  readonly onChange: (value: string | undefined) => void;
};

export default function DropdownField({
  placeholder,
  options,
  value,
  label,
  disabled,
  onChange,
}: Props) {
  return (
    <div>
      {label && (
        <label className="text-gray-600 text-sm mb-1 font-medium block">
          {label}
        </label>
      )}
      <div className="relative">
        <select
          disabled={disabled}
          className={
            'appearance-none block px-2 py-1 border border-gray-400 focus:outline-none focus:border-primary w-full rounded-lg shadow-sm ' +
            (disabled ? 'cursor-not-allowed bg-gray-100 text-gray-400' : '')
          }
          value={value}
          onChange={(event) =>
            onChange(
              event.target.value === '_undefined'
                ? undefined
                : event.target.value,
            )
          }
        >
          {placeholder && <option value={'_undefined'}>{placeholder}</option>}
          {options.map((option) => {
            if ('children' in option) {
              return (
                <optgroup key={option.text} label={option.text}>
                  {option.children.map((child) => (
                    <option key={child.value} value={child.value}>
                      {child.text}
                    </option>
                  ))}
                </optgroup>
              );
            }

            return (
              <option
                key={option.value}
                value={option.value}
                disabled={option.disabled}
              >
                {option.text}
              </option>
            );
          })}
        </select>
        <div className="pointer-events-none absolute inset-y-0 right-0 pr-3 flex items-center text-gray-400">
          <FontAwesomeIcon icon={faChevronDown} />
        </div>
      </div>
    </div>
  );
}
