import { isSameDay } from 'date-fns';
import filters, { DATETIME_FORMAT_MEDIUM } from '../../filters';
import Table from '../content/table/Table';
import TableBody from '../content/table/TableBody';
import TableCell from '../content/table/TableCell';
import TableHeader from '../content/table/TableHeader';
import TableHeaderCell from '../content/table/TableHeaderCell';
import TableRow from '../content/table/TableRow';
import ButtonLink from '../controls/ButtonLink';

type Props = {
  readonly leads: readonly {
    readonly id: string;
    readonly status?: {
      readonly name: string;
    };
    readonly updated: string;
    readonly customer: {
      readonly firstName: string | null;
      readonly lastName: string | null;
      readonly mobilePhone: string | null;
    } | null;
    readonly address: {
      readonly postcode: string | null;
    } | null;
    readonly actions?: readonly {
      readonly due: string;
    }[];
    readonly nextActionDue?: string | null;
  }[];
  readonly showOverdueActions?: boolean;
};

const getActionTheme = (due: Date): 'good' | 'bad' | undefined => {
  const now = new Date();

  if (isSameDay(due, new Date()) && due > now) return 'good';
  if (due < now) return 'bad';

  return undefined;
};

export default function LeadTable({ leads, showOverdueActions }: Props) {
  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHeaderCell>ID</TableHeaderCell>
          <TableHeaderCell>Status</TableHeaderCell>
          <TableHeaderCell>Updated</TableHeaderCell>
          <TableHeaderCell>Customer</TableHeaderCell>
          <TableHeaderCell>Mobile</TableHeaderCell>
          <TableHeaderCell>Postcode</TableHeaderCell>
          {showOverdueActions && (
            <TableHeaderCell>Next Action Due</TableHeaderCell>
          )}
          <TableHeaderCell />
        </TableRow>
      </TableHeader>
      <TableBody>
        {leads.map((lead) => {
          const latestAction = (lead.actions ?? [])[0] ?? null;

          return (
            <TableRow
              key={lead.id}
              theme={
                showOverdueActions && latestAction
                  ? getActionTheme(new Date(latestAction.due))
                  : undefined
              }
            >
              <TableCell>
                <strong>{lead.id}</strong>
              </TableCell>
              <TableCell>{lead.status?.name ?? '-'}</TableCell>
              <TableCell>
                {filters.formatDate(lead.updated, DATETIME_FORMAT_MEDIUM)}
              </TableCell>
              <TableCell>
                {lead.customer && (
                  <>
                    {lead.customer.firstName} {lead.customer.lastName}
                  </>
                )}
              </TableCell>
              <TableCell>
                {lead.customer && <>{lead.customer.mobilePhone}</>}
              </TableCell>
              <TableCell>
                {lead.address && <>{lead.address.postcode}</>}
              </TableCell>
              {showOverdueActions && (
                <TableCell>
                  {lead.nextActionDue
                    ? filters.formatDate(
                        lead.nextActionDue,
                        DATETIME_FORMAT_MEDIUM,
                      )
                    : '-'}
                </TableCell>
              )}
              <TableCell rightAlign>
                <ButtonLink text="View" to={`/leads/${lead.id}`} />
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
}
