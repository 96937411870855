import { AnimatePresence } from 'framer-motion';
import { useMemo, useState } from 'react';
import ExpandableSection from '../../../../../../components/content/ExpandableSection';
import Table from '../../../../../../components/content/table/Table';
import TableBody from '../../../../../../components/content/table/TableBody';
import TableCell from '../../../../../../components/content/table/TableCell';
import TableHeader from '../../../../../../components/content/table/TableHeader';
import TableHeaderCell from '../../../../../../components/content/table/TableHeaderCell';
import TableRow from '../../../../../../components/content/table/TableRow';
import Button from '../../../../../../components/controls/Button';
import CreateReturnTray from '../../../CreateReturnTray';
import { Response } from '../../queries/getOrderQuery';
import EditOrderReturnTray from './components/EditOrderReturnTray';
import { useCancelOrderReturnMutation } from './mutations/cancelOrderReturnMutation';

type Props = {
  readonly orderId: string;
  readonly canReturn: boolean;
  readonly lineItems: Response['order']['lineItems'];
  readonly returns: Response['order']['returns'];
  readonly onUpdate: () => Promise<any>;
};

export function Returns({
  orderId,
  canReturn,
  lineItems,
  returns,
  onUpdate,
}: Props) {
  const [showTray, setShowTray] = useState(false);
  const [selectedOrderReturnId, setSelectedOrderReturnId] = useState<string>();

  const [cancelOrderReturn, { loading: cancelOrderReturnLoading }] =
    useCancelOrderReturnMutation({
      onCompleted: () => onUpdate(),
    });

  const selectedOrderReturn = useMemo(
    () => returns.find((ret) => ret.id === selectedOrderReturnId),
    [returns, selectedOrderReturnId],
  );

  return (
    <>
      <ExpandableSection title="Returns" counter={returns.length}>
        <div className="mb-5">
          {returns.length === 0 ? (
            <p>No returns.</p>
          ) : (
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHeaderCell>#</TableHeaderCell>
                  <TableHeaderCell>Number</TableHeaderCell>
                  <TableHeaderCell>Status</TableHeaderCell>
                  <TableHeaderCell>Items</TableHeaderCell>
                  <TableHeaderCell />
                </TableRow>
              </TableHeader>
              <TableBody>
                {returns.map((orderReturn) => (
                  <TableRow key={orderReturn.id}>
                    <TableCell>{orderReturn.id}</TableCell>
                    <TableCell>{orderReturn.returnNumber ?? '-'}</TableCell>
                    <TableCell>{orderReturn.status.name}</TableCell>
                    <TableCell>
                      {orderReturn.lineItems.reduce(
                        (total, line) => total + line.quantity,
                        0,
                      )}
                    </TableCell>
                    <TableCell rightAlign>
                      <div className="space-x-1">
                        <Button
                          size="sm"
                          text="View"
                          onClick={() =>
                            setSelectedOrderReturnId(orderReturn.id)
                          }
                        />
                        <Button
                          disabled={orderReturn.status.handle !== 'PENDING'}
                          loading={cancelOrderReturnLoading}
                          size="sm"
                          text="Cancel"
                          onClick={() =>
                            cancelOrderReturn({
                              variables: {
                                id: orderReturn.id,
                              },
                            })
                          }
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </div>

        <Button
          size="sm"
          text="Return Items"
          disabled={!canReturn}
          onClick={() => setShowTray(true)}
        />
      </ExpandableSection>

      <AnimatePresence>
        {showTray && (
          <CreateReturnTray
            orderId={orderId}
            lineItems={lineItems}
            returns={returns}
            onClose={() => setShowTray(false)}
            onCreateOrderReturn={async () => {
              await onUpdate();
              setShowTray(false);
            }}
          />
        )}
      </AnimatePresence>

      <AnimatePresence>
        {selectedOrderReturn && (
          <EditOrderReturnTray
            orderReturn={selectedOrderReturn}
            onClose={() => setSelectedOrderReturnId(undefined)}
            onUpdate={onUpdate}
          />
        )}
      </AnimatePresence>
    </>
  );
}
