import { gql, QueryHookOptions, useQuery } from '@apollo/client';

const QUERY = gql`
  query getSuppliers {
    suppliers {
      id
      name
      contacts {
        id
        name
        email
      }
      locations {
        id
        name
      }
    }
  }
`;

export type Response = {
  readonly suppliers: readonly {
    readonly id: string;
    readonly name: string;
    readonly contacts: readonly {
      readonly id: string;
      readonly name: string;
      readonly email: string;
    }[];
    readonly locations: readonly {
      readonly id: string;
      readonly name: string;
    }[];
  }[];
};

export type Supplier = Response['suppliers'][number];

export const useGetSuppliers = (options?: QueryHookOptions<Response>) => {
  const { data } = useQuery<Response>(QUERY, {
    fetchPolicy: 'cache-first',
    ...options,
  });

  return data?.suppliers ?? [];
};
