import { gql } from '@apollo/client';

export const MUTATION = gql`
  mutation($input: UpsertInstallerInput!) {
    createInstaller(input: $input) {
      id
    }
  }
`;

export type Response = {
  readonly createInstaller: {
    readonly id: number;
  };
};
