import { useMutation } from '@apollo/client';
import { useState } from 'react';
import Button from '../../../../../components/controls/Button';
import FileField from '../../../../../components/controls/FileField';
import TextField from '../../../../../components/controls/TextField';
import Modal from '../../../../../components/overlay/Modal';
import { FileUpload } from '../../../../../globals';
import {
  MUTATION as CREATE_INSTALLER_MUTATION,
  Response as CreateInstallerResponse,
} from '../mutations/createInstaller';

type Props = {
  readonly onClose: () => void;
  readonly onCreateSuccess: (installerId: number) => void;
};

export default function NewInstallerModal({ onClose, onCreateSuccess }: Props) {
  const [name, setName] = useState('');
  const [abn, setAbn] = useState('');
  const [confirmationOfInsurance, setConfirmationOfInsurance] =
    useState<FileUpload | null>(null);
  const [cecAccrediation, setCecAccrediation] = useState<FileUpload | null>(
    null,
  );
  const [signedSubcontractorsAgreement, setSignedSubcontractorsAgreement] =
    useState<FileUpload | null>(null);

  const [createInstaller, { loading }] = useMutation<CreateInstallerResponse>(
    CREATE_INSTALLER_MUTATION,
    {
      variables: {
        input: {
          name,
          abn,
          confirmationOfInsuranceId: confirmationOfInsurance?.id ?? null,
          cecAccrediationId: cecAccrediation?.id ?? null,
          signedSubcontractorsAgreementId:
            signedSubcontractorsAgreement?.id ?? null,
        },
      },
      onCompleted: (response) => onCreateSuccess(response.createInstaller.id),
    },
  );

  return (
    <Modal heading="New Installer" onClose={onClose}>
      <form
        onSubmit={(event) => {
          event.preventDefault();
          createInstaller();
        }}
      >
        <div className="grid grid-cols-2 gap-4 mb-4">
          <div>
            <TextField
              type="text"
              placeholder="Name"
              label="Name"
              value={name}
              onChange={(value) => setName(value)}
            />
          </div>
          <div>
            <TextField
              type="text"
              placeholder="ABN"
              label="ABN"
              value={abn}
              onChange={(value) => setAbn(value)}
            />
          </div>
          <div className="col-span-2">
            <FileField
              label="Confirmation of Insurance"
              value={confirmationOfInsurance ? [confirmationOfInsurance] : []}
              onChange={(value) => setConfirmationOfInsurance(value[0] ?? null)}
            />
          </div>
          <div className="col-span-2">
            <FileField
              label="CEC Accrediation"
              value={cecAccrediation ? [cecAccrediation] : []}
              onChange={(value) => setCecAccrediation(value[0] ?? null)}
            />
          </div>
          <div className="col-span-2">
            <FileField
              label="Signed Subcontractors Agreement"
              value={
                signedSubcontractorsAgreement
                  ? [signedSubcontractorsAgreement]
                  : []
              }
              onChange={(value) =>
                setSignedSubcontractorsAgreement(value[0] ?? null)
              }
            />
          </div>
        </div>

        <Button
          primary
          disabled={!name}
          loading={loading}
          type="submit"
          text="Create"
        />
      </form>
    </Modal>
  );
}
