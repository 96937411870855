import { gql, MutationHookOptions, useMutation } from '@apollo/client';

const MUTATION = gql`
  mutation claim($tentativeClaimId: Float!) {
    claim(tentativeClaimId: $tentativeClaimId) {
      id
    }
  }
`;

export type Variables = {
  readonly tentativeClaimId: number;
};

export type Response = {
  readonly claim: {
    readonly id: number;
  };
};

export const useClaimMutation = (
  options: MutationHookOptions<Response, Variables>,
) => useMutation<Response, Variables>(MUTATION, options);
