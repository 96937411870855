import { Address } from '../../../../globals';
import { InputDefinition } from '../../../../inputs/abstract';
import { Lead } from '../../queries/getLeadQuery';

export type LeadOperationsInput = {
  readonly energyRetailerId?: string;
  readonly dealDate: Date | null;
  readonly nmi: string;
  readonly meterNumber: string;
  readonly poleNumber: string;
  readonly expectedInstallDate: Date | null;
  readonly installDate: Date | null;
  readonly retailerId?: string;
  readonly signedProposal: boolean | null;
  readonly depositPaid: boolean | null;
  readonly depositFullFinance: boolean | null;
  readonly financeSubmitted: boolean | null;
  readonly financeApproved: boolean | null;
  readonly ptcSubmitted: boolean | null;
  readonly ptcApproved: boolean | null;
  readonly stockOrdered: boolean | null;
  readonly stcAllocated: boolean | null;
  readonly sitePlanPrinted: boolean | null;
  readonly sitePlanSubmitted: boolean | null;
  readonly userManualSent: boolean | null;
  readonly spvSent: boolean | null;
  readonly meteringSubmitted: boolean | null;
  readonly financeId?: string;
  readonly installerContactId?: string;
  readonly installerDocuments: boolean | null;
  readonly storeys?: string;
  readonly roofTypeId?: string;
  readonly existingSolar: boolean;
  readonly connectionTypeId?: string;
  readonly systemSize: string;
  readonly batterySize: string;
};

export const leadOperationsInputDefinition: InputDefinition<
  LeadOperationsInput,
  Omit<Lead, 'address'> & { readonly address: Partial<Address> | null }
> = {
  getDefaultState() {
    return {
      energyRetailerId: undefined,
      dealDate: null,
      nmi: '',
      meterNumber: '',
      poleNumber: '',
      expectedInstallDate: null,
      installDate: null,
      retailerId: undefined,
      signedProposal: false,
      depositPaid: false,
      depositFullFinance: false,
      financeSubmitted: false,
      financeApproved: false,
      ptcSubmitted: false,
      ptcApproved: false,
      stockOrdered: false,
      stcAllocated: false,
      sitePlanPrinted: false,
      sitePlanSubmitted: false,
      userManualSent: false,
      spvSent: false,
      meteringSubmitted: false,
      financeId: undefined,
      installerContactId: undefined,
      installerDocuments: false,
      storeys: '',
      roofTypeId: undefined,
      existingSolar: false,
      connectionTypeId: undefined,
      systemSize: '',
      batterySize: '',
    };
  },
  toPrimitive(input) {
    return {
      energyRetailerId: input.energyRetailer?.id.toString(),
      dealDate: input.dealDate ? new Date(input.dealDate) : null,
      nmi: input.nmi ? input.nmi : '',
      meterNumber: input.meterNumber ?? '',
      poleNumber: input.poleNumber ? input.poleNumber : '',
      expectedInstallDate: input.expectedInstallDate
        ? new Date(input.expectedInstallDate)
        : null,
      installDate: input.installDate ? new Date(input.installDate) : null,
      retailerId: input.retailer?.id.toString(),
      signedProposal: input.checklist.signedProposal,
      depositPaid: input.checklist.depositPaid,
      depositFullFinance: input.checklist.depositFullFinance,
      financeSubmitted: input.checklist.financeSubmitted,
      financeApproved: input.checklist.financeApproved,
      ptcSubmitted: input.checklist.ptcSubmitted,
      ptcApproved: input.checklist.ptcApproved,
      stockOrdered: input.checklist.stockOrdered,
      stcAllocated: input.checklist.stcAllocated,
      sitePlanPrinted: input.checklist.sitePlanPrinted,
      sitePlanSubmitted: input.checklist.sitePlanSubmitted,
      userManualSent: input.checklist.userManualSent,
      spvSent: input.checklist.spvSent,
      meteringSubmitted: input.checklist.meteringSubmitted,
      financeId: input.finance?.id.toString(),
      installerContactId: input.installerContact?.id.toString(),
      installerDocuments: input.checklist.installerDocuments,
      storeys: input.address?.storeys?.toString() ?? '',
      roofTypeId: input.address?.roofType?.id.toString(),
      existingSolar: input.address?.existingSolar ?? false,
      connectionTypeId: input?.address?.connectionType?.id.toString(),
      systemSize: input.systemSize?.toString() ?? '',
      batterySize: input.batterySize?.toString() ?? '',
    };
  },
  toActual(input) {
    return {
      energyRetailerId: input.energyRetailerId
        ? parseInt(input.energyRetailerId)
        : null,
      dealDate: input.dealDate ? input.dealDate.toString() : undefined,
      nmi: input.nmi,
      meterNumber: input.meterNumber,
      poleNumber: input.poleNumber,
      expectedInstallDate: input.expectedInstallDate
        ? input.expectedInstallDate.toString()
        : undefined,
      installDate: input.installDate ? input.installDate.toString() : undefined,
      retailerId: input.retailerId ? parseInt(input.retailerId) : null,
      financeId: input.financeId ? parseInt(input.financeId) : null,
      systemSize: input.systemSize ? parseInt(input.systemSize) : null,
      batterySize: input.batterySize ? parseInt(input.batterySize) : null,
      checklist: {
        signedProposal: input.signedProposal,
        depositPaid: input.depositPaid,
        depositFullFinance: input.depositFullFinance,
        financeSubmitted: input.financeSubmitted,
        financeApproved: input.financeApproved,
        ptcSubmitted: input.ptcSubmitted,
        ptcApproved: input.ptcApproved,
        stockOrdered: input.stockOrdered,
        stcAllocated: input.stcAllocated,
        sitePlanPrinted: input.sitePlanPrinted,
        sitePlanSubmitted: input.sitePlanSubmitted,
        userManualSent: input.userManualSent,
        spvSent: input.spvSent,
        meteringSubmitted: input.meteringSubmitted,
        installerDocuments: input.installerDocuments,
      },
      installerContactId: input.installerContactId
        ? parseInt(input.installerContactId)
        : null,
      address: {
        connectionTypeId: input.connectionTypeId
          ? parseInt(input.connectionTypeId)
          : undefined,
        roofTypeId: input.roofTypeId ? parseInt(input.roofTypeId) : undefined,
        existingSolar: input.existingSolar,
        storeys: input.storeys ? parseInt(input.storeys) : undefined,
      },
    };
  },
};
