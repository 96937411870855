import Button from '../../../../components/controls/Button';
import Tray from '../../../../components/layout/Tray';
import TextField from '../../../../components/controls/TextField';
import { useMemo, useState } from 'react';
import { useCreateProductCategoryMutation } from './mutations/createProductCategoryMutation';
import { useNavigate } from 'react-router-dom';

type Props = {
  readonly onClose: () => void;
};

export function CreateProductCategoryTray({ onClose }: Props) {
  const navigate = useNavigate();

  const [name, setName] = useState('');

  const [createProductCategory, { loading }] = useCreateProductCategoryMutation(
    {
      variables: {
        input: {
          name,
        },
      },
      onCompleted: (data) => {
        navigate(
          `/inventory/product-categories/${data.createProductCategory.id}`,
        );
      },
    },
  );

  const disabled = useMemo(() => {
    return name.length === 0;
  }, [name]);

  return (
    <Tray
      heading="New Product Category"
      onClose={onClose}
      actions={
        <>
          <Button
            disabled={disabled}
            loading={loading}
            primary
            text="Create"
            onClick={() => createProductCategory()}
          />
        </>
      }
    >
      <div>
        <h4 className="px-5 pt-5 font-bold text-gray-600">Details</h4>
        <div className="grid p-5 gap-5 md:grid-cols-2">
          <div className="md:col-span-2">
            <TextField
              value={name}
              onChange={setName}
              label="Name"
              placeholder="Name"
            />
          </div>
        </div>
      </div>
    </Tray>
  );
}
