import { gql, QueryHookOptions, useQuery } from '@apollo/client';

const QUERY = gql`
  query getLeadStatuses {
    leadStatuses {
      id
      handle
      name
    }
  }
`;

export type Response = {
  readonly leadStatuses: readonly {
    readonly id: number;
    readonly handle:
      | 'unclaimed'
      | 'lead'
      | 'sale'
      | 'installed'
      | 'paid'
      | 'dead'
      | 'quoted';
    readonly name: string;
  }[];
};

export type LeadStatus = Response['leadStatuses'][number];
export type LeadStatusHandle = LeadStatus['handle'];

export const useGetLeadStatuses = (options?: QueryHookOptions<Response>) => {
  const { data } = useQuery<Response>(QUERY, {
    fetchPolicy: 'cache-first',
    ...options,
  });

  return data?.leadStatuses ?? [];
};
