import React from 'react';
import DropdownField from './DropdownField';

type Props = {
  readonly value: string;
  readonly onChange: (value: string) => void;
};

export default function YearField({ value, onChange }: Props) {
  return (
    <DropdownField
      label="Year"
      value={value}
      onChange={(value) => onChange(value ? value : '2020')}
      options={[
        { text: '2020', value: '2020' },
        { text: '2021', value: '2021' },
        { text: '2022', value: '2022' },
        { text: '2023', value: '2023' },
        { text: '2024', value: '2024' },
        { text: '2025', value: '2025' },
        { text: '2026', value: '2026' },
        { text: '2027', value: '2027' },
        { text: '2028', value: '2028' },
        { text: '2029', value: '2029' },
        { text: '2030', value: '2030' },
      ]}
    />
  );
}
