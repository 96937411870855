import { gql } from '@apollo/client';

export const QUERY = gql`
  query getServiceJobStatuses {
    serviceJobStatuses {
      id
      handle
      name
    }
  }
`;

export type Response = {
  readonly serviceJobStatuses: readonly {
    readonly id: string;
    readonly handle: 'open' | 'closed';
    readonly name: string;
  }[];
};
