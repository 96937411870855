import { gql } from '@apollo/client';

export const QUERY = gql`
  query getEnabledLeadSourcesForAgent($id: ID!) {
    user(id: $id) {
      id
      leadSources {
        id
        name
      }
    }
  }
`;

export type Variables = {
  readonly id: string;
};

export type Response = {
  readonly user: {
    readonly id: string;
    readonly leadSources: {
      readonly id: string;
      readonly name: string;
    }[];
  };
};
