import { useMemo } from 'react';
import Button from '../../../components/controls/Button';
import LeadCallButton from '../../../components/leads/LeadCallButton';
import filters from '../../../filters';
import { Response } from '../queries/getTentativeClaimsQuery';

type Props = {
  readonly tentativeClaim: Response['tentativeClaims'][number];
  readonly onClaim: () => void;
  readonly onRelease: () => void;
};

export default function LeadTentativeClaim({
  tentativeClaim,
  onClaim,
  onRelease,
}: Props) {
  const lead = useMemo(() => tentativeClaim.lead, [tentativeClaim]);
  const currentTime = useMemo(() => {
    return tentativeClaim.lead.address?.currentTime ?? 'Unknown';
  }, [tentativeClaim]);

  return (
    <article className="bg-white w-96 rounded-lg shadow-md p-6 text-center">
      <div className="mb-4 border border-gray-200 rounded-lg p-6">
        {lead.customer && (
          <>
            <div className="text-sm">
              <p>
                {lead.customer.firstName} {lead.customer.lastName}
                {lead.address && (
                  <>
                    , {lead.address.state?.abbreviation} {lead.address.postcode}
                  </>
                )}
              </p>
            </div>
            <hr className="my-4" />
            <div className="text-sm">Source: {lead.source.name}</div>
            <hr className="my-4" />
            <div className="text-sm">Current Time: {currentTime}</div>
            <hr className="my-4" />
            <p>
              {lead.customer.mobilePhone && (
                <LeadCallButton number={lead.customer.mobilePhone} />
              )}
            </p>
          </>
        )}
      </div>

      <p className="mb-4 text-sm text-gray-500">
        Expires at{' '}
        <strong>{filters.formatDate(tentativeClaim.expires, 'HH:mm')}</strong>
      </p>

      <div>
        <Button
          text="Answered"
          primary
          className="mr-2"
          onClick={() => onClaim()}
        />
        <Button text="Not Answered" onClick={() => onRelease()} />
      </div>
    </article>
  );
}
