import { faRobot } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactNode } from 'react';
import filters, { DATETIME_FORMAT_MEDIUM } from '../../../../filters';
import { useDeleteNoteMutation } from '../mutations/deleteNoteMutation';
import Markdown from 'react-markdown';

type Props = {
  readonly id: string;
  readonly children: ReactNode;
  readonly agentFullName?: string;
  readonly canDelete?: boolean;
  readonly onDelete?: () => void;
  readonly created: string;
};

export default function Note({
  id,
  children,
  agentFullName,
  canDelete = false,
  onDelete,
  created,
}: Props) {
  const [deleteNote] = useDeleteNoteMutation({
    variables: {
      id: parseInt(id.toString()),
    },
    onCompleted: () => onDelete && onDelete(),
  });

  return (
    <div className="mb-2 border border-gray-300 shadow-sm rounded-md p-3">
      <Markdown className="whitespace-pre-wrap">
        {children?.toString() ?? ''}
      </Markdown>
      <p className="text-gray-500">
        <small>
          {agentFullName ?? (
            <>
              <FontAwesomeIcon icon={faRobot} /> Automated
            </>
          )}{' '}
          &middot; {filters.formatDate(created, DATETIME_FORMAT_MEDIUM)}
          {canDelete && (
            <>
              {' '}
              &middot;{' '}
              <button
                onClick={() => {
                  if (confirm('Delete note?')) deleteNote();
                }}
              >
                Delete
              </button>
            </>
          )}
        </small>
      </p>
    </div>
  );
}
