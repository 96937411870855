import {
  faCheckCircle,
  faExclamationTriangle,
  faInfoCircle,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { ReactNode, useMemo } from 'react';

type Props = {
  readonly children: ReactNode;
  readonly type?: 'warning' | 'info' | 'success';
  readonly actions?: ReactNode;
  readonly className?: string;
};

export default function Notice({
  children,
  type = 'info',
  actions,
  className,
}: Props) {
  const icon = useMemo(() => {
    switch (type) {
      case 'info':
        return faInfoCircle;
      case 'warning':
        return faExclamationTriangle;
      case 'success':
        return faCheckCircle;
    }
  }, [type]);

  return (
    <aside
      className={clsx(
        'flex items-center rounded-md px-4 py-3 space-x-4 border shadow-sm',
        className,
        {
          'bg-yellow-100 text-yellow-800 border-yellow-200': type === 'warning',
          'bg-violet-100 text-violet-800 border-violet-200': type === 'info',
          'bg-green-700 text-green-100 border-green-800': type === 'success',
        },
      )}
    >
      <div className="flex-shrink-0">
        <FontAwesomeIcon icon={icon} />
      </div>
      <div className="flex-grow">{children}</div>
      {actions && <div className="flex-shrink-0">{actions}</div>}
    </aside>
  );
}
