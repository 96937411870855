import Box from '../../../../../components/content/Box';
import MetaBlock from '../../../../../components/content/MetaBlock';
import { Response } from '../queries/getProductCategoryQuery';
import filters, { DATETIME_FORMAT_MEDIUM } from '../../../../../filters';

type Props = {
  readonly productCategory: Response['productCategory'];
};

export default function ProductCategoryMeta({ productCategory }: Props) {
  return (
    <aside className="lg:w-96">
      <Box>
        <MetaBlock label="Created">
          {filters.formatDate(productCategory.created, DATETIME_FORMAT_MEDIUM)}
        </MetaBlock>
        <MetaBlock label="Updated">
          {filters.formatDate(productCategory.updated, DATETIME_FORMAT_MEDIUM)}
        </MetaBlock>
        <MetaBlock label="Products">
          {productCategory.totalProducts.toLocaleString()}
        </MetaBlock>
        <MetaBlock
          label="Pylon Type"
          hint="If set, links this category to the specified component type in Pylon. Products attached to this category with a Pylon ID will be synced with the Pylon API."
        >
          {productCategory.pylonTypeSlug ?? '-'}
        </MetaBlock>
      </Box>
    </aside>
  );
}
