import { useEffect } from 'react';
import { LeadFieldLock } from '../../../../../../../globals';
import {
  calculateCustomerPrice,
  calculateCustomerTotalPrice,
  calculateGST,
  calculateSTCValue,
  calculateSubtotal,
  calculateSystemBaseCost,
  calculateSystemCost,
  calculateTotalInvoice,
  LeadFinanceInput,
} from '../../../leadFinanceInput';
import Divider from '../../../../../../../components/content/Divider';
import ExpandableSection from '../../../../../../../components/content/ExpandableSection';
import Lock from '../../../../../../../components/controls/Lock';
import TextField from '../../../../../../../components/controls/TextField';

type Props = {
  readonly leadId: string;
  readonly input: LeadFinanceInput;
  readonly lock: LeadFieldLock | null;
  readonly onChange: (value: LeadFinanceInput) => void;
  readonly onToggleLock: () => void;
};

export default function LeadFinanceChunkV2({
  leadId,
  onChange,
  input,
  lock,
  onToggleLock,
}: Props) {
  useEffect(() => {
    onChange({
      ...input,
      systemBaseCost: calculateSystemBaseCost(input).toFixed(2),
    });
  }, [
    input.panelCost,
    input.installCost,
    input.racking,
    input.inverter1,
    input.inverter2,
    input.battery1,
    input.battery2,
    input.compliance,
    input.travel,
    input.extras,
  ]);

  useEffect(() => {
    onChange({ ...input, systemCost: calculateSystemCost(input).toFixed(2) });
  }, [input.tehoMargin, input.systemBaseCost]);

  useEffect(() => {
    onChange({
      ...input,
      customerPrice: calculateCustomerPrice(input).toFixed(2),
    });
  }, [input.revenue, input.systemCost]);

  useEffect(() => {
    onChange({
      ...input,
      subtotal: calculateSubtotal(input).toFixed(2),
    });
  }, [input.financeCost, input.customerPrice]);

  useEffect(() => {
    onChange({
      ...input,
      gst: calculateGST(input).toFixed(2),
      totalInvoice: calculateTotalInvoice(input).toFixed(2),
      stcTotalCost: calculateSTCValue(input).toFixed(2),
      totalCustomerPrice: calculateCustomerTotalPrice(input).toFixed(2),
    });
  }, [input.subtotal, input.stcCount, input.stcUnitCost, input.rebates]);

  const locked = !!lock && lock.locked;

  return (
    <ExpandableSection title="Finance">
      <div className="grid grid-cols-4 gap-4">
        <div className="col-span-4 md:col-span-2">
          <TextField
            disabled={locked}
            dataType="dollars"
            type="text"
            label="Panel Cost"
            placeholder="0.00"
            value={input.panelCost}
            onChange={(value) => onChange({ ...input, panelCost: value })}
          />
        </div>
        <div className="col-span-4 md:col-span-2">
          <TextField
            disabled={locked}
            dataType="dollars"
            type="text"
            label="Install Cost"
            placeholder="0.00"
            value={input.installCost}
            onChange={(value) => onChange({ ...input, installCost: value })}
          />
        </div>
        <div className="col-span-4 md:col-span-2">
          <TextField
            disabled={locked}
            dataType="dollars"
            type="text"
            label="Racking"
            placeholder="0.00"
            value={input.racking}
            onChange={(value) => onChange({ ...input, racking: value })}
          />
        </div>
        <div className="hidden md:block md:col-span-2"></div>
        <div className="col-span-4 md:col-span-2">
          <TextField
            disabled={locked}
            dataType="dollars"
            type="text"
            label="Inverter 1"
            placeholder="0.00"
            value={input.inverter1}
            onChange={(value) => onChange({ ...input, inverter1: value })}
          />
        </div>
        <div className="col-span-4 md:col-span-2">
          <TextField
            disabled={locked}
            dataType="dollars"
            type="text"
            label="Inverter 2"
            placeholder="0.00"
            value={input.inverter2}
            onChange={(value) => onChange({ ...input, inverter2: value })}
          />
        </div>
        <div className="col-span-4 md:col-span-2">
          <TextField
            disabled={locked}
            dataType="dollars"
            type="text"
            label="Battery 1"
            placeholder="0.00"
            value={input.battery1}
            onChange={(value) => onChange({ ...input, battery1: value })}
          />
        </div>
        <div className="col-span-4 md:col-span-2">
          <TextField
            disabled={locked}
            dataType="dollars"
            type="text"
            label="Battery 2"
            placeholder="0.00"
            value={input.battery2}
            onChange={(value) => onChange({ ...input, battery2: value })}
          />
        </div>
        <div className="col-span-4 md:col-span-2">
          <TextField
            disabled={locked}
            dataType="dollars"
            type="text"
            label="Compliance"
            placeholder="0.00"
            value={input.compliance}
            onChange={(value) => onChange({ ...input, compliance: value })}
          />
        </div>
        <div className="col-span-4 md:col-span-2">
          <TextField
            disabled={locked}
            dataType="dollars"
            type="text"
            label="Travel"
            placeholder="0.00"
            value={input.travel}
            onChange={(value) => onChange({ ...input, travel: value })}
          />
        </div>
        <div className="col-span-4 md:col-span-2">
          <TextField
            disabled={locked}
            dataType="dollars"
            type="text"
            label="Extras"
            placeholder="0.00"
            value={input.extras}
            onChange={(value) => onChange({ ...input, extras: value })}
          />
        </div>
        <div className="col-span-4">
          <TextField
            dataType="dollars"
            automatic
            type="text"
            label="Subtotal EX GST"
            placeholder="0.00"
            value={input.systemBaseCost}
            onChange={() => {}}
          />
        </div>
      </div>

      <Divider />

      <div className="grid grid-cols-4 gap-4">
        <div className="col-span-4 md:col-span-2">
          <TextField
            disabled={locked}
            dataType="dollars"
            type="text"
            label="Teho Margin"
            placeholder="0.00"
            value={input.tehoMargin}
            onChange={(value) => onChange({ ...input, tehoMargin: value })}
          />
        </div>
        <div className="col-span-4 md:col-span-2">
          <TextField
            dataType="dollars"
            automatic
            type="text"
            label="Subtotal EX GST"
            placeholder="0.00"
            value={input.systemCost}
            onChange={() => {}}
          />
        </div>
      </div>

      <Divider />

      <div className="grid grid-cols-4 gap-4">
        <div className="col-span-4 md:col-span-2">
          <TextField
            disabled={locked}
            dataType="dollars"
            type="text"
            label="Agent Margin"
            placeholder="0.00"
            value={input.revenue}
            onChange={(value) => onChange({ ...input, revenue: value })}
          />
        </div>
        <div className="col-span-4 md:col-span-2">
          <TextField
            dataType="dollars"
            automatic
            type="text"
            label="Subtotal EX GST"
            placeholder="0.00"
            value={input.customerPrice}
            onChange={() => {}}
          />
        </div>
      </div>

      <Divider />

      <div className="grid grid-cols-4 gap-4">
        <div className="col-span-4 md:col-span-2">
          <TextField
            disabled={locked}
            dataType="dollars"
            type="text"
            label="Finance Cost"
            placeholder="0.00"
            value={input.financeCost}
            onChange={(value) => onChange({ ...input, financeCost: value })}
          />
        </div>
        <div className="col-span-4 md:col-span-2">
          <TextField
            dataType="dollars"
            automatic
            type="text"
            label="Subtotal EX GST"
            placeholder="0.00"
            value={input.subtotal}
            onChange={() => {}}
          />
        </div>
      </div>

      <Divider />

      <div className="grid grid-cols-4 gap-4">
        <div className="col-span-4 md:col-span-2">
          <TextField
            automatic
            dataType="dollars"
            type="text"
            label="GST"
            placeholder="0.00"
            value={input.gst}
            onChange={() => {}}
          />
        </div>
        <div className="col-span-4 md:col-span-2">
          <TextField
            dataType="dollars"
            automatic
            type="text"
            label="Total INC GST"
            placeholder="0.00"
            value={input.totalInvoice}
            onChange={() => {}}
          />
        </div>
      </div>

      <Divider />

      <div className="grid grid-cols-4 gap-4">
        <div className="col-span-4 md:col-span-2">
          <TextField
            disabled={locked}
            dataType="dollars"
            type="text"
            label="Rebates"
            placeholder="0.00"
            value={input.rebates}
            onChange={(value) => onChange({ ...input, rebates: value })}
          />
        </div>
        <div className="hidden md:block col-span-2"></div>
        <div className="col-span-4 md:col-span-2">
          <TextField
            disabled={locked}
            type="text"
            label="STCs"
            placeholder="0"
            value={input.stcCount}
            onChange={(value) => onChange({ ...input, stcCount: value })}
          />
        </div>
        <div className="col-span-4 md:col-span-2">
          <TextField
            disabled={locked}
            dataType="dollars"
            type="text"
            label="STC Unit"
            placeholder="0.00"
            value={input.stcUnitCost}
            onChange={(value) => onChange({ ...input, stcUnitCost: value })}
          />
        </div>
        <div className="col-span-4">
          <TextField
            automatic
            dataType="dollars"
            type="text"
            label="STC Value"
            placeholder="0.00"
            value={input.stcTotalCost}
            onChange={(value) => onChange({ ...input, stcTotalCost: value })}
          />
        </div>
      </div>

      <Divider />

      <div className="grid grid-cols-4 gap-4 mb-4">
        <div className="col-span-4">
          <TextField
            automatic
            dataType="dollars"
            type="text"
            label="Customer Price"
            placeholder="0.00"
            value={input.totalCustomerPrice}
            onChange={() => {}}
          />
        </div>
      </div>

      <Lock
        lock={lock}
        leadId={leadId}
        onToggle={(locked) => onToggleLock()}
        group="FINANCE_CALCULATIONS"
      />
    </ExpandableSection>
  );
}
