import { useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import Button from '../../../../../components/controls/Button';
import CheckboxField from '../../../../../components/controls/CheckboxField';
import FileField from '../../../../../components/controls/FileField';
import TextField from '../../../../../components/controls/TextField';
import { FileUpload } from '../../../../../globals';
import {
  MUTATION as UPDATE_INSTALLER_MUTATION,
  Response as UpdateInstallerResponse,
} from '../mutations/updateInstaller';
import { Response as GetInstallerResponse } from '../queries/getInstaller';

type Props = {
  readonly installer: GetInstallerResponse['installer'];
  readonly onUpdate: () => void;
};

export default function InstallerDetailsView({ installer, onUpdate }: Props) {
  const [name, setName] = useState('');
  const [abn, setAbn] = useState('');
  const [confirmationOfInsurance, setConfirmationOfInsurance] =
    useState<FileUpload | null>(null);
  const [cecAccrediation, setCecAccrediation] = useState<FileUpload | null>(
    null,
  );
  const [signedSubcontractorsAgreement, setSignedSubcontractorsAgreement] =
    useState<FileUpload | null>(null);
  const [delivers, setDelivers] = useState(false);
  const [forkliftOnSite, setForkliftOnSite] = useState(false);
  const [address, setAddress] = useState('');

  const [updateInstaller, { loading: updateInstallerLoading }] =
    useMutation<UpdateInstallerResponse>(UPDATE_INSTALLER_MUTATION, {
      variables: {
        id: installer.id,
        input: {
          name,
          abn,
          confirmationOfInsuranceId: confirmationOfInsurance?.id ?? null,
          cecAccrediationId: cecAccrediation?.id ?? null,
          signedSubcontractorsAgreementId:
            signedSubcontractorsAgreement?.id ?? null,
          delivers,
          forkliftOnSite,
          address,
        },
      },
      onCompleted: () => onUpdate(),
    });

  useEffect(() => {
    setName(installer.name);
    setAbn(installer.abn ?? '');
    setConfirmationOfInsurance(installer.confirmationOfInsurance);
    setCecAccrediation(installer.cecAccrediation);
    setSignedSubcontractorsAgreement(installer.signedSubcontractorsAgreement);
    setDelivers(installer.delivers);
    setForkliftOnSite(installer.forkliftOnSite);
    setAddress(installer.address ?? '');
  }, [installer]);

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        updateInstaller();
      }}
    >
      <div className="mb-5">
        <div className="grid gap-4 grid-cols-2 mb-4">
          <div className="col-span-2 md:col-span-1">
            <TextField
              type="text"
              label="Name"
              placeholder="Name"
              value={name}
              onChange={(value) => setName(value)}
            />
          </div>
          <div className="col-span-2 md:col-span-1">
            <TextField
              type="text"
              label="ABN"
              placeholder="ABN"
              value={abn}
              onChange={(value) => setAbn(value)}
            />
          </div>
        </div>
        <div className="grid gap-4 grid-cols-3">
          <div className="col-span-3 md:col-span-1">
            <FileField
              label="Confirmation of Insurance"
              value={confirmationOfInsurance ? [confirmationOfInsurance] : []}
              onChange={(value) => setConfirmationOfInsurance(value[0] ?? null)}
              single
            />
          </div>
          <div className="col-span-3 md:col-span-1">
            <FileField
              label="CEC Accrediation"
              value={cecAccrediation ? [cecAccrediation] : []}
              onChange={(value) => setCecAccrediation(value[0] ?? null)}
              single
            />
          </div>
          <div className="col-span-3 md:col-span-1">
            <FileField
              label="Signed Subcontractors Agreement"
              value={
                signedSubcontractorsAgreement
                  ? [signedSubcontractorsAgreement]
                  : []
              }
              onChange={(value) =>
                setSignedSubcontractorsAgreement(value[0] ?? null)
              }
              single
            />
          </div>

          <div className="col-span-3">
            <TextField
              type="box"
              label="Address"
              value={address}
              onChange={setAddress}
              placeholder="123 Example St, Exampleville, EX 12345"
            />
          </div>

          <div className="col-span-3">
            <CheckboxField checked={delivers} onChange={setDelivers}>
              Delivers
            </CheckboxField>
          </div>
          <div className="col-span-3">
            <CheckboxField
              checked={forkliftOnSite}
              onChange={setForkliftOnSite}
            >
              Forklift on Site
            </CheckboxField>
          </div>
        </div>
      </div>

      <Button
        size="sm"
        type="submit"
        text="Update"
        loading={updateInstallerLoading}
      />
    </form>
  );
}
