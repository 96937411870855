import { useState } from 'react';
import CapabilityGuard from '../../../../components/CapabilityGuard';
import Container from '../../../../components/content/Container';
import SidebarLayout from '../../../../components/layout/SidebarLayout';
import Heading from '../../../../components/layout/ToolbarPage/components/Heading';
import { OrderStatusHandle } from '../../../../globals';
import OrdersSidebar from './components/OrdersSidebar';
import OrdersTable from './components/OrdersTable';
import { useGetOrdersQuery } from './queries/getOrdersQuery';

export function OrdersView() {
  const [statuses, setStatuses] = useState<readonly OrderStatusHandle[]>([
    'DRAFT',
    'READY',
    'SENT',
  ]);
  const [supplierIds, setSupplierIds] = useState<readonly string[]>([]);

  const { data } = useGetOrdersQuery({
    variables: {
      input: {
        statuses,
        supplierIds,
      },
    },
  });

  return (
    <CapabilityGuard requirements={['canViewOrders']}>
      <Container>
        <Heading text="Orders" counter={data?.ordersCount} />

        <SidebarLayout
          sidebar={
            <OrdersSidebar
              filter={{ statuses, supplierIds }}
              onChange={(value) => {
                setStatuses(value.statuses);
                setSupplierIds(value.supplierIds);
              }}
            />
          }
        >
          <OrdersTable orders={data?.orders ?? []} />
        </SidebarLayout>
      </Container>
    </CapabilityGuard>
  );
}
