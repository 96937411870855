import Box from '../../../../../components/content/Box';
import MetaBlock from '../../../../../components/content/MetaBlock';
import { Response } from '../queries/getInstaller';

type Props = {
  readonly installer: Response['installer'];
};

export default function InstallerMeta({ installer }: Props) {
  return (
    <aside className="lg:w-96">
      <Box>
        {/* <MetaBlock label="Created">
          {filters.formatDate(product.created, DATETIME_FORMAT_MEDIUM)}
        </MetaBlock>
        <MetaBlock label="Updated">
          {filters.formatDate(product.updated, DATETIME_FORMAT_MEDIUM)}
        </MetaBlock> */}
        <MetaBlock label="Items on Hand">
          {installer.itemsOnHandCount}
        </MetaBlock>
      </Box>
    </aside>
  );
}
