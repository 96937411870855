import { gql, QueryHookOptions, useQuery } from '@apollo/client';

const QUERY = gql`
  query getUserAccessLevels {
    userAccessLevels {
      id
      handle
      name
    }
  }
`;

export type Response = {
  readonly userAccessLevels: readonly {
    readonly id: string;
    readonly handle: string;
    readonly name: string;
  }[];
};

export type CommissionTier = Response['userAccessLevels'][number];

export const useGetUserAccessLevels = (
  options?: QueryHookOptions<Response>,
) => {
  const { data } = useQuery<Response>(QUERY, {
    fetchPolicy: 'cache-first',
    ...options,
  });

  return data?.userAccessLevels ?? [];
};
