type Props = {
  readonly total: number;
  readonly offset: number;
  readonly limit: number;
  readonly onChange: (offset: number) => void;
};

export default function Pagination({ total, offset, limit, onChange }: Props) {
  const page: number = Math.ceil(offset / limit);
  const totalPages: number = Math.ceil(total / limit);

  const handleChange = (value: number) => onChange(value * limit);

  return (
    <div className="mt-6 pt-6 border-t border-gray-300">
      <p className="text-gray-600 text-sm">
        Page{' '}
        <select
          className="inline-block w-16 shadow px-2 py-1 rounded-lg focus:outline-none border border-gray-300 mx-1"
          value={page}
          onChange={(event) => {
            handleChange(parseInt(event.target.value));
          }}
        >
          {[...Array(totalPages)].map((value, page) => (
            <option key={page} value={page}>
              {page + 1}
            </option>
          ))}
        </select>{' '}
        of {totalPages}
      </p>
    </div>
  );
}
