import { gql, MutationHookOptions, useMutation } from '@apollo/client';

const MUTATION = gql`
  mutation createSupplierLocation($input: CreateSupplierLocationInput!) {
    createSupplierLocation(input: $input) {
      id
    }
  }
`;

export type Variables = {
  readonly input: {
    readonly name: string;
    readonly supplierId: number;
    readonly address?: {
      readonly line1: string;
      readonly line2: string;
      readonly suburb: string;
      readonly stateId?: number;
      readonly postcode: string;
    };
  };
};

export type Response = {
  readonly createSupplierLocation: {
    readonly id: string;
  };
};

export const useCreateSupplierLocationMutation = (
  options?: MutationHookOptions<Response, Variables>,
) => useMutation<Response, Variables>(MUTATION, options);
