import { useQuery } from '@apollo/client';
import { endOfMonth, startOfMonth } from 'date-fns';
import { useMemo } from 'react';
import FilterControls from '../../../../components/controls/FilterControls';
import Filter from '../../../../components/controls/FilterControls/components/Filter';
import MonthPicker from '../../../../components/controls/FilterControls/components/MonthPicker';
import Picker from '../../../../components/controls/FilterControls/components/Picker';
import { GetLeadInput } from '../../../../globals';
import { useUser } from '../../../../hooks/useUser';
import { Lead } from '../../../LeadView/queries/getLeadQuery';
import InstalledLeadsAdminTable from './components/InstalledLeadsAdminTable';
import InstalledLeadsAgentTable from './components/InstalledLeadsAgentTable';
import { QUERY, Response } from './queries/getAgentsQuery';

type Props = {
  readonly criteria: GetLeadInput;
  readonly leads: readonly Lead[];
  readonly onChangeCriteria: (criteria: GetLeadInput) => void;
};

export function InstalledLeads({ leads, criteria, onChangeCriteria }: Props) {
  const user = useUser();

  const { data } = useQuery<Response>(QUERY, {
    skip: user?.accessLevel.handle !== 'admin',
  });

  const date = useMemo(() => {
    return criteria.installDateStart
      ? new Date(criteria.installDateStart)
      : null;
  }, [criteria.installDateStart]);

  const showChecklist = useMemo(
    () =>
      ['admin', 'operations', 'operationsAdmin'].includes(
        user?.accessLevel.handle ?? '',
      ),
    [user],
  );

  return (
    <>
      <FilterControls>
        <Filter label="Install Date">
          <MonthPicker
            value={date}
            onChange={(value) => {
              if (value) {
                onChangeCriteria({
                  ...criteria,
                  installDateStart: startOfMonth(value).toISOString(),
                  installDateEnd: endOfMonth(value).toISOString(),
                });
              } else {
                onChangeCriteria({
                  ...criteria,
                  installDateStart: undefined,
                  installDateEnd: undefined,
                });
              }
            }}
          />
        </Filter>
        {showChecklist && (
          <Filter label="Agent">
            <Picker
              value={criteria.agentId?.toString()}
              onChange={(value) =>
                onChangeCriteria({
                  ...criteria,
                  agentId: value ? parseInt(value) : undefined,
                })
              }
              options={
                data?.users.map((agent) => ({
                  value: agent.id,
                  text: agent.fullName,
                })) ?? []
              }
              placeholder="All agents"
            />
          </Filter>
        )}
      </FilterControls>

      {showChecklist ? (
        <InstalledLeadsAdminTable leads={leads} />
      ) : (
        <InstalledLeadsAgentTable leads={leads} />
      )}
    </>
  );
}
