type PurchaseOrderDocumentOptions = {
  readonly orderIds: readonly string[];
  readonly secret: string;
  readonly format: 'html' | 'pdf';
  readonly combinedOrderId?: string;
};

export function getPurchaseOrderDocumentURL({
  orderIds,
  secret,
  format,
  combinedOrderId,
}: PurchaseOrderDocumentOptions) {
  const url = new URL(process.env.REACT_APP_API_ENDPOINT ?? '');

  url.pathname = (url.pathname + `/orders/preview-po`).replace('//', '/');

  url.searchParams.append('secret', secret);
  url.searchParams.append('format', format);
  url.searchParams.append('orders', orderIds.join(','));

  if (combinedOrderId) {
    url.searchParams.append('combinedOrderId', combinedOrderId);
  }

  return url.toString();
}
