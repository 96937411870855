import { gql, useMutation } from '@apollo/client';
import { useState } from 'react';
import ErrorMessage from '../../../../components/content/ErrorMessage';
import Button from '../../../../components/controls/Button';
import DropdownField from '../../../../components/controls/DropdownField';
import TextField from '../../../../components/controls/TextField';
import Modal from '../../../../components/overlay/Modal';
import { useGetUserAccessLevels } from '../../../../hooks/useGetUserAccessLevel';

const CREATE_USER = gql`
  mutation ($model: CreateUserInput!) {
    createUser(model: $model) {
      email
      created
      accessLevel {
        handle
      }
    }
  }
`;

type Props = {
  readonly onClose: () => void;
  readonly onCreateSuccess: () => void;
};

export function CreateUserModal({ onClose, onCreateSuccess }: Props) {
  const userAccessLevels = useGetUserAccessLevels();

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [accessLevel, setAccessLevel] = useState<string>();

  const [createUser, { error, loading }] = useMutation(CREATE_USER, {
    onCompleted: () => {
      onCreateSuccess();
      onClose();
    },
    onError: (error) => console.error(error),
  });

  const handleCreateUser = () => {
    createUser({
      variables: {
        model: {
          firstName,
          lastName,
          email,
          password,
          accessLevel,
        },
      },
    });
  };

  return (
    <Modal heading="New User" onClose={onClose}>
      <form
        onSubmit={(event) => {
          event.preventDefault();
          handleCreateUser();
        }}
      >
        <div className="grid grid-cols-2 gap-4 mb-4">
          <div>
            <TextField
              label="First Name"
              value={firstName}
              onChange={(value) => setFirstName(value)}
              type="text"
              placeholder="First Name"
            />
          </div>
          <div>
            <TextField
              label="Last Name"
              value={lastName}
              onChange={(value) => setLastName(value)}
              type="text"
              placeholder="Last Name"
            />
          </div>
          <div className="col-span-2">
            <TextField
              label="Email Address"
              value={email}
              onChange={(value) => setEmail(value)}
              type="email"
              placeholder="Email Address"
            />
          </div>
          <div className="col-span-2">
            <TextField
              label="Password"
              value={password}
              onChange={(value) => setPassword(value)}
              type="password"
              placeholder="Password"
            />
          </div>
          <div className="col-span-2">
            <DropdownField
              placeholder="Select Access Level"
              label="Access Level"
              options={userAccessLevels.map((option) => ({
                text: option.name,
                value: option.handle,
              }))}
              value={accessLevel}
              onChange={(value) => setAccessLevel(value)}
            />
          </div>
        </div>

        {error && <ErrorMessage error={error} />}

        <Button type="submit" text="Create" primary loading={loading} />
      </form>
    </Modal>
  );
}
