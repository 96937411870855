import { Route, Routes } from 'react-router-dom';
import CreateLeadView from '../views/CreateLeadView';
import DashboardView from '../views/DashboardView';
import DealsView from '../views/DealsView';
import DiaryView from '../views/DiaryView';
import { HomeView } from '../views/HomeView/HomeView';
import InventoryView from '../views/InventoryView';
import CombinedOrderView from '../views/InventoryView/components/CombinedOrderView';
import InstallerView from '../views/InventoryView/components/InstallerView';
import OrderView from '../views/InventoryView/components/OrderView';
import ProductCategoryView from '../views/InventoryView/components/ProductCategoryView';
import ProductView from '../views/InventoryView/components/ProductView';
import SupplierView from '../views/InventoryView/components/SupplierView';
import LeadPresenterView from '../views/LeadPresenterView';
import LeadView from '../views/LeadView';
import LoginView from '../views/LoginView';
import MyCustomersView from '../views/MyCustomersView';
import SearchView from '../views/SearchView';
import ServiceJobView from '../views/ServiceJobView';
import ServiceJobsView from '../views/ServiceJobsView';
import ToolsView from '../views/ToolsView';
import UsersView from '../views/UsersView';
import Navigation from './navigation/Navigation';

export default function App() {
  return (
    <Routes>
      <Route path="/login/*" element={<LoginView />} />

      <Route
        path="*"
        element={
          <>
            <div className="h-screen flex">
              <div className="flex-shrink-0">
                <Navigation />
              </div>
              <main className="flex min-h-screen flex-col flex-grow overflow-y-auto bg-gray-200">
                <Routes>
                  <Route path="/" element={<HomeView />} />
                  <Route path="/dashboard/*" element={<DashboardView />} />
                  <Route path="/users" element={<UsersView />} />
                  <Route path="/search" element={<SearchView />} />
                  <Route path="/tools/*" element={<ToolsView />} />
                  <Route path="/presenter" element={<LeadPresenterView />} />
                  <Route path="/create" element={<CreateLeadView />} />
                  <Route path="/diary/*" element={<DiaryView />} />
                  <Route
                    path="/leads/status/:status"
                    element={<MyCustomersView />}
                  />
                  <Route path="/leads/:id/*" element={<LeadView />} />
                  <Route path="/leads" element={<MyCustomersView />} />
                  <Route path="/deals/status/:status" element={<DealsView />} />
                  <Route path="/deals" element={<DealsView />} />
                  <Route
                    path="/inventory/installers/:id/*"
                    element={<InstallerView />}
                  />
                  <Route
                    path="/service-jobs/status/:status"
                    element={<ServiceJobsView />}
                  />
                  <Route
                    path="/service-jobs/:id/*"
                    element={<ServiceJobView />}
                  />
                  <Route path="/service-jobs" element={<ServiceJobsView />} />
                  <Route path="/inventory/*" element={<InventoryView />} />
                  <Route
                    path="/inventory/suppliers/:id"
                    element={<SupplierView />}
                  />
                  <Route
                    path="/inventory/product-categories/:id"
                    element={<ProductCategoryView />}
                  />
                  <Route
                    path="/inventory/products/:id"
                    element={<ProductView />}
                  />
                  <Route path="/inventory/orders/:id" element={<OrderView />} />
                  <Route
                    path="/inventory/combined-orders/:id"
                    element={<CombinedOrderView />}
                  />
                </Routes>
              </main>
            </div>
          </>
        }
      />
    </Routes>
  );
}
