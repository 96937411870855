import { useMutation } from '@apollo/client';
import { useState } from 'react';
import {
  MUTATION,
  Response,
  Variables,
} from './mutation/createServiceJobNoteMutation';
import TextField from '../../../../../../components/controls/TextField';
import Button from '../../../../../../components/controls/Button';

type Props = {
  readonly serviceJobId: string;
  readonly onCreate: () => void;
};

export function NewNoteForm({ serviceJobId, onCreate }: Props) {
  const [text, setText] = useState('');

  const [createNote, { loading }] = useMutation<Response, Variables>(MUTATION, {
    variables: {
      serviceJobId,
      content: text,
    },
    onCompleted: () => {
      setText('');
      onCreate();
    },
    onError: (error) => console.error(error),
  });

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        createNote();
      }}
    >
      <div className="mb-2">
        <TextField
          type="box"
          placeholder="Add notes..."
          value={text}
          rows={2}
          onChange={(value) => setText(value)}
        />
      </div>

      <Button
        size="sm"
        disabled={text.length === 0}
        type="submit"
        text="Add Note"
        loading={loading}
      />
    </form>
  );
}
