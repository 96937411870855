import { gql, MutationHookOptions, useMutation } from '@apollo/client';

const MUTATION = gql`
  mutation createProductCategory($input: CreateProductCategoryInput!) {
    createProductCategory(input: $input) {
      id
    }
  }
`;

export type Variables = {
  readonly input: {
    readonly name: string;
  };
};

export type Response = {
  readonly createProductCategory: {
    readonly id: string;
  };
};

export const useCreateProductCategoryMutation = (
  options?: MutationHookOptions<Response, Variables>,
) => useMutation<Response, Variables>(MUTATION, options);
