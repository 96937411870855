import { gql, QueryHookOptions, useQuery } from '@apollo/client';

const QUERY = gql`
  query getStates {
    states {
      id
      name
      abbreviation
    }
  }
`;

export type Response = {
  readonly states: readonly {
    readonly id: string;
    readonly name: string;
    readonly abbreviation: string;
  }[];
};

export type State = Response['states'][number];

export const useGetStates = (options?: QueryHookOptions<Response>) => {
  const { data } = useQuery<Response>(QUERY, {
    fetchPolicy: 'cache-first',
    ...options,
  });

  return data?.states ?? [];
};
