import { gql, MutationHookOptions, useMutation } from '@apollo/client';

const MUTATION = gql`
  mutation updateSupplier($id: ID!, $input: UpdateSupplierInput!) {
    updateSupplier(id: $id, input: $input) {
      id
    }
  }
`;

export type Variables = {
  readonly id: string;
  readonly input: {
    readonly name: string;
  };
};

export type Response = {
  readonly updateSupplier: {
    readonly id: string;
  };
};

export const useUpdateSupplierMutation = (
  options: MutationHookOptions<Response, Variables>,
) => useMutation<Response, Variables>(MUTATION, options);
