import { gql, useLazyQuery } from '@apollo/client';
import { datadogRum } from '@datadog/browser-rum';
import { useFlagsmith } from 'flagsmith/react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useToken } from './useToken';

const QUERY = gql`
  query getUser {
    me {
      user {
        id
        email
        fullName
        firstName
        accessLevel {
          handle
        }
        googleCalendarId
        hasGoogleCredentials
      }
    }
  }
`;

export type Response = {
  readonly me: {
    readonly user: {
      readonly id: string;
      readonly email: string;
      readonly fullName: string;
      readonly firstName: string;
      readonly accessLevel: {
        readonly handle:
          | 'admin'
          | 'operations'
          | 'operationsAdmin'
          | 'sales'
          | 'finance';
      };
      readonly googleCalendarId: string | null;
      readonly hasGoogleCredentials: boolean;
    };
  };
};

export type User = Response['me']['user'];
export type UserRoleHandle = User['accessLevel']['handle'];

/**
 * Fetches the current user from the server and returns it. Redirects to the
 * login page if the user could not be resolved.
 */
export function useUser(): User | null {
  const [token] = useToken();
  const [user, setUser] = useState<User | null>(null);
  const navigate = useNavigate();
  const flagsmith = useFlagsmith();

  const logout = () => {
    setUser(null);
    navigate('/login');
  };

  const [fetchUser] = useLazyQuery<Response>(QUERY, {
    fetchPolicy: 'cache-first',
    onCompleted: (data) => setUser(data.me.user),
    onError: (error) => {
      console.error(`Error fetching current user: ${error.message}`, error);

      logout();
    },
  });

  useEffect(() => {
    if (token) fetchUser();
    else logout();
  }, [token, setUser]);

  useEffect(() => {
    if (user) {
      datadogRum.setUser({
        id: user.id,
        email: user.email,
        name: user.fullName,
      });
    } else {
      datadogRum.clearUser();
    }
  }, [user]);

  return user;
}
