import { faChevronDown, faChevronUp } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactNode, useState } from 'react';

type Props = {
  readonly title: string;
  readonly counter?: number;
  readonly children: ReactNode;
  readonly actions?: ReactNode;
};

export default function ExpandableSection({
  title,
  counter,
  actions,
  children,
}: Props) {
  const [expanded, setExpanded] = useState(true);

  return (
    <section>
      <div className="flex items-center space-x-5">
        <div className="flex items-center flex-grow">
          <h3 className="flex-grow font-bold">
            {title}
            {counter !== undefined && <> ({counter})</>}
          </h3>

          {actions && <div className="flex-shrink-0">{actions}</div>}
        </div>
        <button
          className="inline-block focus:outline-none"
          onClick={(event) => {
            event.preventDefault();
            setExpanded(!expanded);
          }}
        >
          <FontAwesomeIcon icon={expanded ? faChevronDown : faChevronUp} />
        </button>
      </div>
      {expanded && <div className="pt-4">{children}</div>}
    </section>
  );
}
