import ExpandableSection from '../../../../../components/content/ExpandableSection';
import { LeadOperationsInput } from '../leadOperationsInput';
import CheckboxField from '../../../../../components/controls/CheckboxField';
import React from 'react';
import RoofTypesDropdown from '../../../../../components/leads/dropdowns/RoofTypesDropdown';
import StoreysDropdown from '../../../../../components/leads/dropdowns/StoreysDropdown';
import ConnectionTypesDropdown from '../../../../../components/leads/dropdowns/ConnectionTypesDropdown';

type Props = {
  readonly input: LeadOperationsInput;
  readonly onChange: (value: LeadOperationsInput) => void;
};

export default function LeadOperationsPropertyDataSection({
  input,
  onChange,
}: Props) {
  return (
    <ExpandableSection title="Property">
      <div className="grid grid-cols-4 gap-4">
        <div className="col-span-4 md:col-span-2">
          <ConnectionTypesDropdown
            value={input.connectionTypeId}
            onChange={(value) =>
              onChange({ ...input, connectionTypeId: value })
            }
          />
        </div>
        <div className="col-span-4 md:col-span-2">
          <RoofTypesDropdown
            value={input.roofTypeId}
            onChange={(value) => onChange({ ...input, roofTypeId: value })}
          />
        </div>
        <div className="col-span-4 md:col-span-2">
          <StoreysDropdown
            value={input.storeys?.toString()}
            onChange={(value) =>
              onChange({
                ...input,
                storeys: value,
              })
            }
          />
        </div>

        <div className="col-span-4">
          <CheckboxField
            checked={input.existingSolar}
            onChange={(value) => onChange({ ...input, existingSolar: value })}
          >
            Existing Solar
          </CheckboxField>
        </div>
      </div>
    </ExpandableSection>
  );
}
