import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import Box from '../../../components/content/Box';
import DateField from '../../../components/controls/DateField';
import { faExternalLinkAlt } from '@fortawesome/pro-light-svg-icons';

type ExportRow = {
  readonly text: string;
  readonly url: string;
  readonly datePicker: boolean;
};

const ToolsExportView = () => {
  const [dates, setDates] = useState<Record<string, Date>>({});

  const rows: readonly ExportRow[] = [
    {
      text: 'Download all leads from given date.',
      url:
        process.env.REACT_APP_API_ENDPOINT +
        '/export?token=' +
        localStorage.getItem('token'),
      datePicker: true,
    },
    {
      text: 'Download sales with Pylon components.',
      url:
        process.env.REACT_APP_API_ENDPOINT +
        '/export/pylon?token=' +
        localStorage.getItem('token'),
      datePicker: false,
    },
    {
      text: 'Download contact attempt breakdown.',
      url:
        process.env.REACT_APP_API_ENDPOINT +
        '/export/attempts?token=' +
        localStorage.getItem('token'),
      datePicker: false,
    },
    {
      text: 'Download contact attempt history.',
      url:
        process.env.REACT_APP_API_ENDPOINT +
        '/export/attempt-history?token=' +
        localStorage.getItem('token'),
      datePicker: true,
    },
  ];

  useEffect(() => {
    let data: Record<string, Date> = {};

    for (let i = 0; i < rows.length; i++) {
      if (rows[i].datePicker) {
        data[i] = new Date();
      }
    }

    setDates(data);
  }, []);

  return (
    <Box>
      <section>
        {rows.map((row, index) => (
          <div
            key={row.url}
            className="grid grid-cols-3 gap-5 border-b border-gray-200 last:border-0 py-4"
          >
            <div className="flex">
              <p className="self-center">{row.text}</p>
            </div>
            <div className="flex">
              <div className="self-center w-full">
                {row.datePicker && (
                  <DateField
                    value={dates[index]}
                    onChange={(value) => setDates({ ...dates, [index]: value })}
                  />
                )}
              </div>
            </div>
            <div className="flex">
              <p className="self-center">
                <a
                  className="inline-block border font-medium focus:outline-none rounded-full border-transparent bg-primary hover:bg-secondary text-white px-3 py-1"
                  href={
                    row.url +
                    (dates[index] ? `&t=${dates[index].getTime()}` : '')
                  }
                  target="_blank"
                >
                  Download <FontAwesomeIcon icon={faExternalLinkAlt} />
                </a>
              </p>
            </div>
          </div>
        ))}
      </section>
    </Box>
  );
};

export default ToolsExportView;
