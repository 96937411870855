import { gql, useMutation } from '@apollo/client';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import ErrorMessage from '../../../components/content/ErrorMessage';
import Button from '../../../components/controls/Button';
import TextField from '../../../components/controls/TextField';

const REQUEST_PASSWORD_RESET = gql`
  mutation ($email: String!) {
    requestPasswordReset(email: $email)
  }
`;

export default function AuthRequestResetForm() {
  const [email, setEmail] = useState('');

  const [requestPasswordReset, { loading, error }] = useMutation(
    REQUEST_PASSWORD_RESET,
    {
      onError: (error) => console.error(error),
      onCompleted: () => alert('Password reset email send!'),
    },
  );

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();

        requestPasswordReset({ variables: { email } });
      }}
    >
      <h1 className="mb-4 font-medium text-xl">Request Reset Email</h1>

      <p className="mb-4 text-gray-600 text-sm">
        Enter the email address tied to the account you need a password reset
        link sent to. Note that the link will expire after{' '}
        <strong>5 minutes</strong>.
      </p>

      <div className="mb-4">
        <TextField
          placeholder="Email Address"
          type="email"
          value={email}
          onChange={(value) => setEmail(value)}
        />
      </div>

      {error && <ErrorMessage error={error} />}

      <Button loading={loading} type="submit" primary text="Request Reset" />
      <Link
        className="inline-block ml-2 text-sm hover:underline text-gray-400"
        to="/login"
      >
        Back to login
      </Link>
    </form>
  );
}
