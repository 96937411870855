import { gql, MutationHookOptions, useMutation } from '@apollo/client';

const MUTATION = gql`
  mutation createLead(
    $sourceId: Float
    $address: CreateAddressInput
    $customer: UpsertCustomerInput!
  ) {
    createLead(
      model: { sourceId: $sourceId, address: $address, customer: $customer }
    ) {
      id
    }
  }
`;

export type Variables = {
  readonly sourceId: number | null;
  // @todo: explicit type
  readonly address: any;
  // @todo: explicit type
  readonly customer: any;
};

export type Response = {
  readonly createLead: {
    readonly id: string;
  };
};

export const useCreateLeadMutation = (
  options: MutationHookOptions<Response, Variables>,
) => useMutation<Response, Variables>(MUTATION, options);
