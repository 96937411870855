import Box from '../../../../components/content/Box';
import Container from '../../../../components/content/Container';

type Props = {
  readonly googleCalendarId?: string | null;
};

export function GoogleCalendar({ googleCalendarId }: Props) {
  if (!googleCalendarId) {
    return (
      <Container>
        <Box>Google Calendar is not connected.</Box>
      </Container>
    );
  }

  return (
    <div className="p-5 h-full bg-white">
      <iframe
        title="Calendar"
        width="100%"
        height="100%"
        src={`https://calendar.google.com/calendar/u/0/embed?src=${googleCalendarId}&ctz=${encodeURIComponent(
          Intl.DateTimeFormat().resolvedOptions().timeZone,
        )}&showTitle=0&showPrint=0`}
      />
    </div>
  );
}
