import { useMutation, useQuery } from '@apollo/client';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CapabilityGuard from '../../../../components/CapabilityGuard';
import Container from '../../../../components/content/Container';
import Table from '../../../../components/content/table/Table';
import TableBody from '../../../../components/content/table/TableBody';
import TableCell from '../../../../components/content/table/TableCell';
import TableHeader from '../../../../components/content/table/TableHeader';
import TableHeaderCell from '../../../../components/content/table/TableHeaderCell';
import TableRow from '../../../../components/content/table/TableRow';
import Button from '../../../../components/controls/Button';
import ButtonLink from '../../../../components/controls/ButtonLink';
import Heading from '../../../../components/layout/ToolbarPage/components/Heading';
import NewInstallerModal from './components/NewInstallerModal';
import {
  MUTATION as TOGGLE_INSTALLER_ARCHIVED_MUTATION,
  Response as ToggleInstallerArchivedResponse,
} from './mutations/toggleInstallerArchived';
import {
  QUERY as GET_INSTALLERS_QUERY,
  Response as GetInstallerResponse,
} from './queries/getInstallers';

const InstallersView = () => {
  const navigate = useNavigate();

  const [showNewModal, setShowNewModal] = useState(false);

  const {
    data: installers,
    loading: installersLoading,
    refetch: refetchInstallers,
  } = useQuery<GetInstallerResponse>(GET_INSTALLERS_QUERY);

  const [toggleArchived, { loading }] =
    useMutation<ToggleInstallerArchivedResponse>(
      TOGGLE_INSTALLER_ARCHIVED_MUTATION,
      {
        onCompleted: () => refetchInstallers(),
      },
    );

  return (
    <CapabilityGuard requirements={['canViewInstallers']}>
      <Container>
        <Heading
          text="Installers"
          actions={
            <>
              <Button
                onClick={() => setShowNewModal(true)}
                primary
                text="New Installer"
              />
            </>
          }
        />

        {installersLoading ? (
          <p>Loading&hellip;</p>
        ) : (
          <Table>
            <TableHeader>
              <TableRow>
                <TableHeaderCell>ID</TableHeaderCell>
                <TableHeaderCell>Name</TableHeaderCell>
                <TableHeaderCell>ABN</TableHeaderCell>
                <TableHeaderCell>Items on Hand</TableHeaderCell>
                <TableHeaderCell />
              </TableRow>
            </TableHeader>
            <TableBody>
              {installers ? (
                installers.installers.map((installer) => (
                  <TableRow key={installer.id}>
                    <TableCell compress>{installer.id}</TableCell>
                    <TableCell>{installer.name}</TableCell>
                    <TableCell>{installer.abn ?? '-'}</TableCell>
                    <TableCell>{installer.itemsOnHandCount}</TableCell>
                    <TableCell rightAlign>
                      <div className="space-x-2">
                        <Button
                          size="sm"
                          text={installer.archived ? 'Unarchive' : 'Archive'}
                          onClick={() =>
                            toggleArchived({ variables: { id: installer.id } })
                          }
                          loading={loading}
                        />
                        <ButtonLink
                          text="View"
                          to={`/inventory/installers/${installer.id}`}
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colspan={4}>No installers.</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        )}
      </Container>

      {showNewModal && (
        <NewInstallerModal
          onClose={() => setShowNewModal(false)}
          onCreateSuccess={(installerId) => {
            navigate(`/inventory/installers/${installerId}`);
          }}
        />
      )}
    </CapabilityGuard>
  );
};

export default InstallersView;
