import { gql } from '@apollo/client';

export const MUTATION = gql`
  mutation resetUser2FA($userId: ID!) {
    resetUser2FA(userId: $userId) {
      id
    }
  }
`;

export type Variables = {
  readonly userId: string;
};

export type Response = {
  readonly resetUser2FA: {
    readonly id: string;
  };
};
