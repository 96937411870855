import { ReactNode } from 'react';

type Props = {
  readonly children: ReactNode;
};

export default function Container({ children }: Props) {
  return (
    <div className="px-6 pt-6 pb-10">
      <div className="container mx-auto">{children}</div>
    </div>
  );
}
