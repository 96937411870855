import { gql, QueryHookOptions, useQuery } from '@apollo/client';

const QUERY = gql`
  query getInstallers {
    installers {
      id
      name
      archived
      contacts {
        id
        name
        archived
      }
    }
  }
`;

export type Response = {
  readonly installers: readonly {
    readonly id: number;
    readonly name: string;
    readonly archived: boolean;
    readonly contacts: readonly {
      readonly id: number;
      readonly name: string;
      readonly archived: boolean;
    }[];
  }[];
};

export type Installer = Response['installers'][number];

export const useGetInstallers = (options?: QueryHookOptions<Response>) => {
  const { data } = useQuery<Response>(QUERY, {
    fetchPolicy: 'cache-first',
    ...options,
  });

  return data?.installers ?? [];
};
