import { gql, QueryHookOptions, useQuery } from '@apollo/client';

const QUERY = gql`
  query getLeadPool($id: ID!) {
    leadPool(id: $id) {
      id
      name
      created
      updated
      fromCreatedDate
      toCreatedDate
      minDials
      maxDials
      size
      leadSources {
        id
        name
      }
      agents {
        id
      }
      statuses {
        id
        handle
        name
      }
      allowedTerminationReasons {
        id
        name
      }
    }
  }
`;

export type Variables = {
  readonly id: number;
};

export type Response = {
  readonly leadPool: {
    readonly id: number;
    readonly name: string;
    readonly created: string;
    readonly updated: string;
    readonly fromCreatedDate: string | null;
    readonly toCreatedDate: string | null;
    readonly minDials: number | null;
    readonly maxDials: number | null;
    readonly size: number;
    readonly leadSources: {
      readonly id: string;
      readonly name: string;
    }[];
    readonly statuses: {
      readonly id: string;
      readonly handle: string;
      readonly name: string;
    }[];
    readonly agents: {
      readonly id: string;
    }[];
    readonly allowedTerminationReasons: {
      readonly id: string;
      readonly name: string;
    }[];
  };
};

export const useGetLeadPoolQuery = (
  options?: QueryHookOptions<Response, Variables>,
) => useQuery<Response, Variables>(QUERY, options);
