import { gql, MutationHookOptions, useMutation } from '@apollo/client';

export const MUTATION = gql`
  mutation ($leadId: Float!, $content: String!, $context: String) {
    createNote(leadId: $leadId, content: $content, context: $context) {
      created
      content
    }
  }
`;

export type Variables = {
  readonly leadId: number;
  readonly content: string;
  readonly context?: string;
};

export type Response = {
  readonly createNote: {
    readonly created: string;
    readonly content: string;
  };
};

export const useCreateNoteMutation = (
  options: MutationHookOptions<Response, Variables>,
) => useMutation<Response, Variables>(MUTATION, options);
