import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLink } from 'react-router-dom';
import clsx from 'clsx';

type Props = {
  readonly text: string;
  readonly link: string;
  readonly icon?: IconProp;
};

export default function NavigationLink({ text, link, icon }: Props) {
  return (
    <div className="hover:bg-secondary-lighter">
      <NavLink
        to={link}
        className={({ isActive }) =>
          clsx('block px-6 py-2 text-sm', {
            'bg-primary hover:bg-primary': isActive,
          })
        }
      >
        {icon && (
          <span className="inline-block w-full lg:w-8 text-center lg:text-left">
            <FontAwesomeIcon icon={icon} />
          </span>
        )}
        <span className="hidden lg:inline-block font-medium">{text}</span>
      </NavLink>
    </div>
  );
}
