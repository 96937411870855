import { gql, QueryHookOptions, useQuery } from '@apollo/client';

const QUERY = gql`
  query getProductCategories {
    productCategories {
      id
      created
      updated
      name
      totalProducts
    }
  }
`;

export type Variables = {};

export type Response = {
  readonly productCategories: readonly {
    readonly id: string;
    readonly created: string;
    readonly updated: string;
    readonly name: string;
    readonly totalProducts: number;
  }[];
};

export const useGetProductCategoriesQuery = (
  options?: QueryHookOptions<Response, Variables>,
) => useQuery<Response, Variables>(QUERY, options);
