import { AnimatePresence } from 'framer-motion';
import { useState } from 'react';
import CapabilityGuard from '../../../../components/CapabilityGuard';
import Container from '../../../../components/content/Container';
import Table from '../../../../components/content/table/Table';
import TableBody from '../../../../components/content/table/TableBody';
import TableCell from '../../../../components/content/table/TableCell';
import TableHeader from '../../../../components/content/table/TableHeader';
import TableHeaderCell from '../../../../components/content/table/TableHeaderCell';
import TableRow from '../../../../components/content/table/TableRow';
import Button from '../../../../components/controls/Button';
import ButtonLink from '../../../../components/controls/ButtonLink';
import Heading from '../../../../components/layout/ToolbarPage/components/Heading';
import CreateSupplierTray from '../CreateSupplierTray';
import { useGetSuppliersQuery } from './queries/getSuppliersQuery';

export function SuppliersView() {
  const [showCreateTray, setShowCreateTray] = useState(false);

  const { data } = useGetSuppliersQuery();

  return (
    <CapabilityGuard requirements={['canViewOrders']}>
      <Container>
        <Heading
          text="Suppliers"
          actions={
            <>
              <Button
                primary
                text="Create"
                onClick={() => setShowCreateTray(true)}
              />
            </>
          }
        />

        <Table>
          <TableHeader>
            <TableRow>
              <TableHeaderCell>ID</TableHeaderCell>
              <TableHeaderCell>Name</TableHeaderCell>
              <TableHeaderCell>Contacts</TableHeaderCell>
              <TableHeaderCell>Locations</TableHeaderCell>
              <TableHeaderCell />
            </TableRow>
          </TableHeader>
          <TableBody>
            {data?.suppliers.length === 0 ? (
              <TableRow>
                <TableCell colspan={5}>No suppliers.</TableCell>
              </TableRow>
            ) : (
              (data?.suppliers ?? []).map((supplier) => (
                <TableRow key={supplier.id}>
                  <TableCell>{supplier.id}</TableCell>
                  <TableCell>{supplier.name || '-'}</TableCell>
                  <TableCell>{supplier.contacts.length}</TableCell>
                  <TableCell>{supplier.locations.length}</TableCell>
                  <TableCell rightAlign>
                    <ButtonLink
                      text="View"
                      to={`/inventory/suppliers/${supplier.id}`}
                    />
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </Container>

      <AnimatePresence>
        {showCreateTray && (
          <CreateSupplierTray onClose={() => setShowCreateTray(false)} />
        )}
      </AnimatePresence>
    </CapabilityGuard>
  );
}
