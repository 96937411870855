import DropdownField from '../../controls/DropdownField';
import React from 'react';
import { useGetDistributors } from '../../../hooks/useGetDistributors';

type Props = {
  readonly value?: string;
  readonly onChange: (value?: string) => void;
};

export default function DistributorsDropdown({ value, onChange }: Props) {
  const distributors = useGetDistributors();

  return (
    <DropdownField
      label="Distributor"
      placeholder="Distributor"
      value={value}
      options={distributors.map((distributor) => ({
        text: distributor.name,
        value: distributor.id.toString(),
      }))}
      onChange={onChange}
    />
  );
}
