import ToolbarDropdown from './ToolbarDropdown';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import clsx from 'clsx';
import { User, useUser } from '../../../../../../hooks/useUser';
import { useMemo } from 'react';

type Props = {
  readonly title?: boolean;
  readonly text: string;
  readonly nav?: readonly {
    readonly text: string;
    readonly href: string;
    readonly icon: IconProp;
    readonly roles?: readonly User['accessLevel']['handle'][];
  }[];
};

export default function ToolbarItem({ title, text, nav }: Props) {
  const user = useUser();

  const availableNav = useMemo(() => {
    if (nav) {
      return nav.filter((item) => {
        if (item.roles) {
          if (!user || !item.roles.includes(user.accessLevel.handle)) {
            return false;
          }
        }

        return true;
      });
    }

    return undefined;
  }, [user, nav]);

  return (
    <div className="relative">
      <div
        className={clsx(
          'px-5 border-l flex items-center border-gray-300 h-14',
          {
            'font-bold border-l-0': title,
          },
        )}
      >
        {availableNav ? (
          <ToolbarDropdown text={text} items={availableNav} />
        ) : (
          text
        )}
      </div>
    </div>
  );
}
