import { faQuestionCircle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactNode, useState } from 'react';
import Modal from '../../overlay/Modal';

type Props = {
  readonly children?: ReactNode;
  readonly rightAlign?: boolean;
  readonly hint?: ReactNode;
};

export default function TableHeaderCell({ children, rightAlign, hint }: Props) {
  const [showHint, setShowHint] = useState(false);

  return (
    <th
      scope="col"
      className={
        'px-4 py-2 text-left text-xs font-medium text-white uppercase tracking-wider' +
        (rightAlign ? ' text-right' : '')
      }
    >
      {children}{' '}
      {hint && (
        <button onClick={() => setShowHint(true)}>
          <FontAwesomeIcon icon={faQuestionCircle} />
        </button>
      )}
      {showHint && (
        <Modal
          heading={children?.toString()}
          onClose={() => setShowHint(false)}
        >
          {hint}
        </Modal>
      )}
    </th>
  );
}
