import { ReactNode } from 'react';

type Props = {
  readonly children: ReactNode;
  readonly sidebar: ReactNode;
};

export default function SplitLayout({ children, sidebar }: Props) {
  return (
    <section className="flex flex-col lg:flex-row">
      <div className="flex-grow">{children}</div>
      <aside className="lg:max-w-md lg:pl-4 lg:pt-0 pt-4 flex-shrink-0">
        {sidebar}
      </aside>
    </section>
  );
}
