import { gql, MutationHookOptions } from '@apollo/client';
import { useModifiedMutation } from '../../../../../apollo/hooks/useModifiedMutation';

const MUTATION = gql`
  mutation createOrderReturn($input: CreateOrderReturnInput!) {
    createOrderReturn(input: $input) {
      id
    }
  }
`;

export type Variables = {
  readonly input: {
    readonly orderId: string;
    readonly lineItems: readonly {
      readonly productId: string;
      readonly quantity: number;
    }[];
  };
};

export type Response = {
  readonly createOrderReturn: {
    readonly id: string;
  };
};

export const useCreateOrderReturnMutation = (
  options: MutationHookOptions<Response, Variables>,
) => useModifiedMutation<Response, Variables>(MUTATION, options);
