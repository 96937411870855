import { gql, useMutation } from '@apollo/client';
import { useState } from 'react';
import Divider from '../../content/Divider';
import Button from '../../controls/Button';
import { default as DateField } from '../../controls/DateField';
import DropdownField from '../../controls/DropdownField';
import TextField from '../../controls/TextField';
import { useGetLeadActionTypes } from '../../../hooks/useGetLeadActionTypes';

const CREATE_ACTION = gql`
  mutation ($id: Float!, $model: CreateLeadActionInput!) {
    createAction(leadId: $id, model: $model) {
      id
    }
  }
`;

type Props = {
  readonly leadId: string;
  readonly onCreateAction: () => void;
};

export default function LeadCreateActionForm({
  leadId,
  onCreateAction,
}: Props) {
  const leadActionTypes = useGetLeadActionTypes();

  const [due, setDue] = useState<Date | null>(null);
  const [typeId, setTypeId] = useState<string | undefined>('2');
  const [content, setContent] = useState('');

  const [createAction, { loading }] = useMutation(CREATE_ACTION, {
    onCompleted: () => onCreateAction(),
    onError: (error) => console.error(error),
  });

  const handleCreateAction = () => {
    const tz = () => {
      try {
        return Intl.DateTimeFormat().resolvedOptions().timeZone;
      } catch {
        //
      }

      return 'Australia/Sydney';
    };
    createAction({
      variables: {
        id: parseInt(leadId),
        model: {
          dueUnix: due ? due.getTime() : undefined,
          typeId: typeId ? parseInt(typeId) : undefined,
          content,
          timezone: tz(),
        },
      },
    });
  };

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        handleCreateAction();
      }}
    >
      <div className="grid grid-cols-2 gap-4">
        <div className="col-span-2 md:col-span-1">
          <DateField
            time
            label="Action Date"
            value={due}
            onChange={(value) => setDue(value)}
          />
        </div>
        <div className="col-span-2 md:col-span-1">
          <DropdownField
            label="Type"
            value={typeId}
            onChange={(value) => setTypeId(value)}
            options={leadActionTypes.map((type) => ({
              text: type.name,
              value: type.id.toString(),
            }))}
          />
        </div>
        <div className="col-span-2">
          <TextField
            type="box"
            placeholder="Notes..."
            label="Notes"
            value={content}
            onChange={(value) => setContent(value)}
          />
        </div>
      </div>

      <Divider />

      <Button
        disabled={!(due && typeId && content.length > 0)}
        loading={loading}
        type="submit"
        text="Create Action"
      />
    </form>
  );
}
