import { gql, MutationHookOptions } from '@apollo/client';
import { useModifiedMutation } from '../../../../../../../../../apollo/hooks/useModifiedMutation';

const MUTATION = gql`
  mutation updateOrderReturn($id: ID!, $input: UpdateOrderReturnInput!) {
    updateOrderReturn(id: $id, input: $input) {
      id
    }
  }
`;

export type Variables = {
  readonly id: string;
  readonly input: {
    readonly returnNumber: string | null;
    readonly status: string;
  };
};

export type Response = {
  readonly updateOrderReturn: {
    readonly id: string;
  };
};

export const useUpdateOrderReturnMutation = (
  options: MutationHookOptions<Response, Variables>,
) => useModifiedMutation(MUTATION, options);
