import { useMemo, useState } from 'react';
import { Route, Routes, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Box from '../../components/content/Box';
import Container from '../../components/content/Container';
import Divider from '../../components/content/Divider';
import ErrorMessage from '../../components/content/ErrorMessage';
import Page from '../../components/content/Page';
import SplitLayout from '../../components/content/SplitLayout';
import Button from '../../components/controls/Button';
import Modal from '../../components/overlay/Modal';
import { useUser } from '../../hooks/useUser';
import LeadActionsView from './components/LeadActionsView';
import LeadActivityView from './components/LeadActivityView';
import LeadDocumentsView from './components/LeadDocumentsView';
import LeadFinanceView from './components/LeadFinanceView';
import LeadNavigation from './components/LeadNavigation';
import LeadNotesView from './components/LeadNotesView';
import LeadOperationsView from './components/LeadOperationsView';
import LeadOrdersView from './components/LeadOrdersView';
import LeadPylonView from './components/LeadPylonView';
import LeadRelatedView from './components/LeadRelatedView';
import LeadSalesView from './components/LeadSalesView';
import LeadServiceJobsView from './components/LeadServiceJobsView';
import LeadSidebar from './components/LeadSidebar';
import { useDeleteLeadMutation } from './mutations/deleteLeadMutation';
import { useGetLeadLocksQuery } from './queries/getLeadLocksQuery';
import { useGetLeadQuery } from './queries/getLeadQuery';

type Params = {
  readonly id: string;
};

export function LeadView() {
  const { id } = useParams<Params>();

  const user = useUser();

  const updatedNotification = () =>
    toast('Lead was updated successfully.', {
      theme: 'light',
    });

  if (!id) {
    // @todo: some kind of 404
    return null;
  }

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const { data, error, loading, refetch } = useGetLeadQuery({
    fetchPolicy: 'no-cache',
    variables: {
      id: parseInt(id),
    },
  });

  const related = useMemo(() => {
    return (
      data?.lead.customer?.leads.filter((lead) => String(lead.id) !== id) ?? []
    );
  }, [data]);

  const blocked = useMemo(() => {
    if (data && user) {
      if (
        user.accessLevel.handle === 'sales' &&
        data.lead.status.handle === 'unclaimed' &&
        data.lead.agent?.id.toString() !== user.id
      ) {
        return true;
      }
    }

    return false;
  }, [data, user]);

  const serviceJobs = useMemo(() => {
    return data?.lead.customer?.serviceJobs ?? [];
  }, [data]);

  const leadLocksQuery = useGetLeadLocksQuery({
    fetchPolicy: 'no-cache',
    variables: {
      id: parseInt(id),
    },
  });

  const [deleteLead, { loading: deleteLeadLoading }] = useDeleteLeadMutation({
    variables: {
      id,
    },
    onCompleted: async () => {
      await refetch();
      setShowDeleteModal(false);
    },
  });

  if (loading || leadLocksQuery.loading) {
    return <Container>Loading&hellip;</Container>;
  }

  if (error) {
    return (
      <Page>
        <Container>
          <ErrorMessage error={error} />
        </Container>
      </Page>
    );
  }

  const canAccessFinance =
    user && ['admin', 'finance'].includes(user.accessLevel.handle);

  const handleUpdate = () => {
    // @todo: replace with toast
    setShowConfirmationModal(true);
    refetch();
  };

  if (data) {
    return (
      <>
        <LeadNavigation
          lead={data.lead}
          relatedCount={related.length}
          serviceJobCount={serviceJobs.length}
          ordersCount={data.lead.orders.length}
          hasPylonDesign={data.lead.pylonDesignId !== null}
          onDelete={() => setShowDeleteModal(true)}
        />

        <Page>
          <Container>
            <SplitLayout
              sidebar={
                <LeadSidebar lead={data.lead} onUpdate={() => handleUpdate()} />
              }
            >
              {blocked ? (
                <Box>
                  <p>Unclaimed lead isn't owned by current agent</p>
                </Box>
              ) : (
                <Box>
                  <Routes>
                    <Route
                      index
                      element={
                        <LeadSalesView
                          lead={data.lead}
                          onUpdate={() => handleUpdate()}
                        />
                      }
                    />
                    <Route
                      path="/operations"
                      element={
                        <LeadOperationsView
                          lead={data.lead}
                          onUpdate={() => handleUpdate()}
                        />
                      }
                    />
                    {canAccessFinance && (
                      <Route
                        path="/finance"
                        element={
                          <LeadFinanceView
                            lead={data.lead}
                            paymentsLock={
                              leadLocksQuery.data?.lead.paymentsLock ?? null
                            }
                            commissionLock={
                              leadLocksQuery.data?.lead.commissionLock ?? null
                            }
                            financeCalculationsLock={
                              leadLocksQuery.data?.lead
                                .financeCalculationsLock ?? null
                            }
                            onUpdate={() => handleUpdate()}
                            onToggleLock={() => leadLocksQuery.refetch()}
                          />
                        }
                      />
                    )}
                    <Route
                      path="/notes"
                      element={
                        <LeadNotesView
                          leadId={data.lead.id}
                          onUpdate={handleUpdate}
                        />
                      }
                    />
                    <Route
                      path="/actions"
                      element={
                        <LeadActionsView
                          lead={data.lead}
                          onUpdate={() => handleUpdate()}
                        />
                      }
                    />
                    <Route
                      path="/documents"
                      element={
                        <LeadDocumentsView
                          lead={data.lead}
                          onUpdate={() => handleUpdate()}
                        />
                      }
                    />
                    <Route
                      path="/pylon"
                      element={
                        <LeadPylonView
                          lead={data.lead}
                          onUpdate={() => handleUpdate()}
                        />
                      }
                    />
                    <Route
                      path="/related"
                      element={
                        <LeadRelatedView
                          leadId={data.lead.id}
                          related={related}
                        />
                      }
                    />
                    <Route
                      path="/service-jobs"
                      element={
                        <LeadServiceJobsView
                          leadId={data.lead.id}
                          customerId={data.lead.customer?.id}
                          jobs={serviceJobs}
                          onCreate={() => handleUpdate()}
                        />
                      }
                    />
                    <Route
                      path="/orders"
                      element={
                        <LeadOrdersView
                          lead={data.lead}
                          onUpdate={() => refetch()}
                        />
                      }
                    />
                    {user?.accessLevel.handle === 'admin' && (
                      <Route
                        path="/activity"
                        element={<LeadActivityView leadId={data.lead.id} />}
                      />
                    )}
                  </Routes>
                </Box>
              )}
            </SplitLayout>
          </Container>
        </Page>

        {showConfirmationModal && (
          <Modal
            onClose={() => setShowConfirmationModal(false)}
            heading="Lead Updated"
          >
            <p>Lead was updated successfully.</p>

            <Divider />

            <Button
              onClick={() => setShowConfirmationModal(false)}
              text="Close"
            />
          </Modal>
        )}

        {showDeleteModal && (
          <Modal
            heading="Delete Lead"
            onClose={() => setShowDeleteModal(false)}
          >
            <p>
              Are you sure you want to delete this lead? It will still be
              viewable using its link but won't appear in searches, reports,
              etc.
            </p>

            <Divider />

            <Button
              className="mr-2"
              onClick={() => deleteLead()}
              primary
              text="Delete"
              loading={deleteLeadLoading}
            />
            <Button onClick={() => setShowDeleteModal(false)} text="Cancel" />
          </Modal>
        )}
      </>
    );
  }

  return null;
}
