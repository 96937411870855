import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import Divider from '../../../../components/content/Divider';
import ErrorMessage from '../../../../components/content/ErrorMessage';
import ExpandableSection from '../../../../components/content/ExpandableSection';
import Button from '../../../../components/controls/Button';
import Notes from '../../../../components/leads/Notes';
import { Lead } from '../../queries/getLeadQuery';
import LeadOperationsDataSection from './components/LeadOperationsDataSection';
import LeadOperationsPropertyDataSection from './components/LeadOperationsPropertyDataSection';
import { leadOperationsInputDefinition } from './leadOperationsInput';
import { useUpdateLeadOperationsInfoMutation } from './mutations/updateLeadOperationsInfoMutation';

type Props = {
  readonly lead: Lead;
  readonly onUpdate: () => void;
};

export function LeadOperationsView({ lead, onUpdate }: Props) {
  const [operationsInput, setOperationsInput] = useState(
    leadOperationsInputDefinition.getDefaultState(),
  );

  useEffect(() => {
    setOperationsInput(leadOperationsInputDefinition.toPrimitive(lead));
  }, [lead]);

  const [updateLeadOperations, { loading, error }] =
    useUpdateLeadOperationsInfoMutation({
      onCompleted: () => onUpdate(),
      onError: (error) => console.error(error),
    });

  return (
    <>
      <Helmet>
        <title>Operations | Lead {lead.id} | Doug CRM</title>
      </Helmet>

      <LeadOperationsDataSection
        input={operationsInput}
        pylonDesign={lead.pylonDesign}
        hasOrders={lead.orders.length > 0}
        onChange={(value) => setOperationsInput(value)}
      />

      <Divider />

      <ExpandableSection title="Notes">
        <Notes leadId={lead.id} ctx="operations" />
      </ExpandableSection>

      <Divider />

      <LeadOperationsPropertyDataSection
        input={operationsInput}
        onChange={(value) => setOperationsInput(value)}
      />

      <Divider />

      {error && (
        <>
          <ErrorMessage error={error} />
          <Divider />
        </>
      )}

      <Button
        loading={loading}
        text="Save"
        onClick={() =>
          updateLeadOperations({
            variables: {
              id: parseInt(lead.id.toString()),
              model: leadOperationsInputDefinition.toActual(operationsInput),
            },
          })
        }
      />
    </>
  );
}
