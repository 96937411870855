import React from 'react';
import { CustomerInput } from '../../../../../globals';
import TextField from '../../../../../components/controls/TextField';
import ExpandableSection from '../../../../../components/content/ExpandableSection';

type Props = {
  readonly customer: CustomerInput;
  readonly onChange: (value: CustomerInput) => void;
};

export default function LeadCustomerDataSection({ customer, onChange }: Props) {
  return (
    <ExpandableSection title="Customer">
      <div className="grid grid-cols-3 gap-4">
        <div className="col-span-3 md:col-span-1">
          <TextField
            type="text"
            label="First Name"
            placeholder="First Name"
            value={customer.firstName}
            onChange={(value) => onChange({ ...customer, firstName: value })}
          />
        </div>
        <div className="col-span-3 md:col-span-1">
          <TextField
            type="text"
            label="Middle Name"
            placeholder="Middle Name"
            value={customer.middleName}
            onChange={(value) => onChange({ ...customer, middleName: value })}
          />
        </div>
        <div className="col-span-3 md:col-span-1">
          <TextField
            type="text"
            label="Last Name"
            placeholder="Last Name"
            value={customer.lastName}
            onChange={(value) => onChange({ ...customer, lastName: value })}
          />
        </div>
        <div className="col-span-3">
          <TextField
            type="email"
            label="Email Address"
            placeholder="Email Address"
            value={customer.email}
            onChange={(value) => onChange({ ...customer, email: value })}
          />
        </div>
        <div className="col-span-3 md:col-span-1">
          <TextField
            type="text"
            label="Mobile Phone"
            placeholder="Mobile Phone"
            value={customer.mobilePhone}
            onChange={(value) => onChange({ ...customer, mobilePhone: value })}
          />
        </div>
        <div className="col-span-3 md:col-span-1">
          <TextField
            type="text"
            label="Home Phone"
            placeholder="Home Phone"
            value={customer.homePhone}
            onChange={(value) => onChange({ ...customer, homePhone: value })}
          />
        </div>
        <div className="col-span-3 md:col-span-1">
          <TextField
            type="text"
            label="Work Phone"
            placeholder="Work Phone"
            value={customer.workPhone}
            onChange={(value) => onChange({ ...customer, workPhone: value })}
          />
        </div>
      </div>
    </ExpandableSection>
  );
}
