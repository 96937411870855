import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCaretDown } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useClickAway } from '@uidotdev/usehooks';
import { AnimatePresence, motion } from 'framer-motion';
import { useMemo, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

type Props = {
  readonly text: string;
  readonly items: readonly {
    readonly text: string;
    readonly href: string;
    readonly icon: IconProp;
  }[];
};

export default function ToolbarDropdown({ items }: Props) {
  const location = useLocation();
  const ref = useClickAway<HTMLElement>(() => setVisible(false));

  const [visible, setVisible] = useState(false);

  const text = useMemo(() => {
    return (
      items.findLast((item) => location.pathname.startsWith(item.href))?.text ??
      items[0].text
    );
  }, [items, location]);

  return (
    <span ref={ref}>
      <button
        className="flex items-center space-x-2"
        onClick={() => setVisible(!visible)}
      >
        <span>{text}</span>
        <span className="w-5 h-5 rounded-md border border-gray-300 bg-white flex items-center justify-center">
          <FontAwesomeIcon icon={faCaretDown} />
        </span>
      </button>

      <AnimatePresence>
        {visible && (
          <motion.nav
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.1, ease: 'easeInOut' }}
            className="absolute z-10 border border-gray-300 space-y-1 p-2 min-w-full shadow-md bg-gray-100 top-full left-0 mt-1 rounded-md"
          >
            {items.map((item) => (
              <NavLink
                key={item.href}
                to={item.href}
                className="flex items-center w-full py-1 pl-3 pr-6 rounded-md space-x-3 hover:bg-gray-200"
                onClick={() => setVisible(false)}
              >
                <FontAwesomeIcon
                  className="opacity-75 text-xs"
                  icon={item.icon}
                />
                <span className="whitespace-nowrap">{item.text}</span>
              </NavLink>
            ))}
          </motion.nav>
        )}
      </AnimatePresence>
    </span>
  );
}
