import { gql, MutationHookOptions, useMutation } from '@apollo/client';

const MUTATION = gql`
  mutation startOrder($input: StartOrderInput!) {
    startOrder(input: $input) {
      id
    }
  }
`;

export type Variables = {
  readonly input: {
    readonly leadId: string;
    readonly supplierId?: string;
    readonly lineItems: readonly {
      readonly productId: string;
      readonly quantity: number;
    }[];
  };
};

export type Response = {
  readonly startOrder: {
    readonly id: string;
  };
};

export const useStartOrderMutation = (
  options: MutationHookOptions<Response, Variables>,
) => useMutation<Response, Variables>(MUTATION, options);
