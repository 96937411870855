import clsx from 'clsx';
import { ReactNode } from 'react';

type Props = {
  readonly children?: ReactNode;
  readonly className?: string;
};

export default function Table({ children, className }: Props) {
  return (
    <section className={clsx('flex flex-col', className)}>
      <div className="shadow-md bg-white overflow-auto rounded-lg">
        <table className="min-w-full divide-y divide-gray-200">
          {children}
        </table>
      </div>
    </section>
  );
}
