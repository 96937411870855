import TextField from '../../../../../components/controls/TextField';
import EnergyRetailerDropdown from '../../../../../components/leads/dropdowns/EnergyRetailerDropdown';
import DistributorsDropdown from '../../../../../components/leads/dropdowns/DistributorsDropdown';
import { AdditionalFields } from '../types';
import Button from '../../../../../components/controls/Button';

type Props = {
  readonly loading: boolean;
  readonly value: AdditionalFields;
  readonly onChange: (value: AdditionalFields) => void;
  readonly onSave: () => void;
};

export default function PowerBillAdditionalData({
  loading,
  value,
  onChange,
  onSave,
}: Props) {
  return (
    <div className="flex items-end space-x-5">
      <div className="flex-grow grid gap-5 grid-cols-3">
        <TextField
          value={value.nmi ?? ''}
          placeholder="NMI"
          onChange={(v) => onChange({ ...value, nmi: v })}
          label="NMI"
        />
        <EnergyRetailerDropdown
          onChange={(v) => onChange({ ...value, energyRetailerId: v })}
          value={value.energyRetailerId}
        />
        <DistributorsDropdown
          onChange={(v) => onChange({ ...value, distributorId: v })}
          value={value.distributorId}
        />
      </div>
      <div className="">
        <Button loading={loading} text="Save" size="sm" onClick={onSave} />
      </div>
    </div>
  );
}
