import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import { OrderStatusHandle } from '../globals';

const QUERY = gql`
  query getOrderStatuses {
    orderStatuses {
      id
      handle
      name
    }
  }
`;

export type Response = {
  readonly orderStatuses: readonly {
    readonly id: string;
    readonly handle: OrderStatusHandle;
    readonly name: string;
  }[];
};

export type OrderStatus = Response['orderStatuses'][number];

export const useGetOrderStatuses = (options?: QueryHookOptions<Response>) => {
  const { data } = useQuery<Response>(QUERY, {
    fetchPolicy: 'cache-first',
    ...options,
  });

  return data?.orderStatuses ?? [];
};
