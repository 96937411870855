import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Box from '../../../../components/content/Box';
import Container from '../../../../components/content/Container';
import Divider from '../../../../components/content/Divider';
import ExpandableSection from '../../../../components/content/ExpandableSection';
import SplitLayout from '../../../../components/content/SplitLayout';
import Button from '../../../../components/controls/Button';
import TextField from '../../../../components/controls/TextField';
import ToolbarPage from '../../../../components/layout/ToolbarPage';
import Heading from '../../../../components/layout/ToolbarPage/components/Heading';
import ToolbarItem from '../../../../components/layout/ToolbarPage/components/Toolbar/components/ToolbarItem';
import InventoryToolbar from '../InventoryToolbar';
import SupplierContactsTable from './components/SupplierContactsTable';
import SupplierLocationsTable from './components/SupplierLocationsTable';
import SupplierMeta from './components/SupplierMeta';
import { useUpdateSupplierMutation } from './mutations/updateSupplierMutation';
import { useGetSupplierQuery } from './queries/getSupplierQuery';

type Params = {
  readonly id: string;
};

type Props = {};

export function SupplierView({}: Props) {
  const { id } = useParams<Params>();

  const [name, setName] = useState('');

  const { data, refetch } = useGetSupplierQuery({
    variables: { id: id! },
  });

  const [updateSupplier, { loading }] = useUpdateSupplierMutation({
    variables: {
      id: String(id),
      input: {
        name,
      },
    },
    onCompleted: () => refetch(),
  });

  useEffect(() => {
    if (data) {
      setName(data.supplier.name);
    }
  }, [data]);

  if (!data) {
    return null;
  }

  return (
    <ToolbarPage
      requiredCapabilities={['canViewOrders']}
      toolbar={
        <InventoryToolbar>
          <ToolbarItem text={data.supplier.name} />
        </InventoryToolbar>
      }
    >
      <Container>
        <Heading
          text={data.supplier.name}
          actions={
            <Button
              loading={loading}
              primary
              text="Save"
              onClick={() => updateSupplier()}
            />
          }
        />

        <SplitLayout sidebar={<SupplierMeta supplier={data.supplier} />}>
          <Box>
            <ExpandableSection title="Details">
              <div className="grid gap-5 md:grid-cols-2">
                <div className="md:col-span-2">
                  <TextField
                    value={name}
                    onChange={setName}
                    label="Name"
                    placeholder="Name"
                  />
                </div>
              </div>
            </ExpandableSection>
            <Divider />
            <ExpandableSection
              title="Contacts"
              counter={data.supplier.contacts.length}
            >
              <SupplierContactsTable
                supplierId={parseInt(data.supplier.id)}
                contacts={data.supplier.contacts}
                onChange={() => refetch()}
              />
            </ExpandableSection>
            <Divider />
            <ExpandableSection
              title="Locations"
              counter={data.supplier.locations.length}
            >
              <SupplierLocationsTable
                supplierId={parseInt(data.supplier.id)}
                locations={data.supplier.locations}
                onChange={() => refetch()}
              />
            </ExpandableSection>
          </Box>
        </SplitLayout>
      </Container>
    </ToolbarPage>
  );
}
