import { ReactNode } from 'react';

type Props = {
  readonly label: string;
  readonly children: ReactNode;
};

export default function Filter({ label, children }: Props) {
  return (
    <div className="flex items-center shadow-sm bg-gray-100 border border-gray-300 rounded-md py-1 pr-1">
      <label className="text-gray-500 text-sm px-4">{label}</label>
      <div>{children}</div>
    </div>
  );
}
