import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../../../components/controls/Button';
import TextField from '../../../../components/controls/TextField';
import Tray from '../../../../components/layout/Tray';
import { useCreateSupplierMutation } from './mutations/createSupplierMutation';

type Props = {
  readonly onClose: () => void;
};

export function CreateSupplierTray({ onClose }: Props) {
  const navigate = useNavigate();

  const [name, setName] = useState('');

  const [createSupplier, { loading }] = useCreateSupplierMutation({
    variables: {
      input: {
        name,
      },
    },
    onCompleted: (data) => {
      navigate(`/inventory/suppliers/${data.createSupplier.id}`);
    },
  });

  const disabled = useMemo(() => {
    return name.length === 0;
  }, [name]);

  return (
    <Tray
      heading="New Supplier"
      onClose={onClose}
      actions={
        <>
          <Button
            disabled={disabled}
            loading={loading}
            primary
            text="Create"
            onClick={() => createSupplier()}
          />
        </>
      }
    >
      <div>
        <h4 className="px-5 pt-5 font-bold text-gray-600">Details</h4>
        <div className="grid p-5 gap-5 md:grid-cols-2">
          <div className="md:col-span-2">
            <TextField
              value={name}
              onChange={setName}
              label="Name"
              placeholder="Name"
            />
          </div>
        </div>
      </div>
    </Tray>
  );
}
