import { gql, QueryHookOptions, useQuery } from '@apollo/client';

const QUERY = gql`
  query getOverview {
    productsCount(input: { productCategoryIds: [] })
    suppliers {
      id
    }
    productCategories {
      id
    }
    ordersCount(input: { statuses: [DRAFT, READY, SENT] })
    installersCount
    itemsOnHand {
      quantity
      product {
        id
        name
      }
      installer {
        id
        name
      }
    }
  }
`;

export type Variables = {};

export type Response = {
  readonly productsCount: number;
  readonly suppliers: readonly {
    readonly id: string;
  }[];
  readonly productCategories: readonly {
    readonly id: string;
  }[];
  readonly ordersCount: number;
  readonly installersCount: number;
  readonly itemsOnHand: readonly {
    readonly quantity: number;
    readonly product: {
      readonly id: string;
      readonly name: string;
    };
    readonly installer: {
      readonly id: string;
      readonly name: string;
    };
  }[];
};

export const useGetOverviewQuery = (
  options?: QueryHookOptions<Response, Variables>,
) => useQuery<Response, Variables>(QUERY, options);
