import { gql } from '@apollo/client';

export const MUTATION = gql`
  mutation ($id: Float!) {
    toggleLeadSourceArchived(id: $id) {
      id
    }
  }
`;

export type Response = {
  readonly toggleLeadSourceArchived: {
    readonly id: number;
  };
};
