import { ReactNode } from 'react';

type Props = {
  readonly heading: string;
  readonly children?: ReactNode;
};

export default function PylonDesignDataRow({ heading, children }: Props) {
  return (
    <>
      <div>
        <strong>{heading}</strong>
      </div>
      <div className="col-span-3">{children}</div>
    </>
  );
}
