import { ApolloError } from '@apollo/client';
import React from 'react';

type Props = {
  readonly error: ApolloError;
};

export default function ErrorMessage({ error }: Props) {
  return (
    <div className="mb-4 bg-red-600 text-red-200 px-4 py-3 text-sm rounded-lg">
      {error.graphQLErrors && error.graphQLErrors.length > 0 ? (
        <ul>
          {error.graphQLErrors.map((err, index) => (
            <li key={index}>{err.message}</li>
          ))}
        </ul>
      ) : (
        <p>{error.message}</p>
      )}
    </div>
  );
}
