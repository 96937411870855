import { gql, useLazyQuery } from '@apollo/client';

const QUERY = gql`
  query searchProducts($search: String!) {
    products(input: { query: $search, productCategoryIds: [] }) {
      id
      name
      sku
      packSize
    }
  }
`;

export type Variables = {
  readonly search: string;
};

export type Response = {
  readonly products: readonly {
    readonly id: string;
    readonly name: string;
    readonly sku: string;
    readonly packSize: number;
  }[];
};

export const useSearchProductsQuery = () =>
  useLazyQuery<Response, Variables>(QUERY);
