import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import { OrderStatusHandle } from '../../../../../globals';

const MUTATION = gql`
  mutation setCombinedOrderStatus($id: ID!, $status: OrderStatusHandle!) {
    setCombinedOrderStatus(id: $id, status: $status) {
      id
    }
  }
`;

export type Variables = {
  readonly id: string;
  readonly status: OrderStatusHandle;
};

export type Response = {
  readonly setCombinedOrderStatus: {
    readonly id: string;
  };
};

export const useSetCombinedOrderStatusMutation = (
  options: MutationHookOptions<Response, Variables>,
) => useMutation<Response, Variables>(MUTATION, options);
