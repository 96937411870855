import { useQuery } from '@apollo/client';
import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import Container from '../../components/content/Container';
import Table from '../../components/content/table/Table';
import TableBody from '../../components/content/table/TableBody';
import TableCell from '../../components/content/table/TableCell';
import TableHeader from '../../components/content/table/TableHeader';
import TableHeaderCell from '../../components/content/table/TableHeaderCell';
import TableRow from '../../components/content/table/TableRow';
import Button from '../../components/controls/Button';
import ToolbarPage from '../../components/layout/ToolbarPage';
import Heading from '../../components/layout/ToolbarPage/components/Heading';
import Toolbar from '../../components/layout/ToolbarPage/components/Toolbar';
import ToolbarItem from '../../components/layout/ToolbarPage/components/Toolbar/components/ToolbarItem';
import CreateUserModal from './components/CreateUserModal';
import EditUserModal from './components/EditUserModal';
import Reset2FAModal from './components/Reset2FAModal';
import ResetPasswordModal from './components/ResetPasswordModal';
import UserAccessPill from './components/UserAccessPill';
import {
  QUERY as GET_USERS_QUERY,
  Response as GetUsersResponse,
  Variables as GetUsersVariables,
} from './queries/getUsersQuery';

export function UsersView() {
  const [showCreateUserModal, setShowCreateUserModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState<
    GetUsersResponse['users'][number] | null
  >(null);
  const [selectedUserPassword, setSelectedUserPassword] = useState<
    GetUsersResponse['users'][number] | null
  >(null);
  const [selected2FAUser, setSelected2FAUser] = useState<
    GetUsersResponse['users'][number] | null
  >(null);

  const { data, refetch } = useQuery<GetUsersResponse, GetUsersVariables>(
    GET_USERS_QUERY,
  );

  if (!data) {
    return null;
  }

  return (
    <>
      <Helmet>
        <title>Users | Doug CRM</title>
      </Helmet>

      <ToolbarPage
        requiredCapabilities={['canViewUsers']}
        toolbar={
          <Toolbar>
            <ToolbarItem title text="Users" />
          </Toolbar>
        }
      >
        <Container>
          <Heading
            text="Users"
            actions={
              <>
                <Button
                  primary
                  text="Create"
                  onClick={() => setShowCreateUserModal(true)}
                />
              </>
            }
          />

          <Table>
            <TableHeader>
              <TableRow>
                <TableHeaderCell>Display Name</TableHeaderCell>
                <TableHeaderCell>Email</TableHeaderCell>
                <TableHeaderCell>Role</TableHeaderCell>
                <TableHeaderCell>Sources</TableHeaderCell>
                <TableHeaderCell>Lead Pool</TableHeaderCell>
                <TableHeaderCell rightAlign>Actions</TableHeaderCell>
              </TableRow>
            </TableHeader>
            <TableBody>
              {data.users.map((user) => (
                <TableRow
                  theme={user.active ? undefined : 'inactive'}
                  key={user.id}
                >
                  <TableCell>{user.fullName}</TableCell>
                  <TableCell>
                    <a className="underline" href={`mailto:${user.email}`}>
                      {user.email}
                    </a>
                  </TableCell>
                  <TableCell>
                    <UserAccessPill accessLevel={user.accessLevel} />
                  </TableCell>
                  <TableCell>{user.leadSources.length}</TableCell>
                  <TableCell>
                    {user.leadPool ? user.leadPool.name : 'Default'}
                  </TableCell>
                  <TableCell rightAlign>
                    <div className="space-x-1">
                      <Button
                        size="sm"
                        text="Edit"
                        onClick={() => setSelectedUser(user)}
                      />
                      <Button
                        size="sm"
                        text="Reset Password"
                        onClick={() => setSelectedUserPassword(user)}
                      />
                      <Button
                        size="sm"
                        text="Reset 2FA"
                        onClick={() => setSelected2FAUser(user)}
                      />
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Container>
      </ToolbarPage>

      {showCreateUserModal && (
        <CreateUserModal
          onClose={() => setShowCreateUserModal(false)}
          onCreateSuccess={() => refetch()}
        />
      )}

      {selectedUser && (
        <EditUserModal
          user={selectedUser}
          onClose={() => setSelectedUser(null)}
          onEditSuccess={() => refetch()}
        />
      )}

      {selectedUserPassword && (
        <ResetPasswordModal
          user={selectedUserPassword}
          onClose={() => setSelectedUserPassword(null)}
        />
      )}

      {selected2FAUser && (
        <Reset2FAModal
          user={selected2FAUser}
          onClose={() => setSelected2FAUser(null)}
        />
      )}
    </>
  );
}
