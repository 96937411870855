import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import React, { useEffect, useState } from 'react';
import Button from '../../../../../components/controls/Button';
import DropdownField from '../../../../../components/controls/DropdownField';
import { useGetLeadSources } from '../../../../../hooks/useGetLeadSources';
import { useUser } from '../../../../../hooks/useUser';
import { Lead } from '../../../queries/getLeadQuery';

const SET_SOURCE = gql`
  mutation ($id: Float!, $sourceId: Float!) {
    setLeadSource(id: $id, sourceId: $sourceId) {
      id
    }
  }
`;

type Props = {
  readonly lead: Lead;
  readonly onUpdate: () => void;
};

export default function LeadSourceInfo({ lead, onUpdate }: Props) {
  const user = useUser();
  const leadSources = useGetLeadSources();

  const [sourceId, setSourceId] = useState<string>();

  const [setLeadSource, { loading }] = useMutation(SET_SOURCE, {
    variables: {
      id: parseInt(lead.id.toString()),
      sourceId: sourceId ? parseInt(sourceId) : null,
    },
    onCompleted: () => onUpdate(),
    onError: (error) => console.error(error),
  });

  useEffect(() => {
    setSourceId(lead.source.id.toString());
  }, [lead]);

  return (
    <>
      <div>
        <DropdownField
          disabled={user?.accessLevel.handle !== 'admin'}
          value={sourceId}
          options={leadSources.map((source) => ({
            text: source.name,
            value: source.id.toString(),
          }))}
          label="Source"
          onChange={(value) => setSourceId(value)}
        />
      </div>
      {user?.accessLevel.handle === 'admin' && (
        <div className="mt-4">
          <Button
            loading={loading}
            disabled={sourceId === String(lead.source.id)}
            size="sm"
            text="Update Source"
            onClick={() => setLeadSource()}
          />
        </div>
      )}
    </>
  );
}
