import { useState } from 'react';
import Divider from '../../../../components/content/Divider';
import ExpandableSection from '../../../../components/content/ExpandableSection';
import Table from '../../../../components/content/table/Table';
import TableBody from '../../../../components/content/table/TableBody';
import TableCell from '../../../../components/content/table/TableCell';
import TableHeader from '../../../../components/content/table/TableHeader';
import TableHeaderCell from '../../../../components/content/table/TableHeaderCell';
import TableRow from '../../../../components/content/table/TableRow';
import Button from '../../../../components/controls/Button';
import filters, { DATETIME_FORMAT_MEDIUM } from '../../../../filters';
import { ServiceJob } from '../../../../globals';
import CreateServiceJobModal from './components/CreateServiceJobModal';
import ButtonLink from '../../../../components/controls/ButtonLink';
import { Helmet } from 'react-helmet-async';

type Props = {
  readonly leadId: string;
  readonly customerId?: number;
  readonly jobs: readonly ServiceJob[];
  readonly onCreate: () => void;
};

export function LeadServiceJobsView({
  leadId,
  customerId,
  jobs,
  onCreate,
}: Props) {
  const [showModal, setShowModal] = useState(false);

  if (!customerId) {
    return <p>Lead has no customer associated with it.</p>;
  }

  return (
    <>
      <Helmet>
        <title>Service Jobs | Lead {leadId} | Doug</title>
      </Helmet>

      <ExpandableSection title="Service Jobs">
        {jobs.length > 0 ? (
          <>
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHeaderCell>ID</TableHeaderCell>
                  <TableHeaderCell>Created</TableHeaderCell>
                  <TableHeaderCell>Title</TableHeaderCell>
                  <TableHeaderCell>Status</TableHeaderCell>
                  <TableHeaderCell />
                </TableRow>
              </TableHeader>
              <TableBody>
                {jobs.map((job) => (
                  <TableRow key={job.id}>
                    <TableCell>{job.id}</TableCell>
                    <TableCell>
                      {filters.formatDate(job.created, DATETIME_FORMAT_MEDIUM)}
                    </TableCell>
                    <TableCell>{job.title}</TableCell>
                    <TableCell>{job.status.name}</TableCell>
                    <TableCell rightAlign>
                      <ButtonLink text="View" to={`/service-jobs/${job.id}`} />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </>
        ) : (
          <p>No service jobs for this customer.</p>
        )}
      </ExpandableSection>

      <Divider />

      <div>
        <Button
          onClick={() => setShowModal(true)}
          text="New Service Job"
          primary
        />
      </div>

      {showModal && (
        <CreateServiceJobModal
          customerId={customerId}
          onClose={() => setShowModal(false)}
          onCreate={onCreate}
        />
      )}
    </>
  );
}
