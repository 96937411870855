type Props = {
  readonly value?: string;
  readonly onChange: (value?: string) => void;
  readonly options: readonly {
    readonly value: string;
    readonly text: string;
  }[];
  readonly placeholder?: string;
};

export default function Picker({
  value,
  onChange,
  options,
  placeholder,
}: Props) {
  return (
    <select
      value={value}
      onChange={(event) => onChange(event.target.value)}
      className="w-48 bg-gray-200 rounded-md focus:outline-none px-2 py-1 border-none"
    >
      <option value={undefined}>{placeholder ?? 'Pick...'}</option>
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.text}
        </option>
      ))}
    </select>
  );
}
