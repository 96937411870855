import { Helmet } from 'react-helmet-async';
import ExpandableSection from '../../../../components/content/ExpandableSection';
import Table from '../../../../components/content/table/Table';
import TableBody from '../../../../components/content/table/TableBody';
import TableCell from '../../../../components/content/table/TableCell';
import TableHeader from '../../../../components/content/table/TableHeader';
import TableHeaderCell from '../../../../components/content/table/TableHeaderCell';
import TableRow from '../../../../components/content/table/TableRow';
import filters, { DATETIME_FORMAT_MEDIUM } from '../../../../filters';
import ButtonLink from '../../../../components/controls/ButtonLink';

type Props = {
  readonly leadId: string;
  readonly related: readonly {
    readonly id: number;
    readonly updated: string;
    readonly status: {
      readonly id: number;
      readonly name: string;
    };
  }[];
};

export function LeadRelatedView({ leadId, related }: Props) {
  return (
    <>
      <Helmet>
        <title>Related | Lead {leadId} | Doug CRM</title>
      </Helmet>
      <ExpandableSection title="Related Leads">
        {related.length > 0 ? (
          <Table>
            <TableHeader>
              <TableRow>
                <TableHeaderCell>ID</TableHeaderCell>
                <TableHeaderCell>Status</TableHeaderCell>
                <TableHeaderCell>Last Updated</TableHeaderCell>
                <TableHeaderCell />
              </TableRow>
            </TableHeader>
            <TableBody>
              {related.map((lead) => (
                <TableRow key={lead.id}>
                  <TableCell>{lead.id}</TableCell>
                  <TableCell>{lead.status.name}</TableCell>
                  <TableCell>
                    {filters.formatDate(lead.updated, DATETIME_FORMAT_MEDIUM)}
                  </TableCell>
                  <TableCell rightAlign>
                    <ButtonLink text="View" to={`/leads/${lead.id}`} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <p>This customer has no other related leads.</p>
        )}
      </ExpandableSection>
    </>
  );
}
