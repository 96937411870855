import { NavLink } from 'react-router-dom';
import clsx from 'clsx';
import { useMemo } from 'react';
import { faCheck } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type Props = {
  readonly link: string;
  readonly text: string;
  readonly counter?: number;
  readonly status?: 'done';
};

export default function SubNavigationItem({
  link,
  text,
  counter,
  status,
}: Props) {
  const icon = useMemo(() => {
    if (status === 'done') return faCheck;

    return null;
  }, [status]);

  return (
    <span className="bg-gray-300 hover:bg-gray-400 inline-block rounded-t-md mr-1">
      <NavLink
        end
        className={({ isActive }) =>
          clsx('flex items-center px-4 py-2', {
            'bg-secondary text-white rounded-t-lg hover:bg-secondary': isActive,
          })
        }
        to={link}
      >
        <span className="font-medium">{text}</span>
        {counter ? (
          <span className="flex w-6 h-6 items-center justify-center bg-secondary-lightest text-white rounded-full text-xs ml-2 px-2">
            {counter > 100 ? '99+' : counter}
          </span>
        ) : (
          ''
        )}

        {icon && (
          <span className="flex w-6 h-6 items-center justify-center bg-secondary-lightest text-white rounded-full text-xs ml-2 px-2">
            <FontAwesomeIcon icon={icon} />
          </span>
        )}
      </NavLink>
    </span>
  );
}
