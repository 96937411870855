import ExpandableSection from '../../../../../components/content/ExpandableSection';
import Table from '../../../../../components/content/table/Table';
import TableBody from '../../../../../components/content/table/TableBody';
import TableCell from '../../../../../components/content/table/TableCell';
import TableHeader from '../../../../../components/content/table/TableHeader';
import TableHeaderCell from '../../../../../components/content/table/TableHeaderCell';
import TableRow from '../../../../../components/content/table/TableRow';
import ButtonLink from '../../../../../components/controls/ButtonLink';
import { Response } from '../queries/getCombinedOrderQuery';

type Props = {
  readonly order: Response['combinedOrder']['orders'][number];
};

export default function OrderDetails({ order }: Props) {
  return (
    <ExpandableSection
      title={`Order ${order.orderNumber}`}
      actions={
        <>
          <ButtonLink
            size="sm"
            text="View"
            to={`/inventory/orders/${order.id}`}
          />
        </>
      }
    >
      <section className="mb-5">Status - {order.status.name}</section>

      <Table>
        <TableHeader>
          <TableRow>
            <TableHeaderCell>Product</TableHeaderCell>
            <TableHeaderCell rightAlign>Qty</TableHeaderCell>
          </TableRow>
        </TableHeader>
        <TableBody>
          {order.lineItems.map((line) => (
            <TableRow key={line.id}>
              <TableCell>{line.product.name}</TableCell>
              <TableCell rightAlign>{line.quantity}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </ExpandableSection>
  );
}
