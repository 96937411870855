import NewNoteForm from './components/NewNoteForm';
import ExpandableSection from '../../../../components/content/ExpandableSection';
import Note from '../../../../components/leads/Notes/components/Note';
import Divider from '../../../../components/content/Divider';

type Props = {
  readonly serviceJobId: string;
  readonly notes: readonly {
    readonly id: string;
    readonly created: string;
    readonly agent: {
      readonly fullName: string;
    };
    readonly content: string;
  }[];
  readonly onUpdate: () => void;
};

export function ServiceJobNotes({ serviceJobId, notes, onUpdate }: Props) {
  return (
    <>
      <ExpandableSection title="Notes">
        <div>
          {notes.length > 0 ? (
            notes.map((note) => (
              <Note
                id={note.id}
                key={note.id}
                agentFullName={note.agent.fullName}
                created={note.created}
              >
                {note.content}
              </Note>
            ))
          ) : (
            <p>No notes.</p>
          )}
        </div>
      </ExpandableSection>
      <Divider />
      <NewNoteForm serviceJobId={serviceJobId} onCreate={() => onUpdate()} />
    </>
  );
}
