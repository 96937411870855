import { ReactNode } from 'react';

type Props = {
  readonly children: ReactNode;
};

export default function Box({ children }: Props) {
  return (
    <section className="bg-white shadow-md p-4 rounded-md">{children}</section>
  );
}
