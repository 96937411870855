import { Helmet } from 'react-helmet-async';
import Divider from '../../../../components/content/Divider';
import ExpandableSection from '../../../../components/content/ExpandableSection';
import CheckboxField from '../../../../components/controls/CheckboxField';
import CreateLeadDocumentForm from '../../../../components/leads/forms/CreateLeadDocumentForm';
import LeadDocumentCategory from '../LeadDocumentCategory';
import { useToggleAllDocumentsReceivedMutation } from './mutations/toggleAllDocumentsReceivedMutation';
import { Lead } from '../../queries/getLeadQuery';

type Props = {
  readonly lead: Lead;
  readonly onUpdate: () => void;
};

export function LeadDocumentsView({ lead, onUpdate }: Props) {
  const [toggleAllDocumentsReceived] = useToggleAllDocumentsReceivedMutation({
    variables: {
      leadId: lead.id.toString(),
    },
    onCompleted: () => onUpdate(),
  });

  return (
    <>
      <Helmet>
        <title>Documents | Lead {lead.id} | Doug CRM</title>
      </Helmet>
      <ExpandableSection title="Documents">
        <LeadDocumentCategory
          lead={lead}
          title="Front of the House"
          category="frontOfTheHouse"
          onUpdate={onUpdate}
        />
        <LeadDocumentCategory
          lead={lead}
          title="Suggested Battery Location"
          category="suggestedBatteryLocation"
          onUpdate={onUpdate}
        />
        <LeadDocumentCategory
          lead={lead}
          title="Existing Solar Inverter Data Label"
          category="existingSolarInverterDataLabel"
          onUpdate={onUpdate}
        />
        <LeadDocumentCategory
          lead={lead}
          title="Meter and Switch Board"
          category="meterAndSwitchBoard"
          onUpdate={onUpdate}
        />
        <LeadDocumentCategory
          lead={lead}
          title="Pole Greenbox Number"
          category="poleGreenboxNumber"
          onUpdate={onUpdate}
        />
        <LeadDocumentCategory
          lead={lead}
          title="Power Bill"
          category="powerBill"
          onUpdate={onUpdate}
        />

        <LeadDocumentCategory
          lead={lead}
          title="Misc"
          category={null}
          onUpdate={onUpdate}
        />
      </ExpandableSection>

      <Divider />

      <CheckboxField
        checked={lead.allDocumentsReceived}
        onChange={() => toggleAllDocumentsReceived()}
      >
        All Documents Received
      </CheckboxField>

      <Divider />

      <CreateLeadDocumentForm
        leadId={lead.id}
        onCreateAttachment={() => onUpdate()}
      />
    </>
  );
}
