import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import Divider from '../../../../components/content/Divider';
import ErrorMessage from '../../../../components/content/ErrorMessage';
import Button from '../../../../components/controls/Button';
import LeadFinanceDataSection from './components/LeadFinanceDataSection';
import { LeadFieldLock } from '../../../../globals';
import {
  LeadFinanceInput,
  leadFinanceInputDefinition,
} from './leadFinanceInput';
import { useUpdateLeadFinanceInfoMutation } from './mutations/updateLeadFinanceInfoMutation';
import { Lead } from '../../queries/getLeadQuery';

type Props = {
  readonly lead: Lead;
  readonly paymentsLock: LeadFieldLock | null;
  readonly commissionLock: LeadFieldLock | null;
  readonly financeCalculationsLock: LeadFieldLock | null;
  readonly onUpdate: () => void;
  readonly onToggleLock: () => void;
};

export function LeadFinanceView({
  lead,
  paymentsLock,
  commissionLock,
  financeCalculationsLock,
  onToggleLock,
  onUpdate,
}: Props) {
  const [financeInput, setFinanceInput] = useState<LeadFinanceInput>(
    leadFinanceInputDefinition.getDefaultState(),
  );

  useEffect(() => {
    setFinanceInput(leadFinanceInputDefinition.toPrimitive(lead));
  }, [lead]);

  const [updateLeadFinance, { loading, error }] =
    useUpdateLeadFinanceInfoMutation({
      onCompleted: () => onUpdate(),
      onError: (error) => console.error(error),
    });

  return (
    <>
      <Helmet>
        <title>Finance | Lead {lead.id} | Doug CRM</title>
      </Helmet>

      <LeadFinanceDataSection
        lead={lead}
        paymentsLock={paymentsLock}
        commissionLock={commissionLock}
        financeCalculationsLock={financeCalculationsLock}
        input={financeInput}
        onChange={(value) => setFinanceInput(value)}
        onToggleLock={() => onToggleLock()}
        onUpdate={onUpdate}
      />

      <Divider />

      {error && (
        <>
          <ErrorMessage error={error} />
          <Divider />
        </>
      )}

      <Button
        loading={loading}
        text="Save"
        onClick={() =>
          updateLeadFinance({
            variables: {
              id: parseInt(lead.id.toString()),
              model: leadFinanceInputDefinition.toActual(financeInput),
            },
          })
        }
      />
    </>
  );
}
