import { AnimatePresence } from 'framer-motion';
import { useState } from 'react';
import Table from '../../../../../../components/content/table/Table';
import TableBody from '../../../../../../components/content/table/TableBody';
import TableCell from '../../../../../../components/content/table/TableCell';
import TableHeader from '../../../../../../components/content/table/TableHeader';
import TableHeaderCell from '../../../../../../components/content/table/TableHeaderCell';
import TableRow from '../../../../../../components/content/table/TableRow';
import Button from '../../../../../../components/controls/Button';
import CreateSupplierLocationTray from '../../../CreateSupplierLocationTray';
import { Response } from '../../queries/getSupplierQuery';

type Props = {
  readonly supplierId: number;
  readonly locations: Response['supplier']['locations'];
  readonly onChange: () => Promise<any>;
};

export function SupplierLocationsTable({
  supplierId,
  locations,
  onChange,
}: Props) {
  const [showAddTray, setShowAddTray] = useState(false);

  return (
    <div>
      <Table className="mb-5">
        <TableHeader>
          <TableRow>
            <TableHeaderCell>Name</TableHeaderCell>
            <TableHeaderCell>Address</TableHeaderCell>
            <TableHeaderCell>Suburb</TableHeaderCell>
            <TableHeaderCell>State</TableHeaderCell>
            <TableHeaderCell>Postcode</TableHeaderCell>
          </TableRow>
        </TableHeader>
        <TableBody>
          {locations.length > 0 ? (
            locations.map((location) => (
              <TableRow key={location.id}>
                <TableCell>{location.name}</TableCell>
                <TableCell>
                  {location.address?.line1} {location.address?.line2}
                </TableCell>
                <TableCell>{location.address?.suburb ?? '-'}</TableCell>
                <TableCell>
                  {location.address?.state?.abbreviation ?? '-'}
                </TableCell>
                <TableCell>{location.address?.postcode ?? '-'}</TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colspan={5}>No locations.</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>

      <Button size="sm" text="Add" onClick={() => setShowAddTray(true)} />

      <AnimatePresence>
        {showAddTray && (
          <CreateSupplierLocationTray
            supplierId={supplierId}
            onClose={() => setShowAddTray(false)}
            onCreate={onChange}
          />
        )}
      </AnimatePresence>
    </div>
  );
}
