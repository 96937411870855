import Table from '../../../../../components/content/table/Table';
import TableBody from '../../../../../components/content/table/TableBody';
import TableCell from '../../../../../components/content/table/TableCell';
import TableHeader from '../../../../../components/content/table/TableHeader';
import TableHeaderCell from '../../../../../components/content/table/TableHeaderCell';
import TableRow from '../../../../../components/content/table/TableRow';
import TextLink from '../../../../../components/content/TextLink';
import { Response } from '../queries/useGetOverviewQuery';

type Props = {
  readonly itemsOnHand: Response['itemsOnHand'];
};

export default function ItemsOnHandTable({ itemsOnHand }: Props) {
  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHeaderCell>Product</TableHeaderCell>
          <TableHeaderCell>Installer</TableHeaderCell>
          <TableHeaderCell rightAlign>Qty</TableHeaderCell>
        </TableRow>
      </TableHeader>
      <TableBody>
        {itemsOnHand.map((item) => (
          <TableRow key={item.installer.id + item.product.id}>
            <TableCell>
              <TextLink to={`/inventory/products/${item.product.id}`}>
                {item.product.name}
              </TextLink>
            </TableCell>
            <TableCell>
              <TextLink to={`/inventory/installers/${item.installer.id}`}>
                {item.installer.name}
              </TextLink>
            </TableCell>
            <TableCell rightAlign>{item.quantity}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
