import { useMutation } from '@apollo/client';
import { faLock, faLockOpen } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import filters, { DATETIME_FORMAT_MEDIUM } from '../../../filters';
import { LeadFieldLock } from '../../../globals';
import { MUTATION, Response } from './mutations/toggleLeadFieldLock';
import clsx from 'clsx';
import { useUser } from '../../../hooks/useUser';

type Props = {
  readonly lock: LeadFieldLock | null;
  readonly leadId: string;
  readonly group: LeadFieldLock['group'];
  readonly onToggle: (locked: boolean) => void;
};

const theme = (canToggle: boolean, loading: boolean) =>
  clsx(
    'ml-4 bg-gray-200 rounded-full w-8 h-8 flex items-center justify-center',
    {
      'text-gray-300': loading,
      'hover:bg-primary hover:text-white': canToggle,
      'cursor-not-allowed': !canToggle,
    },
  );

export function Lock({ lock, leadId, group, onToggle }: Props) {
  const user = useUser();

  const [toggleLeadFieldLock, { loading }] = useMutation<Response>(MUTATION, {
    onCompleted: (data) => onToggle(data.toggleLeadFieldLock.locked),
  });

  const canToggle = user?.accessLevel.handle === 'admin';

  const handleToggle = () => {
    if (canToggle) {
      toggleLeadFieldLock({
        variables: {
          leadId: parseInt(leadId.toString()),
          group,
        },
      });
    }
  };

  const locked = !!lock && lock.locked;

  return (
    <div className="flex border-t border-gray-100 pt-4 items-center leading-tight justify-end text-right text-sm text-gray-500">
      {lock && locked && (
        <span>
          Locked by {lock && lock.user.fullName}
          <br />
          {lock &&
            filters.formatDate(lock.created.toString(), DATETIME_FORMAT_MEDIUM)}
        </span>
      )}
      <button
        className={theme(canToggle, loading)}
        disabled={!canToggle || loading}
        onClick={(event) => {
          event.preventDefault();
          handleToggle();
        }}
      >
        {locked ? (
          <FontAwesomeIcon icon={faLock} />
        ) : (
          <FontAwesomeIcon icon={faLockOpen} />
        )}
      </button>
    </div>
  );
}
