import Box from '../../../../../components/content/Box';
import MetaBlock from '../../../../../components/content/MetaBlock';
import filters, { DATETIME_FORMAT_MEDIUM } from '../../../../../filters';

type Props = {
  readonly created: string;
  readonly updated: string;
  readonly size: number;
};

export default function LeadPoolMetadata({ created, updated, size }: Props) {
  return (
    <Box>
      <MetaBlock label="Created">
        {filters.formatDate(created, DATETIME_FORMAT_MEDIUM)}
      </MetaBlock>
      <MetaBlock label="Updated">
        {filters.formatDate(updated, DATETIME_FORMAT_MEDIUM)}
      </MetaBlock>
      <MetaBlock label="Size">{size.toLocaleString()}</MetaBlock>
    </Box>
  );
}
