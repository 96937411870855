import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Box from '../../../../components/content/Box';
import Divider from '../../../../components/content/Divider';
import SplitLayout from '../../../../components/content/SplitLayout';
import Button from '../../../../components/controls/Button';
import DateField from '../../../../components/controls/DateField';
import MultiField from '../../../../components/controls/MultiField';
import TextField from '../../../../components/controls/TextField';
import LeadPoolMetadata from './components/LeadPoolMetadata';
import { useUpdateLeadPoolMutation } from './mutations/updateLeadPoolMutation';
import { useGetLeadPoolQuery } from './queries/getLeadPoolQuery';
import { useGetAgents } from '../../../../hooks/useGetAgents';
import { useGetLeadStatuses } from '../../../../hooks/useGetLeadStatuses';
import { useGetLeadSources } from '../../../../hooks/useGetLeadSources';
import { useGetLeadTerminationReasons } from '../../../../hooks/useGetLeadTerminationReasons';

type Params = {
  readonly id: string;
};

export function LeadPoolView() {
  const { id } = useParams<Params>();

  const leadStatuses = useGetLeadStatuses();
  const leadSources = useGetLeadSources();
  const leadTerminationReasons = useGetLeadTerminationReasons();

  if (!id) {
    // @todo: some kind of 404
    return null;
  }

  const agents = useGetAgents();

  const [name, setName] = useState('');
  const [fromCreatedDate, setFromCreatedDate] = useState<Date | null>(null);
  const [toCreatedDate, setToCreatedDate] = useState<Date | null>(null);
  const [minDials, setMinDials] = useState('');
  const [maxDials, setMaxDials] = useState('');
  const [statuses, setStatuses] = useState<readonly string[]>([]);
  const [leadSourceIds, setLeadSourceIds] = useState<readonly string[]>([]);
  const [agentIds, setAgentIds] = useState<readonly string[]>([]);
  const [allowedTerminationReasonIds, setAllowedTerminationReasonIds] =
    useState<readonly string[]>([]);

  const { data, loading, refetch } = useGetLeadPoolQuery({
    variables: { id: parseInt(id) },
  });

  const [updateLeadPool, { loading: updateLeadPoolLoading }] =
    useUpdateLeadPoolMutation({
      variables: {
        id: parseInt(id),
        input: {
          name,
          fromCreatedDate,
          toCreatedDate,
          minDials: minDials ? parseInt(minDials) : null,
          maxDials: maxDials ? parseInt(maxDials) : null,
          statuses,
          leadSourceIds,
          agentIds,
          allowedTerminationReasonIds,
        },
      },
      onCompleted: () => refetch(),
    });

  useEffect(() => {
    if (data) {
      setName(data.leadPool.name);
      setFromCreatedDate(
        data.leadPool.fromCreatedDate
          ? new Date(data.leadPool.fromCreatedDate)
          : null,
      );
      setToCreatedDate(
        data.leadPool.toCreatedDate
          ? new Date(data.leadPool.toCreatedDate)
          : null,
      );
      setMinDials(data.leadPool.minDials?.toString() ?? '');
      setMaxDials(data.leadPool.maxDials?.toString() ?? '');
      setLeadSourceIds(data.leadPool.leadSources.map((source) => source.id));
      setAgentIds(data.leadPool.agents.map((agent) => agent.id));
      setStatuses(data.leadPool.statuses.map((status) => status.handle));
      setAllowedTerminationReasonIds(
        data.leadPool.allowedTerminationReasons.map((reason) => reason.id),
      );
    }
  }, [data]);

  if (loading) {
    return (
      <Box>
        <p>Loading&hellip;</p>
      </Box>
    );
  }

  if (!data) return null;

  return (
    <SplitLayout
      sidebar={
        <div className="lg:w-96">
          <LeadPoolMetadata
            created={data.leadPool.created}
            updated={data.leadPool.updated}
            size={data.leadPool.size}
          />
        </div>
      }
    >
      <Box>
        <form
          onSubmit={(event) => {
            event.preventDefault();
            updateLeadPool();
          }}
          method="post"
        >
          <div className="grid grid-cols-2 gap-5">
            <div className="col-span-2">
              <TextField
                label="Name"
                value={name}
                onChange={setName}
                type="text"
                placeholder="Name"
              />
            </div>
            <div>
              <DateField
                label="From Created Date"
                value={fromCreatedDate}
                onChange={setFromCreatedDate}
              />
            </div>
            <div>
              <DateField
                label="To Created Date"
                value={toCreatedDate}
                onChange={setToCreatedDate}
              />
            </div>
            <div>
              <TextField
                label="Min Dials"
                value={minDials}
                onChange={setMinDials}
                placeholder="Min dial filter"
              />
            </div>
            <div>
              <TextField
                label="Max Dials"
                value={maxDials}
                onChange={setMaxDials}
                placeholder="Max dial filter"
              />
            </div>
            <div className="col-span-2 grid gap-5 grid-cols-4">
              <div>
                <MultiField
                  label="Statuses"
                  value={statuses}
                  onChange={setStatuses}
                  options={leadStatuses.map((status) => ({
                    text: status.name,
                    value: status.handle,
                  }))}
                />
              </div>
              <div>
                <MultiField
                  label="Lead Sources"
                  value={leadSourceIds}
                  onChange={setLeadSourceIds}
                  options={leadSources.map((source) => ({
                    text: source.name,
                    value: source.id,
                  }))}
                />
              </div>
              <div>
                <MultiField
                  label="Allowed Termination Reasons"
                  value={allowedTerminationReasonIds}
                  onChange={setAllowedTerminationReasonIds}
                  options={leadTerminationReasons.map((reason) => ({
                    text: reason.name,
                    value: reason.id,
                  }))}
                />
              </div>
              <div>
                <MultiField
                  label="Assigned Inactive Agents"
                  value={agentIds}
                  onChange={setAgentIds}
                  options={agents
                    .filter((agent) => !agent.active)
                    .map((agent) => ({
                      text: `${agent.fullName ?? agent.email} (${
                        agent.accessLevel.name
                      })`,
                      value: agent.id,
                    }))}
                />
              </div>
            </div>
          </div>

          <Divider />

          <Button
            loading={updateLeadPoolLoading}
            primary
            text="Update"
            type="submit"
          />
        </form>
      </Box>
    </SplitLayout>
  );
}
