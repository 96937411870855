import { LeadFieldLock } from '../../../../../../globals';
import { LeadFinanceInput } from '../../leadFinanceInput';
import LeadDocumentCategory from '../../../LeadDocumentCategory';
import Divider from '../../../../../../components/content/Divider';
import ExpandableSection from '../../../../../../components/content/ExpandableSection';
import CheckboxField from '../../../../../../components/controls/CheckboxField';
import DropdownField from '../../../../../../components/controls/DropdownField';
import Lock from '../../../../../../components/controls/Lock';
import NullableCheckboxField from '../../../../../../components/controls/NullableCheckboxField';
import TextField from '../../../../../../components/controls/TextField';
import CreateLeadDocumentForm from '../../../../../../components/leads/forms/CreateLeadDocumentForm';
import Notes from '../../../../../../components/leads/Notes';
import LeadFinanceChunkV2 from './components/LeadFinanceChunkV2';
import { useEffect } from 'react';
import { useGetCommissionTiers } from '../../../../../../hooks/useGetCommissionTiers';
import { Lead } from '../../../../queries/getLeadQuery';

type Props = {
  readonly lead: Lead;
  readonly paymentsLock: LeadFieldLock | null;
  readonly commissionLock: LeadFieldLock | null;
  readonly financeCalculationsLock: LeadFieldLock | null;
  readonly input: LeadFinanceInput;
  readonly onChange: (value: LeadFinanceInput) => void;
  readonly onUpdate: () => any;
  readonly onToggleLock: () => void;
};

export function LeadFinanceDataSection({
  lead,
  financeCalculationsLock,
  paymentsLock,
  commissionLock,
  input,
  onChange,
  onToggleLock,
  onUpdate,
}: Props) {
  const commissionTiers = useGetCommissionTiers();

  const paymentsLocked = !!(paymentsLock && paymentsLock.locked);
  const commissionLocked = !!(commissionLock && commissionLock.locked);

  useEffect(() => {
    const tier = commissionTiers.find(
      (tier) => tier.id.toString() === input.agentCommissionTierId,
    );

    if (tier && input.revenue && !commissionLocked) {
      onChange({
        ...input,
        agentCommissionPayable: (
          parseFloat(input.revenue) * tier.value
        ).toFixed(2),
      });
    }
  }, [input.agentCommissionTierId, input.revenue, commissionLocked]);

  return (
    <>
      <LeadFinanceChunkV2
        leadId={lead.id}
        input={input}
        onChange={onChange}
        lock={financeCalculationsLock}
        onToggleLock={onToggleLock}
      />

      <Divider />

      <ExpandableSection title="Payments">
        <div className="grid grid-cols-4 gap-4 mb-4">
          <div className="col-span-4 md:col-span-2">
            <TextField
              disabled={paymentsLocked}
              type="text"
              label="Invoice Number"
              placeholder="Invoice Number"
              value={input.invoiceNumber}
              onChange={(value) => onChange({ ...input, invoiceNumber: value })}
            />
          </div>
          <div className="col-span-4 md:col-span-2">
            <TextField
              dataType="dollars"
              disabled={paymentsLocked}
              type="text"
              label="Deposit Paid"
              placeholder="0.00"
              value={input.depositPaid}
              onChange={(value) => onChange({ ...input, depositPaid: value })}
            />
          </div>
          <div className="col-span-4 md:col-span-2">
            <CheckboxField
              disabled={paymentsLocked}
              checked={input.customerPaid}
              onChange={(value) => onChange({ ...input, customerPaid: value })}
            >
              Customer Paid
            </CheckboxField>
          </div>
          <div className="col-span-4 md:col-span-2">
            <NullableCheckboxField
              disabled={paymentsLocked}
              value={input.stcPaymentReceived}
              onChange={(value) =>
                onChange({ ...input, stcPaymentReceived: value })
              }
            >
              STC Payment Received
            </NullableCheckboxField>
          </div>
        </div>

        <Lock
          lock={paymentsLock}
          leadId={lead.id}
          onToggle={(locked) => onToggleLock()}
          group="PAYMENTS"
        />
      </ExpandableSection>

      <Divider />

      <ExpandableSection title="Commission">
        <div className="grid grid-cols-4 gap-4 mb-4">
          <div className="col-span-4 md:col-span-2">
            <DropdownField
              disabled={commissionLocked}
              value={input.agentCommissionTierId}
              options={commissionTiers.map((cmt) => ({
                text: (cmt.value * 100).toFixed(0) + '%',
                value: cmt.id.toString(),
              }))}
              label="Agent Commission Tier"
              placeholder="Agent Commission Tier"
              onChange={(value) =>
                onChange({ ...input, agentCommissionTierId: value })
              }
            />
          </div>
          <div className="col-span-4 md:col-span-2">
            <TextField
              disabled={commissionLocked}
              dataType="dollars"
              type="text"
              label="Agent Commission Payable"
              placeholder="0.00"
              value={input.agentCommissionPayable}
              onChange={(value) =>
                onChange({ ...input, agentCommissionPayable: value })
              }
            />
          </div>
          <div className="col-span-4 md:col-span-2">
            <CheckboxField
              disabled={commissionLocked}
              checked={input.signoff}
              onChange={(value) => onChange({ ...input, signoff: value })}
            >
              Sign Off
            </CheckboxField>
          </div>
        </div>

        <Lock
          lock={commissionLock}
          leadId={lead.id}
          onToggle={(locked) => onToggleLock()}
          group="COMMISSION"
        />
      </ExpandableSection>

      <Divider />

      <ExpandableSection title="Notes">
        <Notes leadId={lead.id} ctx="finance" />
      </ExpandableSection>

      <Divider />

      <ExpandableSection title="Documents">
        <LeadDocumentCategory
          lead={lead}
          category={null}
          ctx="finance"
          preventIgnore
        />

        <CreateLeadDocumentForm
          context="finance"
          leadId={lead.id}
          hideCategory
          onCreateAttachment={() => onUpdate()}
        />
      </ExpandableSection>
    </>
  );
}
