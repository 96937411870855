import { gql, QueryHookOptions, useQuery } from '@apollo/client';

export const QUERY = gql`
  query getServiceJobs($statuses: [String!]!, $offset: Int, $limit: Int) {
    serviceJobs(statuses: $statuses, offset: $offset, limit: $limit) {
      id
      created
      updated
      title
      priority
      customer {
        fullName
        email
      }
    }
    countServiceJobs(statuses: $statuses)
  }
`;

export type Variables = {
  readonly statuses: readonly ('open' | 'closed')[];
  readonly offset?: number;
  readonly limit?: number;
};

export type Response = {
  readonly serviceJobs: readonly {
    readonly id: string;
    readonly created: string;
    readonly updated: string;
    readonly title: string;
    readonly priority: number;
    readonly customer: {
      readonly fullName: string;
      readonly email: string;
    };
  }[];
  readonly countServiceJobs: number;
};

export const useGetServiceJobsQuery = (
  options?: QueryHookOptions<Response, Variables>,
) => useQuery<Response, Variables>(QUERY, options);
