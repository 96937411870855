import { faQuestionCircle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InputHTMLAttributes, ReactNode, useState } from 'react';
import Modal from '../overlay/Modal';
import clsx from 'clsx';

type Props = {
  readonly type?: InputHTMLAttributes<HTMLInputElement>['type'] | 'box';
  readonly placeholder?: string;
  readonly hint?: ReactNode;
  readonly value: string;
  readonly label?: string;
  readonly disabled?: boolean;
  readonly automatic?: boolean;
  readonly dataType?: 'percentage' | 'dollars';
  readonly rows?: number;
  readonly overlay?: ReactNode;
  readonly onChange?: (value: string) => void;
};

const theme = (
  disabled?: boolean,
  automatic?: boolean,
  hasDataType?: boolean,
) =>
  clsx(
    'block bg-white border border-gray-400 px-2 py-1 w-full focus:outline-none focus:border-primary shadow-sm',
    {
      'cursor-not-allowed bg-gray-100 text-gray-400': disabled,
      'cursor-not-allowed bg-gray-300 border-gray-400 text-black': automatic,
      'rounded-lg': !hasDataType,
      'rounded-r-lg': hasDataType,
    },
  );

const dataTypeTheme = (automatic?: boolean) =>
  clsx('w-8 flex items-center text-center border border-r-0 rounded-l-lg =', {
    'bg-gray-300 text-gray-500 border-gray-400': automatic,
    'border-gray-400 bg-gray-100 text-gray-500': !automatic,
  });

export default function TextField({
  type = 'text',
  placeholder,
  hint,
  value,
  label,
  disabled,
  rows,
  overlay,
  automatic,
  dataType,
  onChange,
}: Props) {
  const [showHint, setShowHint] = useState(false);

  return (
    <>
      <div>
        {label && (
          <label className="text-gray-600 text-sm mb-1 font-medium block">
            {label}{' '}
            {hint && (
              <button
                onClick={(event) => {
                  event.preventDefault();
                  setShowHint(true);
                }}
              >
                <FontAwesomeIcon icon={faQuestionCircle} />
              </button>
            )}
          </label>
        )}
        {type === 'box' ? (
          <textarea
            disabled={disabled}
            className={theme(disabled)}
            placeholder={placeholder}
            value={value}
            rows={rows}
            onChange={(event) => onChange && onChange(event.target.value)}
          />
        ) : (
          <div className="flex items-stretch">
            {dataType && (
              <div className={dataTypeTheme(automatic)}>
                <span className="w-full">
                  {dataType === 'dollars' && <>$</>}
                  {dataType === 'percentage' && <>%</>}
                </span>
              </div>
            )}
            <div className="relative w-full">
              <input
                disabled={disabled || automatic}
                className={theme(disabled, automatic, !!dataType)}
                type={type}
                placeholder={placeholder}
                value={value}
                onChange={(event) => onChange && onChange(event.target.value)}
              />
              {overlay && (
                <div className="inset-0 left-auto px-3 absolute pointer-events-none flex items-center justify-center">
                  {overlay}
                </div>
              )}
            </div>
          </div>
        )}
      </div>

      {showHint && (
        <Modal heading={label} onClose={() => setShowHint(false)}>
          {hint}
        </Modal>
      )}
    </>
  );
}
