import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ErrorMessage from '../../../../components/content/ErrorMessage';
import Button from '../../../../components/controls/Button';
import TextField from '../../../../components/controls/TextField';
import Modal from '../../../../components/overlay/Modal';
import { useToken } from '../../../../hooks/useToken';
import { useLoginMutation } from './mutations/loginMutation';

export function LoginForm() {
  const [_, setToken] = useToken();

  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [twoFactorCode, setTwoFactorCode] = useState('');
  const [twoFactorCodeData, setTwoFactorCodeData] = useState<string | null>(
    null,
  );

  const [login, loginVars] = useLoginMutation({
    onCompleted: (data) => {
      if (data.login.twoFactorQRData) {
        setTwoFactorCodeData(data.login.twoFactorQRData);
      }
    },
  });

  const [loginWithToken, loginWithTokenVars] = useLoginMutation({
    onCompleted: (data) => {
      if (data.login.token) {
        setToken(data.login.token);
        navigate('/');
      }
    },
  });

  const handleSubmit = () => {
    if (loginVars.data) {
      loginWithToken({
        variables: {
          email,
          password,
          twoFactorCode,
        },
      });
    } else {
      // Login first.
      login({
        variables: {
          email,
          password,
        },
      });
    }
  };

  const loading = loginVars.loading || loginWithTokenVars.loading;

  return (
    <>
      <div className="container max-w-sm mx-auto self-center">
        <form
          className="p-6 rounded-lg bg-white shadow-md"
          onSubmit={(event) => {
            event.preventDefault();
            handleSubmit();
          }}
        >
          <div className="mb-4">
            <img
              className="w-32"
              src="https://spaces.teho.com.au/logo.png"
              alt="Doug"
            />
          </div>
          <div className="mb-4">
            <div className="mb-2">
              <TextField
                type="email"
                placeholder="Email Address"
                value={email}
                onChange={(value) => setEmail(value)}
              />
            </div>
            <div>
              <TextField
                type="password"
                placeholder="Password"
                value={password}
                onChange={(value) => setPassword(value)}
              />
            </div>
            {loginVars.data && (
              <div className="mt-2">
                <TextField
                  type="text"
                  placeholder="Two Factor Code"
                  value={twoFactorCode}
                  onChange={(value) => setTwoFactorCode(value)}
                />
              </div>
            )}
          </div>

          {loginVars.error && <ErrorMessage error={loginVars.error} />}

          {loginWithTokenVars.error && (
            <ErrorMessage error={loginWithTokenVars.error} />
          )}

          <Button primary loading={loading} text="Login" />
          <Link
            className="inline-block ml-2 text-sm hover:underline text-gray-400"
            to="/login/reset"
          >
            Forgot password?
          </Link>
        </form>
      </div>

      {twoFactorCodeData && (
        <Modal>
          <h2 className="mb-4 font-bold">2FA Setup Required</h2>

          <p className="mb-4 text-sm text-gray-600">
            Two-factor authentication is required on this account to continue.
          </p>
          <p className="mb-4 text-sm text-gray-600">
            Download and install{' '}
            <a
              className="text-blue-500 hover:underline"
              href="https://support.google.com/accounts/answer/1066447?co=GENIE.Platform%3DAndroid&hl=en"
              target="_blank"
            >
              Google Authenticator
            </a>{' '}
            on your mobile device and scan the below QR code:
          </p>

          <figure className="bg-gray-100 p-4 rounded-lg mb-4">
            <img
              className="block mx-auto rounded-lg shadow-lg"
              src={twoFactorCodeData}
            />
          </figure>

          <p className="mb-4 text-sm text-gray-600">
            Your 2FA code will be required for all future login attempts.
          </p>

          <Button
            primary
            onClick={() => setTwoFactorCodeData(null)}
            text="I'm Done"
          />
        </Modal>
      )}
    </>
  );
}
