import { gql, MutationHookOptions, useMutation } from '@apollo/client';

const MUTATION = gql`
  mutation ($value: Float!) {
    createCommissionTier(value: $value) {
      id
    }
  }
`;

export type Variables = {
  readonly value: number;
};

export type Response = {
  readonly createCommissionTier: {
    readonly id: number;
  };
};

export const useCreateCommissionTierMutation = (
  options: MutationHookOptions<Response, Variables>,
) => useMutation<Response, Variables>(MUTATION, options);
