import { gql, useMutation } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import Button from '../../../../../components/controls/Button';
import DropdownField from '../../../../../components/controls/DropdownField';
import { useGetLeadStatuses } from '../../../../../hooks/useGetLeadStatuses';
import { Lead } from '../../../queries/getLeadQuery';

const UPDATE_STATUS = gql`
  mutation ($id: Float!, $status: String!) {
    updateLeadStatus(id: $id, status: $status) {
      id
    }
  }
`;

type Props = {
  readonly lead: Lead;
  readonly onUpdateStatus: () => void;
};

export default function LeadStatusSwitcher({ lead, onUpdateStatus }: Props) {
  const leadStatuses = useGetLeadStatuses();

  const [status, setStatus] = useState<string>();

  const [updateLeadStatus, { loading }] = useMutation(UPDATE_STATUS, {
    onCompleted: () => onUpdateStatus(),
    onError: (error) => console.error(error),
  });

  useEffect(() => {
    setStatus(lead.status.handle);
  }, [lead]);

  return (
    <div>
      <div className="mb-4">
        <DropdownField
          label="Status"
          value={status}
          onChange={(value) => setStatus(value)}
          options={leadStatuses.map((status) => ({
            value: status.handle,
            text: status.name,
          }))}
        />
      </div>
      <Button
        size="sm"
        primary
        loading={loading}
        disabled={status === lead.status.handle}
        text="Update"
        onClick={() => {
          updateLeadStatus({
            variables: { id: parseInt(lead.id.toString()), status },
          });
        }}
      />
    </div>
  );
}
