import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import Divider from '../../../../components/content/Divider';
import ErrorMessage from '../../../../components/content/ErrorMessage';
import Button from '../../../../components/controls/Button';
import LeadAddressDataSection from './components/LeadAddressDataSection';
import LeadCustomerDataSection from './components/LeadCustomerDataSection';
import { AddressInput, CustomerInput } from '../../../../globals';
import { useUpdateLeadSalesInfoMutation } from './mutations/updateLeadSalesMutation';
import { LeadSalesInput } from './types';
import { Lead } from '../../queries/getLeadQuery';

type Props = {
  readonly lead: Lead;
  readonly onUpdate: () => void;
};

export function LeadSalesView({ lead, onUpdate }: Props) {
  const [customer, setCustomer] = useState<CustomerInput>({
    firstName: '',
    lastName: '',
    middleName: '',
    mobilePhone: '',
    homePhone: '',
    workPhone: '',
    email: '',
  });

  const [address, setAddress] = useState<AddressInput>({
    line1: '',
    line2: '',
    suburb: '',
    stateId: undefined,
    postcode: '',
    connectionTypeId: undefined,
    roofTypeId: undefined,
    existingSolar: false,
    storeys: undefined,
  });

  const [salesInput, setSalesInput] = useState<LeadSalesInput>({
    financeId: undefined,
    energyBill: '',
    systemSize: '',
    batterySize: '',
    quoted: false,
  });

  useEffect(() => {
    if (lead.customer) {
      setCustomer({
        ...customer,
        firstName: lead.customer.firstName ? lead.customer.firstName : '',
        lastName: lead.customer.lastName ? lead.customer.lastName : '',
        middleName: lead.customer.middleName ? lead.customer.middleName : '',
        mobilePhone: lead.customer.mobilePhone ? lead.customer.mobilePhone : '',
        workPhone: lead.customer.workPhone ? lead.customer.workPhone : '',
        homePhone: lead.customer.homePhone ? lead.customer.homePhone : '',
        email: lead.customer.email,
      });
    }

    if (lead.address) {
      setAddress({
        ...address,
        line1: lead.address.line1 ? lead.address.line1 : '',
        line2: lead.address.line2 ? lead.address.line2 : '',
        suburb: lead.address.suburb ? lead.address.suburb : '',
        stateId: lead.address.state ? lead.address.state.id.toString() : '',
        postcode: lead.address.postcode ? lead.address.postcode : '',
        connectionTypeId: lead.address.connectionType
          ? lead.address.connectionType.id.toString()
          : '',
        roofTypeId: lead.address.roofType
          ? lead.address.roofType.id.toString()
          : '',
        existingSolar: lead.address.existingSolar
          ? lead.address.existingSolar
          : false,
        storeys: lead.address.storeys ? lead.address.storeys.toString() : '',
      });
    }

    setSalesInput({
      financeId: lead.finance ? lead.finance.id.toString() : undefined,
      energyBill: lead.energyBill ? lead.energyBill.toFixed(2) : '',
      systemSize: lead.systemSize ? lead.systemSize.toFixed(2) : '',
      batterySize: lead.batterySize ? lead.batterySize.toFixed(2) : '',
      quoted: lead.quoted,
    });
  }, [lead]);

  const [updateLeadSales, { loading, error }] = useUpdateLeadSalesInfoMutation({
    variables: {
      id: parseInt(lead.id.toString()),
      model: {
        customer: {
          firstName: customer.firstName,
          lastName: customer.lastName,
          middleName: customer.middleName,
          mobilePhone: customer.mobilePhone,
          homePhone: customer.homePhone,
          workPhone: customer.workPhone,
          email: customer.email,
        },
        address: {
          line1: address.line1 ? address.line1 : '',
          line2: address.line2 ? address.line2 : '',
          suburb: address.suburb ? address.suburb : '',
          stateId: address.stateId ? parseInt(address.stateId) : undefined,
          postcode: address.postcode ? address.postcode : '',
        },
        financeId: salesInput.financeId
          ? parseInt(salesInput.financeId)
          : undefined,
        quoted: salesInput.quoted,
      },
    },
    onCompleted: () => onUpdate(),
    onError: (error) => console.error(error),
  });

  return (
    <>
      <Helmet>
        <title>Lead {lead.id} | Doug CRM</title>
      </Helmet>

      <LeadCustomerDataSection
        customer={customer}
        onChange={(value) => setCustomer(value)}
      />
      <Divider />
      <LeadAddressDataSection
        address={address}
        onChange={(value) => setAddress(value)}
      />

      <Divider />

      {error && (
        <>
          <ErrorMessage error={error} />
          <Divider />
        </>
      )}

      <Button loading={loading} text="Save" onClick={() => updateLeadSales()} />
    </>
  );
}
