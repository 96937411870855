import { gql, QueryHookOptions, useQuery } from '@apollo/client';

const QUERY = gql`
  query getEnergyRetailers {
    energyRetailers {
      id
      name
      archived
    }
  }
`;

export type Response = {
  readonly energyRetailers: readonly {
    readonly id: string;
    readonly name: string;
    readonly archived: boolean;
  }[];
};

export type EnergyRetailer = Response['energyRetailers'][number];

export const useGetEnergyRetailers = (options?: QueryHookOptions<Response>) => {
  const { data } = useQuery<Response>(QUERY, {
    fetchPolicy: 'cache-first',
    ...options,
  });

  return data?.energyRetailers ?? [];
};
