import { useState } from 'react';
import Table from '../../../components/content/table/Table';
import TableBody from '../../../components/content/table/TableBody';
import TableCell from '../../../components/content/table/TableCell';
import TableFooter from '../../../components/content/table/TableFooter';
import TableHeader from '../../../components/content/table/TableHeader';
import TableHeaderCell from '../../../components/content/table/TableHeaderCell';
import TableRow from '../../../components/content/table/TableRow';
import Button from '../../../components/controls/Button';
import TextField from '../../../components/controls/TextField';

export type BasicObjectTableItem = {
  readonly text: string;
  readonly id: number;
  readonly archived: boolean;
};

type Props = {
  readonly items: readonly BasicObjectTableItem[];
  readonly onAddItem: (text: string) => void;
  readonly onToggleArchived: (id: number, archived: boolean) => void;
};

export default function BasicObjectTable({
  items,
  onAddItem,
  onToggleArchived,
}: Props) {
  const [text, setText] = useState('');

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHeaderCell>ID</TableHeaderCell>
          <TableHeaderCell>Value</TableHeaderCell>
          <TableHeaderCell rightAlign>Archived</TableHeaderCell>
        </TableRow>
      </TableHeader>
      <TableBody>
        {items.map((item) => (
          <TableRow key={item.id}>
            <TableCell compress>
              <code>{item.id}</code>
            </TableCell>
            <TableCell>{item.text}</TableCell>
            <TableCell rightAlign>
              <Button
                size="sm"
                onClick={() => onToggleArchived(item.id, !item.archived)}
                text={item.archived ? 'Unarchive' : 'Archive'}
              />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
      <TableFooter>
        <TableRow>
          <TableCell />
          <TableCell>
            <TextField
              type="text"
              placeholder="New row"
              value={text}
              onChange={(value) => setText(value)}
            />
          </TableCell>
          <TableCell rightAlign>
            <Button
              disabled={!text}
              size="sm"
              text="Add"
              onClick={() => onAddItem(text)}
            />
          </TableCell>
        </TableRow>
      </TableFooter>
    </Table>
  );
}
