import React from 'react';
import filters from '../../../filters';
import { Response } from '../queries/getDiaryQuery';

type Props = {
  readonly action: Response['diary'][number];
  readonly onView: () => void;
};

export default function DiaryActionBlock({ action, onView }: Props) {
  return (
    <div
      className="bg-gray-100 rounded-lg px-2 py-2 mb-1 cursor-pointer hover:bg-primary hover:text-white"
      onClick={() => onView()}
    >
      <p className="text-sm">
        {action.type.name}
        <span className="float-right">
          {filters.formatDate(action.due, 'h:mm aaa')}
        </span>
      </p>
      {action.lead.customer && (
        <p className="text-sm">
          <strong>
            {action.lead.customer.firstName} {action.lead.customer.lastName}
          </strong>
        </p>
      )}
    </div>
  );
}
