import { useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import LeadRequestForm from './components/LeadRequestForm';
import LeadTentativeClaim from './components/LeadTentativeClaim';
import { useClaimMutation } from './mutations/claimMutation';
import { useMakeTentativeClaimMutation } from './mutations/makeTentativeClaimMutation';
import { useRecordClaimFailureMutation } from './mutations/recordClaimFailureMutation';
import { useGetTentativeClaimsQuery } from './queries/getTentativeClaimsQuery';

export function LeadPresenterView() {
  const navigate = useNavigate();

  const { data, refetch } = useGetTentativeClaimsQuery();

  const [makeTentativeClaim, { error }] = useMakeTentativeClaimMutation({
    onCompleted: () => refetch(),
    onError: (error) => console.error(error),
  });

  const [recordClaimFailure] = useRecordClaimFailureMutation({
    onCompleted: () => refetch(),
    onError: (error) => console.error(error),
  });

  const [claim] = useClaimMutation({
    onCompleted: (data) => navigate(`/leads/${data.claim.id}`),
    onError: (error) => console.error(error),
  });

  const tentativeClaims = useMemo(() => data?.tentativeClaims ?? [], [data]);

  return (
    <>
      <Helmet>
        <title>Lead Presenter | Doug CRM</title>
      </Helmet>
      <section className="w-full h-screen flex">
        {tentativeClaims.length > 0 ? (
          <section className="self-center mx-auto">
            {tentativeClaims.slice(0, 1).map((tentativeClaim) => (
              <LeadTentativeClaim
                key={tentativeClaim.id}
                tentativeClaim={tentativeClaim}
                onClaim={() =>
                  claim({
                    variables: {
                      tentativeClaimId: parseInt(tentativeClaim.id.toString()),
                    },
                  })
                }
                onRelease={() =>
                  recordClaimFailure({
                    variables: {
                      tentativeClaimId: parseInt(tentativeClaim.id.toString()),
                    },
                  })
                }
              />
            ))}
          </section>
        ) : (
          <LeadRequestForm
            error={error}
            onRequestLead={() => makeTentativeClaim()}
          />
        )}
      </section>
    </>
  );
}
