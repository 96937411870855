import { gql, MutationHookOptions, useMutation } from '@apollo/client';

const MUTATION = gql`
  mutation markQuoted($id: Float!) {
    markQuoted(id: $id) {
      id
    }
  }
`;

export type Variables = {
  readonly id: number;
};

export type Response = {
  readonly markQuoted: {
    readonly id: number;
  };
};

export const useMarkQuotedMutation = (
  options: MutationHookOptions<Response, Variables>,
) =>
  useMutation<Response, Variables>(MUTATION, {
    onError: (error) => console.error(error),
    ...options,
  });
