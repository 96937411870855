import Status from '../../../components/misc/Status';
import Box from '../../../components/content/Box';
import Table from '../../../components/content/table/Table';
import TableBody from '../../../components/content/table/TableBody';
import TableCell from '../../../components/content/table/TableCell';
import TableHeader from '../../../components/content/table/TableHeader';
import TableHeaderCell from '../../../components/content/table/TableHeaderCell';
import TableRow from '../../../components/content/table/TableRow';
import filters, { DATETIME_FORMAT_DATE } from '../../../filters';
import { isNullOrTrue } from '../support';
import ButtonLink from '../../../components/controls/ButtonLink';
import { Lead } from '../../LeadView/queries/getLeadQuery';

type Props = {
  readonly leads: readonly Lead[];
};

export default function DealsTableWithSaleStatus({ leads }: Props) {
  if (leads.length === 0) {
    return <Box>No leads.</Box>;
  }

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHeaderCell>ID</TableHeaderCell>
          <TableHeaderCell>Status</TableHeaderCell>
          <TableHeaderCell>Deal Date</TableHeaderCell>
          <TableHeaderCell>Customer</TableHeaderCell>
          <TableHeaderCell>Postcode</TableHeaderCell>
          <TableHeaderCell>Expected Install Date</TableHeaderCell>
          <TableHeaderCell>All Documents</TableHeaderCell>
          <TableHeaderCell>PTC Submitted</TableHeaderCell>
          <TableHeaderCell>PTC Approved</TableHeaderCell>
          <TableHeaderCell />
        </TableRow>
      </TableHeader>
      <TableBody>
        {leads.map((lead) => (
          <TableRow key={lead.id}>
            <TableCell>
              <strong>{lead.id}</strong>
            </TableCell>
            <TableCell>{lead.status.name}</TableCell>
            <TableCell>
              {lead.dealDate
                ? filters.formatDate(lead.dealDate, DATETIME_FORMAT_DATE)
                : '-'}
            </TableCell>
            <TableCell>
              {lead.customer && (
                <>
                  {lead.customer.firstName} {lead.customer.lastName}
                </>
              )}
            </TableCell>
            <TableCell>
              {lead.address && <>{lead.address.postcode}</>}
            </TableCell>
            <TableCell>
              {lead.expectedInstallDate
                ? filters.formatDate(
                    lead.expectedInstallDate,
                    DATETIME_FORMAT_DATE,
                  )
                : '-'}
            </TableCell>
            <TableCell compress>
              <Status
                value={isNullOrTrue(lead.allDocumentsReceived) ? 'good' : 'bad'}
              />
            </TableCell>
            <TableCell compress>
              <Status
                value={
                  isNullOrTrue(lead.checklist.ptcSubmitted) ? 'good' : 'bad'
                }
              />
            </TableCell>
            <TableCell compress>
              <Status
                value={
                  isNullOrTrue(lead.checklist.ptcApproved) ? 'good' : 'bad'
                }
              />
            </TableCell>

            <TableCell rightAlign>
              <ButtonLink text="View" to={`/leads/${lead.id}`} />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
