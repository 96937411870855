import Table from '../../../components/content/table/Table';
import TableBody from '../../../components/content/table/TableBody';
import TableCell from '../../../components/content/table/TableCell';
import TableHeader from '../../../components/content/table/TableHeader';
import TableHeaderCell from '../../../components/content/table/TableHeaderCell';
import TableRow from '../../../components/content/table/TableRow';
import filters, {
  DATETIME_FORMAT_DATE,
  DATETIME_FORMAT_MEDIUM,
} from '../../../filters';
import ButtonLink from '../../../components/controls/ButtonLink';
import { Lead } from '../../LeadView/queries/getLeadQuery';

type Props = {
  readonly leads: readonly Lead[];
};

export default function DealsTable({ leads }: Props) {
  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHeaderCell>ID</TableHeaderCell>
          <TableHeaderCell>Status</TableHeaderCell>
          <TableHeaderCell>Updated</TableHeaderCell>
          <TableHeaderCell>Customer</TableHeaderCell>
          <TableHeaderCell>Mobile</TableHeaderCell>
          <TableHeaderCell>Postcode</TableHeaderCell>
          <TableHeaderCell>Expected Install Date</TableHeaderCell>
          <TableHeaderCell>Install Date</TableHeaderCell>
          <TableHeaderCell>Agent</TableHeaderCell>
          <TableHeaderCell />
        </TableRow>
      </TableHeader>
      <TableBody>
        {leads.map((lead) => (
          <TableRow key={lead.id}>
            <TableCell>
              <strong>{lead.id}</strong>
            </TableCell>
            <TableCell>{lead.status.name}</TableCell>
            <TableCell>
              {filters.formatDate(lead.updated, DATETIME_FORMAT_MEDIUM)}
            </TableCell>
            <TableCell>
              {lead.customer && (
                <>
                  {lead.customer.firstName} {lead.customer.lastName}
                </>
              )}
            </TableCell>
            <TableCell>
              {lead.customer && <>{lead.customer.mobilePhone}</>}
            </TableCell>
            <TableCell>
              {lead.address && <>{lead.address.postcode}</>}
            </TableCell>
            <TableCell>
              {lead.expectedInstallDate
                ? filters.formatDate(
                    lead.expectedInstallDate,
                    DATETIME_FORMAT_DATE,
                  )
                : '-'}
            </TableCell>
            <TableCell>
              {lead.installDate
                ? filters.formatDate(lead.installDate, DATETIME_FORMAT_DATE)
                : '-'}
            </TableCell>
            <TableCell>
              {lead.agent ? <>{lead.agent.fullName}</> : '-'}
            </TableCell>
            <TableCell rightAlign>
              <ButtonLink text="View" to={`/leads/${lead.id}`} />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
