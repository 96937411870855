import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt } from '@fortawesome/pro-light-svg-icons';
import { useUser } from '../../hooks/useUser';
import { useLogout } from '../../hooks/useLogout';

export default function NavigationUserInfo() {
  const logout = useLogout();
  const user = useUser();

  if (!user) {
    return null;
  }

  return (
    <div>
      <div className="hidden lg:block">
        <p>{user.fullName}</p>
        <p className="text-ellipsis overflow-hidden">
          <small>{user.email}</small>
        </p>
      </div>

      <div>
        <button
          className="text-center lg:text-left lg:text-xs"
          onClick={() => logout()}
        >
          <FontAwesomeIcon icon={faSignOutAlt} />{' '}
          <span className="hidden lg:inline-block">Logout</span>
        </button>
      </div>
    </div>
  );
}
