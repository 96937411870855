import { gql } from '@apollo/client';

export const MUTATION = gql`
  mutation($id: Int!) {
    toggleInstallerArchived(id: $id) {
      archived
    }
  }
`;

export type Response = {
  readonly toggleInstallerArchived: {
    readonly archived: boolean;
  };
};
