import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import { ReactNode } from 'react';
import OverlayPortal from '../OverlayPortal';

type Props = {
  readonly heading: string;
  readonly children: ReactNode;
  readonly actions?: ReactNode;
  readonly size?: 'sm' | 'md' | 'lg';
  readonly onClose?: () => void;
};

export function Tray({
  heading,
  children,
  actions,
  size = 'sm',
  onClose,
}: Props) {
  return (
    <OverlayPortal>
      <motion.div
        className="fixed inset-0 bg-black bg-opacity-60 backdrop-blur-sm"
        exit={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        initial={{ opacity: 0 }}
        transition={{ duration: 0.15, ease: 'easeInOut' }}
        onMouseUp={(event) => {
          if (event.target === event.currentTarget && onClose) {
            onClose();
          }
        }}
      >
        <motion.aside
          className={clsx(
            'relative flex h-screen overflow-hidden ml-auto flex-col w-full bg-white shadow-2xl',
            {
              'max-w-md': size === 'sm',
              'max-w-lg': size === 'md',
              'max-w-3xl': size === 'lg',
            },
          )}
          initial={{ left: '25px' }}
          animate={{ left: 0 }}
          exit={{ left: '25px' }}
          transition={{ duration: 0.15, ease: 'easeInOut' }}
        >
          <div className="flex items-center flex-shrink-0 p-5 bg-gray-100">
            <h3 className="font-bold flex-grow">{heading}</h3>
            {onClose && (
              <button
                className="text-gray-600 hover:text-black"
                onClick={(event) => {
                  event.preventDefault();
                  onClose();
                }}
              >
                <FontAwesomeIcon icon={faTimes} />
              </button>
            )}
          </div>
          <section className="flex-grow overflow-y-auto">{children}</section>
          {actions && (
            <div className="border-t border-gray-200 flex-shrink-0 flex items-center p-5 space-x-2">
              {actions}
            </div>
          )}
        </motion.aside>
      </motion.div>
    </OverlayPortal>
  );
}
