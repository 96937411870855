import React, { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import AuthApplyResetForm from './AuthApplyResetForm';
import AuthRequestResetForm from './AuthRequestResetForm';

const AuthResetForm: FC = () => {
  const location = useLocation();

  const [token, setToken] = useState<string | null>(null);

  useEffect(() => {
    const params: URLSearchParams = new URLSearchParams(location.search);

    setToken(params.get('token'));
  }, [location]);

  return (
    <div className="container max-w-sm mx-auto self-center">
      <div className="p-6 rounded-lg bg-white shadow-md">
        {token ? (
          <AuthApplyResetForm token={token} />
        ) : (
          <AuthRequestResetForm />
        )}
      </div>
    </div>
  );
};

export default AuthResetForm;
