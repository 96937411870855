import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import { LeadFieldLock } from '../../../globals';

const QUERY = gql`
  fragment LeadFieldLockFields on LeadFieldLock {
    created
    locked
    user {
      fullName
    }
  }
  query ($id: ID!) {
    lead(id: $id) {
      paymentsLock: fieldLock(group: "PAYMENTS") {
        ...LeadFieldLockFields
      }
      commissionLock: fieldLock(group: "COMMISSION") {
        ...LeadFieldLockFields
      }
      financeCalculationsLock: fieldLock(group: "FINANCE_CALCULATIONS") {
        ...LeadFieldLockFields
      }
    }
  }
`;

export type Variables = {
  readonly id: number;
};

export type Response = {
  readonly lead: {
    readonly paymentsLock: LeadFieldLock | null;
    readonly commissionLock: LeadFieldLock | null;
    readonly financeCalculationsLock: LeadFieldLock | null;
  };
};

export const useGetLeadLocksQuery = (
  options: QueryHookOptions<Response, Variables>,
) => useQuery<Response, Variables>(QUERY, options);
