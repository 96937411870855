import React, { ReactNode } from 'react';
import filters, { DATETIME_FORMAT_MEDIUM } from '../../../filters';

type Props = {
  readonly children?: ReactNode;
  readonly date?: string;
  readonly user?: {
    readonly fullName: string;
  };
};

export default function ContentStreamItem({ date, user, children }: Props) {
  return (
    <article>
      <div className="px-4 py-2 bg-gray-50 text-sm text-gray-400 border-b border-gray-100">
        <div className="grid grid-cols-5">
          <div className="col-span-2">
            {date && <>{filters.formatDate(date, DATETIME_FORMAT_MEDIUM)}</>}
          </div>
          <div className="text-right col-span-2">{user?.fullName}</div>
          <div></div>
        </div>
      </div>
      <div className="p-4">{children}</div>
    </article>
  );
}
