import { ReactNode } from 'react';

type Props = {
  readonly sidebar: ReactNode;
  readonly children: ReactNode;
};

export function SidebarLayout({ sidebar, children }: Props) {
  return (
    <section className="flex space-x-5">
      <aside className="w-72 flex-shrink-0">{sidebar}</aside>
      <div className="flex-grow">{children}</div>
    </section>
  );
}
