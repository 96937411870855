import Button from '../../../../../../components/controls/Button';
import Modal from '../../../../../../components/overlay/Modal';
import { useDeleteLeadPoolMutation } from './mutations/deleteLeadPoolMutation';

type Props = {
  readonly poolId: number;
  readonly onClose: () => void;
  readonly onDelete: () => void;
};

export function DeletePoolModal({ poolId, onClose, onDelete }: Props) {
  const [deleteLeadPool, { loading }] = useDeleteLeadPoolMutation({
    variables: {
      id: poolId,
    },
    onCompleted: () => onDelete(),
  });

  return (
    <Modal heading="Delete Lead Pool" onClose={onClose}>
      <div className="prose max-w-none mb-5">
        <p>
          Delete this lead pool? All assigned agents will be reverted to the
          default pool.
        </p>
      </div>

      <Button
        text="Delete"
        primary
        loading={loading}
        onClick={() => deleteLeadPool()}
      />
    </Modal>
  );
}
