import Box from '../../../../components/content/Box';
import Container from '../../../../components/content/Container';
import ExpandableSection from '../../../../components/content/ExpandableSection';
import Heading from '../../../../components/layout/ToolbarPage/components/Heading';
import { useCapabilities } from '../../../../hooks/useCapabilities';
import ItemsOnHandTable from './components/ItemsOnHandTable';
import Tile from './components/Tile';
import { useGetOverviewQuery } from './queries/useGetOverviewQuery';

export function OverviewView() {
  const { data } = useGetOverviewQuery();
  const capabilities = useCapabilities();

  if (!data) {
    return null;
  }

  return (
    <Container>
      <Heading text="Overview" />

      <div className="grid grid-cols-5 gap-3 mb-5">
        {capabilities.canViewOrders && (
          <>
            <Tile
              heading="Orders"
              total={data.ordersCount}
              link="/inventory/orders"
            />
            <Tile
              heading="Suppliers"
              total={data.suppliers.length}
              link="/inventory/suppliers"
            />
            <Tile
              heading="Product Categories"
              total={data.productCategories.length}
              link="/inventory/product-categories"
            />
            <Tile
              heading="Products"
              total={data.productsCount}
              link="/inventory/products"
            />
          </>
        )}
        <Tile
          heading="Installers"
          total={data.installersCount}
          link="/inventory/installers"
        />
      </div>

      {capabilities.canViewOrders && (
        <Box>
          <ExpandableSection title="Items on Hand">
            <ItemsOnHandTable itemsOnHand={data.itemsOnHand} />
          </ExpandableSection>
        </Box>
      )}
    </Container>
  );
}
