import {
  DocumentNode,
  OperationVariables,
  QueryHookOptions,
  useQuery,
} from '@apollo/client';
import { toast } from 'react-toastify';
import { formatErrorMessage } from '../support';

/**
 * Uses an enriched query hook that displays a toast notification on error.
 *
 * @param document The GQL document.
 * @param options Query options.
 */
export function useModifiedQuery<R, V extends OperationVariables>(
  document: DocumentNode,
  options?: QueryHookOptions<R, V>,
) {
  return useQuery<R, V>(document, {
    ...options,
    onError: (error) =>
      toast(formatErrorMessage(error), {
        type: 'error',
        theme: 'colored',
        position: 'bottom-center',
      }),
  });
}
