import { gql, MutationHookOptions, useMutation } from '@apollo/client';

const MUTATION = gql`
  mutation recordClaimFailure($tentativeClaimId: Float!) {
    recordClaimFailure(tentativeClaimId: $tentativeClaimId) {
      failed
    }
  }
`;

export type Variables = {
  readonly tentativeClaimId: number;
};

export type Response = {
  readonly recordClaimFailure: {
    readonly failed: boolean;
  };
};

export const useRecordClaimFailureMutation = (
  options: MutationHookOptions<Response, Variables>,
) => useMutation<Response, Variables>(MUTATION, options);
