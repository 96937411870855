import { gql, MutationHookOptions, useMutation } from '@apollo/client';

const MUTATION = gql`
  mutation login($email: String!, $password: String!, $twoFactorCode: String) {
    login(email: $email, password: $password, twoFactorCode: $twoFactorCode) {
      twoFactorQRData
      token
      user {
        id
      }
    }
  }
`;

export type Variables = {
  readonly email: string;
  readonly password: string;
  readonly twoFactorCode?: string | null;
};

export type Response = {
  readonly login: {
    readonly token: string | null;
    readonly twoFactorQRData: string | null;
    readonly user: {
      readonly id: number;
    };
  };
};

export const useLoginMutation = (
  options: MutationHookOptions<Response, Variables>,
) =>
  useMutation<Response, Variables>(MUTATION, {
    onError: (error) => console.error(error),
    ...options,
  });
