import { gql, useMutation } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import Button from '../../../../../components/controls/Button';
import DropdownField from '../../../../../components/controls/DropdownField';
import { useGetAgents } from '../../../../../hooks/useGetAgents';
import { useUser } from '../../../../../hooks/useUser';
import { Lead } from '../../../queries/getLeadQuery';

type Props = {
  readonly agent: {
    readonly id: number;
    readonly fullName: string;
    readonly email: string;
  } | null;
  readonly lead: Lead;
  readonly onUpdate: () => void;
};

const SET_AGENT = gql`
  mutation ($id: Float!, $agentId: Float!) {
    setLeadAgent(id: $id, agentId: $agentId) {
      id
    }
  }
`;

const CLAIM_LEAD = gql`
  mutation ($id: Float!) {
    claimLead(id: $id) {
      id
    }
  }
`;

export default function LeadAgentInfo({ agent, lead, onUpdate }: Props) {
  const user = useUser();
  const agents = useGetAgents();

  const [selectedAgentId, setSelectedAgentId] = useState<string>();

  const [setAgent, { loading: loadingSetAgent }] = useMutation(SET_AGENT, {
    onCompleted: () => onUpdate(),
    onError: (error) => console.error(error),
  });

  const [claimLead, { loading: loadingClaimLead }] = useMutation(CLAIM_LEAD, {
    variables: { id: parseInt(lead.id.toString()) },
    onCompleted: () => onUpdate(),
    onError: (error) => console.error(error),
  });

  useEffect(() => {
    // todo: what the
    setSelectedAgentId(agent?.id.toString() ?? '_undefined');
  }, [agent]);

  return (
    <div>
      <div>
        {user?.accessLevel.handle === 'admin' ? (
          <>
            {agents && (
              <div className="grid gap-4">
                <div>
                  <DropdownField
                    label="Agent"
                    placeholder="Unassigned"
                    options={agents.map((agent) => ({
                      value: agent.id.toString(),
                      text: `${agent.fullName ?? agent.email} (${
                        agent.accessLevel.name
                      })`,
                    }))}
                    value={selectedAgentId}
                    onChange={(value) => setSelectedAgentId(value)}
                  />
                </div>
                <div>
                  <Button
                    disabled={
                      !selectedAgentId ||
                      !!(agent && selectedAgentId === agent.id.toString())
                    }
                    loading={loadingSetAgent}
                    size="sm"
                    primary
                    text="Update Agent"
                    onClick={() => {
                      selectedAgentId &&
                        setAgent({
                          variables: {
                            id: parseInt(lead.id.toString()),
                            agentId: parseInt(selectedAgentId),
                          },
                        });
                    }}
                  />
                </div>
              </div>
            )}
          </>
        ) : (
          <>
            <p className="text-sm text-gray-400 mb-1">Agent</p>
            {agent ? (
              <>
                <p>
                  <strong>{agent.fullName}</strong>
                </p>
                <p>{agent.email}</p>
              </>
            ) : (
              <>
                <p className="mb-2">No agent assigned.</p>
                <Button
                  loading={loadingClaimLead}
                  onClick={() => claimLead()}
                  primary
                  size="sm"
                  text="Claim Lead"
                />
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
}
