import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import { UserRoleHandle } from './useUser';

const QUERY = gql`
  query getAgents {
    users {
      id
      email
      firstName
      lastName
      fullName
      active
      accessLevel {
        name
        handle
      }
    }
  }
`;

export type Response = {
  readonly users: readonly {
    readonly id: string;
    readonly email: string;
    readonly firstName: string;
    readonly lastName: string;
    readonly fullName: string;
    readonly active: boolean;
    readonly accessLevel: {
      readonly name: string;
      readonly handle: UserRoleHandle;
    };
  }[];
};

export type Agent = Response['users'][number];

export const useGetAgents = (options?: QueryHookOptions<Response>) => {
  const { data } = useQuery<Response>(QUERY, {
    fetchPolicy: 'cache-first',
    ...options,
  });

  return data?.users ?? [];
};
