import { ReactNode } from 'react';
import clsx from 'clsx';

type Props = {
  readonly children?: ReactNode;
  readonly rightAlign?: boolean;
  readonly compress?: boolean;
  readonly colspan?: number;
};

const theme = (rightAlign?: boolean) =>
  clsx('px-4 py-2 whitespace-nowrap', {
    'text-right': rightAlign,
  });

export default function TableCell({
  children,
  rightAlign,
  compress,
  colspan,
}: Props) {
  return (
    <td
      colSpan={colspan}
      width={compress ? '1' : undefined}
      className={theme(rightAlign)}
    >
      {children}
    </td>
  );
}
