import { gql, QueryHookOptions } from '@apollo/client';
import { useModifiedQuery } from '../../../../../apollo/hooks/useModifiedQuery';
import { FileUpload } from '../../../../../globals';

export const QUERY = gql`
  query ($id: Int!) {
    installer(id: $id) {
      id
      name
      abn
      archived
      delivers
      forkliftOnSite
      address
      confirmationOfInsurance {
        id
        name
        url
        description
        created
      }
      cecAccrediation {
        id
        name
        url
        description
        created
      }
      signedSubcontractorsAgreement {
        id
        name
        url
        description
        created
      }
      contacts {
        id
        name
        electricalLicenceNumber
        cecAccrediationNumber
        email
        phone
        archived
      }
      itemsOnHandCount
      itemsOnHand {
        product {
          id
          name
          sku
        }
        quantity
      }
    }
  }
`;

export type Variables = {
  readonly id: number;
};

export type Response = {
  readonly installer: {
    readonly id: string;
    readonly name: string;
    readonly abn: string | null;
    readonly confirmationOfInsurance: FileUpload | null;
    readonly cecAccrediation: FileUpload | null;
    readonly signedSubcontractorsAgreement: FileUpload | null;
    readonly archived: boolean;
    readonly delivers: boolean;
    readonly forkliftOnSite: boolean;
    readonly address: string | null;
    readonly contacts: readonly {
      readonly id: number;
      readonly name: string;
      readonly electricalLicenceNumber: string | null;
      readonly cecAccrediationNumber: string | null;
      readonly email: string | null;
      readonly phone: string | null;
      readonly archived: boolean;
    }[];
    readonly itemsOnHandCount: number;
    readonly itemsOnHand: readonly {
      readonly product: {
        readonly id: string;
        readonly name: string;
        readonly sku: string;
      };
      readonly quantity: number;
    }[];
  };
};

export const useGetInstallerQuery = (
  options: QueryHookOptions<Response, Variables>,
) => useModifiedQuery<Response, Variables>(QUERY, options);
