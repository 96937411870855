import { format } from 'date-fns';

export const DATETIME_FORMAT_SHORT = 'LLL d HH:mm';
export const DATETIME_FORMAT_MEDIUM = 'do LLL yyyy HH:mm';
export const DATETIME_FORMAT_DATE = 'do LLL yyyy';

const filters = {
  formatDate: (input: string, pattern: string) =>
    format(new Date(input), pattern),
  formatMoney: (input: string | number) =>
    parseFloat(input.toString()).toLocaleString('en-AU', {
      style: 'currency',
      currency: 'AUD',
    }),
  sum<T>(list: readonly T[], key: keyof T): number {
    return list.reduce(
      (total, entry) => total + parseFloat(String(entry[key])),
      0,
    );
  },
  formatPercentage: (input: number, units = 1) =>
    `${(input * 100).toFixed(units)}%`,
  truncateMiddle(
    input: string,
    maxLength: number = 20,
    glue: string = '...',
  ): string {
    if (input.length > maxLength) {
      const sideMaxLength = Math.floor((maxLength - glue.length) / 2);

      return [
        input.slice(0, sideMaxLength),
        input.slice(input.length - sideMaxLength),
      ].join(glue);
    }

    return input;
  },
};

export default filters;
