import { ReactNode } from 'react';
import { Helmet } from 'react-helmet-async';

type Props = {
  readonly text: string;
  readonly counter?: number;
  readonly actions?: ReactNode;
};

export default function Heading({ text, counter, actions }: Props) {
  return (
    <>
      <Helmet>
        <title>{text} | Doug CRM</title>
      </Helmet>
      <section className="flex h-14 items-center mb-5">
        <div className="flex-grow flex items-center space-x-2">
          <h2 className="inline-block text-xl font-light">{text}</h2>
          {counter !== undefined && <span>({counter})</span>}
        </div>
        {actions && <div className="flex-shrink-0 space-x-2">{actions}</div>}
      </section>
    </>
  );
}
