import ReactDatePicker from 'react-datepicker';
import clsx from 'clsx';

type Props = {
  readonly time?: boolean;
  readonly value: Date | null;
  readonly label?: string;
  readonly disabled?: boolean;
  readonly onChange?: (value: Date | null) => void;
};

const theme = (disabled: boolean) =>
  clsx(
    'block bg-white border border-gray-400 px-2 py-1 w-full rounded-lg focus:outline-none focus:border-primary shadow-sm',
    {
      'cursor-not-allowed bg-gray-100 text-gray-400': disabled,
    },
  );

export default function DateField({
  value,
  label,
  time,
  disabled = false,
  onChange,
}: Props) {
  return (
    <div className="relative">
      {label && (
        <label className="text-gray-600 text-sm mb-1 font-medium block">
          {label}
        </label>
      )}
      <ReactDatePicker
        className={theme(disabled)}
        wrapperClassName="w-full"
        placeholderText={label}
        showTimeInput={time}
        dateFormat={time ? 'dd/MM/yyyy HH:mm' : 'dd/MM/yyyy'}
        selected={value ?? undefined}
        onChange={(value) => onChange && onChange(value)}
        popperPlacement="bottom"
      />
    </div>
  );
}
