import Divider from '../../../../../components/content/Divider';
import ExpandableSection from '../../../../../components/content/ExpandableSection';
import DateField from '../../../../../components/controls/DateField';
import DropdownField from '../../../../../components/controls/DropdownField';
import NullableCheckboxField from '../../../../../components/controls/NullableCheckboxField';
import TextField from '../../../../../components/controls/TextField';
import { useGetEnergyRetailers } from '../../../../../hooks/useGetEnergyRetailers';
import { useGetFinanciers } from '../../../../../hooks/useGetFinanciers';
import { useGetInstallers } from '../../../../../hooks/useGetInstallers';
import { Lead } from '../../../queries/getLeadQuery';
import { LeadOperationsInput } from '../leadOperationsInput';

type Props = {
  readonly input: LeadOperationsInput;
  readonly pylonDesign: Lead['pylonDesign'];
  readonly hasOrders: boolean;
  readonly onChange: (value: LeadOperationsInput) => void;
};

export default function LeadOperationsDataSection({
  input,
  pylonDesign,
  hasOrders,
  onChange,
}: Props) {
  const installers = useGetInstallers();
  const energyRetailers = useGetEnergyRetailers();
  const financiers = useGetFinanciers();

  return (
    <>
      <ExpandableSection title="Operations">
        <div className="grid grid-cols-4 gap-4">
          <div className="col-span-4 lg:col-span-2">
            <DropdownField
              value={input.energyRetailerId}
              options={energyRetailers.map((retailer) => ({
                text: retailer.name,
                value: retailer.id.toString(),
              }))}
              label="Energy Retailer"
              placeholder="Energy Retailer"
              onChange={(value) =>
                onChange({ ...input, energyRetailerId: value })
              }
            />
          </div>
          <div className="col-span-4 lg:col-span-2">
            {installers && (
              <DropdownField
                value={input.installerContactId}
                disabled={hasOrders}
                options={installers
                  // Don't show archived installers.
                  .filter((installer) => !installer.archived)
                  // Don't show installers where all contacts are archived.
                  .filter((installer) =>
                    installer.contacts.some((contact) => !contact.archived),
                  )
                  .map((installer) => ({
                    text: installer.name,
                    children: installer.contacts
                      // Don't show archived contacts.
                      .filter((contact) => !contact.archived)
                      .map((contact) => ({
                        text: contact.name,
                        value: contact.id.toString(),
                      })),
                  }))}
                label="Installer"
                placeholder="Installer"
                onChange={(value) =>
                  onChange({ ...input, installerContactId: value })
                }
              />
            )}
          </div>
          <div className="col-span-2">
            <TextField
              type="text"
              label="System Size (kW)"
              placeholder="0.00"
              value={pylonDesign?.systemSize?.toFixed(2) ?? input.systemSize}
              disabled={!!pylonDesign?.systemSize}
              hint={
                pylonDesign?.systemSize
                  ? 'This value is pulled from the associated Pylon design.'
                  : undefined
              }
              onChange={(value) => onChange({ ...input, systemSize: value })}
            />
          </div>
          <div className="col-span-2">
            <TextField
              type="text"
              label="Battery Size (kWh)"
              placeholder="0.00"
              value={pylonDesign?.batterySize?.toFixed(2) ?? input.batterySize}
              disabled={!!pylonDesign?.batterySize}
              hint={
                pylonDesign?.batterySize
                  ? 'This value is pulled from the associated Pylon design.'
                  : undefined
              }
              onChange={(value) => onChange({ ...input, batterySize: value })}
            />
          </div>

          <div className="col-span-4">
            <DateField
              label="Deal Date"
              value={input.dealDate}
              onChange={(value) => onChange({ ...input, dealDate: value })}
            />
          </div>
          <div className="col-span-4 md:col-span-1">
            <TextField
              type="text"
              label="NMI"
              placeholder="NMI"
              value={input.nmi}
              onChange={(value) => onChange({ ...input, nmi: value })}
            />
          </div>
          <div className="col-span-4 md:col-span-2">
            <TextField
              type="text"
              label="Meter Number"
              placeholder="Meter Number"
              value={input.meterNumber}
              onChange={(value) => onChange({ ...input, meterNumber: value })}
            />
          </div>
          <div className="col-span-4 md:col-span-1">
            <TextField
              type="text"
              label="Pole Number"
              placeholder="Pole Number"
              value={input.poleNumber}
              onChange={(value) => onChange({ ...input, poleNumber: value })}
            />
          </div>
          <div className="col-span-4 md:col-span-2">
            <DateField
              label="Booked Installation Date"
              value={input.expectedInstallDate}
              onChange={(value) =>
                onChange({ ...input, expectedInstallDate: value })
              }
            />
          </div>
          <div className="col-span-4 md:col-span-2">
            <DateField
              label="Finalised and Commissioned Date"
              value={input.installDate}
              onChange={(value) => onChange({ ...input, installDate: value })}
            />
          </div>
        </div>
      </ExpandableSection>

      <Divider />

      <ExpandableSection title="Checklist">
        <h3 className="font-bold text-sm text-gray-600 mb-4">
          Signed Proposal
        </h3>

        <div className="grid grid-cols-4 gap-4 ">
          <div className="col-span-4 md:col-span-2">
            <NullableCheckboxField
              size="lg"
              value={input.signedProposal}
              onChange={(value) =>
                onChange({ ...input, signedProposal: value })
              }
            >
              Signed Proposal
            </NullableCheckboxField>
          </div>
        </div>

        <Divider />

        <h3 className="font-bold text-sm text-gray-600 mb-4">Deposit</h3>

        <div className="grid grid-cols-4 gap-4 ">
          <div className="col-span-4 md:col-span-2">
            <NullableCheckboxField
              size="lg"
              value={input.depositPaid}
              onChange={(value) => onChange({ ...input, depositPaid: value })}
            >
              Paid
            </NullableCheckboxField>
          </div>
        </div>

        <Divider />

        <h3 className="font-bold text-sm text-gray-600 mb-4">Finance</h3>

        <div className="grid grid-cols-4 gap-4 ">
          <div className="col-span-4">
            <DropdownField
              label="Finance"
              placeholder="Finance"
              value={input.financeId}
              options={
                financiers
                  ? financiers.map((finance) => ({
                      text: finance.name,
                      value: finance.id.toString(),
                    }))
                  : []
              }
              onChange={(value) => onChange({ ...input, financeId: value })}
            />
          </div>
        </div>

        <Divider />

        <h3 className="font-bold text-sm text-gray-600 mb-4">PTC</h3>

        <div className="grid grid-cols-4 gap-4 ">
          <div className="col-span-4 md:col-span-2">
            <NullableCheckboxField
              size="lg"
              value={input.ptcSubmitted}
              onChange={(value) => onChange({ ...input, ptcSubmitted: value })}
            >
              Submitted
            </NullableCheckboxField>
          </div>
          <div className="col-span-4 md:col-span-2">
            <NullableCheckboxField
              size="lg"
              value={input.ptcApproved}
              onChange={(value) => onChange({ ...input, ptcApproved: value })}
            >
              Approved
            </NullableCheckboxField>
          </div>
        </div>

        <Divider />

        <h3 className="font-bold text-sm text-gray-600 mb-4">Post-Sale</h3>

        <div className="grid grid-cols-4 gap-4 ">
          <div className="col-span-4 md:col-span-2">
            <NullableCheckboxField
              size="lg"
              value={input.userManualSent}
              onChange={(value) =>
                onChange({ ...input, userManualSent: value })
              }
            >
              User Manual Sent
            </NullableCheckboxField>
          </div>

          <div className="col-span-4 md:col-span-2">
            <NullableCheckboxField
              size="lg"
              value={input.meteringSubmitted}
              onChange={(value) =>
                onChange({ ...input, meteringSubmitted: value })
              }
            >
              Metering Submitted
            </NullableCheckboxField>
          </div>
        </div>
      </ExpandableSection>
    </>
  );
}
