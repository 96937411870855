import React from 'react';
import DropdownField from './DropdownField';

type Props = {
  readonly value: string;
  readonly onChange: (value: string) => void;
};

export default function MonthField({ value, onChange }: Props) {
  return (
    <DropdownField
      label="Month"
      value={value}
      onChange={(value) => onChange(value ? value : '1')}
      options={[
        { text: 'January', value: '1' },
        { text: 'February', value: '2' },
        { text: 'March', value: '3' },
        { text: 'April', value: '4' },
        { text: 'May', value: '5' },
        { text: 'June', value: '6' },
        { text: 'July', value: '7' },
        { text: 'August', value: '8' },
        { text: 'September', value: '9' },
        { text: 'October', value: '10' },
        { text: 'November', value: '11' },
        { text: 'December', value: '12' },
      ]}
    />
  );
}
