import { gql, QueryHookOptions, useQuery } from '@apollo/client';

const QUERY = gql`
  query getProductCategoriesPage {
    productCategories {
      id
      name
      pylonTypeSlug
    }
  }
`;

export type Response = {
  readonly productCategories: readonly {
    readonly id: string;
    readonly name: string;
    readonly pylonTypeSlug: string | null;
  }[];
};

export type RoofType = Response['productCategories'][number];

export const useGetProductCategories = (
  options?: QueryHookOptions<Response>,
) => {
  const { data } = useQuery<Response>(QUERY, {
    fetchPolicy: 'cache-first',
    ...options,
  });

  return data?.productCategories ?? [];
};
