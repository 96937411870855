import { faExternalLinkAlt, faUpload } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import { useState } from 'react';
import { FileUpload } from '../../globals';
import Modal from '../overlay/Modal';
import Button from './Button';
import TextField from './TextField';

type Props = {
  readonly label?: string;
  readonly value: readonly FileUpload[];
  readonly single?: boolean;
  readonly onChange: (value: readonly FileUpload[]) => void;
};

const client = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
});

export default function FileField({
  label,
  value,
  onChange,
  single = false,
}: Props) {
  const [uploading, setUploading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [files, setFiles] = useState<readonly File[]>([]);
  const [description, setDescription] = useState('');

  const handleUpload = async () => {
    if (files) {
      setUploading(true);

      const data = new FormData();

      for (const file of files) {
        data.append('file[]', file);
      }

      data.append('description', description);

      try {
        const response = await client.post<readonly FileUpload[]>(
          'storage',
          data,
          {
            headers: {
              'content-type': 'multipart/form-data',
              authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          },
        );

        onChange(response.data);
        setShowModal(false);
        setFiles([]);
        setDescription('');
      } catch (error: any) {
        console.error(`Failed to upload file: ${error.message}`);
      }

      setUploading(false);
    }
  };

  return (
    <>
      {label && (
        <label className="text-gray-600 text-sm mb-1 font-medium block">
          {label}
          {value.length > 0 && ` (${value.length})`}
        </label>
      )}
      <div className="flex items-stretch w-full">
        <div className="flex-grow bg-white border border-gray-400 px-2 py-1 rounded-l-lg shadow-sm overflow-hidden space-x-2">
          {value.length > 0 ? (
            value.map((entry) => (
              <a
                key={entry.id}
                className="text-blue-800 hover:text-blue-700"
                href={entry.url}
                target="_blank"
                rel="noreferrer"
              >
                {entry.name} <FontAwesomeIcon icon={faExternalLinkAlt} />
              </a>
            ))
          ) : (
            <span className="text-gray-400">No files selected</span>
          )}
        </div>
        <button
          className="flex items-center px-4 rounded-r-lg border border-l-0 border-gray-400 bg-gray-100 hover:bg-gray-200 hover:border-primary"
          onClick={(event) => {
            event.preventDefault();
            setShowModal(true);
          }}
        >
          <span className="text-center w-full text-gray-600">
            <FontAwesomeIcon icon={faUpload} />
          </span>
        </button>
      </div>

      {showModal && (
        <Modal
          heading={label ?? 'File Upload'}
          onClose={() => {
            if (!uploading) setShowModal(false);
          }}
        >
          <div className="relative flex items-center bg-gray-200 rounded-lg py-8 mb-4 overflow-auto">
            <input
              className="absolute inset-0 opacity-0 cursor-pointer"
              type="file"
              multiple={!single}
              onChange={(event) =>
                setFiles(Array.from(event.target.files ?? []))
              }
            />

            <div className="w-full text-center text-sm">
              {files.length > 0 ? (
                <div>
                  <p className="font-bold mb-2">
                    {files.length} file{files.length === 1 ? '' : 's'} selected:
                  </p>
                  {files.map((file) => (
                    <p key={file.name}>{file.name}</p>
                  ))}
                </div>
              ) : (
                <span className="text-gray-600">
                  <FontAwesomeIcon icon={faUpload} /> Pick File
                </span>
              )}
            </div>
          </div>

          <div className="mb-4">
            <TextField
              type="box"
              rows={2}
              label="Description"
              placeholder="Description..."
              value={description}
              onChange={(value) => setDescription(value)}
            />
          </div>

          <Button
            disabled={files.length === 0}
            loading={uploading}
            text="Upload"
            onClick={() => handleUpload()}
          />
        </Modal>
      )}
    </>
  );
}
