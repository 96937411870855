import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import { Lead } from '../../LeadView/queries/getLeadQuery';

const QUERY = gql`
  query ($offset: Float!, $limit: Float!, $criteria: GetLeadsInput!) {
    leads(offset: $offset, limit: $limit, criteria: $criteria) {
      id
      updated
      status {
        name
      }
      customer {
        firstName
        lastName
        mobilePhone
        email
      }
      address {
        postcode
      }
      nextActionDue
      hasOverdueAction
      actions {
        id
        due
        type {
          id
          name
        }
      }
    }
    countLeads(criteria: $criteria)
  }
`;

export type Variables = {
  readonly offset: number;
  readonly limit: number;
  // @todo: explicit type
  readonly criteria: any;
};

export type Response = {
  readonly leads: readonly Lead[];
  readonly countLeads: number;
};

export const useGetLeadsQuery = (
  options: QueryHookOptions<Response, Variables>,
) => useQuery<Response, Variables>(QUERY, options);
