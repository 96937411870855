import clsx from 'clsx';
import { useEffect, useMemo, useState } from 'react';
import Table from '../../../../../../components/content/table/Table';
import TableBody from '../../../../../../components/content/table/TableBody';
import TableHeader from '../../../../../../components/content/table/TableHeader';
import TableHeaderCell from '../../../../../../components/content/table/TableHeaderCell';
import TableRow from '../../../../../../components/content/table/TableRow';
import Button from '../../../../../../components/controls/Button';
import { Response } from '../../queries/getOrderQuery';
import OrderLineItem from './components/OrderLineItem';
import { useUpdateLineItemsMutation } from './mutations/updateLineItemsMutation';

type Props = {
  readonly orderStatus: Response['order']['status']['handle'];
  readonly lineItems: Response['order']['lineItems'];
  readonly onUpdate: () => Promise<any>;
};

export function OrderLineItems({ orderStatus, lineItems, onUpdate }: Props) {
  const [updatedLineItems, setUpdatedLineItems] = useState<
    readonly LineItemUpdate[]
  >([]);
  const [updateLineItems, { loading: updateLineItemsLoading }] =
    useUpdateLineItemsMutation({
      variables: {
        input: updatedLineItems.map((lineItem) => ({
          id: lineItem.id.toString(),
          quantity: parseInt(lineItem.quantity),
          received: parseInt(lineItem.received),
          used: parseInt(lineItem.used),
          cost: lineItem.cost ? parseFloat(lineItem.cost) : null,
        })),
      },
      onCompleted: () => onUpdate(),
    });

  const actions = useMemo(() => {
    if (orderStatus === 'SENT') {
      return (
        <Button
          disabled={updateLineItemsLoading}
          size="sm"
          text="Update Line Items"
          onClick={() => updateLineItems()}
        />
      );
    }

    return null;
  }, [orderStatus]);

  useEffect(() => {
    setUpdatedLineItems(
      lineItems.map((line) => ({
        id: line.id,
        quantity: line.quantity.toString(),
        received: line.received.toString(),
        used: line.used.toString(),
        cost: line.cost?.toFixed(2) ?? '',
      })),
    );
  }, [lineItems]);

  return (
    <>
      <section>
        <div
          className={clsx({
            'mb-5': actions !== null,
          })}
        >
          <Table>
            <TableHeader>
              <TableRow>
                <TableHeaderCell>Product</TableHeaderCell>
                <TableHeaderCell>Category</TableHeaderCell>
                <TableHeaderCell>Required</TableHeaderCell>
                <TableHeaderCell>Received</TableHeaderCell>
                <TableHeaderCell>Used</TableHeaderCell>
                {(orderStatus === 'SENT' || orderStatus === 'ARCHIVED') && (
                  <>
                    <TableHeaderCell>Subtotal</TableHeaderCell>
                  </>
                )}
              </TableRow>
            </TableHeader>
            <TableBody>
              {updatedLineItems.map((lineItem, index) => (
                <OrderLineItem
                  key={lineItem.id}
                  loading={updateLineItemsLoading}
                  orderStatus={orderStatus}
                  lineItem={lineItems[index]}
                  value={lineItem}
                  onChange={(value) => {
                    setUpdatedLineItems(
                      updatedLineItems.map((line) =>
                        line.id === value.id ? value : line,
                      ),
                    );
                  }}
                />
              ))}
            </TableBody>
          </Table>
        </div>
        {actions}
      </section>
    </>
  );
}
