import { getDaysInMonth } from 'date-fns';
import { isSameDay } from 'date-fns/esm';
import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Route, Routes } from 'react-router-dom';
import Box from '../../components/content/Box';
import Container from '../../components/content/Container';
import Divider from '../../components/content/Divider';
import Page from '../../components/content/Page';
import Button from '../../components/controls/Button';
import MonthField from '../../components/controls/MonthField';
import TextField from '../../components/controls/TextField';
import DiaryBlock from './components/DiaryBlock';
import SubNavigation from '../../components/navigation/SubNavigation';
import Modal from '../../components/overlay/Modal';
import filters, { DATETIME_FORMAT_MEDIUM } from '../../filters';
import DiarySyncButton from './components/DiarySyncButton';
import GoogleCalendar from './components/GoogleCalendar';
import { useUser } from '../../hooks/useUser';
import { Response, useGetDiaryQuery } from './queries/getDiaryQuery';
import ButtonLink from '../../components/controls/ButtonLink';

type DiaryLineItem = {
  readonly date: Date;
  readonly actions: Response['diary'];
};

export function DiaryView() {
  const user = useUser();

  const [selectedAction, setSelectedAction] = useState<
    Response['diary'][number] | null
  >(null);

  const [month, setMonth] = useState(String(new Date().getMonth() + 1));
  const [year, setYear] = useState(String(new Date().getFullYear()));
  const [lines, setLines] = useState<readonly DiaryLineItem[]>([]);

  useGetDiaryQuery({
    fetchPolicy: 'no-cache',
    variables: {
      month: parseInt(month),
      year: parseInt(year),
    },
    onCompleted: (data) => {
      const lines: DiaryLineItem[] = [];
      const days = getDaysInMonth(
        new Date(parseInt(year), parseInt(month) - 1, 1),
      );

      for (let i = 1; i <= days; i++) {
        const date = new Date(parseInt(year), parseInt(month) - 1, i);
        const actions = data.diary.filter((action) =>
          isSameDay(new Date(action.due), date),
        );

        lines.push({ date, actions });
      }

      setLines(lines);
    },
  });

  return (
    <>
      <Helmet>
        <title>Diary | Doug CRM</title>
      </Helmet>

      <SubNavigation
        heading="Diary"
        controls={
          <DiarySyncButton
            hasGoogleCredentials={user?.hasGoogleCredentials ?? false}
          />
        }
        tabs={[
          { text: 'Google Calendar', url: '/diary' },
          { text: 'Legacy', url: '/diary/legacy' },
        ]}
      />

      <Page>
        <Routes>
          <Route
            index
            element={
              <GoogleCalendar googleCalendarId={user?.googleCalendarId} />
            }
          />
          <Route
            path="/legacy"
            element={
              <Container>
                <Box>
                  <>
                    <div className="grid grid-cols-2 gap-4 border-b border-gray-300 pb-5 mb-5">
                      <div>
                        <MonthField
                          value={month}
                          onChange={(value) => setMonth(value)}
                        />
                      </div>
                      <div>
                        <TextField
                          label="Year"
                          type="text"
                          placeholder="2XXX"
                          value={year}
                          onChange={(value) => setYear(value)}
                        />
                      </div>
                    </div>

                    <div className="grid gap-2 lg:grid-cols-3">
                      {lines.map((line) => (
                        <div key={line.date.toDateString()}>
                          <DiaryBlock
                            date={line.date}
                            actions={line.actions}
                            onView={(action) => setSelectedAction(action)}
                          />
                        </div>
                      ))}
                    </div>
                  </>
                </Box>
              </Container>
            }
          />
        </Routes>
      </Page>

      {selectedAction && (
        <Modal onClose={() => setSelectedAction(null)}>
          <h2 className="font-bold text-lg mb-2">{selectedAction.type.name}</h2>
          <time className="text-sm text-gray-500">
            {filters.formatDate(selectedAction.due, DATETIME_FORMAT_MEDIUM)}
          </time>

          {selectedAction.content && (
            <>
              <h4 className="text-gray-600 mb-4 text-sm mt-4">Notes</h4>
              <p className="">{selectedAction.content}</p>
            </>
          )}

          {selectedAction.lead.customer && (
            <>
              <Divider />
              <h4 className="text-gray-600 mb-4 text-sm">Customer</h4>
              <p>
                {selectedAction.lead.customer.firstName}{' '}
                {selectedAction.lead.customer.lastName}
              </p>
              <p>{selectedAction.lead.customer.mobilePhone}</p>
            </>
          )}

          <Divider />

          <div className="space-x-2">
            <ButtonLink
              size="md"
              text="View Lead"
              to={`/leads/${selectedAction.lead.id}`}
            />
            <Button text="Close" onClick={() => setSelectedAction(null)} />
          </div>
        </Modal>
      )}
    </>
  );
}
