import { gql, MutationHookOptions, useMutation } from '@apollo/client';

const MUTATION = gql`
  mutation markDead($id: Float!, $reasonId: Float) {
    markDead(id: $id, reasonId: $reasonId) {
      id
    }
  }
`;

export type Variables = {
  readonly id: number;
  readonly reasonId?: number;
};

export type Response = {
  readonly markDead: {
    readonly id: number;
  };
};

export const useMarkDeadMutation = (
  options: MutationHookOptions<Response, Variables>,
) =>
  useMutation<Response, Variables>(MUTATION, {
    onError: (error) => console.error(error),
    ...options,
  });
