import { AnimatePresence } from 'framer-motion';
import { useState } from 'react';
import Table from '../../../../../../components/content/table/Table';
import TableBody from '../../../../../../components/content/table/TableBody';
import TableCell from '../../../../../../components/content/table/TableCell';
import TableHeader from '../../../../../../components/content/table/TableHeader';
import TableHeaderCell from '../../../../../../components/content/table/TableHeaderCell';
import TableRow from '../../../../../../components/content/table/TableRow';
import Button from '../../../../../../components/controls/Button';
import CreateSupplierContactTray from '../../../CreateSupplierContactTray';
import { Response } from '../../queries/getSupplierQuery';

type Props = {
  readonly supplierId: number;
  readonly contacts: Response['supplier']['contacts'];
  readonly onChange: () => Promise<any>;
};

export function SupplierContactsTable({
  supplierId,
  contacts,
  onChange,
}: Props) {
  const [showAddTray, setShowAddTray] = useState(false);

  return (
    <div>
      <Table className="mb-5">
        <TableHeader>
          <TableRow>
            <TableHeaderCell>Name</TableHeaderCell>
            <TableHeaderCell>Email</TableHeaderCell>
            <TableHeaderCell>Phone</TableHeaderCell>
          </TableRow>
        </TableHeader>
        <TableBody>
          {contacts.length > 0 ? (
            contacts.map((contact) => (
              <TableRow key={contact.id}>
                <TableCell>{contact.name}</TableCell>
                <TableCell>{contact.email}</TableCell>
                <TableCell>{contact.phone ?? '-'}</TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colspan={3}>No contacts.</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>

      <Button size="sm" text="Add" onClick={() => setShowAddTray(true)} />

      <AnimatePresence>
        {showAddTray && (
          <CreateSupplierContactTray
            supplierId={supplierId}
            onClose={() => setShowAddTray(false)}
            onCreate={onChange}
          />
        )}
      </AnimatePresence>
    </div>
  );
}
