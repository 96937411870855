import { gql, MutationHookOptions, useMutation } from '@apollo/client';

const MUTATION = gql`
  mutation ($name: String!) {
    createFinance(name: $name) {
      id
    }
  }
`;

export type Variables = {
  readonly name: string;
};

export type Response = {
  readonly createFinance: {
    readonly id: number;
  };
};

export const useCreateFinanceMutation = (
  options: MutationHookOptions<Response, Variables>,
) => useMutation<Response, Variables>(MUTATION, options);
