import { useQuery } from '@apollo/client';
import { capitalCase } from 'change-case';
import { endOfMonth, startOfMonth } from 'date-fns';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router';
import Box from '../../components/content/Box';
import Container from '../../components/content/Container';
import ErrorMessage from '../../components/content/ErrorMessage';
import Page from '../../components/content/Page';
import Pagination from '../../components/controls/Pagination';
import SubNavigation from '../../components/navigation/SubNavigation';
import { GetLeadInput } from '../../globals';
import DealsTable from './components/DealsTable';
import DealsTableWithSaleStatus from './components/DealsTableWithSaleStatus';
import InstalledLeads from './components/InstalledLeads';
import { QUERY, Response } from './queries/getLeads';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../../hooks/useUser';
import { LeadStatusHandle } from '../../hooks/useGetLeadStatuses';

const LIMIT = 30;

type Params = {
  readonly status: LeadStatusHandle;
};

export function DealsView() {
  const user = useUser();
  const navigate = useNavigate();

  const { status } = useParams<Params>();

  const statuses: readonly LeadStatusHandle[] = ['sale', 'installed'];

  const [offset, setOffset] = useState(0);

  const [criteria, setCriteria] = useState<GetLeadInput>({
    statuses: [],
    order: 'DESC',
    orderBy: 'updated',
  });

  useEffect(() => {
    if (!status) {
      navigate(`/deals/status/${statuses[0]}`);
    } else {
      setCriteria({
        ...criteria,
        statuses: [status],
        orderBy: status === 'sale' ? 'dealDate' : 'updated',
        agentId: undefined,
        installDateStart:
          status === 'installed' && user?.accessLevel.handle !== 'admin'
            ? startOfMonth(new Date()).toISOString()
            : undefined,
        installDateEnd:
          status === 'installed' && user?.accessLevel.handle !== 'admin'
            ? endOfMonth(new Date()).toISOString()
            : undefined,
      });
    }
  }, [status]);

  useEffect(() => {
    setOffset(0);
  }, [criteria]);

  const { data, loading, error } = useQuery<Response>(QUERY, {
    fetchPolicy: 'no-cache',
    variables: {
      offset,
      limit: LIMIT,
      criteria,
    },
  });

  return (
    <>
      <Helmet>
        <title>Deals | Doug CRM</title>
      </Helmet>

      <SubNavigation
        heading={data ? `Deals (${data?.countLeads})` : 'Deals'}
        tabs={statuses.map((status) => ({
          text: capitalCase(status),
          url: '/deals/status/' + status,
        }))}
      />

      <Page>
        <Container>
          {loading ? (
            <Box>
              <p>Loading&hellip;</p>
            </Box>
          ) : (
            <>
              {(() => {
                switch (status) {
                  case 'sale':
                    return (
                      <DealsTableWithSaleStatus leads={data?.leads ?? []} />
                    );
                  case 'installed':
                    return (
                      <InstalledLeads
                        leads={data?.leads ?? []}
                        criteria={criteria}
                        onChangeCriteria={(value) => setCriteria(value)}
                      />
                    );
                  default:
                    return <DealsTable leads={data?.leads ?? []} />;
                }
              })()}

              {data && data.leads.length > 0 && (
                <Pagination
                  offset={offset}
                  limit={LIMIT}
                  total={data?.countLeads ?? 0}
                  onChange={(value) => setOffset(value)}
                />
              )}
            </>
          )}
          {error && (
            <Box>
              <ErrorMessage error={error} />
            </Box>
          )}
        </Container>
      </Page>
    </>
  );
}
