import { gql, QueryHookOptions, useQuery } from '@apollo/client';

const QUERY = gql`
  query getSuppliers {
    suppliers {
      id
      created
      updated
      name
      contacts {
        id
      }
      locations {
        id
      }
    }
  }
`;

export type Variables = {};

export type Response = {
  readonly suppliers: {
    readonly id: string;
    readonly created: string;
    readonly updated: string;
    readonly name: string;
    readonly contacts: readonly {
      readonly id: string;
    }[];
    readonly locations: readonly {
      readonly id: string;
    }[];
  }[];
};

export const useGetSuppliersQuery = (
  options?: QueryHookOptions<Response, Variables>,
) => useQuery<Response, Variables>(QUERY, options);
