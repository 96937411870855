import { gql, QueryHookOptions, useQuery } from '@apollo/client';

const QUERY = gql`
  query getDashboardAgents($month: Float!, $year: Float!) {
    agents {
      id
      firstName
      lastName
      report(month: $month, year: $year) {
        totalSales
        totalSalesRevenue
        totalSalesKilowatts
        totalSalesKilowattHours
        totalInstallations
        totalInstallationsRevenue
        totalInstallationsKilowatts
        totalInstallationsKilowattHours
        totalInstallationsProjectedCommission
        totalDealInvoice
        totalInstalledInvoice
        totalClaims
      }
    }
  }
`;

export type Variables = {
  readonly month: number;
  readonly year: number;
};

export type Response = {
  readonly agents: readonly {
    readonly id: number;
    readonly firstName: string;
    readonly lastName: string;
    readonly report: {
      readonly totalSales: number;
      readonly totalSalesRevenue: number;
      readonly totalSalesKilowatts: number;
      readonly totalSalesKilowattHours: number;
      readonly totalInstallations: number;
      readonly totalInstallationsRevenue: number;
      readonly totalInstallationsKilowatts: number;
      readonly totalInstallationsKilowattHours: number;
      readonly totalInstallationsProjectedCommission: number;
      readonly totalDealInvoice: number;
      readonly totalInstalledInvoice: number;
      readonly totalClaims: number;
    };
  }[];
};

export const useGetAgentsQuery = (
  options: QueryHookOptions<Response, Variables>,
) =>
  useQuery<Response, Variables>(QUERY, {
    fetchPolicy: 'no-cache',
    ...options,
  });
