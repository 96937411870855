import { gql, QueryHookOptions, useQuery } from '@apollo/client';

const QUERY = gql`
  query getSupplier($id: ID!) {
    supplier(id: $id) {
      id
      created
      updated
      name
      contacts {
        id
        name
        email
        phone
      }
      locations {
        id
        name
        address {
          line1
          line2
          suburb
          state {
            abbreviation
          }
          postcode
        }
      }
    }
  }
`;

export type Variables = {
  readonly id: string | number;
};

export type Response = {
  readonly supplier: {
    readonly id: string;
    readonly created: string;
    readonly updated: string;
    readonly name: string;
    readonly contacts: readonly {
      readonly id: string;
      readonly name: string;
      readonly email: string;
      readonly phone: string | null;
    }[];
    readonly locations: readonly {
      readonly id: string;
      readonly name: string;
      readonly address: {
        readonly line1: string | null;
        readonly line2: string | null;
        readonly suburb: string | null;
        readonly state: {
          readonly abbreviation: string;
        } | null;
        readonly postcode: string | null;
      };
    }[];
  };
};

export const useGetSupplierQuery = (
  options?: QueryHookOptions<Response, Variables>,
) => useQuery<Response, Variables>(QUERY, options);
