import { AnimatePresence } from 'framer-motion';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CapabilityGuard from '../../../../components/CapabilityGuard';
import Container from '../../../../components/content/Container';
import Button from '../../../../components/controls/Button';
import SidebarLayout from '../../../../components/layout/SidebarLayout';
import Heading from '../../../../components/layout/ToolbarPage/components/Heading';
import CreateProductTray from '../CreateProductTray';
import ProductsSidebar from './components/ProductsSidebar';
import ProductsTable from './components/ProductsTable';
import { useGetProductsQuery } from './queries/getProductsQuery';

export function ProductsView() {
  const navigate = useNavigate();

  const [showCreateTray, setShowCreateTray] = useState(false);
  const [productCategoryIds, setProductCategoryIds] = useState<
    readonly string[]
  >([]);

  const { data } = useGetProductsQuery({
    variables: {
      input: {
        productCategoryIds,
      },
    },
  });

  return (
    <CapabilityGuard requirements={['canViewOrders']}>
      <Container>
        <Heading
          text="Products"
          counter={data?.productsCount}
          actions={
            <>
              <Button
                primary
                text="Create"
                onClick={() => setShowCreateTray(true)}
              />
            </>
          }
        />

        <SidebarLayout
          sidebar={
            <ProductsSidebar
              filter={{ productCategoryIds }}
              onChange={(value) =>
                setProductCategoryIds(value.productCategoryIds)
              }
            />
          }
        >
          <ProductsTable products={data?.products ?? []} />
        </SidebarLayout>
      </Container>

      <AnimatePresence>
        {showCreateTray && (
          <CreateProductTray
            onClose={() => setShowCreateTray(false)}
            onCreate={(id) => navigate(`/inventory/products/${id}`)}
          />
        )}
      </AnimatePresence>
    </CapabilityGuard>
  );
}
