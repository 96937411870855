import clsx from 'clsx';
import { Link } from 'react-router-dom';

type Props = {
  readonly text: string;
  readonly to: string;
  readonly size?: 'sm' | 'md';
};

export default function ButtonLink({ text, to, size = 'sm' }: Props) {
  return (
    <Link
      className={clsx(
        'inline-block text-gray-700 bg-white hover:text-gray-900 border font-medium focus:outline-none rounded-md border-gray-300 hover:bg-gray-100 shadow-sm',
        {
          'px-3 py-1 text-sm': size === 'sm',
          'px-4 py-2': size === 'md',
        },
      )}
      to={to}
    >
      {text}
    </Link>
  );
}
