import { faCheck, faSlash } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactNode } from 'react';
import clsx from 'clsx';

type Props = {
  readonly children?: ReactNode;
  readonly size?: 'md' | 'lg';
  readonly value?: boolean | null;
  readonly disabled?: boolean;
  readonly onChange: (value: boolean | null) => void;
};

const theme = (
  size: Props['size'],
  value: boolean | null,
  disabled?: boolean,
) =>
  clsx(
    'rounded-lg text-center leading-none text-xs focus:outline-none border shadow-sm',
    {
      'w-6 h-6 text-xs': size === 'md',
      'w-9 h-9': size === 'lg',
      'bg-green-400 border-green-500 text-white': value && !disabled,
      'border-gray-400 text-gray-200': value === false && !disabled,
      'bg-gray-100 border-gray-400 cursor-not-allowed': disabled,
      'text-gray-100': disabled && value === false,
      'text-gray-400 ': disabled && value,
      'bg-red-300 text-white border-red-400': value === null && !disabled,
    },
  );

export default function NullableCheckboxField({
  size = 'md',
  children,
  value,
  disabled,
  onChange,
}: Props) {
  const title = () => {
    if (value === true) return 'Checked';
    if (value === false) return 'Unchecked';

    return 'Not Applicable';
  };

  return (
    <div className="flex items-center">
      <div className="flex-shrink">
        <button
          title={title()}
          disabled={disabled}
          onClick={(event) => {
            event.preventDefault();

            const next = () => {
              if (value === false) return true;
              if (value === true) return null;

              return false;
            };

            onChange(next());
          }}
          className={theme(size, value ?? null, disabled)}
        >
          {value === null ? (
            <FontAwesomeIcon icon={faSlash} />
          ) : (
            <FontAwesomeIcon icon={faCheck} />
          )}
        </button>
      </div>

      <div className="flex-grow pl-2 text-sm">{children}</div>
    </div>
  );
}
