import { gql, useMutation } from '@apollo/client';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ErrorMessage from '../../../components/content/ErrorMessage';
import Button from '../../../components/controls/Button';
import TextField from '../../../components/controls/TextField';

const APPLY_PASSWORD_RESET = gql`
  mutation ($token: String!, $password: String!) {
    applyPasswordReset(token: $token, password: $password) {
      email
    }
  }
`;

type Props = {
  readonly token: string;
};

export default function AuthApplyResetForm({ token }: Props) {
  const navigate = useNavigate();

  const [password, setPassword] = useState('');

  const [applyPasswordReset, { error, loading }] = useMutation(
    APPLY_PASSWORD_RESET,
    {
      onError: (error) => console.error(error),
      onCompleted: () => {
        alert('Password reset successful.');
        navigate('/login');
      },
    },
  );

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();

        applyPasswordReset({
          variables: {
            token,
            password,
          },
        });
      }}
    >
      <h1 className="mb-4 font-medium text-xl">Reset Password</h1>

      <p className="mb-4 text-gray-600 text-sm">
        Enter a new password for this account. This will also invalidate your
        2FA setup, which you will be prompted to reconfigure.
      </p>

      <div className="mb-4">
        <TextField
          placeholder="New Password"
          type="password"
          value={password}
          onChange={(value) => setPassword(value)}
        />
      </div>

      {error && <ErrorMessage error={error} />}

      <Button loading={loading} type="submit" primary text="Reset" />
      <Link
        className="inline-block ml-2 text-sm hover:underline text-gray-400"
        to="/login"
      >
        Back to login
      </Link>
    </form>
  );
}
