import { MutationHookOptions, gql, useMutation } from '@apollo/client';

const MUTATION = gql`
  mutation removeLeadLineItem($id: ID!) {
    removeLeadLineItem(id: $id) {
      id
    }
  }
`;

export type Variables = {
  readonly id: string;
};

export type Response = {
  readonly removeLeadLineItem: {
    readonly id: string;
  };
};

export const useRemoveLeadLineItemMutation = (
  options?: MutationHookOptions<Response, Variables>,
) => useMutation<Response, Variables>(MUTATION, options);
