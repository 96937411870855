import { gql, MutationHookOptions, useMutation } from '@apollo/client';

const MUTATION = gql`
  mutation updateLeadPool($id: ID!, $input: UpdateLeadPoolInput!) {
    updateLeadPool(id: $id, input: $input) {
      id
    }
  }
`;

export type Variables = {
  readonly id: number;
  readonly input: {
    readonly name: string;
    readonly fromCreatedDate: Date | null;
    readonly toCreatedDate: Date | null;
    readonly minDials: number | null;
    readonly maxDials: number | null;
    readonly leadSourceIds: readonly string[];
    readonly agentIds: readonly string[];
    readonly statuses: readonly string[];
    readonly allowedTerminationReasonIds: readonly string[];
  };
};

export type Response = {
  readonly updateLeadPool: {
    readonly id: number;
  };
};

export const useUpdateLeadPoolMutation = (
  options: MutationHookOptions<Response, Variables>,
) => useMutation<Response, Variables>(MUTATION, options);
