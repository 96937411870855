import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import Box from '../../components/content/Box';
import Container from '../../components/content/Container';
import Divider from '../../components/content/Divider';
import ErrorMessage from '../../components/content/ErrorMessage';
import ExpandableSection from '../../components/content/ExpandableSection';
import Page from '../../components/content/Page';
import Button from '../../components/controls/Button';
import DropdownField from '../../components/controls/DropdownField';
import LeadAddressDataSection from '../LeadView/components/LeadSalesView/components/LeadAddressDataSection';
import LeadCustomerDataSection from '../LeadView/components/LeadSalesView/components/LeadCustomerDataSection';
import SubNavigation from '../../components/navigation/SubNavigation';
import { AddressInput, CustomerInput } from '../../globals';
import { useCreateLeadMutation } from './mutations/createLeadMutation';
import { useGetLeadSources } from '../../hooks/useGetLeadSources';

export function CreateLeadView() {
  const navigate = useNavigate();

  const leadSources = useGetLeadSources();

  const [sourceId, setSourceId] = useState<string>();

  const [customer, setCustomer] = useState<CustomerInput>({
    firstName: '',
    lastName: '',
    middleName: '',
    mobilePhone: '',
    homePhone: '',
    workPhone: '',
    email: '',
  });

  const [address, setAddress] = useState<AddressInput>({
    line1: '',
    line2: '',
    suburb: '',
    stateId: undefined,
    postcode: '',
    connectionTypeId: undefined,
    roofTypeId: undefined,
    existingSolar: false,
    storeys: '',
  });

  const [createLead, { error, loading }] = useCreateLeadMutation({
    variables: {
      sourceId: sourceId ? parseInt(sourceId) : null,
      customer,
      address: {
        line1: address.line1,
        line2: address.line2,
        suburb: address.suburb,
        stateId: address.stateId ? parseInt(address.stateId) : undefined,
        postcode: address.postcode,
        roofTypeId: address.roofTypeId
          ? parseInt(address.roofTypeId)
          : undefined,
        connectionTypeId: address.connectionTypeId
          ? parseInt(address.connectionTypeId)
          : undefined,
        storeys: address.storeys ? parseInt(address.storeys) : undefined,
        existingSolar: address.existingSolar,
      },
    },
    onCompleted: (data) => navigate(`/leads/${data.createLead.id}`),
    onError: (error) => console.error(error),
  });

  return (
    <>
      <Helmet>
        <title>Create Lead | Doug CRM</title>
      </Helmet>

      <SubNavigation heading="Create New Lead" />

      <Page>
        <Container>
          <div className="grid lg:grid-cols-5 gap-4">
            <div className="lg:col-span-3">
              <Box>
                <form
                  onSubmit={(event) => {
                    event.preventDefault();
                    createLead();
                  }}
                >
                  <ExpandableSection title="Meta">
                    <div className="grid gap-4">
                      <div>
                        <DropdownField
                          value={sourceId}
                          options={leadSources.map((source) => ({
                            text: source.name,
                            value: source.id.toString(),
                          }))}
                          label="Source"
                          placeholder="Source"
                          onChange={(value) => setSourceId(value)}
                        />
                      </div>
                    </div>
                  </ExpandableSection>

                  <Divider />

                  <LeadCustomerDataSection
                    customer={customer}
                    onChange={(value) => setCustomer(value)}
                  />
                  <Divider />

                  <LeadAddressDataSection
                    address={address}
                    onChange={(value) => setAddress(value)}
                  />

                  <Divider />

                  {error && (
                    <>
                      <ErrorMessage error={error} />
                      <Divider />
                    </>
                  )}

                  <Button
                    type="submit"
                    primary
                    text="Create"
                    loading={loading}
                  />
                </form>
              </Box>
            </div>
          </div>
        </Container>
      </Page>
    </>
  );
}
