import { faChevronDown, faChevronUp } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AnimatePresence } from 'framer-motion';
import { ReactNode, useState } from 'react';

type Props = {
  readonly children: ReactNode;
  readonly title: string;
};

export default function SidebarSection({ children, title }: Props) {
  const [show, setShow] = useState(true);

  return (
    <section className="p-3 border-b-2 border-gray-200 last:border-0">
      <div className="flex items-center">
        <h3 className="flex-grow text-gray-600 text-sm">{title}</h3>
        <button
          onClick={() => setShow(!show)}
          className="text-sm text-gray-600"
        >
          <FontAwesomeIcon icon={show ? faChevronDown : faChevronUp} />
        </button>
      </div>
      <AnimatePresence>
        {show && <div className="pt-2">{children}</div>}
      </AnimatePresence>
    </section>
  );
}
