import DropdownField from '../../controls/DropdownField';
import React from 'react';

type Props = {
  readonly value?: string;
  readonly onChange: (value?: string) => void;
};

export default function StoreysDropdown({ value, onChange }: Props) {
  return (
    <DropdownField
      label="Storeys"
      placeholder="Storeys"
      value={value}
      options={[
        { value: '1', text: '1' },
        { value: '2', text: '2' },
        { value: '3', text: '3' },
        { value: '4', text: '4' },
        { value: '5', text: '5+' },
      ]}
      onChange={onChange}
    />
  );
}
