import { useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import ErrorMessage from '../../../../../components/content/ErrorMessage';
import Button from '../../../../../components/controls/Button';
import TextField from '../../../../../components/controls/TextField';
import Modal from '../../../../../components/overlay/Modal';
import {
  MUTATION as UPDATE_INSTALLER_CONTACT,
  Response as UpdateInstallerContactResponse,
} from '../mutations/updateInstallerContact';
import { Response } from '../queries/getInstaller';

type Props = {
  readonly contact: Response['installer']['contacts'][0];
  readonly onClose: () => void;
  readonly onUpdateInstallerContact: () => void;
};

export default function UpdateInstallerContactModal({
  contact,
  onClose,
  onUpdateInstallerContact: onAddInstallerContact,
}: Props) {
  const [name, setName] = useState('');
  const [electricalLicenceNumber, setElectricalLicenceNumber] = useState('');
  const [cecAccrediationNumber, setCecAccrediationNumber] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');

  const [updateInstallerContact, { loading, error }] =
    useMutation<UpdateInstallerContactResponse>(UPDATE_INSTALLER_CONTACT, {
      variables: {
        id: contact.id,
        input: {
          name,
          electricalLicenceNumber,
          cecAccrediationNumber,
          email,
          phone,
        },
      },
      onCompleted: () => {
        onClose();
        return onAddInstallerContact();
      },
    });

  useEffect(() => {
    setName(contact.name);
    setElectricalLicenceNumber(contact.electricalLicenceNumber ?? '');
    setCecAccrediationNumber(contact.cecAccrediationNumber ?? '');
    setEmail(contact.email ?? '');
    setPhone(contact.phone ?? '');
  }, [contact]);

  return (
    <Modal heading="Add Contact" onClose={onClose}>
      <form
        onSubmit={(event) => {
          event.preventDefault();
          updateInstallerContact();
        }}
      >
        <div className="grid gap-4 grid-cols-2 mb-4">
          <div className="col-span-2">
            <TextField
              label="Name"
              placeholder="Name"
              value={name}
              onChange={(value) => setName(value)}
            />
          </div>
          <div>
            <TextField
              label="Electrical Licence Number"
              placeholder="Electrical Licence Number"
              value={electricalLicenceNumber}
              onChange={(value) => setElectricalLicenceNumber(value)}
            />
          </div>
          <div>
            <TextField
              label="CEC Accrediation Number"
              placeholder="CEC Accrediation Number"
              value={cecAccrediationNumber}
              onChange={(value) => setCecAccrediationNumber(value)}
            />
          </div>
          <div>
            <TextField
              label="Email Address"
              placeholder="Email Address"
              value={email}
              onChange={(value) => setEmail(value)}
            />
          </div>
          <div>
            <TextField
              label="Phone Number"
              placeholder="Phone Number"
              value={phone}
              onChange={(value) => setPhone(value)}
            />
          </div>
        </div>

        {error && <ErrorMessage error={error} />}

        <Button type="submit" text="Update" loading={loading} />
      </form>
    </Modal>
  );
}
