import React from 'react';
import { Helmet } from 'react-helmet-async';
import Divider from '../../../components/content/Divider';
import ExpandableSection from '../../../components/content/ExpandableSection';
import ContentStream from '../../../components/content/stream/ContentStream';
import ContentStreamEmpty from '../../../components/content/stream/ContentStreamEmpty';
import ContentStreamItem from '../../../components/content/stream/ContentStreamItem';
import LeadCreateActionForm from '../../../components/leads/forms/LeadCreateActionForm';
import { Lead } from '../queries/getLeadQuery';

type Props = {
  readonly lead: Lead;
  readonly onUpdate: () => void;
};

export default function LeadActionsView({ lead, onUpdate }: Props) {
  return (
    <>
      <Helmet>
        <title>Actions | Lead {lead.id} | Doug CRM</title>
      </Helmet>
      <ExpandableSection title={`Actions (${lead.actions.length})`}>
        <ContentStream>
          {lead.actions.length > 0 ? (
            <>
              {lead.actions.map((action) => (
                <ContentStreamItem
                  key={action.id}
                  user={action.agent ?? undefined}
                  date={action.due}
                >
                  <p className="text-sm text-gray-400 font-medium">
                    {action.type.name}
                  </p>
                  {action.content && <p className="mt-2">{action.content}</p>}
                </ContentStreamItem>
              ))}
            </>
          ) : (
            <ContentStreamEmpty text="No actions." />
          )}
        </ContentStream>
      </ExpandableSection>

      <Divider />

      <LeadCreateActionForm
        leadId={lead.id}
        onCreateAction={() => onUpdate()}
      />
    </>
  );
}
