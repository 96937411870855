import { useGetLeadActivitiesQuery } from './queries/getLeadActivitiesQuery';
import Table from '../../../../components/content/table/Table';
import TableHeader from '../../../../components/content/table/TableHeader';
import TableRow from '../../../../components/content/table/TableRow';
import TableHeaderCell from '../../../../components/content/table/TableHeaderCell';
import TableBody from '../../../../components/content/table/TableBody';
import TableCell from '../../../../components/content/table/TableCell';
import { format } from 'date-fns';
import { Helmet } from 'react-helmet-async';
import Markdown from 'react-markdown';

type Props = {
  readonly leadId: string;
};

export function LeadActivityView({ leadId }: Props) {
  const { data, loading } = useGetLeadActivitiesQuery({
    variables: {
      leadId,
    },
  });

  if (!data || loading) {
    return <p>Loading&hellip;</p>;
  }

  return (
    <>
      <Helmet>
        <title>Activity | Lead {leadId} | Doug</title>
      </Helmet>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHeaderCell>Time</TableHeaderCell>
            <TableHeaderCell>Type</TableHeaderCell>
            <TableHeaderCell>Agent</TableHeaderCell>
            <TableHeaderCell>Summary</TableHeaderCell>
          </TableRow>
        </TableHeader>
        <TableBody>
          {data.leadActivities.length > 0 ? (
            data.leadActivities.map((activity) => (
              <TableRow key={activity.id}>
                <TableCell>
                  {format(new Date(activity.timestamp), 'do MMM yyyy HH:mm')}
                </TableCell>
                <TableCell>
                  <code>{activity.type}</code>
                </TableCell>
                <TableCell>{activity.agent?.fullName ?? '-'}</TableCell>
                <TableCell>
                  <Markdown className="whitespace-break-spaces">
                    {activity.summary || '-'}
                  </Markdown>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colspan={4}>No activity</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </>
  );
}
