import {
  faBagShopping,
  faCarBattery,
  faChartLine,
  faList,
  faParachuteBox,
  faToolbox,
} from '@fortawesome/pro-light-svg-icons';
import { ReactNode, useMemo } from 'react';
import Toolbar from '../../../components/layout/ToolbarPage/components/Toolbar';
import ToolbarItem from '../../../components/layout/ToolbarPage/components/Toolbar/components/ToolbarItem';
import { useCapabilities } from '../../../hooks/useCapabilities';

type Props = {
  readonly children?: ReactNode;
};

export default function InventoryToolbar({ children }: Props) {
  const capabilities = useCapabilities();

  const nav = useMemo(() => {
    return capabilities.canViewOrders
      ? [
          { text: 'Overview', href: '/inventory', icon: faChartLine },
          {
            text: 'Suppliers',
            href: '/inventory/suppliers',
            icon: faParachuteBox,
          },
          {
            text: 'Installers',
            href: '/inventory/installers',
            icon: faToolbox,
          },
          {
            text: 'Product Categories',
            href: '/inventory/product-categories',
            icon: faList,
          },
          {
            text: 'Products',
            href: '/inventory/products',
            icon: faCarBattery,
          },
          {
            text: 'Orders',
            href: '/inventory/orders',
            icon: faBagShopping,
          },
        ]
      : [
          { text: 'Overview', href: '/inventory', icon: faChartLine },
          {
            text: 'Installers',
            href: '/inventory/installers',
            icon: faToolbox,
          },
        ];
  }, [capabilities]);

  return (
    <Toolbar>
      <ToolbarItem title text="Inventory" />
      <ToolbarItem text={'Inventory'} nav={nav} />
      {children}
    </Toolbar>
  );
}
