import { AnimatePresence } from 'framer-motion';
import { useState } from 'react';
import CapabilityGuard from '../../../../components/CapabilityGuard';
import Container from '../../../../components/content/Container';
import Table from '../../../../components/content/table/Table';
import TableBody from '../../../../components/content/table/TableBody';
import TableCell from '../../../../components/content/table/TableCell';
import TableHeader from '../../../../components/content/table/TableHeader';
import TableHeaderCell from '../../../../components/content/table/TableHeaderCell';
import TableRow from '../../../../components/content/table/TableRow';
import Button from '../../../../components/controls/Button';
import ButtonLink from '../../../../components/controls/ButtonLink';
import Heading from '../../../../components/layout/ToolbarPage/components/Heading';
import CreateProductCategoryTray from '../CreateProductCategoryTray';
import { useGetProductCategoriesQuery } from './queries/getProductCategoriesQuery';

export function ProductCategoriesView() {
  const [showCreateTray, setShowCreateTray] = useState(false);

  const { data } = useGetProductCategoriesQuery();

  return (
    <CapabilityGuard requirements={['canViewOrders']}>
      <Container>
        <Heading
          text="Product Categories"
          actions={
            <>
              <Button
                primary
                text="Create"
                onClick={() => setShowCreateTray(true)}
              />
            </>
          }
        />

        <Table>
          <TableHeader>
            <TableRow>
              <TableHeaderCell>Name</TableHeaderCell>
              <TableHeaderCell>Products</TableHeaderCell>
              <TableHeaderCell />
            </TableRow>
          </TableHeader>
          <TableBody>
            {data?.productCategories.length === 0 ? (
              <TableRow>
                <TableCell colspan={3}>No product categories.</TableCell>
              </TableRow>
            ) : (
              (data?.productCategories ?? []).map((category) => (
                <TableRow key={category.id}>
                  <TableCell>{category.name || '-'}</TableCell>
                  <TableCell>{category.totalProducts}</TableCell>
                  <TableCell rightAlign>
                    <ButtonLink
                      text="View"
                      to={`/inventory/product-categories/${category.id}`}
                    />
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </Container>

      <AnimatePresence>
        {showCreateTray && (
          <CreateProductCategoryTray onClose={() => setShowCreateTray(false)} />
        )}
      </AnimatePresence>
    </CapabilityGuard>
  );
}
