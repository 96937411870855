import { Helmet } from 'react-helmet-async';
import ExpandableSection from '../../../components/content/ExpandableSection';
import Notes from '../../../components/leads/Notes';

type Props = {
  readonly leadId: string;
  readonly onUpdate: () => void;
};

export default function LeadNotesView({ leadId, onUpdate }: Props) {
  return (
    <>
      <Helmet>
        <title>Notes | Lead {leadId} | Doug CRM</title>
      </Helmet>
      <ExpandableSection title="Notes">
        <Notes leadId={leadId} onNoteAdded={onUpdate} />
      </ExpandableSection>
    </>
  );
}
