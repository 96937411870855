import { useMutation } from '@apollo/client';
import Button from '../../../../components/controls/Button';
import Modal from '../../../../components/overlay/Modal';
import { Response as GetUsersResponse } from '../../queries/getUsersQuery';
import {
  MUTATION as RESET_USER_2FA_MUTATION,
  Response as ResetUser2FAResponse,
  Variables as ResetUser2FAVariables,
} from './mutations/resetUser2FAMutation';

type Props = {
  readonly user: GetUsersResponse['users'][number];
  readonly onClose: () => void;
};

export function Reset2FAModal({ user, onClose }: Props) {
  const [mutate, { loading }] = useMutation<
    ResetUser2FAResponse,
    ResetUser2FAVariables
  >(RESET_USER_2FA_MUTATION, {
    variables: {
      userId: user.id,
    },
    onCompleted: () => onClose(),
  });

  return (
    <Modal onClose={onClose} heading="Reset 2FA">
      <div className="mb-4">
        <p>
          Reset user's two-factor state? They will need to go through the setup
          process next time they attempt to login.
        </p>
      </div>

      <Button
        text="Reset 2FA"
        primary
        loading={loading}
        onClick={() => mutate()}
      />
    </Modal>
  );
}
