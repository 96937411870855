import Box from '../../../../../components/content/Box';
import { Link, To } from 'react-router-dom';

type Props = {
  readonly heading: string;
  readonly total: number;
  readonly link: To;
};

export default function Tile({ heading, total, link }: Props) {
  return (
    <Box>
      <Link to={link}>
        <div className="text-4xl mb-4">{total.toLocaleString()}</div>
        <h4 className="text-gray-600">{heading}</h4>
      </Link>
    </Box>
  );
}
