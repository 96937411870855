import Box from '../../../../../components/content/Box';
import MetaBlock from '../../../../../components/content/MetaBlock';
import { Response } from '../queries/getSupplierQuery';
import filters, { DATETIME_FORMAT_MEDIUM } from '../../../../../filters';

type Props = {
  readonly supplier: Response['supplier'];
};

export default function SupplierMeta({ supplier }: Props) {
  return (
    <aside className="lg:w-96">
      <Box>
        <MetaBlock label="Created">
          {filters.formatDate(supplier.created, DATETIME_FORMAT_MEDIUM)}
        </MetaBlock>
        <MetaBlock label="Updated">
          {filters.formatDate(supplier.updated, DATETIME_FORMAT_MEDIUM)}
        </MetaBlock>
      </Box>
    </aside>
  );
}
