import { gql } from '@apollo/client';

export const QUERY = gql`
  query {
    leadSources {
      id
      name
      shareCode
      archived
    }
  }
`;

export type Response = {
  readonly leadSources: readonly {
    readonly id: number;
    readonly name: string;
    readonly shareCode: string | null;
    readonly archived: boolean;
  }[];
};
