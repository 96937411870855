import { gql, MutationHookOptions, useMutation } from '@apollo/client';

const MUTATION = gql`
  mutation sendCombinedOrderToSupplier($id: ID!) {
    sendCombinedOrderToSupplier(id: $id) {
      id
    }
  }
`;

export type Variables = {
  readonly id: string;
};

export type Response = {
  readonly sendCombinedOrderToSupplier: {
    readonly id: string;
  };
};

export const useSendCombinedOrderToSupplierMutation = (
  options: MutationHookOptions<Response, Variables>,
) => useMutation<Response, Variables>(MUTATION, options);
