import { ReactNode, useState } from 'react';
import Modal from '../overlay/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/pro-light-svg-icons';

type Props = {
  readonly label: string;
  readonly children: ReactNode;
  readonly hint?: ReactNode;
};

export default function MetaBlock({ label, hint, children }: Props) {
  const [showHint, setShowHint] = useState(false);

  return (
    <>
      <div className="mb-4 last:mb-0">
        <div className="text-sm text-gray-400 mb-1">
          {label}{' '}
          {hint && (
            <button onClick={() => setShowHint(true)}>
              <FontAwesomeIcon icon={faQuestionCircle} />
            </button>
          )}
        </div>
        <div>{children}</div>
      </div>
      {showHint && (
        <Modal heading={label} onClose={() => setShowHint(false)}>
          {hint}
        </Modal>
      )}
    </>
  );
}
