import { gql, MutationHookOptions, useMutation } from '@apollo/client';

const MUTATION = gql`
  mutation ($id: Float!) {
    toggleFinanceArchived(id: $id) {
      id
    }
  }
`;

export type Variables = {
  readonly id: number;
};

export type Response = {
  readonly toggleFinanceArchived: {
    readonly id: number;
  };
};

export const useToggleFinanceArchivedMutation = (
  options: MutationHookOptions<Response, Variables>,
) => useMutation<Response, Variables>(MUTATION, options);
