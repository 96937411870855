import { gql, MutationHookOptions } from '@apollo/client';
import { useModifiedMutation } from '../../../../../../../apollo/hooks/useModifiedMutation';

const MUTATION = gql`
  mutation cancelOrderReturn($id: ID!) {
    cancelOrderReturn(id: $id) {
      id
    }
  }
`;

export type Variables = {
  readonly id: string;
};

export type Response = {
  readonly cancelOrderReturn: {
    readonly id: string;
  };
};

export const useCancelOrderReturnMutation = (
  options: MutationHookOptions<Response, Variables>,
) => useModifiedMutation(MUTATION, options);
