import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Box from '../../../../components/content/Box';
import Container from '../../../../components/content/Container';
import ExpandableSection from '../../../../components/content/ExpandableSection';
import SplitLayout from '../../../../components/content/SplitLayout';
import Button from '../../../../components/controls/Button';
import TextField from '../../../../components/controls/TextField';
import ToolbarPage from '../../../../components/layout/ToolbarPage';
import Heading from '../../../../components/layout/ToolbarPage/components/Heading';
import ToolbarItem from '../../../../components/layout/ToolbarPage/components/Toolbar/components/ToolbarItem';
import InventoryToolbar from '../InventoryToolbar';
import ProductCategoryMeta from './components/ProductCategoryMeta';
import { useUpdateProductCategoryMutation } from './mutations/updateProductCategoryMutation';
import { useGetProductCategoryQuery } from './queries/getProductCategoryQuery';

type Params = {
  readonly id: string;
};

type Props = {};

export function ProductCategoryView({}: Props) {
  const { id } = useParams<Params>();

  const [name, setName] = useState('');

  const { data, refetch } = useGetProductCategoryQuery({
    variables: { id: id! },
  });

  const [update, { loading }] = useUpdateProductCategoryMutation({
    variables: {
      id: id!,
      input: {
        name,
      },
    },
    onCompleted: () => refetch(),
  });

  useEffect(() => {
    if (data) {
      setName(data.productCategory.name);
    }
  }, [data]);

  if (!data) {
    return null;
  }

  return (
    <ToolbarPage
      requiredCapabilities={['canViewOrders']}
      toolbar={
        <InventoryToolbar>
          <ToolbarItem text={data.productCategory.name} />
        </InventoryToolbar>
      }
    >
      <Container>
        <Heading
          text={data.productCategory.name}
          actions={
            <Button
              loading={loading}
              primary
              text="Save"
              onClick={() => update()}
            />
          }
        />

        <SplitLayout
          sidebar={
            <ProductCategoryMeta productCategory={data.productCategory} />
          }
        >
          <Box>
            <ExpandableSection title="Details">
              <div className="grid gap-5 md:grid-cols-2">
                <TextField
                  value={name}
                  onChange={setName}
                  label="Name"
                  placeholder="Name"
                />
              </div>
            </ExpandableSection>
          </Box>
        </SplitLayout>
      </Container>
    </ToolbarPage>
  );
}
