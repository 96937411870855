import { useState } from 'react';
import Button from '../../../controls/Button';
import TextField from '../../../controls/TextField';
import { useCreateNoteMutation } from '../mutations/createNoteMutation';

type Props = {
  readonly leadId: string;
  readonly context?: string | null;
  readonly onSubmit: () => void;
};

export default function Form({ leadId, context, onSubmit }: Props) {
  const [text, setText] = useState('');

  const [createNote, { loading }] = useCreateNoteMutation({
    variables: {
      leadId: parseInt(leadId.toString()),
      content: text,
      context: context ?? undefined,
    },
    onCompleted: () => {
      setText('');
      onSubmit();
    },
    onError: (error) => console.error(error),
  });

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        createNote();
      }}
    >
      <div className="mb-2">
        <TextField
          type="box"
          placeholder="Add notes..."
          value={text}
          rows={2}
          onChange={(value) => setText(value)}
        />
      </div>

      <Button
        size="sm"
        disabled={text.length === 0}
        type="submit"
        text="Add Note"
        loading={loading}
      />
    </form>
  );
}
