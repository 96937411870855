import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactNode } from 'react';
import clsx from 'clsx';
import { faCheck } from '@fortawesome/pro-light-svg-icons';

type Props = {
  readonly children?: ReactNode;
  readonly size?: 'sm' | 'md' | 'lg';
  readonly checked?: boolean;
  readonly disabled?: boolean;
  readonly onChange: (checked: boolean) => void;
};

const theme = (size: Props['size'], checked: boolean, disabled?: boolean) =>
  clsx('text-center leading-none focus:outline-none border shadow-sm', {
    'w-5 h-5 text-xs rounded-md': size === 'sm',
    'w-6 h-6 text-xs rounded-lg': size === 'md',
    'w-9 h-9 rounded-lg': size === 'lg',
    'bg-green-400 border-green-500 text-white': checked && !disabled,
    'border-gray-400 text-gray-200': !checked && !disabled,
    'bg-gray-100 border-gray-400 cursor-not-allowed': disabled,
    'text-gray-100': disabled && !checked,
    'text-gray-400 ': disabled && checked,
  });

const labelTheme = (size: Props['size']) =>
  clsx('flex-grow', {
    'text-sm pl-2': size === 'md' || size === 'sm',
    'pl-4': size === 'lg',
  });

export default function CheckboxField({
  size = 'md',
  children,
  checked,
  disabled,
  onChange,
}: Props) {
  const title = () => {
    return checked ? 'Checked' : 'Unchecked';
  };

  return (
    <div className="flex items-center">
      <div className="flex-shrink">
        <button
          title={title()}
          disabled={disabled}
          onClick={(event) => {
            event.preventDefault();
            onChange(!checked);
          }}
          className={theme(size, checked ?? false, disabled)}
        >
          <FontAwesomeIcon icon={faCheck} />
        </button>
      </div>

      <div className={labelTheme(size)}>{children}</div>
    </div>
  );
}
