import { gql, MutationHookOptions, useMutation } from '@apollo/client';

const MUTATION = gql`
  mutation updateCombinedOrder($id: ID!, $input: UpdateCombinedOrderInput!) {
    updateCombinedOrder(id: $id, input: $input) {
      id
    }
  }
`;

export type Variables = {
  readonly id: string;
  readonly input: {
    readonly pickup?: boolean;
    readonly pickupDate?: string;
  };
};

export type Response = {
  readonly updateCombinedOrder: {
    readonly id: string;
  };
};

export const useUpdateCombinedOrderMutation = (
  options: MutationHookOptions<Response, Variables>,
) => useMutation<Response, Variables>(MUTATION, options);
