import { gql, QueryHookOptions, useQuery } from '@apollo/client';

export const QUERY = gql`
  query getServiceJob($id: ID!) {
    serviceJob(id: $id) {
      id
      created
      updated
      title
      description
      priority
      status {
        id
        handle
        name
      }
      customer {
        id
        fullName
        email
        mobilePhone
      }
      notes {
        id
        created
        agent {
          id
          fullName
        }
        content
      }
      documents {
        id
        created
        fileUpload {
          name
          description
          url
          filename
          extension
          agent {
            fullName
          }
        }
      }
    }
  }
`;

export type Variables = {
  readonly id: string;
};

export type Response = {
  readonly serviceJob: {
    readonly id: string;
    readonly created: string;
    readonly updated: string;
    readonly title: string;
    readonly description: string | null;
    readonly priority: number;
    readonly status: {
      readonly id: string;
      readonly handle: 'open' | 'closed';
      readonly name: string;
    };
    readonly customer: {
      readonly id: string;
      readonly fullName: string;
      readonly email: string;
      readonly mobilePhone: string | null;
    };
    readonly notes: readonly {
      readonly id: string;
      readonly created: string;
      readonly agent: {
        readonly id: string;
        readonly fullName: string;
      };
      readonly content: string;
    }[];
    readonly documents: readonly {
      readonly id: string;
      readonly created: string;
      readonly fileUpload: {
        readonly name: string;
        readonly description: string;
        readonly url: string;
        readonly filename: string;
        readonly extension: string;
        readonly agent: {
          readonly fullName: string;
        };
      };
    }[];
  };
};

export const useGetServiceJobQuery = (
  options?: QueryHookOptions<Response, Variables>,
) => useQuery<Response, Variables>(QUERY, options);
