import { MouseEvent, ReactNode } from 'react';
import PortalMount from './PortalMount';
import clsx from 'clsx';

type Props = {
  readonly children?: ReactNode;
  readonly heading?: string;
  readonly large?: boolean;
  readonly onClose?: () => void;
};

export default function Modal({ heading, children, large, onClose }: Props) {
  const handleMouseUp = (event: MouseEvent) => {
    if (event.target === event.currentTarget) {
      onClose && onClose();
    }
  };

  return (
    <PortalMount>
      <div
        className="fixed top-0 left-0 bottom-0 right-0 bg-black bg-opacity-50 backdrop-blur-sm z-50 overflow-auto flex px-2"
        onMouseUp={handleMouseUp}
      >
        <div
          className={clsx('relative shadow-2xl w-full m-auto flex-col', {
            'max-w-4xl': large,
            'max-w-lg': !large,
          })}
        >
          {heading && (
            <div className="p-4 bg-secondary rounded-t-md text-white">
              <h5 className="font-medium">{heading}</h5>
            </div>
          )}
          <div
            className={clsx('p-4 bg-white', {
              'rounded-md': !heading,
              'rounded-b-md': heading,
            })}
          >
            {children}
          </div>
        </div>
      </div>
    </PortalMount>
  );
}
