import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import { OrderStatusHandle } from '../globals';

const QUERY = gql`
  query getOrderReturnStatuses {
    orderReturnStatuses {
      id
      handle
      name
    }
  }
`;

export type Response = {
  readonly orderReturnStatuses: readonly {
    readonly id: string;
    readonly handle: OrderStatusHandle;
    readonly name: string;
  }[];
};

export type OrderStatus = Response['orderReturnStatuses'][number];

export const useGetOrderReturnStatuses = (
  options?: QueryHookOptions<Response>,
) => {
  const { data } = useQuery<Response>(QUERY, {
    fetchPolicy: 'cache-first',
    ...options,
  });

  return data?.orderReturnStatuses ?? [];
};
