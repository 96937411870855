import { gql, MutationHookOptions, useMutation } from '@apollo/client';

const MUTATION = gql`
  mutation sendToSupplier($id: ID!, $input: SendOrderToSupplierInput!) {
    sendToSupplier(id: $id, input: $input) {
      id
    }
  }
`;

export type Variables = {
  readonly id: string;
  readonly input: {
    readonly message: string;
  };
};

export type Response = {
  readonly sendToSupplier: {
    readonly id: string;
  };
};

export const useSendToSupplierMutation = (
  options?: MutationHookOptions<Response, Variables>,
) => useMutation<Response, Variables>(MUTATION, options);
