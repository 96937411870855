import { gql, MutationHookOptions, useMutation } from '@apollo/client';

const MUTATION = gql`
  mutation updateProduct($id: ID!, $input: UpdateProductInput!) {
    updateProduct(id: $id, input: $input) {
      id
    }
  }
`;

export type Variables = {
  readonly id: string;
  readonly input: {
    readonly name: string;
    readonly sku: string;
    readonly archived: boolean;
    readonly trackStock: boolean;
    readonly packSize: number;
    readonly categoryId: number | null;
    readonly pylonId: string | null;
  };
};

export type Response = {
  readonly updateProduct: {
    readonly id: string;
  };
};

export const useUpdateProductMutation = (
  options: MutationHookOptions<Response, Variables>,
) => useMutation<Response, Variables>(MUTATION, options);
