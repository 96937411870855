import { useMemo, useState } from 'react';
import Button from '../../../../components/controls/Button';
import DropdownField from '../../../../components/controls/DropdownField';
import TextField from '../../../../components/controls/TextField';
import Tray from '../../../../components/layout/Tray';
import { useGetInstallers } from '../../../../hooks/useGetInstallers';
import { useCreateStockTransferMutation } from './mutations/createStockTransferMutation';

type Props = {
  readonly productId: string;
  readonly originInstallerId: string;
  readonly onClose: () => void;
  readonly onTransfer: () => void;
};

export function TransferStockTray({
  productId,
  originInstallerId,
  onClose,
  onTransfer,
}: Props) {
  const installers = useGetInstallers();

  const [quantity, setQuantity] = useState('');
  const [targetInstallerId, setTargetInstallerId] = useState<string>();

  const canSubmit = useMemo(() => {
    return parseInt(quantity) > 0 && !!targetInstallerId;
  }, [quantity, targetInstallerId]);

  const [createStockTransfer, { loading }] = useCreateStockTransferMutation({
    onCompleted: () => onTransfer(),
  });

  return (
    <Tray
      heading="Transfer Stock"
      onClose={onClose}
      actions={
        <Button
          primary
          text="Transfer"
          disabled={!canSubmit}
          loading={loading}
          onClick={() =>
            createStockTransfer({
              variables: {
                input: {
                  productId,
                  originInstallerId,
                  targetInstallerId: targetInstallerId!,
                  quantity: parseInt(quantity),
                },
              },
            })
          }
        />
      }
    >
      <div>
        <h4 className="px-5 pt-5 font-bold text-gray-600">Details</h4>
        <div className="grid p-5 gap-5">
          <div>
            <TextField
              value={quantity}
              onChange={setQuantity}
              label="Quantity"
              placeholder="Quantity"
            />
          </div>
          <div>
            <DropdownField
              value={targetInstallerId}
              onChange={setTargetInstallerId}
              label="Target Installer"
              placeholder="Select recipient..."
              options={installers
                .filter(
                  (installer) =>
                    installer.id.toString() !== originInstallerId.toString(),
                )
                .map((installer) => ({
                  text: installer.name,
                  value: installer.id.toString(),
                }))}
            />
          </div>
        </div>
      </div>
    </Tray>
  );
}
