import { useState } from 'react';
import Box from '../../../../components/content/Box';
import Divider from '../../../../components/content/Divider';
import Button from '../../../../components/controls/Button';
import TextField from '../../../../components/controls/TextField';
import { useSetSettingsMutation } from './mutations/setSettingsMutation';
import { useGetSettingsQuery } from './queries/getSettingsQuery';

export function SettingsView() {
  const [maximumAttemptCount, setMaximumAttemptCount] = useState('');
  const [tentativeClaimTimer, setTentativeClaimTimer] = useState('');
  const [failureCooldown, setFailureCooldown] = useState('');
  const [welcomeMessage, setWelcomeMessage] = useState('');
  const [defaultTarget, setDefaultTarget] = useState('');

  const { loading, refetch } = useGetSettingsQuery({
    onCompleted: (data) => {
      setMaximumAttemptCount(data.maximumAttemptCount?.toString() ?? '');
      setTentativeClaimTimer(data.tentativeClaimTimer?.toString() ?? '');
      setFailureCooldown(data.failureCooldown?.toString() ?? '');
      setWelcomeMessage(data.welcomeMessage?.toString() ?? '');
      setDefaultTarget(data.defaultTarget?.toString() ?? '');
    },
  });

  const [setSettings, { loading: updating }] = useSetSettingsMutation({
    onError: (error) => console.error(error),
    onCompleted: () => refetch(),
  });

  return (
    <Box>
      {loading ? (
        <p>Loading&hellip;</p>
      ) : (
        <form
          onSubmit={(event) => {
            event.preventDefault();
            setSettings({
              variables: {
                maximumAttemptCount: parseInt(maximumAttemptCount),
                tentativeClaimTimer: parseInt(tentativeClaimTimer),
                failureCooldown: parseInt(failureCooldown),
                welcomeMessage,
                defaultTarget: parseInt(defaultTarget),
              },
            });
          }}
        >
          <div className="grid gap-4">
            <div>
              <TextField
                type="text"
                label="Maximum Attempt Count"
                hint={`The amount of non-answers for a lead before it moves to the "dead" status and stops appearing in the pool of claimable leads.`}
                placeholder="5"
                value={maximumAttemptCount}
                onChange={(value) => setMaximumAttemptCount(value)}
              />
            </div>

            <div>
              <TextField
                type="text"
                label="Tentative Claim Timer"
                hint={`The amount of time (in seconds) that an agent will hold a tentative claim on a lead after appearing in the lead presenter.`}
                placeholder="600"
                value={tentativeClaimTimer}
                onChange={(value) => setTentativeClaimTimer(value)}
              />
            </div>

            <div>
              <TextField
                type="text"
                label="Failure Cooldown"
                hint={`The amount of time (in seconds) that a lead will not appear in the lead presenter after a failed contact attempt.`}
                placeholder="600"
                value={failureCooldown}
                onChange={(value) => setFailureCooldown(value)}
              />
            </div>

            <div>
              <TextField
                type="box"
                label="Default Welcome Message"
                hint={`The default welcome message to show agents on the dashboard.`}
                placeholder="Have a great day..."
                value={welcomeMessage}
                onChange={setWelcomeMessage}
              />
            </div>

            <div>
              <TextField
                type="text"
                label="Default Target"
                hint={`The default target for agents.`}
                placeholder="100"
                value={defaultTarget}
                onChange={setDefaultTarget}
              />
            </div>
          </div>

          <Divider />

          <Button
            text="Update"
            type="submit"
            primary
            loading={loading || updating}
          />
        </form>
      )}
    </Box>
  );
}
