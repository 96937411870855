import clsx from 'clsx';
import { ReactNode } from 'react';
import CheckboxField from './CheckboxField';

type Props = {
  readonly label?: string;
  readonly value: readonly string[];
  readonly onChange: (value: readonly string[]) => void;
  readonly options: readonly {
    readonly text: ReactNode;
    readonly value: string;
  }[];
  readonly disabled?: boolean;
};

const MultiField = ({ label, value, onChange, options, disabled }: Props) => {
  return (
    <div>
      {label && (
        <label className="text-gray-600 text-sm mb-1 font-medium block">
          {label}
        </label>
      )}
      <div className="space-y-1">
        {options.map((option, index) => {
          const checked = value.includes(option.value);

          return (
            <div className="flex items-center" key={index}>
              <div className="flex-shrink-0">
                <CheckboxField
                  disabled={disabled}
                  checked={checked}
                  onChange={(checked) =>
                    onChange(
                      checked
                        ? value.concat(option.value)
                        : value.filter((item) => item !== option.value),
                    )
                  }
                />
              </div>
              <div
                className={clsx('flex-grow', {
                  'opacity-30': disabled && !checked,
                })}
              >
                {option.text}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default MultiField;
