import Note from './components/Note';
import Form from './components/Form';
import { useGetNotesQuery } from './queries/getNotesQuery';
import { useUser } from '../../../hooks/useUser';

type Props = {
  readonly leadId: string;
  readonly ctx?: string;
  readonly onNoteAdded?: () => void;
};

export function Notes({ leadId, ctx, onNoteAdded }: Props) {
  const user = useUser();

  const { data, loading, refetch } = useGetNotesQuery({
    variables: {
      leadId: parseInt(leadId.toString()),
      context: ctx ?? undefined,
    },
  });

  const canDelete = user?.accessLevel.handle === 'admin';

  return (
    <>
      <div className="mb-2">
        {loading ? (
          <p>Loading&hellip;</p>
        ) : data && data.notes.length > 0 ? (
          <div>
            {data.notes.map((note) => (
              <Note
                id={note.id}
                key={note.id}
                agentFullName={note.agent?.fullName}
                created={note.created}
                canDelete={canDelete}
                onDelete={() => refetch()}
              >
                {note.content}
              </Note>
            ))}
          </div>
        ) : (
          <p>No notes.</p>
        )}
      </div>

      <Form
        leadId={leadId}
        context={ctx}
        onSubmit={async () => {
          await refetch();
          onNoteAdded && onNoteAdded();
        }}
      />
    </>
  );
}
