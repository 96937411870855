import { UserRoleHandle, useUser } from './useUser';

export type Capabilities = {
  readonly canViewInventory: boolean;
  readonly canViewOrders: boolean;
  readonly canViewInstallers: boolean;
  readonly canViewUsers: boolean;
  readonly canViewServiceJobs: boolean;
  readonly canViewTools: boolean;
};

export function useCapabilities(): Capabilities {
  const user = useUser();
  const accessLevel = user?.accessLevel.handle;

  if (!accessLevel) {
    return {
      canViewInventory: false,
      canViewOrders: false,
      canViewInstallers: false,
      canViewUsers: false,
      canViewServiceJobs: false,
      canViewTools: false,
    };
  }

  const is = (...roles: readonly UserRoleHandle[]) =>
    roles.includes(accessLevel);

  return {
    canViewInventory: is('admin', 'finance', 'operations', 'operationsAdmin'),
    canViewOrders: is('admin', 'finance', 'operationsAdmin'),
    canViewInstallers: is('admin', 'finance', 'operations', 'operationsAdmin'),
    canViewUsers: is('admin'),
    canViewServiceJobs: is('admin', 'operations', 'operationsAdmin'),
    canViewTools: is('admin', 'operations', 'operationsAdmin'),
  };
}
