import { gql, MutationHookOptions, useMutation } from '@apollo/client';

const MUTATION = gql`
  mutation deleteLead($id: ID!) {
    deleteLead(leadId: $id) {
      id
    }
  }
`;

export type Variables = {
  readonly id: string;
};

export type Response = {
  readonly deleteLead: {
    readonly id: string;
  };
};

export const useDeleteLeadMutation = (
  options: MutationHookOptions<Response, Variables>,
) => useMutation<Response, Variables>(MUTATION, options);
