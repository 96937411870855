import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import {
  Address,
  LeadDocument,
  OrderStatusHandle,
  ServiceJob,
} from '../../../globals';
import { LeadStatusHandle } from '../../../hooks/useGetLeadStatuses';

const QUERY = gql`
  query ($id: ID!) {
    lead(id: $id) {
      id
      created
      updated
      deleted
      source {
        id
        name
      }
      status {
        id
        handle
        name
      }
      lineItems {
        id
        quantity
        allocated
        fromStockOnHand
        product {
          id
          name
          sku
          category {
            id
            name
          }
        }
      }
      orders {
        id
        orderNumber
        created
        sentToSupplierAt
        lineItemsCount
        supplier {
          id
          name
        }
        status {
          name
          handle
        }
        lineItems {
          id
          product {
            id
          }
        }
      }
      agent {
        id
        firstName
        lastName
        email
        accessLevel {
          handle
          name
        }
      }
      customer {
        id
        firstName
        middleName
        lastName
        email
        mobilePhone
        homePhone
        workPhone
        leads {
          id
          status {
            id
            name
            handle
          }
          updated
        }
        serviceJobs {
          id
          created
          updated
          title
          description
          status {
            id
            handle
            name
          }
        }
      }
      address {
        line1
        line2
        suburb
        connectionType {
          id
        }
        state {
          id
          abbreviation
          name
        }
        roofType {
          id
        }
        countOfArrays
        storeys
        postcode
        existingSolar
        wifiStrength
      }
      actions {
        id
        due
        type {
          id
          name
        }
        content
        agent {
          firstName
          lastName
          fullName
        }
      }
      documents {
        id
        created
        category
        context
        fileUpload {
          url
          name
          description
          filename
          extension
          agent {
            fullName
          }
        }
      }
      finance {
        id
        name
      }
      energyBill
      systemBaseCost
      systemCost
      systemSell
      systemSellFinance
      systemSize
      batterySize
      customerPrice
      revenue
      quoted
      signoff
      energyRetailer {
        id
        name
      }
      retailer {
        id
        name
      }
      distributor {
        id
        name
      }
      nmi
      meterNumber
      poleNumber
      expectedInstallDate
      installDate
      dealDate
      invoiceNumber
      agentCommissionTier {
        id
        value
      }
      agentCommissionPayable
      quoted
      totalInvoice
      depositPaid
      customerPaid
      stcPaymentReceived
      pylonDesignId
      panelCost
      installCost
      racking
      inverter1
      inverter2
      battery1
      battery2
      compliance
      travel
      extras
      tehoMargin
      financeRate
      financeCost
      gst
      rebates
      stcCount
      stcUnitCost
      stcTotalCost
      subtotal
      totalCustomerPrice
      notesCount
      checklist {
        id
        signedProposal
        depositPaid
        depositFullFinance
        financeSubmitted
        financeApproved
        ptcSubmitted
        ptcApproved
        stockOrdered
        stcAllocated
        sitePlanPrinted
        sitePlanSubmitted
        userManualSent
        spvSent
        meteringSubmitted
        installerDocuments
      }
      installerContact {
        id
        name
        installer {
          name
          itemsOnHand {
            quantity
            product {
              id
            }
          }
        }
      }
      ignoredDocuments
      allDocumentsReceived
      pylonDesign {
        systemSize
        batterySize
      }
      latestStatusChange {
        id
        timestamp
        status {
          id
          handle
          name
        }
        agent {
          id
          fullName
          email
        }
      }
    }
  }
`;

export type Variables = {
  readonly id: number;
};

export type Response = {
  readonly lead: {
    readonly id: string;
    readonly created: string;
    readonly updated: string;
    readonly deleted: string | null;
    readonly source: {
      readonly id: number;
      readonly name: string;
    };
    readonly customer: {
      readonly id: number;
      readonly firstName: string | null;
      readonly middleName: string | null;
      readonly lastName: string | null;
      readonly email: string;
      readonly mobilePhone: string | null;
      readonly workPhone: string | null;
      readonly homePhone: string | null;
      readonly leads: readonly {
        readonly id: number;
        readonly status: {
          readonly id: number;
          readonly name: string;
          readonly handle: LeadStatusHandle;
        };
        readonly updated: string;
      }[];
      readonly serviceJobs: readonly ServiceJob[];
    } | null;
    readonly address: Address | null;
    readonly documents: readonly LeadDocument[];
    readonly status: {
      readonly id: number;
      readonly handle: LeadStatusHandle;
      readonly name: string;
    };
    readonly statusChanges: readonly {
      // todo
    }[];
    readonly lineItems: readonly {
      readonly id: string;
      readonly quantity: number;
      readonly allocated: number;
      readonly fromStockOnHand: number;
      readonly product: {
        readonly id: string;
        readonly name: string;
        readonly sku: string;
        readonly category: {
          readonly id: string;
          readonly name: string;
        } | null;
      };
    }[];
    readonly orders: readonly {
      readonly id: string;
      readonly orderNumber: string;
      readonly created: string;
      readonly sentToSupplierAt: string | null;
      readonly lineItemsCount: number;
      readonly supplier: {
        readonly id: string;
        readonly name: string;
      } | null;
      readonly status: {
        readonly name: string;
        readonly handle: OrderStatusHandle;
      };
      readonly lineItems: readonly {
        readonly id: string;
        readonly product: {
          readonly id: string;
        };
      }[];
    }[];
    readonly actions: readonly {
      readonly id: number;
      readonly created: string;
      readonly due: string;
      readonly type: {
        readonly id: number;
        readonly name: string;
      };
      readonly content: string;
      readonly agent: {
        readonly fullName: string;
      } | null;
    }[];
    readonly agent: {
      readonly id: number;
      readonly fullName: string;
      readonly email: string;
    } | null;
    readonly finance: {
      readonly id: number;
      readonly name: string;
    } | null;
    readonly energyBill: number | null;
    readonly systemBaseCost: number | null;
    readonly systemCost: number | null;
    readonly systemSell: number | null;
    readonly systemSellFinance: number | null;
    readonly systemSize: number | null;
    readonly batterySize: number | null;
    readonly customerPrice: number | null;
    readonly revenue: number | null;
    readonly quoted: boolean;
    readonly signoff: boolean;
    readonly energyRetailer: {
      readonly id: number;
      readonly name: string;
    } | null;
    readonly nmi: string | null;
    readonly meterNumber: string | null;
    readonly poleNumber: string | null;
    readonly expectedInstallDate: string | null;
    readonly installDate: string | null;
    readonly dealDate: string | null;
    readonly invoiceNumber: string | null;
    readonly agentCommissionTier: {
      readonly id: number;
      readonly value: number;
    } | null;
    readonly agentCommissionPayable: number | null;
    readonly retailer: {
      readonly id: number;
      readonly name: string;
    } | null;
    readonly totalInvoice: number | null;
    readonly depositPaid: number | null;
    readonly customerPaid: boolean;
    readonly stcPaymentReceived: boolean | null;
    readonly pylonDesignId: string | null;
    readonly panelCost: number | null;
    readonly installCost: number | null;
    readonly racking: number | null;
    readonly inverter1: number | null;
    readonly inverter2: number | null;
    readonly battery1: number | null;
    readonly battery2: number | null;
    readonly compliance: number | null;
    readonly travel: number | null;
    readonly extras: number | null;
    readonly tehoMargin: number | null;
    readonly financeRate: number | null;
    readonly financeCost: number | null;
    readonly gst: number | null;
    readonly rebates: number | null;
    readonly stcCount: number | null;
    readonly stcUnitCost: number | null;
    readonly stcTotalCost: number | null;
    readonly subtotal: number | null;
    readonly totalCustomerPrice: number | null;
    readonly notesCount: number;
    readonly checklist: {
      readonly signedProposal: boolean | null;
      readonly depositPaid: boolean | null;
      readonly depositFullFinance: boolean | null;
      readonly financeSubmitted: boolean | null;
      readonly financeApproved: boolean | null;
      readonly ptcSubmitted: boolean | null;
      readonly ptcApproved: boolean | null;
      readonly stockOrdered: boolean | null;
      readonly stcAllocated: boolean | null;
      readonly sitePlanPrinted: boolean | null;
      readonly sitePlanSubmitted: boolean | null;
      readonly userManualSent: boolean | null;
      readonly spvSent: boolean | null;
      readonly meteringSubmitted: boolean | null;
      readonly installerDocuments: boolean | null;
    };
    readonly installerContact: {
      readonly id: number;
      readonly name: string;
      readonly installer: {
        readonly name: string;
        readonly itemsOnHand: readonly {
          readonly quantity: number;
          readonly product: {
            readonly id: string;
          };
        }[];
      };
    } | null;
    readonly ignoredDocuments: readonly LeadDocument['category'][];
    readonly allDocumentsReceived: boolean;
    readonly hasOverdueAction?: boolean;
    readonly nextActionDue?: string;
    readonly distributor: {
      readonly id: number;
      readonly name: string;
    } | null;
    readonly pylonDesign: {
      readonly systemSize: number | null;
      readonly batterySize: number | null;
    } | null;
    readonly latestStatusChange: {
      readonly id: number;
      readonly timestamp: string;
      readonly status: {
        readonly id: number;
        readonly handle: string;
        readonly name: string;
      };
      readonly agent: {
        readonly id: number;
        readonly fullName: string | null;
        readonly email: string;
      } | null;
    } | null;
  };
};

export type Lead = Response['lead'];

export const useGetLeadQuery = (
  options: QueryHookOptions<Response, Variables>,
) => useQuery<Response, Variables>(QUERY, options);
