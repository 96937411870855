import { gql, MutationHookOptions, useMutation } from '@apollo/client';

const MUTATION = gql`
  mutation createLeadPool($input: CreateLeadPoolInput!) {
    createLeadPool(input: $input) {
      id
    }
  }
`;

export type Variables = {
  readonly input: {
    readonly name: string;
  };
};

export type Response = {
  readonly createLeadPool: {
    readonly id: number;
  };
};

export const useCreateLeadPoolMutation = (
  options: MutationHookOptions<Response, Variables>,
) => useMutation<Response, Variables>(MUTATION, options);
