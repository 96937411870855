import clsx from 'clsx';
import { ButtonHTMLAttributes } from 'react';

type Props = {
  readonly type?: ButtonHTMLAttributes<HTMLButtonElement>['type'];
  readonly text: string;
  readonly disabled?: boolean;
  readonly loading?: boolean;
  readonly onClick?: () => void;
  readonly primary?: boolean;
  readonly size?: 'sm' | 'md';
  readonly className?: string;
};

export default function Button({
  type,
  text,
  disabled,
  loading,
  primary,
  size = 'md',
  className,
  onClick,
}: Props) {
  return (
    <button
      disabled={disabled || loading}
      className={clsx(
        'border font-medium focus:outline-none rounded-md shadow-sm',
        className,
        disabled || loading
          ? 'border-gray-200 text-gray-300 bg-gray-100 cursor-not-allowed'
          : {
              'border-transparent bg-primary hover:bg-secondary text-white':
                primary,
              'border-gray-300 hover:bg-gray-100': !primary,
            },
        {
          'px-3 py-1 text-sm': size === 'sm',
          'px-4 py-2': size === 'md',
        },
      )}
      type={type}
      onClick={(event) => {
        if (onClick) {
          event.preventDefault();
          onClick();
        }
      }}
    >
      {text}
    </button>
  );
}
