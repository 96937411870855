import { isSameDay } from 'date-fns';
import filters from '../../../filters';
import { Response } from '../queries/getDiaryQuery';
import DiaryActionBlock from './DiaryActionBlock';
import clsx from 'clsx';
import { useMemo } from 'react';

type Props = {
  readonly date: Date;
  readonly actions: Response['diary'];
  readonly onView: (action: Response['diary'][number]) => void;
};

export default function DiaryBlock({ date, actions, onView }: Props) {
  const state = useMemo(() => {
    if (isSameDay(new Date(), date)) return 'SAME_DAY';
    if (actions.length > 0) return 'HAS_ACTIONS';

    return 'DEFAULT';
  }, [date]);

  return (
    <article
      className={clsx('border flex flex-col p-2 rounded-md lg:h-56', {
        'border-primary': state === 'SAME_DAY',
        'border-gray-400': state === 'HAS_ACTIONS',
        'border-gray-300': state === 'DEFAULT',
      })}
    >
      <time
        className={clsx(
          'block flex-shrink rounded-lg text-sm font-mono px-4 py-2',
          {
            'bg-primary text-white': state === 'SAME_DAY',
            'bg-secondary text-white': state === 'HAS_ACTIONS',
            'bg-gray-100 text-gray-400': state === 'DEFAULT',
          },
        )}
      >
        {filters.formatDate(date.toString(), 'EEE')}{' '}
        <strong>{filters.formatDate(date.toString(), 'dd')}</strong>
      </time>

      <div className="mt-1 overflow-y-auto flex-grow">
        {actions.length > 0 ? (
          <div>
            {actions.map((action) => (
              <DiaryActionBlock
                key={action.id}
                action={action}
                onView={() => onView(action)}
              />
            ))}
          </div>
        ) : (
          <p className="text-gray-300 text-sm p-2 leading-10">&ndash;</p>
        )}
      </div>
    </article>
  );
}
