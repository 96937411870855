import {
  Sidebar,
  SidebarOption,
  SidebarSection,
} from '../../../../../components/layout/SidebarLayout';
import { useGetProductCategories } from '../../../../../hooks/useGetProductCategories';

type Props = {
  readonly filter: {
    readonly productCategoryIds: readonly string[];
  };
  readonly onChange: (value: Props['filter']) => void;
};

export default function ProductsSidebar({ filter, onChange }: Props) {
  const productCategories = useGetProductCategories();

  return (
    <Sidebar>
      <SidebarSection title="Categories">
        {productCategories.map((productCategory) => (
          <SidebarOption
            value={filter.productCategoryIds.includes(productCategory.id)}
            onChange={(value) =>
              onChange({
                productCategoryIds: value
                  ? filter.productCategoryIds.concat(productCategory.id)
                  : filter.productCategoryIds.filter(
                      (id) => id !== productCategory.id,
                    ),
              })
            }
            key={productCategory.id}
          >
            {productCategory.name}
          </SidebarOption>
        ))}
      </SidebarSection>
    </Sidebar>
  );
}
