import { gql, QueryHookOptions, useQuery } from '@apollo/client';

const QUERY = gql`
  query {
    tentativeClaims {
      id
      created
      expires
      lead {
        id
        customer {
          firstName
          lastName
          email
          mobilePhone
        }
        address {
          postcode
          timezone
          currentTime
          state {
            abbreviation
          }
        }
        source {
          name
        }
      }
    }
  }
`;

export type Variables = {};

export type Response = {
  readonly tentativeClaims: readonly {
    readonly id: number;
    readonly created: string;
    readonly expires: string;
    readonly lead: {
      readonly id: number;
      readonly customer: {
        readonly firstName: string;
        readonly lastName: string;
        readonly email: string;
        readonly mobilePhone: string;
      };
      readonly address: {
        readonly postcode: string;
        readonly timezone: string;
        readonly currentTime: string;
        readonly state: {
          readonly abbreviation: string;
        } | null;
      };
      readonly source: {
        readonly name: string;
      };
    };
  }[];
};

export const useGetTentativeClaimsQuery = (
  options?: QueryHookOptions<Response, Variables>,
) => useQuery<Response, Variables>(QUERY, options);
