import { useMutation } from '@apollo/client';
import Table from '../../../../../components/content/table/Table';
import TableBody from '../../../../../components/content/table/TableBody';
import TableCell from '../../../../../components/content/table/TableCell';
import TableHeader from '../../../../../components/content/table/TableHeader';
import TableHeaderCell from '../../../../../components/content/table/TableHeaderCell';
import TableRow from '../../../../../components/content/table/TableRow';
import Button from '../../../../../components/controls/Button';
import {
  MUTATION as TOGGLE_INSTALLER_CONTACT_ARCHIVED_MUTATION,
  Response as ToggleInstallerContactArchivedResponse,
} from '../mutations/toggleInstallerContactArchived';
import { Response as GetInstallerResponse } from '../queries/getInstaller';

type Props = {
  readonly installer: GetInstallerResponse['installer'];
  readonly onToggleArchived: () => void;
  readonly onSelectInstallerContact: (
    contact: GetInstallerResponse['installer']['contacts'][0],
  ) => void;
};

export default function InstallerContactsView({
  installer,
  onToggleArchived,
  onSelectInstallerContact,
}: Props) {
  const [toggleArchived, { loading }] =
    useMutation<ToggleInstallerContactArchivedResponse>(
      TOGGLE_INSTALLER_CONTACT_ARCHIVED_MUTATION,
      {
        onCompleted: () => onToggleArchived(),
      },
    );

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHeaderCell>Name</TableHeaderCell>
          <TableHeaderCell>Electrical License #</TableHeaderCell>
          <TableHeaderCell>CEC Accrediation #</TableHeaderCell>
          <TableHeaderCell>Email</TableHeaderCell>
          <TableHeaderCell>Phone</TableHeaderCell>
          <TableHeaderCell rightAlign>Actions</TableHeaderCell>
        </TableRow>
      </TableHeader>
      <TableBody>
        {installer.contacts.length > 0 ? (
          installer.contacts.map((contact) => (
            <TableRow key={contact.id}>
              <TableCell>{contact.name}</TableCell>
              <TableCell>{contact.electricalLicenceNumber ?? '-'}</TableCell>
              <TableCell>{contact.cecAccrediationNumber ?? '-'}</TableCell>
              <TableCell>{contact.email ?? '-'}</TableCell>
              <TableCell>{contact.phone ?? '-'}</TableCell>
              <TableCell rightAlign>
                <Button
                  size="sm"
                  className="mr-2"
                  text={contact.archived ? 'Unarchive' : 'Archive'}
                  onClick={() =>
                    toggleArchived({ variables: { id: contact.id } })
                  }
                  loading={loading}
                />
                <Button
                  text="Edit"
                  size="sm"
                  onClick={() => onSelectInstallerContact(contact)}
                />
              </TableCell>
            </TableRow>
          ))
        ) : (
          <TableRow>
            <TableCell colspan={6}>No contacts&hellip;</TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
}
