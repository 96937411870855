import { useLazyQuery } from '@apollo/client';
import { faCheck } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect } from 'react';
import { QUERY, Response } from './queries/getGoogleAuthenticationUrl';

type Props = {
  readonly hasGoogleCredentials: boolean;
};

export function DiarySyncButton({ hasGoogleCredentials }: Props) {
  const [getGoogleAuthenticationUrl, { data, loading }] =
    useLazyQuery<Response>(QUERY);

  useEffect(() => {
    if (!hasGoogleCredentials) {
      getGoogleAuthenticationUrl();
    }
  }, [hasGoogleCredentials]);

  if (hasGoogleCredentials) {
    return (
      <span className="inline-block text-xs px-3 py-1 bg-green-500 text-green-50 rounded-full">
        Google Calendar Connected <FontAwesomeIcon icon={faCheck} />
      </span>
    );
  }

  if (!data || loading) {
    return null;
  }

  return (
    <a
      className="inline-block px-3 py-1 text-sm bg-primary text-white rounded-full hover:bg-black"
      href={data.googleAuthenticationUrl}
    >
      Link Google Calendar
    </a>
  );
}
