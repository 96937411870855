import { faChevronCircleLeft } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFlags } from 'flagsmith/react';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import Button from '../../../components/controls/Button';
import SubNavigation, {
  SubNavigationTab,
} from '../../../components/navigation/SubNavigation';
import { FLAG_INVENTORY_MANAGEMENT } from '../../../flags';
import { useCapabilities } from '../../../hooks/useCapabilities';
import { useUser } from '../../../hooks/useUser';

type Props = {
  readonly lead: {
    readonly id: string;
    readonly deleted: string | null;
    readonly customer: {
      readonly firstName: string | null;
      readonly lastName: string | null;
    } | null;
    readonly notesCount: number;
    readonly documents: readonly {
      readonly context: string | null;
    }[];
    readonly actions: readonly {
      // don't really need to access anything in this component
    }[];
  };
  readonly relatedCount: number;
  readonly serviceJobCount: number;
  readonly ordersCount: number;
  readonly hasPylonDesign: boolean;
  readonly onDelete: () => void;
};

export default function LeadNavigation({
  lead,
  relatedCount,
  serviceJobCount,
  ordersCount,
  hasPylonDesign,
  onDelete,
}: Props) {
  const user = useUser();
  const capabilities = useCapabilities();
  const flags = useFlags([FLAG_INVENTORY_MANAGEMENT]);

  console.log(capabilities);

  const tabs = useMemo(() => {
    const result: SubNavigationTab[] = [
      { text: 'Sales', url: `/leads/${lead.id}` },
      { text: 'Operations', url: `/leads/${lead.id}/operations` },
      {
        text: 'Finance',
        url: `/leads/${lead.id}/finance`,
        roles: ['admin', 'finance'],
      },
      {
        text: 'Notes',
        url: `/leads/${lead.id}/notes`,
        counter: lead.notesCount,
      },
      {
        text: 'Documents',
        url: `/leads/${lead.id}/documents`,
        counter: lead.documents.filter((document) => document.context === null)
          .length,
      },
      {
        text: 'Actions',
        url: `/leads/${lead.id}/actions`,
        counter: lead.actions.length,
      },
      {
        text: 'Pylon',
        url: `/leads/${lead.id}/pylon`,
        status: hasPylonDesign ? 'done' : undefined,
      },
      {
        text: 'Related',
        url: `/leads/${lead.id}/related`,
        counter: relatedCount,
      },
      {
        text: 'Service Jobs',
        url: `/leads/${lead.id}/service-jobs`,
        counter: serviceJobCount,
      },
    ];

    if (flags.inventory_management.enabled && capabilities.canViewOrders) {
      result.push({
        text: 'Orders',
        url: `/leads/${lead.id}/orders`,
        counter: ordersCount,
      });
    }

    result.push({
      text: 'Activity',
      url: `/leads/${lead.id}/activity`,
      roles: ['admin'],
    });

    return result;
  }, [flags.inventory_management, capabilities]);

  return (
    <SubNavigation
      heading={
        <>
          Lead {lead.id}{' '}
          {lead.customer
            ? ['-', lead.customer.firstName, lead.customer.lastName]
                .join(' ')
                .trim() ?? ''
            : ''}
          {lead.deleted && ' (Deleted)'}
        </>
      }
      tabs={tabs}
      controls={
        <>
          {user?.accessLevel.handle === 'admin' && lead.deleted === null && (
            <Button onClick={() => onDelete()} text="Delete" />
          )}
        </>
      }
    >
      <Link to="/leads" className="text-sm text-gray-500 hover:text-primary">
        <FontAwesomeIcon icon={faChevronCircleLeft} /> Back to My Customers
      </Link>
    </SubNavigation>
  );
}
