import StoreysDropdown from '../../../../../components/leads/dropdowns/StoreysDropdown';
import RoofTypesDropdown from '../../../../../components/leads/dropdowns/RoofTypesDropdown';
import { AdditionalFields } from '../types';
import Button from '../../../../../components/controls/Button';

type Props = {
  readonly loading: boolean;
  readonly value: AdditionalFields;
  readonly onChange: (value: AdditionalFields) => void;
  readonly onSave: () => void;
};

export default function FrontOfHouseAdditionalData({
  loading,
  value,
  onChange,
  onSave,
}: Props) {
  return (
    <div className="flex items-end space-x-5">
      <div className="flex-grow grid gap-5 grid-cols-2">
        <StoreysDropdown
          onChange={(v) => onChange({ ...value, storeys: v })}
          value={value.storeys}
        />
        <RoofTypesDropdown
          onChange={(v) => onChange({ ...value, roofTypeId: v })}
          value={value.roofTypeId}
        />
      </div>
      <div className="">
        <Button loading={loading} text="Save" size="sm" onClick={onSave} />
      </div>
    </div>
  );
}
