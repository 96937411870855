import { gql, MutationHookOptions, useMutation } from '@apollo/client';

const MUTATION = gql`
  mutation createServiceJobDocuments(
    $serviceJobId: ID!
    $fileUploadIds: [ID!]!
  ) {
    createServiceJobDocuments(
      serviceJobId: $serviceJobId
      fileUploadIds: $fileUploadIds
    ) {
      id
    }
  }
`;

export type Variables = {
  readonly serviceJobId: string;
  readonly fileUploadIds: readonly number[];
};

export type Response = {
  readonly createServiceJobDocument: {
    readonly id: string;
  };
};

export const useCreateServiceJobDocumentMutation = (
  options: MutationHookOptions<Response, Variables>,
) => useMutation<Response, Variables>(MUTATION, options);
