import { MutationHookOptions } from '@apollo/client';
import Box from '../../../../components/content/Box';
import Divider from '../../../../components/content/Divider';
import ExpandableSection from '../../../../components/content/ExpandableSection';
import BasicObjectTable from '../BasicObjectTable';
import LeadSources from './components/LeadSources';
import { useCreateCommissionTierMutation } from './mutations/createCommissionTierMutation';
import { useCreateEnergyRetailerMutation } from './mutations/createEnergyRetainerMutation';
import { useCreateFinanceMutation } from './mutations/createFinanceMutation';
import { useCreateLeadTerminationReasonMutation } from './mutations/createLeadTerminationReasonMutation';
import { useToggleCommissionTierArchivedMutation } from './mutations/toggleCommissionTierArchivedMutation';
import { useToggleEnergyRetailerArchivedMutation } from './mutations/toggleEnergyRetailerArchivedMutation';
import { useToggleFinanceArchivedMutation } from './mutations/toggleFinanceArchivedMutation';
import { useToggleLeadTerminationReasonArchivedMutation } from './mutations/toggleLeadTerminationReasonArchivedMutation';
import { useGetObjectsQuery } from './queries/getObjectsQuery';
import { useCreateDistributorMutation } from './mutations/createDistributorMutation';
import { useToggleDistributorArchivedMutation } from './mutations/toggleDistributorArchivedMutation';

export function ObjectsView() {
  const { data, loading, refetch } = useGetObjectsQuery();

  const opts: MutationHookOptions<any, any> = {
    onError: (error) => console.error(error),
    onCompleted: () => refetch(),
  };

  const [createFinance] = useCreateFinanceMutation(opts);
  const [toggleFinanceArchived] = useToggleFinanceArchivedMutation(opts);
  const [createEnergyRetailer] = useCreateEnergyRetailerMutation(opts);
  const [toggleEnergyRetailerArchived] =
    useToggleEnergyRetailerArchivedMutation(opts);
  const [createCommissionTier] = useCreateCommissionTierMutation(opts);
  const [toggleCommissionTierArchived] =
    useToggleCommissionTierArchivedMutation(opts);
  const [createLeadTerminationReason] =
    useCreateLeadTerminationReasonMutation(opts);
  const [toggleLeadTerminationReasonArchived] =
    useToggleLeadTerminationReasonArchivedMutation(opts);
  const [createDistributor] = useCreateDistributorMutation(opts);
  const [toggleDistibutorArchived] = useToggleDistributorArchivedMutation(opts);

  if (!data || loading) {
    return (
      <Box>
        <p>Loading&hellip;</p>
      </Box>
    );
  }

  return (
    <Box>
      <ExpandableSection title="Sources">
        <LeadSources />
      </ExpandableSection>
      <Divider />
      <ExpandableSection title="Finance">
        <BasicObjectTable
          items={data.finances}
          onAddItem={(text) => createFinance({ variables: { name: text } })}
          onToggleArchived={(id, archived) =>
            toggleFinanceArchived({
              variables: { id: parseInt(id.toString()) },
            })
          }
        />
      </ExpandableSection>
      <Divider />
      <ExpandableSection title="Energy Retailers">
        <BasicObjectTable
          items={data.energyRetailers}
          onAddItem={(text) =>
            createEnergyRetailer({ variables: { name: text } })
          }
          onToggleArchived={(id, archived) =>
            toggleEnergyRetailerArchived({
              variables: { id: parseInt(id.toString()) },
            })
          }
        />
      </ExpandableSection>
      <Divider />
      <ExpandableSection title="Commission Tiers">
        <BasicObjectTable
          items={data.commissionTiers.map((item) => ({
            ...item,
            text: (parseFloat(item.text) * 100).toFixed(0) + '%',
          }))}
          onAddItem={(text) =>
            createCommissionTier({
              variables: { value: parseFloat(text) / 100 },
            })
          }
          onToggleArchived={(id, archived) =>
            toggleCommissionTierArchived({
              variables: { id: parseInt(id.toString()) },
            })
          }
        />
      </ExpandableSection>
      <Divider />
      <ExpandableSection title="Lead Termination Reasons">
        <BasicObjectTable
          items={data.leadTerminationReasons}
          onAddItem={(text) =>
            createLeadTerminationReason({
              variables: { name: text },
            })
          }
          onToggleArchived={(id, archived) =>
            toggleLeadTerminationReasonArchived({
              variables: { id: parseInt(id.toString()) },
            })
          }
        />
      </ExpandableSection>
      <Divider />
      <ExpandableSection title="Distributors">
        <BasicObjectTable
          items={data.distributors}
          onAddItem={(text) =>
            createDistributor({
              variables: { name: text },
            })
          }
          onToggleArchived={(id, archived) =>
            toggleDistibutorArchived({
              variables: { id: parseInt(id.toString()) },
            })
          }
        />
      </ExpandableSection>
    </Box>
  );
}
