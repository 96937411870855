import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import { AdditionalFields } from '../types';

const MUTATION = gql`
  mutation updateLeadDocumentAdditionalFields(
    $leadId: ID!
    $data: LeadDocumentAdditionalFieldsInput!
  ) {
    updateLeadDocumentAdditionalFields(leadId: $leadId, data: $data) {
      id
    }
  }
`;

export type Variables = {
  readonly leadId: number | string;
  readonly data: AdditionalFields;
};

export type Response = {
  readonly updateLeadDocumentAdditionalFields: {
    readonly id: string;
  };
};

export const useUpdateLeadDocumentAdditionalFieldsMutation = (
  options: MutationHookOptions<Response, Variables>,
) => useMutation<Response, Variables>(MUTATION, options);
