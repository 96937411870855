import { gql, MutationHookOptions, useMutation } from '@apollo/client';

export const MUTATION = gql`
  mutation ($leadId: ID!) {
    toggleAllDocumentsReceived(leadId: $leadId)
  }
`;

export type Variables = {
  readonly leadId: string;
};

export type Response = {
  readonly toggleAllDocumentsReceived: boolean;
};

export const useToggleAllDocumentsReceivedMutation = (
  options: MutationHookOptions<Response, Variables>,
) => useMutation<Response, Variables>(MUTATION, options);
