import { gql } from '@apollo/client';

export const QUERY = gql`
  query ($id: ID!) {
    pylonDesign(id: $id) {
      id
      pdf
      description
      subtotal
      stcCount
      stcTotal
      total
      systemSize
      batterySize
      modules {
        sku
        type
        description
        quantity
      }
    }
  }
`;

export type Response = {
  readonly pylonDesign: {
    readonly id: string;
    readonly pdf: string | null;
    readonly description: string | null;
    readonly subtotal: string | null;
    readonly stcCount: number | null;
    readonly stcTotal: string | null;
    readonly systemSize: number | null;
    readonly batterySize: number | null;
    readonly total: string | null;
    readonly modules: readonly {
      readonly sku: string;
      readonly type: string;
      readonly description: string;
      readonly quantity: number;
    }[];
  };
};
