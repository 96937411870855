import { gql, MutationHookOptions, useMutation } from '@apollo/client';

const MUTATION = gql`
  mutation updateLeadSalesInfo($id: Float!, $model: UpdateLeadSalesInput!) {
    updateLeadSalesInfo(id: $id, model: $model) {
      id
    }
  }
`;

export type Variables = {
  readonly id: number;
  // @todo: Add explicit type
  readonly model: any;
};

export type Response = {
  readonly updateLeadSalesInfo: {
    readonly id: string;
  };
};

export const useUpdateLeadSalesInfoMutation = (
  options?: MutationHookOptions<Response, Variables>,
) => useMutation<Response, Variables>(MUTATION, options);
