import { gql, useMutation } from '@apollo/client';
import { useState } from 'react';
import ErrorMessage from '../../../../components/content/ErrorMessage';
import Button from '../../../../components/controls/Button';
import TextField from '../../../../components/controls/TextField';
import Modal from '../../../../components/overlay/Modal';
import { Response } from '../../queries/getUsersQuery';

const RESET_PASSWORD = gql`
  mutation resetPassword($id: ID!, $password: String!) {
    updateUserCredentials(id: $id, model: { password: $password }) {
      id
    }
  }
`;

type Props = {
  readonly user: Response['users'][number];
  readonly onClose: () => void;
};

export function ResetPasswordModal({ user, onClose }: Props) {
  const [password, setPassword] = useState('');

  const [resetPassword, { error, loading }] = useMutation(RESET_PASSWORD, {
    onCompleted: () => {
      onClose();
    },
    onError: (error) => console.error(error),
  });

  const handleResetPassword = () => {
    resetPassword({
      variables: {
        id: parseInt(user.id.toString()),
        password,
      },
    });
  };

  return (
    <Modal heading="Reset User Password" onClose={onClose}>
      <form
        onSubmit={(event) => {
          event.preventDefault();
          handleResetPassword();
        }}
      >
        <div className="grid grid-cols-1 gap-4 mb-4">
          <div>
            <TextField
              disabled
              label="Email Address"
              value={user.email}
              type="email"
              placeholder="Email Address"
            />
          </div>
          <div>
            <TextField
              label="Password"
              value={password}
              onChange={(value) => setPassword(value)}
              type="password"
              placeholder="Password"
            />
          </div>
        </div>

        {error && <ErrorMessage error={error} />}

        <Button type="submit" text="Reset Password" primary loading={loading} />
      </form>
    </Modal>
  );
}
